<template lang="pug">
    transition(name="slide")
        div(
            v-click-away="close",
            class="sz-DropdownProfile")
            ul(class="sz-DropdownProfile-options")
                li(
                    @click.stop="goToProfilePage",
                    class="sz-DropdownProfile-option") {{ $t(`navigationBar.profile`) }}
                li(
                    @click.stop="logout",
                    class="sz-DropdownProfile-option") {{ $t(`navigationBar.logout`) }}
                div(class="sz-DropdownProfile-divider")
                div(
                    v-if="userHasAdminRole",
                    class="sz-DropdownProfile-section")
                    div(
                        @click.stop="openAdminSection",
                        class="sz-DropdownProfile-section-header sz-DropdownProfile-section-header-clickable")
                        div(class="sz-DropdownProfile-section-header-text") {{ $t(`navigationBar.admin`) }}
                        SvgIcon(
                            icon="arrow",
                            width="15",
                            height="15",
                            class="sz-DropdownProfile-section-header-expandSection",
                            :class="`sz-DropdownProfile-section-header-expandSection${adminSectionOpen ? `-open`: `-closed`}`")
                    transition(name="slide")
                        div(
                            v-show="adminSectionOpen",
                            class="sz-DropdownProfile-section-options")
                            li(
                                v-if="userHasLBAdminRole"
                                @click.stop="goToDeviceManagement",
                                class="sz-DropdownProfile-section-option") {{ $t(`navigationBar.adminPages.devices`) }}
                            li(
                                v-if="canAccessAssessmentManagement",
                                @click.stop="goToAssessmentManagement",
                                class="sz-DropdownProfile-section-option") {{ $t(`navigationBar.adminPages.assessmentManagement`) }}
                            li(
                                v-if="canAccessLbAdminPage",
                                @click.stop="goToLbManagement",
                                class="sz-DropdownProfile-section-option") {{ $t(`navigationBar.adminPages.lbManagement`) }}
                            li(
                                v-if="canAccessCustomerManagement"
                                @click.stop="goToCustomerManagement",
                                class="sz-DropdownProfile-section-option") {{ $t(`navigationBar.adminPages.customerManagement`) }}
                            li(
                                v-if="canAccessCompanyManagement"
                                @click.stop="goToCompanyManagement",
                                class="sz-DropdownProfile-section-option") {{ $t(`navigationBar.adminPages.companyManagement`) }}
                div(class="sz-DropdownProfile-divider")
                div(class="sz-DropdownProfile-section")
                    div(
                        @click.stop="openCompanySection",
                        class="sz-DropdownProfile-section-header",
                        :class="{'sz-DropdownProfile-section-header-clickable': canAccessCustomerManagement && hasCompanies}")
                        div(class="sz-DropdownProfile-section-header-text") {{ $t(`navigationBar.changeCompany`)}}
                        SvgIcon(
                            v-if="canAccessCustomerManagement && hasCompanies"
                            icon="arrow",
                            width="15",
                            height="15",
                            class="sz-DropdownProfile-section-header-expandSection",
                            :class="`sz-DropdownProfile-section-header-expandSection${companySectionOpen ? `-open`: `-closed`}`")
                    transition(name="slide")
                        div(
                            v-show="companySectionOpen",
                            class="sz-DropdownProfile-section-options")
                            div(class="sz-DropdownProfile-section-scrollable")
                                li(
                                    v-for="company in companyList",
                                    @click.stop="setCurrentCompany(company)"
                                    :class="`sz-DropdownProfile-section-option${isCurrentCompanyActive(company) ? `-selected`: ``}`") {{ company.name }}
                div(class="sz-DropdownProfile-divider")
                div(class="sz-DropdownProfile-section")
                    div(
                        @click.stop="openCustomerSection",
                        class="sz-DropdownProfile-section-header",
                        :class="{'sz-DropdownProfile-section-header-clickable': canAccessLbAdminPage && hasCustomers}")
                        div(class="sz-DropdownProfile-section-header-text") {{ $t(`navigationBar.changeCustomer`)}}
                        SvgIcon(
                            v-if="hasCustomers"
                            icon="arrow",
                            width="15",
                            height="15",
                            class="sz-DropdownProfile-section-header-expandSection",
                            :class="`sz-DropdownProfile-section-header-expandSection${customerSectionOpen ? `-open`: `-closed`}`")
                    transition(name="slide")
                        div(
                            v-show="customerSectionOpen",
                            class="sz-DropdownProfile-section-options")
                            div(class="sz-DropdownProfile-section-scrollable")
                                li(
                                    v-for="customer in customerList",
                                    @click.stop="setCurrentCustomer(customer)",
                                    :class="`sz-DropdownProfile-section-option${isCurrentCustomerActive(customer) ? `-selected`: ``}`") {{ customer.name }}
</template>

<script>
    import { ClickAway } from 'directives/clickAway'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import { userCanAccessPage, userHasAnyRole } from 'helpers/permissionsHelper'

    import SvgIcon from "../Shared/SvgIcon"

    export default {
        name: "DropdownProfile",

        components: {
            SvgIcon,
        },

        directives: {
            ClickAway,
        },

        data () {
            return {
                adminSectionOpen: false,
                companySectionOpen: false,
                customerSectionOpen: false,
                currentSort: 'name',
            }
        },

        computed: {
            ...mapGetters([
                'currentCompany',
                'currentCustomer',
                'userHasAdminRole',
                'userHasLBAdminRole',
                'auth0User',
                'availableCompanies',
                'availableCustomers',
            ]),

            canAccessAssessmentManagement () {
                return userCanAccessPage(this.auth0User, constants.SETTINGS_PAGES.ASSESSMENT)
            },

            canAccessLbAdminPage () {
                return userCanAccessPage(this.auth0User, constants.SETTINGS_PAGES.LB_ADMIN)
            },

            canAccessCustomerManagement () {
                return userCanAccessPage(this.auth0User, constants.SETTINGS_PAGES.CUSTOMER)
            },

            canAccessCompanyManagement () {
                return userCanAccessPage(this.auth0User, constants.SETTINGS_PAGES.COMPANY)
            },

            canAccessCustomerList () {
                return userHasAnyRole(this.auth0User, [constants.ROLES.LB_ADMIN, constants.ROLES.LB_ASSESSOR])
            },

            canAccessCompanyList() {
                return userHasAnyRole(this.auth0User, [constants.ROLES.LB_ADMIN, constants.ROLES.LB_ASSESSOR, constants.ROLES.CUSTOMER_ADMIN, constants.ROLES.CUSTOMER_ASSESSOR])
            },

            companyList () {
                if (this.availableCompanies) {
                    return this.availableCompanies.sort((a,b) => {
                        let direction = 1 //sort ascending order
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * direction
                        if (a[this.currentSort] > b[this.currentSort]) return direction
                        return 0
                    })
                }
                return []
            },

            customerList () {
                if (this.availableCustomers) {
                    return this.availableCustomers.sort((a,b) => {
                        let direction = 1 //sort ascending order
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * direction
                        if (a[this.currentSort] > b[this.currentSort]) return direction
                        return 0
                    })
                }
                return []
            },

            hasCompanies () {
                return this.companyList.length > 1
            },

            hasCustomers () {
                return this.customerList.length > 1
            },

            currentCompanyName () {
                return this.availableCompanies.find(company => company.id === this.currentCompany).name
            },

            currentCustomerName () {
                return this.availableCustomers.find(customer => customer.id === this.currentCustomer).name
            },
        },

        mounted () {
            if (!this.availableCompanies || this.availableCompanies.length === 0) {
                if (this.canAccessCompanyList === true) {
                    this.$store.dispatch('getAvailableCompanies')
                }
            }

            if (!this.availableCustomers || this.availableCustomers.length === 0) {
                if (this.canAccessCustomerList === true) {
                    this.$store.dispatch('getAvailableCustomers')
                }
            }
        },

        methods: {
            goToProfilePage () {
                this.goToManagementDashboard(constants.SETTINGS_PAGES.PROFILE)
            },

            goToDeviceManagement () {
                if (this.$router.currentRoute.name !== 'devices') {
                    this.$router.push({ name: 'devices' })
                }
            },

            goToAssessmentManagement () {
                this.goToManagementDashboard(constants.SETTINGS_PAGES.ASSESSMENT)
            },

            goToLbManagement () {
                this.goToManagementDashboard(constants.SETTINGS_PAGES.LB_ADMIN)
            },

            goToCustomerManagement () {
                this.goToManagementDashboard(constants.SETTINGS_PAGES.CUSTOMER)
            },

            goToCompanyManagement () {
                this.goToManagementDashboard(constants.SETTINGS_PAGES.COMPANY)
            },

            goToManagementDashboard (currentSettingsPage) {
                if (this.$router.currentRoute.name === constants.PAGES.MANAGEMENT) {
                    return currentSettingsPage !== this.$router.currentRoute.params.currentSettingsPage
                        ? this.$router.push({
                            name: constants.PAGES.MANAGEMENT,
                            params: {
                                currentSettingsPage: currentSettingsPage,
                            },
                            replace: true,
                        })
                        : null
                }
                return this.$router.push({
                        name: constants.PAGES.MANAGEMENT,
                        params: {
                            currentSettingsPage: currentSettingsPage,
                        },
                        replace: true,
                })
            },

            logout () {
                this.$auth.logout({
                    returnTo: window.location.origin,
                })
            },

            close () {
                this.$emit('closeDropdown')
            },

            openAdminSection () {
                this.adminSectionOpen = !this.adminSectionOpen
            },

            openCompanySection () {
                if (this.canAccessCustomerManagement) {
                    this.companySectionOpen = !this.companySectionOpen
                }
            },

            openCustomerSection () {
                if (this.canAccessLbAdminPage) {
                    this.customerSectionOpen = !this.customerSectionOpen
                }
            },

            async setCurrentCompany (company) {
                await this.$store.dispatch('updateCurrentCompany', company)
                if (this.$router.currentRoute.name !== 'management' && this.$router.currentRoute.name !== 'home') {
                    this.$router.push({ name: 'home' })
                }
            },

            async setCurrentCustomer (customer) {
                await this.$store.dispatch('updateCurrentCustomer', customer)
                if (this.$router.currentRoute.name !== 'management' && this.$router.currentRoute.name !== 'home') {
                    this.$router.push({ name: 'home' })
                }
            },

            isCurrentCompanyActive(company) {
                return company.id === this.currentCompany
            },

            isCurrentCustomerActive(customer) {
                return customer.id === this.currentCustomer
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

    }

    li {
        margin: 0;
    }

    .sz-DropdownProfile {
        position: absolute;
        right: 1.5rem;
        top: 4.5rem;
        min-width: 9.75rem;
        z-index: $layer-dropdown-z-index;
        background-color: $color-dropdown-background;
        color: $color-unselected-text;
        font-weight: $heading-font-weight;
        padding: 0.1rem 0.25rem 0.2rem 0.05rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        user-select: none;

        &-options {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
        }

        &-divider {
            height: 0.0525rem;
            width: 100%;
            background-color: $color-base-grey;
            align-self: center;
            opacity: 0.2;
        }

        &-option {
            display: flex;
            justify-content: flex-start;
            width: 90%;
            padding: 0.5rem 0.5rem 0.5rem 0.7rem;
            cursor: pointer;

            &:hover {
                background-color: $color-lifebooster-medium-green;
                color: $color-text;
            }
        }

        &-section {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            width: 100%;

            &-scrollable {
                overflow-y: scroll;
                overflow-x: hidden;
                @include scrollbar-widget;
                max-height: 250px;
                min-width: 9rem;
            }

            &-header {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 0.5rem 0.5rem 0.7rem;
                cursor: default;

                &-clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: $color-lifebooster-medium-green;
                        color: $color-text;
                    }
                }

                &-expandSection {
                    padding-top: 0.25rem;

                    &-open {
                        transform:rotate(180deg);
                    }
                }
            }

            &-options {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                width: 100%;
                padding-left: 0.75rem;
                cursor: pointer;
            }

            &-option {
                display: flex;
                justify-content: flex-start;
                width: 85%;
                padding: 0.35rem 0.5rem;
                cursor: pointer;

                &:hover {
                    background-color: $color-lifebooster-medium-green;
                    color: $color-text;
                }

                &-selected {
                    background-color: $color-lifebooster-medium-green;
                    color: $color-text;
                    display: flex;
                    justify-content: flex-start;
                    width: 85%;
                    padding: 0.35rem 0.5rem;
                    cursor: pointer;
                }
            }
        }
    }

    .slide-enter-active {
        transition: all .3s ease;
        max-height: 250px;
    }

    .slide-leave-active {
        transition: all .2s ease;
        max-height: 250px;
    }

    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }

    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }
</style>

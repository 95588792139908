import DeviceService from 'services/device.service'
import LogService from 'services/log.service'
import constants from 'helpers/constants'

const deviceManagement = {
    state: {
        deviceSet: undefined,
        downloadingDevices: false,
        successfulDeviceDownload: true,
        uploadingDevices: false,
        uploadingCertificates: false,
        successfulDeviceUpload: true,
        successfulCertificateUpload: true,
        loadingBatches: false,
        validatingSid: false,
        startingCapture: false,
        loadingSetStatuses: false,
        allNetworkConfigs: [],
        allFirmware: [],
        allOperationConfigs: [],
        allAzureConfigs: [],
        updatingSetConfiguration: false,
        updateSetConfigurationStatus: null,
        uploadingConfiguration: false,
        loadingConfigurations: false,
        updatingConfiguration: false,
        updateConfigurationStatus: null,
    },

    getters: {
        deviceSet: (state) => {
            return state.deviceSet
        },

        downloadingDevices: (state) => {
            return state.downloadingDevices
        },

        uploadingDevices: (state) => {
            return state.uploadingDevices
        },

        uploadingCertificates: (state) => {
            return state.uploadingCertificates
        },

        loadingBatches: (state) => {
            return state.loadingBatches
        },

        successfulDeviceDownload: (state) => {
            return state.successfulDeviceDownload
        },

        successfulDeviceUpload: (state) => {
            return state.successfulDeviceUpload
        },

        successfulCertificateUpload: (state) => {
            return state.successfulCertificateUpload
        },

        validatingSid: (state) => {
            return state.validatingSid
        },

        startingCapture: (state) => {
            return state.startingCapture
        },

        loadingSetStatuses: (state) => {
            return state.loadingSetStatuses
        },

        allNetworkConfigs: (state) => {
            return [
                ...state.allNetworkConfigs,
                // Default config is 'unassigned', we need to add this to the list since the BE doesn't provide it
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                },
            ]
        },

        allFirmware: (state) => {
            return [
                ...state.allFirmware,
                // Default config is 'unassigned', we need to add this to the list since the BE doesn't provide it
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    deviceType: constants.DEVICE_TYPES.SGDT,
                },
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    deviceType: constants.DEVICE_TYPES.ISGCT,
                },
            ]
        },

        allOperationConfigs: (state) => {
            return [
                ...state.allOperationConfigs,
                // Default config is 'unassigned', we need to add this to the list since the BE doesn't provide it
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    deviceType: constants.DEVICE_TYPES.SGDT,
                },
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    deviceType: constants.DEVICE_TYPES.ISGCT,
                },
            ]
        },

        allAzureConfigs: (state) => {
            return [
                ...state.allAzureConfigs,
                // Default config is 'unassigned', we need to add this to the list since the BE doesn't provide it
                {
                    id: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                    description: constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION,
                },
            ]
        },

        updatingSetConfiguration: (state) => {
            return state.updatingSetConfiguration
        },

        updateSetConfigurationStatus: (state) => {
            return state.updateSetConfigurationStatus
        },

        uploadingConfiguration: (state) => {
            return state.uploadingConfiguration
        },

        loadingConfigurations: (state) => {
            return state.loadingConfigurations
        },

        updatingConfiguration: (state) => {
            return state.updatingConfiguration
        },

        updateConfigurationStatus: (state) => {
            return state.updateConfigurationStatus
        },
    },
    mutations: {
        setDeviceSet (state, payload) {
            state.deviceSet = payload
        },

        setDownloadingDevices (state, payload) {
            state.downloadingDevices = payload
        },

        setUploadingDevices (state, payload) {
            state.uploadingDevices = payload
        },

        setUploadingCertificates (state, payload) {
            state.uploadingCertificates = payload
        },

        setDownloadDeviceSuccess (state, payload) {
            state.successfulDeviceDownload = payload
        },

        setUploadingDevicesSuccess (state, payload) {
            state.successfulDeviceUpload = payload
        },

        setUploadingCertificatesSuccess (state, payload) {
            state.successfulCertificateUpload = payload
        },

        setLoadingBatches (state, payload) {
            state.loadingBatches = payload
        },

        setValidatingSid (state, payload) {
            state.validatingSid = payload
        },

        setStartingCapture (state, payload) {
            state.startingCapture = payload
        },

        setLoadingSetStatuses (state, payload) {
            state.loadingSetStatuses = payload
        },

        setAllNetworkConfigs (state, payload) {
            state.allNetworkConfigs = payload.map(p => {
                return {
                    id: p.id,
                    description: p.profile.description,
                }
            })
        },

        setAllFirmware (state, payload) {
            state.allFirmware = payload.map(p => {
                return {
                    id: p.id,
                    description: p.profile.description,
                    deviceType: p.profile.deviceType,
                }
            })
        },

        setAllOperationConfigs (state, payload) {
            state.allOperationConfigs = payload.map(p => {
                return {
                    id: p.id,
                    description: p.profile.description,
                    deviceType: p.profile.deviceType,
                }
            })
        },

        setAllAzureConfigs (state, payload) {
            state.allAzureConfigs = payload.map(p => {
                return {
                    id: p.id,
                    description: p.profile.description,
                }
            })
        },

        setUpdatingSetConfiguration (state, payload) {
            state.updatingSetConfiguration = payload
        },

        setUpdateSetConfigurationStatus (state, payload) {
            state.updateSetConfigurationStatus = payload
        },

        setUploadingConfiguration (state, payload) {
            state.uploadingConfiguration = payload
        },

        setLoadingConfigurations (state, payload) {
            state.loadingConfigurations = payload
        },

        setUpdatingConfiguration (state, payload) {
            state.updatingConfiguration = payload
        },

        setUpdateConfigurationStatus (state, payload) {
            state.updateConfigurationStatus = payload
        },
    },
    actions: {
        async downloadDevices ({ commit }) {
            try {
                commit('setDownloadingDevices', true)
                let devices = await DeviceService.downloadDevices()
                commit('setDownloadingDevices', false)
                return devices
            } catch (error) {
                commit('setDownloadingDevices', false)
                commit('setDownloadDeviceSuccess', false)
                console.error(error)
                throw new Error(error.response)
            }
        },

        async getDeviceBatches ({ commit }) {
            try {
                commit('setLoadingBatches', true)
                let batches = await DeviceService.getBatches()
                batches = batches.reverse()
                commit('setLoadingBatches', false)
                return batches
            } catch (error) {
                console.log(error)
            }
        },

        async uploadDevices ({ commit }, payload) {
            try {
                commit('setUploadingDevices', true)
                let result = await DeviceService.uploadDevices(payload)
                commit('setUploadingDevices', false)
                commit('setUploadingDevicesSuccess', true)
                return result
            } catch (error) {
                commit('setUploadingDevicesSuccess', false)
                commit('setUploadingDevices', false)
                throw new Error(error.response)
            }
        },

        async getBatchStatus ({ commit }, payload) {
            try {
                return await DeviceService.getBatchStatus(payload)
            } catch (error) {
                throw new Error(error)
            }
        },

        async uploadCertificates ({ commit }, payload) {
            try {
                commit('setUploadingCertificates', true)
                let result = await DeviceService.uploadCertificates(payload)
                commit('setUploadingCertificates', false)
                commit('setUploadingCertificatesSuccess', true)
                return result
            } catch (error) {
                throw new Error(error)
            }
        },

        async getDeviceSet ({ commit }, payload) {
            try {
                commit('setValidatingSid', true)
                let set = await DeviceService.getDeviceSet(payload)
                commit('setDeviceSet', set)
                commit('setValidatingSid', false)
            } catch(error) {
                commit('setValidatingSid', false)
                throw new Error(error)
            }
        },

        async startCapture ({commit}, payload) {
            try {
                commit('setStartingCapture', true)
                let result = await DeviceService.startCapture(payload)
                commit('setStartingCapture', false)
                return result
            } catch (error) {
                commit('setStartingCapture', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to start capture.`)
            }
        },

        async getActiveCapture ({ commit }, payload) {
            try {
                let capture = await DeviceService.getActiveCapture(payload)
                return capture
            } catch (error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw error
            }
        },

        async getAllSetStatuses ({ commit }, payload) {
            try {
                commit('setLoadingSetStatuses', true)
                let statuses = await DeviceService.getAllSetStatuses()
                commit('setLoadingSetStatuses', false)
                return statuses
            } catch (error) {
                commit('setLoadingSetStatuses', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain device set statuses.`)
            }
        },

        async getSetStatus({ commit }, payload) {
            try {
              return await DeviceService.getSetStatus(payload)
            } catch(error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain device set status.`)
            }
        },

        async getAllFirmware({ commit }, payload) {
            try {
                let firmware = []
                firmware = await DeviceService.getAllFirmware()
                commit('setAllFirmware', firmware)
            } catch(error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain all firmware.`)
            }
        },

        async getAllNetworkConfigs({ commit }, payload) {
            try {
                let networks = []
                networks = await DeviceService.getAllNetworkConfigs()
                commit('setAllNetworkConfigs', networks)
            } catch(error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain all network configs.`)
            }
        },

        async getAllAzureConfigs({ commit }, payload) {
            try {
                let azureConfigs = []
                azureConfigs = await DeviceService.getAllAzureConfigs()
                commit('setAllAzureConfigs', azureConfigs)
            } catch(error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain all azure configs.`)
            }
        },

        async getAllOperationConfigs({ commit }, payload) {
            try {
                let operations = []
                operations = await DeviceService.getAllOperationConfigs()
                commit('setAllOperationConfigs', operations)
            } catch(error) {
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain all operation configs.`)
            }
        },

        async updateSetConfigurations({ commit }, payload) {
            try {
                commit('setUpdatingSetConfiguration', true)
                await DeviceService.updateSetConfigurations(payload)
                commit('setUpdatingSetConfiguration', false)
                commit('setUpdateSetConfigurationStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch(error) {
                commit('setUpdatingSetConfiguration', false)
                commit('setUpdateSetConfigurationStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to update set configurations.`)
            }
        },

        async uploadFirmware({ commit }, payload) {
            try {
                commit('setUploadingConfiguration', true)
                let result = await DeviceService.uploadFirmware(payload)
                commit('setUploadingConfiguration', false)
            } catch(error) {
                commit('setUploadingConfiguration', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to upload firmware.`)
            }
        },

        async uploadNetworkConfig({ commit }, payload) {
            try {
                commit('setUploadingConfiguration', true)
                let result = await DeviceService.uploadNetworkConfig(payload)
                commit('setUploadingConfiguration', false)
            } catch(error) {
                commit('setUploadingConfiguration', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to upload network config.`)
            }
        },

        async uploadOperationsConfig({ commit }, payload) {
            try {
                commit('setUploadingConfiguration', true)
                let result = await DeviceService.uploadOperationsConfig(payload)
                commit('setUploadingConfiguration', false)
            } catch(error) {
                commit('setUploadingConfiguration', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to upload operations config.`)
            }
        },

        async uploadAzureConfig({ commit }, payload) {
            try {
                commit('setUploadingConfiguration', true)
                let result = await DeviceService.uploadAzureConfig(payload)
                commit('setUploadingConfiguration', false)
            } catch(error) {
                commit('setUploadingConfiguration', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to upload azure config.`)
            }
        },

        async getAllConfigurations({ commit }, payload) {
            try {
                commit('setLoadingConfigurations', true)
                let statuses = await DeviceService.getAllConfigurations()
                commit('setLoadingConfigurations', false)
                return statuses
            } catch (error) {
                commit('setLoadingConfigurations', false)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to obtain all configurations.`)
            }
        },

        async renameFirmware({ commit }, payload) {
            try {
              commit('setUpdatingConfiguration', true)
              await DeviceService.renameFirmware(payload)
              commit('setUpdatingConfiguration', false)
              commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (error) {
                commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to rename firmware.`)
            }
        },

        async renameNetworkConfig({ commit }, payload) {
            try {
              commit('setUpdatingConfiguration', true)
              await DeviceService.renameNetworkConfig(payload)
              commit('setUpdatingConfiguration', false)
              commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (error) {
                commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to rename network config.`)
            }
        },

        async renameOperationsConfig({ commit }, payload) {
            try {
              commit('setUpdatingConfiguration', true)
              await DeviceService.renameOperationsConfig(payload)
              commit('setUpdatingConfiguration', false)
              commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (error) {
                commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to rename operations config.`)
            }
        },

        async renameAzureConfig({ commit }, payload) {
            try {
              commit('setUpdatingConfiguration', true)
              await DeviceService.renameAzureConfig(payload)
              commit('setUpdatingConfiguration', false)
              commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (error) {
                commit('setUpdateConfigurationStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                LogService.send(constants.LOG_LEVELS.ERROR, error.message)
                throw new Error(`Failed to rename azure config.`)
            }
        },
    },
}

export default deviceManagement

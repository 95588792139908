import ApiService from './api.service'
import LogService from 'services/log.service'
import constants from 'helpers/constants'

const DeviceService = {
    uploadDevices: async function (devices) {
        const url = `/api/device/bulk`
        try {
            const response = await ApiService.put(url, devices)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    uploadCertificates: async function (devices) {
        const url = `/api/device/certificate`
        try {
            const response = await ApiService.post(
                url,
                devices,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    downloadDevices: async function () {
        const url = `/api/device`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    getBatches: async function () {
        const url = `/api/device/bulk`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    getBatchStatus: async function (batch) {
        const url = `/api/device/bulk/${batch}`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    getDeviceSet: async function (setID) {
        const url = `/api/device/set/${setID}`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            throw new Error(error.response)
        }
    },

    startCapture: async function (payload) {
        const url = `/device/initiate-capture`
        try {
            const response = await ApiService.post(url, payload)
            return response.data
        } catch (error) {
            throw new Error(error.response.data)
        }
    },

    getActiveCapture: async function (setID) {
        const url = `/set/${setID}/activeCaptures`
        try {
            const response = await ApiService.get(url, setID)
            return response.data
        } catch (error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to validate if an active capture exists.`)
            
        }
    },

    getAllSetStatuses: async function () {
        const url = `/api/firmware/sets/status`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain device set statuses.`)
        }
    },

    getSetStatus: async function(setID) {
        const url = `/api/firmware/sets/${setID}/status`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain device set status.`)
        }
    },

    getAllFirmware: async function() {
        const url = `/api/firmware`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain all firmware.`)
        }
    },

    getAllNetworkConfigs: async function() {
        const url = `/api/firmware/network`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain all network configs.`)
        }
    },

    getAllAzureConfigs: async function() {
        const url = `/api/firmware/az`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain all azure configs.`)
        }
    },

    getAllOperationConfigs: async function() {
        const url = `/api/firmware/operation`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to obtain all operation configs.`)
        }
    },

    updateSetConfigurations: async function(sets) {
        const url = `/api/firmware/sets/update`
        try {
            const response = await ApiService.post(url, sets)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to update set configurations.`)
        }
    },

    uploadFirmware: async function (firmware) {
        const url = `/api/firmware`
        try {
            const response = await ApiService.put(
                url,
                firmware,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to upload firmware.`)
        }
    },

    uploadNetworkConfig: async function (network) {
        const url = `/api/firmware/network`
        try {
            const response = await ApiService.put(
                url,
                network,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to upload network config.`)
        }
    },

    uploadOperationsConfig: async function (operation) {
        const url = `/api/firmware/operation`
        try {
            const response = await ApiService.put(
                url,
                operation,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to upload operations config.`)
        }
    },

    uploadAzureConfig: async function (azure) {
        const url = `/api/firmware/az`
        try {
            const response = await ApiService.put(
                url,
                azure,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to upload azure config.`)
        }
    },

    getAllConfigurations: async function () {
        const url = `/api/firmware/configDesc`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to get all configurations.`)
        }
    },

    renameFirmware: async function (firmware) {
        const url = `/api/firmware`
        try {
            const response = await ApiService.post(
                url,
                firmware)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to rename firmware.`)
        }
    },

    renameNetworkConfig: async function (network) {
        const url = `/api/firmware/network`
        try {
            const response = await ApiService.post(
                url,
                network)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to rename network config.`)
        }
    },

    renameOperationsConfig: async function (operation) {
        const url = `/api/firmware/operation`
        try {
            const response = await ApiService.post(
                url,
                operation)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to rename operations config.`)
        }
    },

    renameAzureConfig: async function (azure) {
        const url = `/api/firmware/az`
        try {
            const response = await ApiService.post(
                url,
                azure)
            return response.data
        } catch(error) {
            LogService.send(constants.LOG_LEVELS.ERROR, error.message)
            throw new Error(`Failed to rename azure config.`)
        }
    },
}

export default DeviceService

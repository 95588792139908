<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
    <div class="body">
      <div
        v-for="(line, idx) in body"
        :key="idx"
      >
        <div
          :id="`body${idx}`"
          class="body-line"
        >
          {{ line }}
        </div>
      </div>
    </div>
    <div class="buttons">
      <div
        class="buttons-cancel"
        @click.stop="close"
      >
        {{ $t("modals.cancel") }}
      </div>
      <div
        class="buttons-confirm"
        @click.stop="goToPage"
      >
        {{ $t("modals.continue") }}
      </div>
    </div>
  </div>
</template>

<script>
    import constants from 'helpers/constants'
    import { convertEmailsInHTMLBodyToHyperLinks  } from 'helpers/utilities.js'

    export default {
        name: 'ModalGoToExternalPage',

        props: {
            title: {
                type: String,
                required: false,
            },
            subtitle: {
                type: String,
                required: false,
            },
            body: {
                type: Array,
                required: true,
            },
            targetURL: {
                type: String,
                required: true,
            },
        },

        mounted() {
            // Scans the body for any email links and converts them into hyperlinks
            // directly in the HTML within the DOM
            this.body.map((line, idx) => {
                var bodyText = document.getElementById(`body${idx}`)
                var bodyInnerHTML = bodyText.innerHTML

                let newBodyHTML = convertEmailsInHTMLBodyToHyperLinks(bodyInnerHTML)

                bodyText.innerHTML = newBodyHTML
            })
        },

        methods: {
            goToPage() {
                window.location.href = this.targetURL
                this.$emit('close')
            },

            close() {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss">
    @import '~design';

    a, a:link, a:visited {
        color: $color-lifebooster-extra-light-green !important;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25vw;
        height: auto;
        padding: 1rem 1.75rem 1rem 1rem;
        color: $color-text;
    }

    .title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 1rem;
    }

    .subtitle {
        align-self: flex-start;
        font-size: 1.25rem;
        font-weight: 700;
        padding-bottom: 1.5rem;
        padding-left: 1rem;
        text-align: left;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &-line {
            text-align: left;
            font-size: 1rem;
            font-weight: 400;
            padding: 0 0 1.25rem 1rem;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @extend %font-topbar-heading;
        font-size: 0.938rem;
        width: 100%;
        cursor: pointer;
        padding: 0.5rem 0 0.75rem 0;

        &-confirm {
            padding: 1rem;
            background-color: $color-lifebooster-medium-green;
            width: 100%;

            &:hover {
                background-color: $color-lifebooster-light-green;
            }
        }

        &-cancel {
            padding: 1rem;
            width: 100%;
        }
    }
</style>

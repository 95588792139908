<template lang="pug">
    form(
        class="sz-FormEditWidget")
        div(class="sz-FormEditWidget-body")
            div(class="sz-FormEditWidget-section")
                div(class="sz-FormEditWidget-row")
                    <!-- Widget Title Input -->
                    FormSectionInput(
                        :title="sectionTitles.title",
                        :maxLength="50",
                        placeholder="Please add title",
                        :defaultInput="defaultTitle",
                        @updated="updateTitle")

                div(class="sz-FormEditWidget-row")
                    <!-- Widget Type Selector -->
                    FormSectionSelection(
                        :title="sectionTitles.type",
                        :options="widgetTypeOptions",
                        alignment="column",
                        selectionType="radio",
                        :defaultSelectionOptions="defaultType",
                        @updateSelectedOptions="updateWidgetType")

                div(
                    v-if="isDistributionChart",
                    class="sz-FormEditWidget-row")
                    <!-- Widget Distribution Chart Type Selector -->
                    FormSectionSelection(
                        :title="sectionTitles.distributionType",
                        :options="distributionChartOptions",
                        alignment="row",
                        selectionType="radio",
                        :defaultSelectionOptions="defaultDistributionType",
                        @updateSelectedOptions="updateDistributionChartType")

                div(
                    v-if="isAnalysisChart",
                    class="sz-FormEditWidget-row")
                    <!-- Widget Progress Chart Type Selector -->
                    FormSectionSelection(
                        :title="sectionTitles.analysisType",
                        :options="analysisChartOptions",
                        alignment="row",
                        selectionType="radio",
                        :defaultSelectionOptions="defaultAnalysisType",
                        @updateSelectedOptions="updateAnalysisChartType")

                div(class="sz-FormEditWidget-row sz-FormEditWidget-row-date" v-if="isWidgetSelected && isATypeSelectedForBarOrAnalysis")
                    <!-- Widget Date Preset Selector -->
                    FormSectionDropdownSingleSelect(
                        :title="sectionTitles.presets",
                        v-if="!isAnalysisChart || isAnalysisTagChart || isAnalysisAndNoTypeSelected",
                        :defaultSelectedOption="defaultPreset",
                        :options="presetOptions",
                        :placeholder="presetPlaceholder",
                        @updateSelectedOption="updateSelectedPreset",
                        ref="presetPicker",
                        class="sz-FormEditWidget-row-date-presets")
                    <!-- Widget Date Range Selector -->
                    FormSectionDateRangePicker(
                        v-if="!isAnalysisChart || isAnalysisTagChart || isAnalysisAndNoTypeSelected",
                        :title="sectionTitles.interval",
                        :defaultSelectedInterval="defaultInterval",
                        @updateSelectedDateRange="updateSelectedInterval",
                        ref="datePicker")

                    div(class="sz-FormEditWidget-label" v-if="isAnalysisChart && isAnalysisTimeTypeChart") {{sectionTitles.groupComparisonLabel}}

                    FormSectionDateRangePicker(
                        class="sz-FormEditWidget-sectionSelectionSpacing"
                        v-if="isAnalysisChart && isAnalysisTimeTypeChart",
                        :title="sectionTitles.intervalRef",
                        :defaultSelectedInterval="defaultIntervalRef",
                        ref="refDatePicker"
                        @updateSelectedDateRange="updateSelectedRefInterval")

                    FormSectionInput(
                        v-if="isAnalysisChart && isAnalysisTimeTypeChart"
                        :title="sectionTitles.timeRefLabel",
                        textSize="content",
                        class="sz-FormEditWidget-sectionInputSpacing",
                        :placeholder="this.$t(`modals.modalEditWidget.changeAnalysisLabels.refTimePlaceholder`)",
                        @updated="updateRefTimeLabel"
                        :useInputTag="formUseInput")

                    FormSectionDateRangePicker(
                        class="sz-FormEditWidget-sectionSelectionSpacing"
                        v-if="isAnalysisChart && isAnalysisTimeTypeChart && isFirstTimeGroupSelected"
                        :title="sectionTitles.intervalComp",
                        :defaultSelectedInterval="defaultIntervalComp",
                        ref="compDatePicker",
                        @updateSelectedDateRange="updateSelectedCompInterval")

                    FormSectionInput(
                        v-if="isAnalysisChart && isAnalysisTimeTypeChart && isFirstTimeGroupSelected"
                        :title="sectionTitles.timeCompLabel",
                        class="sz-FormEditWidget-sectionInputSpacing",
                        textSize="content",
                        :placeholder="this.$t(`modals.modalEditWidget.changeAnalysisLabels.compTimePlaceholder`)",
                        @updated="updateCompTimeLabel"
                        :useInputTag="formUseInput")

            div(class="sz-FormEditWidget-divider")
            div(class="sz-FormEditWidget-section")
                div(class="sz-FormEditWidget-row" v-if="isWidgetSelected && isAnIntervalSelected")
                    <!-- Risk Score Type Selector -->
                    FormSectionSelection(
                        v-if="!isSummaryChartType",
                        :title="sectionTitles.riskScoreType",
                        :options="riskScoreTypeOptions",
                        alignment="column",
                        selectionType="radio",
                        :defaultSelectionOptions="defaultRiskScoreType",
                        @updateSelectedOptions="updateRiskScoreType")

                <!-- Widget Risk Type Selector - organized by module -->
                div(v-if="isRiskScoreTypeSelected && isWidgetSelected && isAnIntervalSelected && !isSummaryChartType" class="sz-FormEditWidget-row-title") {{ sectionTitles.modules }}
                div(v-if="isRiskScoreTypeSelected && isWidgetSelected && isAnIntervalSelected  && !isSummaryChartType" class="sz-FormEditWidget-row-selections-modules")
                    DropdownMultiSelect(
                        v-for="module in riskModules",
                        :key="module.type",
                        :options="riskModuleRiskTypes[module.type]",
                        :defaultSelectionOptions="defaultRiskTypes[module.type]",
                        :selectionTitle="module.name",
                        :disableSelection="disableRiskModule(module.type)",
                        @updateSelectedOptions="updateSelectedRisks($event, module.type)",
                        ref="riskTypeSelectors",
                        class="sz-FormEditWidget-row-selections-selection")
            div(class="sz-FormEditWidget-divider")
            div(class="sz-FormEditWidget-section")
                div(class="sz-FormEditWidget-row" v-if="(isWidgetSelected && isRiskSelected && isAnIntervalSelected) || isSummaryChartType")
                    <!-- Widget Assessment Tag Selector -->
                    FormSectionTagSelection(
                        v-if="!isAnalysisChart || isAnalysisTimeTypeChart",
                        :title="sectionTitles.assessmentTags",
                        :options="assessmentTagOptions",
                        :defaultSelectedTags="defaultAssessmentTags",
                        @updateSelectedTags="updateSelectedAssessmentTags")

                div(class="sz-FormEditWidget-row sz-FormEditWidget-row-userCharacteristics" v-if="(isWidgetSelected && isRiskSelected && isAnIntervalSelected) || isSummaryChartType")
                    div(v-if="isAnalysisChart && isAnalysisTagChart" class="optional-elements-spacing")
                        div(class="sz-FormEditWidget-label") {{sectionTitles.groupComparisonLabel}}
                        FormSectionTagSelection(
                            class="sz-FormEditWidget-sectionSelectionSpacing"
                            :title="sectionTitles.refAssessmentTags",
                            :options="assessmentRefTagOptions",
                            :defaultSelectedTags="defaultRefAssessmentTagsName",
                            @updateSelectedTags="updateSelectedRefAssessmentTags")
                        div(class="sz-FormEditWidget-sectionInputSpacing")
                            FormSectionInput(
                                :title="sectionTitles.refAssessmentTagsLabel",
                                textSize="content",
                                :placeholder="this.$t(`modals.modalEditWidget.changeAnalysisLabels.refTagPlaceholder`)",
                                @updated="updateRefTagLabel"
                                :useInputTag="formUseInput")
                        FormSectionTagSelection(
                            class="sz-FormEditWidget-sectionSelectionSpacing"
                            v-if="isFirstTagGroupSelected || !isAnalysisChart"
                            :title="sectionTitles.compAssessmentTags",
                            :options="assessmentCompTagOptions",
                            :defaultSelectedTags="defaultCompAssessmentTagsName",
                            @updateSelectedTags="updateSelectedCompAssessmentTags")
                        div(class="sz-FormEditWidget-sectionInputSpacing")
                            FormSectionInput(
                                :title="sectionTitles.compAssessmentTagsLabel",
                                v-if="isFirstTagGroupSelected"
                                textSize="content",
                                :placeholder="this.$t(`modals.modalEditWidget.changeAnalysisLabels.compTagPlaceholder`)",
                                @updated="updateCompTagLabel"
                                :useInputTag="formUseInput")

                    <!-- Widget Job Type Selector -->
                    FormSectionTagSelection(
                        v-if="isFirstTagGroupSelected || !isAnalysisTagChart"
                        :title="sectionTitles.jobTypes",
                        :options="jobTypeOptions",
                        :defaultSelectedTags="defaultJobTypes",
                        @updateSelectedTags="updateSelectedJobTypes",
                        class="sz-FormEditWidget-row-selections-selection")

                    <!-- Widget Gender Selector -->
                    FormSectionTagSelection(
                        v-if="isFirstTagGroupSelected || !isAnalysisTagChart"
                        :title="sectionTitles.genders",
                        :options="genderOptions",
                        :defaultSelectedTags="defaultGenders",
                        @updateSelectedTags="updateSelectedGenders",
                        class="sz-FormEditWidget-row-selections-selection")

        div(class="sz-FormEditWidget-footer")
            div(
                @click="cancelEdit",
                class="sz-FormEditWidget-footer-button \
                    sz-FormEditWidget-footer-cancel") {{ $t(`modals.modalEditWidget.cancel`)}}
            div(
                type="submit",
                @click="saveWidget",
                class="sz-FormEditWidget-footer-button \
                    sz-FormEditWidget-footer-save") {{ $t(`modals.modalEditWidget.save`)}}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import formValidator from 'mixins/formValidator'
    import { cloneDeep, difference } from 'lodash'
    import constructModalSettings from 'mixins/modalSettings'
    import EventBus from 'src/eventBus'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import FormSectionSelection from 'components/Shared/FormSectionSelection'
    import DropdownMultiSelect from 'components/Shared/DropdownMultiSelect'
    import FormSectionDateRangePicker from 'components/Shared/FormSectionDateRangePicker'
    import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
    import FormSectionDropdownSingleSelect from "components/Shared/FormSectionDropdownSingleSelect"

    export default {
        name: "FormEditWidget",

        components: {
            FormSectionDropdownSingleSelect,
            DropdownMultiSelect,
            FormSectionInput,
            FormSectionSelection,
            FormSectionDateRangePicker,
            FormSectionTagSelection,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            widgetSettings: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                title: '',
                type: '',
                distributionChartType: '',
                analysisChartType: '',
                riskScoreType: '',
                order: null,
                interval: '',
                intervalRef: '',
                intervalComp: '',
                refTimeLabel: '',
                compTimeLabel: '',
                genders: [],
                riskTypes: [],
                assessmentTags: [],
                refAssessmentTags: [],
                compAssessmentTags: [],
                refTagLabel: '',
                compTagLabel: '',
                jobTypes: [],
            }
        },

        computed: {
            ...mapGetters([
                'jobs',
                'allAssessmentTags',
            ]),

            newSettings () {
                return {
                    order: this.widgetSettings.order,
                    title: this.title,
                    type: this.newType,
                    interval: this.intervalIsPreset ? constants.DATE_PRESET_INTERVAL_MAP[this.interval] : this.interval,
                    riskScoreType: this.newRiskScoreType,
                    genders: this.newGenders,
                    riskTypes: this.riskTypes,
                    assessmentTags: this.newAssessmentTags,
                    jobTypes: this.newJobs,
                }
            },

          newAnalysisSettings () {
                const assessmentTags = this.newAssessmentTags
                const interval = this.intervalIsPreset ? constants.DATE_PRESET_INTERVAL_MAP[this.interval] : this.interval

                return {
                    order: this.widgetSettings.order,
                    title: this.title,
                    type: this.newType,
                    riskScoreType: this.newRiskScoreType,
                    genders: this.newGenders,
                    riskTypes: this.riskTypes,
                    groups: [
                        {
                            label: this.newRefLabel,
                            assessmentTags: this.isAnalysisTagChart ? this.refAssessmentTags : assessmentTags,
                            interval: this.isAnalysisTimeTypeChart ? this.intervalRef : interval,
                        },
                        {
                            label: this.newCompLabel,
                            assessmentTags: this.isAnalysisTagChart ? this.compAssessmentTags : assessmentTags,
                            interval: this.isAnalysisTimeTypeChart ? this.intervalComp : interval,
                        },
                    ],
                    jobTypes: this.newJobs,
                }
            },

            newType () {
                if(this.type == constants.WIDGET_TYPES_API_KEYS.DISTRIBUTION) {
                    return this.distributionChartType
                }
                else if(this.type == constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS) {
                    return this.analysisChartType
                }
                else {
                    return this.type
                }
            },

            newRiskScoreType () {
                if(this.isSummaryChartType) {
                    this.riskScoreType = constants.INTERNAL_KEYS.RISK_TYPE_NONE
                }
                return this.riskScoreType
            },

            newGenders () {
                return (this.genders && this.genders.length)
                    ? this.genders
                    : []
            },

            newAssessmentTags () {
                return (this.assessmentTags && this.assessmentTags.length)
                    ? this.assessmentTags
                    : []
            },

            newRefLabel() {
                if(this.isAnalysisTimeTypeChart) {
                    return this.refTimeLabel && this.refTimeLabel != '' ?
                        this.refTimeLabel :  this.$t(`modals.modalEditWidget.changeAnalysisLabels.refTimeLabel`)
                }
                else {
                    return this.refTagLabel && this.refTagLabel != '' ?
                        this.refTagLabel : this.$t(`modals.modalEditWidget.changeAnalysisLabels.refTagLabel`)
                }
            },

            newCompLabel() {
                  if(this.isAnalysisTimeTypeChart) {
                    return this.compTimeLabel && this.compTimeLabel != '' ?
                        this.compTimeLabel : this.$t(`modals.modalEditWidget.changeAnalysisLabels.compTimeLabel`)
                }
                else {
                    return this.compTagLabel && this.compTagLabel != '' ?
                        this.compTagLabel :  this.$t(`modals.modalEditWidget.changeAnalysisLabels.compTagLabel`)
                }
            },

            newRefAssessmentTags () {
                return (this.refAssessmentTags && this.refAssessmentTags.length)
                    ? this.refAssessmentTags
                    : []
            },

            newCompAssessmentTags () {
                return (this.compAssessmentTags && this.compAssessmentTags.length)
                    ? this.compAssessmentTags
                    : []
            },

            newRefTagLabel () {
                return (this.refTagLabel && this.compAssessmentTags.length != 0)
                    ? this.refTagLabel
                    : []
            },


            newJobs () {
                let jobs = (this.jobTypes && this.jobTypes.length)
                    ? this.jobTypes
                    : []
                return jobs.map((job) => {
                    return typeof job === "object" ? job.name : job
                })
            },

            sectionTitles () {
                let that = this
                return constants.FORM_EDIT_WIDGET_SECTIONS.reduce(function (acc, section) {
                    acc[section] = that.$t(`modals.modalEditWidget.sections.${section}`)
                    return acc
                }, {})
            },

            formUseInput () {
                return true
            },

            widgetTypeOptions () {
                return Object.values(constants.WIDGET_TYPES_API_KEYS).map((widgetType) => {
                    return {
                        type: widgetType,
                        name: this.$t(`modals.modalEditWidget.widgetTypes.${widgetType}`),
                        icon: constants.WIDGET_TYPE_ICON_TYPE[widgetType],
                    }
                })
            },

            riskScoreTypeOptions () {
                return Object.values(constants.RISK_SCORE_TYPES_API_KEYS).map((riskScoreType) => {
                    return {
                        type: riskScoreType,
                        name: this.$t(`modals.modalEditWidget.riskScoreTypes.${riskScoreType}`),
                        icon: constants.WIDGET_TYPE_ICON_TYPE[riskScoreType],
                    }
                })
            },

            distributionChartOptions () {
                return Object.values(constants.DISTRIBUTION_CHART_TYPE_API_KEYS).map((distributionChartType) => {
                    return {
                        type: distributionChartType,
                        name: this.$t(`modals.modalEditWidget.distributionBarChartTypes.${distributionChartType}`),
                        icon: constants.WIDGET_TYPE_ICON_TYPE[distributionChartType],
                    }
                })
            },

            analysisChartOptions () {
                return Object.values(constants.ANALYSIS_CHART_TYPE_API_KEYS).map((analysisChartType) => {
                    return {
                        type: analysisChartType,
                        name: this.$t(`modals.modalEditWidget.changeAnalysisBarChartTypes.${analysisChartType}`),
                        icon: constants.WIDGET_TYPE_ICON_TYPE[analysisChartType],
                    }
                })
            },

            isWidgetSelected() {
                return this.type != ''
            },

            isDistributionChart () {
                return this.type === constants.WIDGET_TYPES_API_KEYS.DISTRIBUTION
            },

            isAnalysisChart () {
                return this.type === constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS
            },

            isAnalysisTimeTypeChart() {
                return this.analysisChartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART
            },

            isAnalysisTagChart() {
                return this.analysisChartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART
            },

            isAnalysisAndNoTypeSelected() {
                return this.type === constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS && this.analysisChartType == ''
            },

            isRiskSelected() {
                return this.riskTypes != undefined && this.riskTypes.length != 0
            },

            isAnIntervalSelected() {
                return (this.interval || (this.intervalRef && this.intervalComp)) &&
                    (this.interval != '' || (this.intervalRef != '' && this.intervalComp != ''))
            },

            isFirstTimeGroupSelected() {
                return this.intervalRef && this.intervalRef != ''
            },

            isFirstTagGroupSelected() {
                return this.refAssessmentTags && this.refAssessmentTags.length > 0
            },

            isATypeSelectedForBarOrAnalysis() {
                if(this.type === constants.WIDGET_TYPES_API_KEYS.DISTRIBUTION ||
                    this.type === constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS) {
                    return (this.analysisChartType != '' || this.distributionChartType != '')
                }

                return true
            },

            presetOptions () {
                let presetOptions =  Object.values(constants.DATE_PRESETS).map((preset) => {
                    return {
                        name: this.$t(`modals.modalEditWidget.presetTypes.${preset}`),
                        type: preset,
                    }
                })

                return presetOptions
            },

            assessmentTagOptions () {
                return this.allAssessmentTags.map(tag => tag.name)
            },

            assessmentCompTagOptions () {
                return this.allAssessmentTags.filter(tag => !this.refAssessmentTags.includes(tag.id)).map(tag => tag.name)
            },

            assessmentRefTagOptions () {
                return this.allAssessmentTags.filter(tag => !this.compAssessmentTags.includes(tag.id)).map(tag => tag.name)
            },

            jobTypeOptions () {
                return this.jobs.map(job => job.name)
            },

            genderOptions () {
                return Object.values(constants.GENDER_NAME_API_MAP)
            },

            riskModules () {
                return Object.values(constants.RISK_MODULES)
                    .filter(module => module !== constants.RISK_MODULES.AGGREGATE)
                    .map(riskType => {
                        return {
                            type: riskType,
                            name: this.$t(`individualSummary.riskTypes.${riskType}`),
                        }
                    })
            },

            riskModuleRiskTypes () {
                let that = this
                return Object.values(constants.RISK_MODULES)
                    .filter(module => module !== constants.RISK_MODULES.AGGREGATE)
                    .reduce(function (acc, module) {
                        acc[module] = constants.RISK_MODULE_RISK_TYPE_MAP[module].map((risk) => {
                            return { type: risk, name: that.$t(`riskTypes.${risk}`)}
                        })
                        return acc
                    }, {})
            },

            defaultTitle () {
                return this.widgetSettings.title || ''
            },

            defaultType () {
                if (Object.values(constants.DISTRIBUTION_CHART_TYPE_API_KEYS).some(key => key === this.widgetSettings.type)) {
                    return constants.WIDGET_TYPES_API_KEYS.DISTRIBUTION
                }

                if (Object.values(constants.ANALYSIS_CHART_TYPE_API_KEYS).some(key => key === this.widgetSettings.type)) {
                    return constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS
                }

                return this.widgetSettings.type || ''
            },

            defaultDistributionType () {
                if (Object.values(constants.DISTRIBUTION_CHART_TYPE_API_KEYS).some(key => key === this.widgetSettings.type)) {
                    return this.widgetSettings.type
                }

                return ''
            },

            defaultAnalysisType () {
                if (Object.values(constants.ANALYSIS_CHART_TYPE_API_KEYS).some(key => key === this.widgetSettings.type)) {
                    return this.widgetSettings.type
                }

                return ''
            },

            defaultRiskScoreType () {
                if (Object.values(constants.RISK_SCORE_TYPES_API_KEYS).some(key => key === this.widgetSettings.riskScoreType)) {
                    return this.widgetSettings.riskScoreType
                }

                return ''
            },

            defaultRiskTypes () {
                let that = this
                let defaultRisks = {}
                if (this.widgetSettings.riskTypes) {
                    defaultRisks = Object.values(constants.RISK_MODULES)
                        .filter(module => module !== constants.RISK_MODULES.AGGREGATE)
                        .reduce(function (acc, module) {
                            acc[module] = constants.RISK_MODULE_RISK_TYPE_MAP[module].filter((risk) => {
                                return that.widgetSettings.riskTypes.includes(risk)
                            })
                            return acc
                        }, {})
                }
                return defaultRisks
            },

            defaultInterval () {
                return this.widgetSettings.interval ||
                    (this.widgetSettings.groups ? this.widgetSettings.groups[0].interval : '')
            },

            defaultIntervalRef () {
                return this.widgetSettings.groups ? this.widgetSettings.groups[0].interval : ''
            },

            defaultIntervalComp () {
                return this.widgetSettings.groups ? this.widgetSettings.groups[1].interval : ''
            },

            defaultPreset () {
                let interval = this.widgetSettings.interval ||
                    (this.widgetSettings.groups ? this.widgetSettings.groups[0].interval : '')
                let intervalIsInPresetMap = Object.values(constants.DATE_PRESET_INTERVAL_MAP).includes(interval)
                return intervalIsInPresetMap ? constants.INTERVAL_DATE_PRESET_MAP[interval] : interval
            },

            defaultAssessmentTags () {
                return (this.widgetSettings.assessmentTags || [])
                    .filter(tagID => this.allAssessmentTagIDs.includes(tagID))
                        .map(tagID => this.allAssessmentTags
                            .find(tag => tag.id === tagID).name)
            },

            defaultRefAssessmentTagsName() {
                return this.defaultRefAssessmentTags.map(tag => tag.name)
            },

            defaultCompAssessmentTagsName() {
                return this.defaultCompAssessmentTags.map(tag => tag.name)
            },

            defaultRefAssessmentTags () {
            let defaultTags = (this.widgetSettings.groups ? this.widgetSettings.groups[0].assessmentTags : [])
            .filter(tagID => this.allAssessmentTagIDs.includes(tagID))
                .map(tagID => this.allAssessmentTags
                    .find(tag => tag.id === tagID))

            this.refAssessmentTags = defaultTags.map(tag => tag.id)
            return defaultTags
            },

            defaultCompAssessmentTags () {
                let defaultTags = (this.widgetSettings.groups ? this.widgetSettings.groups[1].assessmentTags : [])
                    .filter(tagID => this.allAssessmentTagIDs.includes(tagID))
                        .map(tagID => this.allAssessmentTags
                            .find(tag => tag.id === tagID))

                this.compAssessmentTags = defaultTags.map(tag => tag.id)
                return defaultTags
            },

            defaultJobTypes () {
                return this.widgetSettings.jobTypes || []
            },

            defaultGenders () {
                return (this.widgetSettings.genders || []).map((gender) => {
                    return constants.GENDER_NAME_API_MAP[gender]
                })
            },

            intervalIsPreset () {
                let widgetSettingsHasInterval = this.interval
                let intervalIsPreset = false
                if (widgetSettingsHasInterval) {
                    intervalIsPreset = Object.values(constants.DATE_PRESET_INTERVAL_MAP).includes(this.interval)
                        || Object.values(constants.DATE_PRESETS).includes(this.interval)
                }
                return intervalIsPreset
            },

            presetPlaceholder () {
                return this.$t(`modals.modalEditWidget.presetPlaceholder`)
            },

            isRiskScoreTypeSelected() {
                return this.riskScoreType || undefined
            },

            isSummaryChartType() {
                return this.type === constants.WIDGET_TYPES_API_KEYS.SUMMARY
            },

            allAssessmentTagIDs () {
                return this.allAssessmentTags.map(tag => tag.id)
            },
        },

        watch: {
            interval () {
                this.intervalIsPreset ?  this.clearDatePicker() : this.clearPreset()
            },

            riskScoreType() {
                this.clearRiskTypes()
            },
        },

        mounted () {
            this.populateDefaults()
        },

        methods: {
            populateDefaults () {
                for (let key of Object.keys(this.widgetSettings)) {
                    this.$data[key] = this.widgetSettings[key]
                }
            },

            updateRefTimeLabel (newRefTimeLabel) {
                this.refTimeLabel = newRefTimeLabel
            },

             updateCompTimeLabel (newCompTimeLabel) {
                this.compTimeLabel = newCompTimeLabel
            },

            updateRefTagLabel (newRefTagLabel) {
                this.refTagLabel = newRefTagLabel
            },

            updateCompTagLabel (newCompTagLabel) {
                this.compTagLabel = newCompTagLabel
            },

            updateTitle (newTitle) {
                this.title = newTitle
            },

            updateWidgetType (newWidgetType) {
                if(newWidgetType == constants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS) {
                    this.clearAnalysisDatePickers()
                    this.clearDatePicker()
                    this.clearPreset()
                    this.intervalComp = ''
                    this.intervalRef = ''
                    this.interval = ''
                    this.distributionChartType = ''
                }
                else if(newWidgetType == constants.WIDGET_TYPES_API_KEYS.DISTRIBUTION) {
                    this.analysisChartType = ''
                    this.interval = ''
                }

                this.type = newWidgetType
            },

            updateRiskScoreType (newRiskScoreType) {
                this.riskScoreType = newRiskScoreType
            },

            updateDistributionChartType (newChartType) {
                this.distributionChartType = newChartType
            },

            updateAnalysisChartType (newChartType) {

                if(newChartType == constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART) {
                    this.interval = ''
                    this.clearDatePicker()
                }
                else if(newChartType == constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART) {
                    this.intervalComp = ''
                    this.intervalRef = ''
                    this.clearAnalysisDatePickers()
                }

                this.analysisChartType = newChartType
            },

            updateSelectedRisks (newRisks, riskModule) {
                // pull out all risk types associated with the module that emitted the newly selected risks,
                // then concatenate these new risks to the currently selected risk types.
                this.riskTypes = this.riskTypes.filter((risk) => {
                    return !constants.RISK_MODULE_RISK_TYPE_MAP[riskModule].includes(risk)
                })
                if (newRisks && newRisks.length) {
                    this.riskTypes = this.riskTypes.concat(newRisks)
                }
            },

            updateSelectedInterval (newInterval) {
                this.interval = newInterval
            },

            updateSelectedRefInterval (newInterval) {
                this.intervalRef = newInterval
            },

            updateSelectedCompInterval (newInterval) {
                this.intervalComp = newInterval
            },

            updateSelectedPreset (newPreset) {
                this.interval = newPreset
            },

            updateSelectedAssessmentTags (newTags) {
                if (newTags && newTags.length) {
                    newTags = newTags
                        .map(tagName => this.allAssessmentTags
                            .find(tag => tag.name === tagName).id)
                }

                this.assessmentTags = newTags
            },

            updateSelectedCompAssessmentTags (newTags) {
                if (newTags && newTags.length) {
                    newTags = newTags
                        .map(tagName => this.allAssessmentTags
                            .find(tag => tag.name === tagName).id)
                }

                this.compAssessmentTags = newTags
            },

            updateSelectedRefAssessmentTags (newTags) {
                if (newTags && newTags.length) {
                    newTags = newTags
                        .map(tagName => this.allAssessmentTags
                            .find(tag => tag.name === tagName).id)
                }

                this.refAssessmentTags = newTags
            },

            updateSelectedJobTypes (newJobs) {
                this.jobTypes = newJobs
            },

            updateSelectedGenders (newGenders) {
                this.genders = newGenders.map((gender) => {
                    return constants.GENDER_API_NAME_MAP[gender]
                })
            },

            disableRiskModule(riskModule) {
                if (this.riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                  let diff = difference(this.riskTypes, constants.RISK_MODULE_RISK_TYPE_MAP[riskModule])
                  return diff.length > 0 ? true : false
                } else {
                  return false
                }
            },

            clearRiskTypes() {
                if (this.$refs.riskTypeSelectors) {
                    this.$refs.riskTypeSelectors.map(selector => selector.clearSelectedOptions())
                }
            },

            clearPreset () {
                if(this.$refs.presetPicker) {
                    this.$refs.presetPicker.clearSelectedOption()
                }
            },

            clearDatePicker () {
                if(this.$refs.datePicker) {
                    this.$refs.datePicker.clearDatePickers()
                }
            },

            clearAnalysisDatePickers() {
                if(this.$refs.refDatePicker && this.$refs.compDatePicker) {
                    this.$refs.compDatePicker.clearDatePickers()
                    this.$refs.refDatePicker.clearDatePickers()
                }
            },

            cancelEdit () {
                this.$emit('close')
            },

            async saveWidget () {
                let validator = {}
                if(this.isAnalysisChart) {
                    validator = this.validateAnalysisSettings()
                }
                else {
                    validator = this.validateWidgetSettings()
                }

                if (validator.valid) {
                    if (!this.widgetSettings.id) {
                        try {
                            this.$emit('savingWidget', true)
                            await this.$store.dispatch('newWidget', this.isAnalysisChart ? this.newAnalysisSettings : this.newSettings)
                            this.$emit('savingWidget', false)
                            this.$emit('close')
                        } catch (e) {
                            await this.showFailureModal(e)
                            this.$emit('savingWidget', false)
                        }
                    }
                    else {
                        try {
                            this.$emit('savingWidget', true)
                            let newWidgetSettings = cloneDeep(this.isAnalysisChart ? this.newAnalysisSettings : this.newSettings)
                            newWidgetSettings.id = this.widgetSettings.id
                            await this.$store.dispatch('saveWidget', newWidgetSettings)
                            this.$emit('savingWidget', false)
                            this.$emit('close')
                            EventBus.$emit('UPDATE_MUTABLE_WIDGET_SETTINGS', {
                                widgetId: newWidgetSettings.id,
                            })
                        } catch (e) {
                            await this.showFailureModal(e)
                            this.$emit('savingWidget', false)
                        }
                    }
                } else {
                    this.$store.dispatch("showModal", this.constructModalSettings(
                        constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_INVALID_FORM_INPUT,
                        false,
                        {
                            errors: validator,
                            type: 'widget',
                            dismissible: false,
                        },
                        false,
                        true)
                    )
                }
            },

            async showFailureModal (error) {
                let message = ``
                if (error.message.includes(constants.HTTP_RESPONSE_CODES.BAD_REQUEST)) {
                    message = this.$t("widgets.permissionsError")
                } else if (error.message.includes(constants.HTTP_RESPONSE_CODES.NOT_FOUND)) {
                    message = this.$t("widgets.doesNotExist")
                } else if (error.message.includes(constants.HTTP_RESPONSE_CODES.INTERNAL_SERVER_ERROR)) {
                    message = this.$t("serverError")
                } else {
                    message = this.$t("pleaseTryAgain")
                }

                await this.failModal(
                      message,
                      this.$t("somethingWentWrong"),
                      () => this.showConfirmation = true)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .optional-elements-spacing {
        padding: 0px 5px 15px 0px;
    }

    .sz-FormEditWidget {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 5;

        &-body {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            align-items: stretch;
        }

        &-section {
            display: flex;
            flex-direction: column;
            width: 30%;
            height: 100%;
            margin: 0 1.25rem;
        }

        &-divider {
            width: 0.0625rem;
            height: inherit;
            background-color: $color-base-grey;
            opacity: 0.2;
        }

        &-label {
            @extend %font-topbar-heading;
            font-size: 15px;
            align-items: flex-start;
            text-align: left;
            color: $color-white;
            padding-bottom: 0.25rem;
        }

        &-sectionSelectionSpacing {
            margin: 15px 0px 15px 8px;
        }

        &-sectionInputSpacing {
        margin: 0px 0px 15px 8px;
    }

        &-row {
            padding-bottom: 0.75rem;

            &-title {
                display: flex;
                @extend %font-topbar-heading;
                font-size: 12px;
                color: $color-white;
            }
            &-selections {
                &-selection {
                    margin-bottom: 1rem;
                }

                &-modules {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &-date {
                display: flex;
                flex-direction: column;
                &-presets {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-bottom: 0.75rem;
                }
            }
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
            }

            &-save {
                @extend %button-layout;
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1rem;
                border-radius: 0;
            }
        }
    }

    .slide-enter-active {
        transition: all .3s ease;
        max-height: 250px;
    }

    .slide-leave-active {
        transition: all .2s ease;
        max-height: 250px;
    }

    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }

    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }
</style>

<template>
  <div class="sz-ModalUploadConfiguration">
    <LoadingSpinner 
      v-show="uploadingConfiguration" 
      class="loading" 
      height="large"
    />
    <div class="sz-ModalUploadConfiguration-title">
      {{ $t(`modals.modalUploadConfiguration.title`) }}
    </div>
    <div class="sz-ModalUploadConfiguration-body">
      <div class="sz-ModalUploadConfiguration-body-select">
        <FormSectionSelection 
          :title="$t(`modals.modalUploadConfiguration.configType`)" 
          :options="configTypes" 
          alignment="row" 
          selection-type="radio" 
          @updateSelectedOptions="updateConfigType"
        />
      </div>
      <span class="sz-ModalUploadConfiguration-body-uploadFile-title">
        {{ $t(`modals.modalUploadConfiguration.selectFile`) }}
      </span>
      <div class="sz-ModalUploadConfiguration-body-uploadFile">
        <input
          id="files" 
          ref="files" 
          type="file" 
          :accept="acceptFileType" 
          single="single" 
          :disabled="!configTypeSelected" 
          @change="handleFilesUpload"
        >
      </div>
      <div 
        class="sz-ModalUploadConfiguration-body-deviceTypeDropdown"
        :class="`sz-ModalUploadConfiguration-body-deviceTypeDropdown${doesConfigRequireDeviceType() ? '' : '-disabled'}`"
      >
        <FormSectionDropdownSingleSelect
          :key="this.configType"
          :title="$t(`modals.modalUploadConfiguration.deviceTypeTitle`)"
          :options="deviceTypeOptions"
          :is-sorted="true"
          :placeholder="doesConfigRequireDeviceType()
            ? $t('modals.modalUploadConfiguration.deviceTypeRequiredPlaceholder')
            : $t('modals.modalUploadConfiguration.deviceTypeNotRequiredPlaceholder')"
          @updateSelectedOption="selectDeviceType"
        />
      </div>
      <div class="sz-ModalUploadConfiguration-body-description">
        <FormSectionInput
          :key="this.configType"
          :title="$t(`modals.modalUploadConfiguration.addDesc`)" 
          :placeholder="$t(`modals.modalUploadConfiguration.addDescPlaceholder`)" 
          text-size="noAllCaps" 
          :use-input-tag="changeableDescription"
          :disable-input="configTypeSelected ? false : true"
          @updated="updateDescription"
        />
      </div>
    </div>
    <div class="sz-ModalUploadConfiguration-footer">
      <div 
        class="sz-ModalUploadConfiguration-footer-buttons sz-ModalUploadConfiguration-footer-buttons-cancel" 
        @click.stop="close"
      >
        {{ $t(`modals.modalUploadConfiguration.cancel`) }}
      </div>
      <div 
        class="sz-ModalUploadConfiguration-footer-buttons sz-ModalUploadConfiguration-footer-buttons-upload" 
        :class="{'inactive': settingsValid === false}" 
        @click.stop="upload"
      >
        {{ $t(`modals.modalUploadConfiguration.upload`) }}
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import FormSectionSelection from 'components/Shared/FormSectionSelection'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'

    export default {
        name: "ModalUploadConfiguration",

        components: {
            LoadingSpinner,
            FormSectionSelection,
            FormSectionInput,
            FormSectionDropdownSingleSelect,
        },

        mixins: [
            modalSettings,
        ],

        data() {
            return {
                files: null,
                configType: '',
                description: '',
                deviceType: '',
            }
        },

        computed: {
            ...mapGetters([
                'uploadingConfiguration',
            ]),

            hasFiles() {
                return this.files && this.files.length > 0
            },

            acceptFileType() {
                return constants.DEVICE_CONFIG_FILE_TYPES[this.configType]
            },

            configTypeSelected() {
                return constants.DEVICE_CONFIG_TYPES.includes(this.configType)
            },

            configTypes() {
                return constants.DEVICE_CONFIG_TYPES.map((type) => {
                    return {
                        type: type,
                        name: this.$t(`modals.modalUploadConfiguration.${type}`),
                        icon: type,
                    }
                })
            },

            isDescriptionValid() {
                return !this.isDescriptionTooShort
            },

            isDescriptionTooShort() {
                return !!(this.description.length === 0)
            },

            isDeviceTypeValid() {
                return !!(this.deviceType && Object.values(constants.DEVICE_TYPES).includes(this.deviceType))
            },

            isDeviceTypeConfigurationValid() {
                switch(this.configType) {
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE:
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS:
                        return this.isDeviceTypeValid

                    case constants.DEVICE_CONFIG_CHARACTERISTICS.AZURE:
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK:
                        return this.deviceType === null
                    
                    case undefined:
                        // no device type should be selected when a configuration isn't selected
                        return false

                    default:
                        return false
                }
            },

            settingsValid() {
                return !!(this.hasFiles && this.configTypeSelected && this.isDescriptionValid && this.isDeviceTypeConfigurationValid)
            },

            changeableDescription() {
                return true
            },

            deviceTypeOptions() {
                return Object.values(constants.DEVICE_TYPES).map((deviceType) => {
                    return {
                        name: deviceType,
                        type: deviceType,
                    }
                })
            },
        },

        methods: {
            updateConfigType(newConfigType) {
                this.configType = newConfigType
                this.$refs.files.value = null
                this.files = null
                this.description = ''
            },

            updateDescription(newDescription) {
                this.description = newDescription
            },

            handleFilesUpload() {
                this.files = this.$refs.files.files
            },

            close() {
                this.$emit('close')
            },

            async upload() {
                if (this.settingsValid) {
                    let formData = this.generateFormData()
                    try {
                        switch(this.configType) {
                            case constants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK:
                                await this.$store.dispatch('uploadNetworkConfig', formData)
                                break
                            case constants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE:
                                await this.$store.dispatch('uploadFirmware', formData)
                                break
                            case constants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS:
                                await this.$store.dispatch('uploadOperationsConfig', formData)
                                break
                            case constants.DEVICE_CONFIG_CHARACTERISTICS.AZURE:
                                await this.$store.dispatch('uploadAzureConfig', formData)
                                break
                        }
                        this.openSuccessModal()
                    } catch (error) {
                        this.openFailureModal(error)
                    }
                }
            },

            generateFormData() {
                let formData = new FormData()
                formData.append(constants.DEVICE_CONFIG_API_PARAMS.FILE, this.files[0])
                formData.append(constants.DEVICE_CONFIG_API_PARAMS.DESC, this.description)
                formData.append(constants.DEVICE_CONFIG_API_PARAMS.DISABLE, false)
                if (this.configType === constants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE || this.configType === constants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS) {
                    formData.append(constants.DEVICE_CONFIG_API_PARAMS.DEVICE_TYPE, this.deviceType)
                }

                return formData
            },

            openSuccessModal() {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_SUCCESS,
                    true,
                    {
                        message: this.$t('modals.modalUploadConfiguration.success'),
                        dismissible: false,
                    },
                    true,
                    true)
                )
            },

            openFailureModal(error) {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_FAILURE,
                    false,
                    {
                        message: this.$t('modals.modalUploadConfiguration.failure'),
                        reason: error.toString(),
                        dismissible: true,
                    },
                    true,
                    true)
                )
            },

            selectDeviceType(deviceType) {
                this.deviceType = deviceType
            },

            doesConfigRequireDeviceType() {
                switch(this.configType) {
                    case null:
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.AZURE:
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK:
                        return false
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE:
                    case constants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS:
                        return true
                    default:
                        return true
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalUploadConfiguration {
        min-height: 10rem;
        width: 30vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &-title {
            @extend %font-heading;
            margin: 1rem 0;
        }

        &-body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            width: 100%;
            padding-left: 0.25rem;

            &-warning {
                @extend %font-content;
                color: $color-risk-red;
                font-style: italic;
                padding-bottom: 0.25rem;
            }

            &-select {
                width: 100%;
                padding-bottom: 1rem;
            }

            &-uploadFile {
                @extend %font-content;
                font-size: 12px;
                padding-bottom: 1rem;

                &-title {
                    @extend %font-heading;
                    font-size: 12px;
                    font-weight: 500;
                    padding-bottom: 0.25rem;
                }
            }

            &-deviceTypeDropdown {
                width: 95%;
                padding-right: 0.75rem;
                padding-bottom: 1rem;
                
                &-disabled {
                    ::v-deep .sz-FormSectionDropdownSingleSelect-selectedOption {
                        color: gray;
                    }

                    ::v-deep .sz-FormSectionDropdownSingleSelect-option {
                        @extend %font-content;
                        pointer-events:none;
                        color: gray;
                    }

                    ::v-deep .sz-FormSectionDropdownSingleSelect-box, ::v-deep .sz-FormSectionDropdownSingleSelect-options {
                        cursor: not-allowed;
                    }
                }
            }

            &-description {
                width: 95%;
                padding-bottom: 1rem;

                ::v-deep .sz-FormSectionInput-input.sz-FormSectionInput-input {
                    @extend %font-content;
                    width: 100%; // default is not 100% and makes it difficult to match the description with the device type dropdown
                    font-size: 16px;
                }
            }
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.5rem;

            &-buttons {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 0.5rem;
                cursor: pointer;
                font-size: 12px;

                &-cancel {
                    @extend %button-layout;
                    background-color: transparent;
                }

                &-upload {
                    @extend %button-layout;
                    background-color: $color-lifebooster-light-green;
                    padding: 0.5rem 1rem;
                    border-radius: 0;
                }
            }
        }
    }

    .inactive {
        @extend %button-disabled;
        background-color: $color-button-disabled-bg;
        color: $color-unselected-text;
        cursor: not-allowed;
    }

    .loading {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: lighten($color-box-silver, 30%);
        opacity: .5;
        cursor: default;
        border-radius: 3px;
    }
</style>

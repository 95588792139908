<template lang="pug">
    div(class="sz-ModalInvalidFormInput")
        div(class="sz-ModalInvalidFormInput-title") {{ $t(`modals.modalInvalidFormInput.header`)}}
        div(class="sz-ModalInvalidFormInput-body")
            div(class="sz-ModalInvalidFormInput-message") {{ $t(`modals.modalInvalidFormInput.message`, { numberOfErrors, type }) }}
            ul(class="sz-ModalInvalidFormInput-errors")
                li(
                    v-for="error in errorMessages",
                    class="sz-ModalInvalidFormInput-error") {{ error }}
        div(class="sz-ModalInvalidFormInput-footer")
            div(
                @click.stop="close",
                class="sz-ModalInvalidFormInput-button sz-ModalInvalidFormInput-button-okay") {{ $t(`modals.modalInvalidFormInput.okay`) }}
</template>

<script>
    export default {
        name: "ModalInvalidFormInput",

        props: {
            errors: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },

        computed: {
            numberOfErrors () {
                return this.errorMessages.length
            },

            errorMessages () {
                return Object.keys(this.errors)
                    .map((key) => {
                        if (!this.errors[key] && key !== 'valid') {
                            return this.$t(`modals.modalInvalidFormInput.${key}`)
                        }
                    })
                    .filter(message => !!message)
            },
        },

        methods: {
            close () {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalInvalidFormInput {
        width: 19rem;
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:  $color-dropdown-background;
        color: $color-white;
        padding: 1rem;




        &-title {
            @extend %font-topbar-heading;
            width: 100%;
            padding-bottom: 0.75rem;
        }

        &-body {
            width: 100%
        }

        &-message {
            padding-bottom: 0.75rem;
        }

        &-errors {
            padding-bottom: 0.75rem;
            font-weight: 400;
        }

        li.sz-ModalInvalidFormInput-error {
            text-align: left;
        }

        &-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &-button {
            @extend %button-layout;
            @extend %font-topbar-heading;

            color: $color-white;
            cursor: pointer;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            border-radius: 0;

            &-okay {
                &:hover {
                    background-color:  lighten($color-lifebooster-light-green, 10%);
                }
            }
        }
    }
</style>

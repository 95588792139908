<template lang="pug">
    transition(name="modal")
        div(
            v-if="showModal",
            @click="clickOutside"
            class="sz-Modal-mask")
            div(
                :class="{'dark': this.modal.commonSettings.dark}"
                class="sz-Modal-container")
                div(
                    v-if="showCloseButton",
                    @click="closeModal()",
                    class="sz-Modal-close")
                    SvgIcon(
                        icon="plus",
                        width=16,
                        height=16,
                        class="sz-Modal-closeButton")
                component(
                    :is="modalType",
                    v-bind="modal.modalSpecificSettings",
                    @close="closeModal")

</template>

<script>
import ModalDefault from './ModalDefault'
import SvgIcon from '../Shared/SvgIcon'
import ModalEditWidget from './ModalEditWidget'
import ModalDeleteWidget from './ModalDeleteWidget'
import ModalEditUser from './ModalEditUser'
import ModalBulkUpload from './ModalBulkUpload'
import ModalConfirmCapture from './ModalConfirmCapture'
import ModalUploadCertificates from './ModalUploadCertificates'
import ModalInitiateCapture from './ModalInitiateCapture'
import ModalSuccess from './ModalSuccess'
import ModalFailure from './ModalFailure'
import ModalInvalidFormInput from './ModalInvalidFormInput'
import ModalEditBulkUser from './ModalEditBulkUser'
import ModalConfirmation from './ModalConfirmation'
import ModalDropdownSelect from './ModalDropdownSelect'
import ModalBulkEditAssessments from './ModalBulkEditAssessments'
import ModalEditConfigurations from './ModalEditConfigurations'
import ModalUploadConfiguration from './ModalUploadConfiguration'
import ModalEditDefaultAssessmentTags from './ModalEditDefaultAssessmentTags'
import ModalGoToExternalPage from './ModalGoToExternalPage'
import ModalUploadTaskBreakdown from './ModalUploadTaskBreakdown'
import ModalManageTaskBreakdown from './ModalManageTaskBreakdown'
import ModalCaptureDetails from './ModalCaptureDetails'

export default {
  name: 'Modal',

  components: {
    ModalDefault,
    SvgIcon,
    ModalEditWidget,
    ModalDeleteWidget,
    ModalEditUser,
    ModalBulkUpload,
    ModalConfirmCapture,
    ModalUploadCertificates,
    ModalInitiateCapture,
    ModalSuccess,
    ModalFailure,
    ModalEditBulkUser,
    ModalInvalidFormInput,
    ModalConfirmation,
    ModalDropdownSelect,
    ModalBulkEditAssessments,
    ModalEditConfigurations,
    ModalUploadConfiguration,
    ModalEditDefaultAssessmentTags,
    ModalGoToExternalPage,
    ModalUploadTaskBreakdown,
    ModalManageTaskBreakdown,
    ModalCaptureDetails,
  },

  props: {
    modal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
    }
  },

  computed: {
    modalType() {
      return this.modal.commonSettings.modalType
    },

    showCloseButton() {
      return this.modal.commonSettings.showCloseButton
    },
  },

  mounted() {
    this.showModal = true
  },

  methods: {
    closeModal() {
      this.showModal = false
      this.$store.dispatch('closeModal')
    },

    clickOutside() {
      if (this.modal.commonSettings.clickOutside) {
        this.closeModal()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.sz-Modal {
  &-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-modal-mask;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $layer-modal-z-index;
  }
  &-container {
    position: relative;
    background: $color-modal-background;
    box-shadow: $color-modal-box-shadow;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    min-height: 2rem;
    padding: 0.5rem;
    color: $color-table-font-black;

    &.dark {
      background-color: $color-dropdown-background;
    }
  }

  &-close {
    position: absolute;
    right: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.25rem;
    margin: 0.25rem;
    cursor: pointer;
  }

  &-closeButton {
    transform: rotate(45deg);
    color: $color-white;
  }
}

.modal {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>

import CompanyService from 'services/company.service'
import CustomerService from 'services/customer.service'
import WorkerService from 'services/worker.service'
import MeService from 'services/me.service'
import LbAdminService from 'services/lbAdmin.service'
import { getInstance } from 'auth/index'
import { getRoles, userHasRole, userIsAdmin, userIsAssessor } from 'helpers/permissionsHelper'
import constants from 'helpers/constants'
import { cloneDeep } from 'lodash'

const user = {
  state: {
    OCTs: [],
    jobs: [],
    currentCompany: undefined,
    currentCustomer: undefined,
    defaultCompanyName: undefined,
    defaultCustomerName: undefined,
    availableCompanies: undefined,
    availableCustomers: undefined,
    companyMapper: {},
    customerMapper: {},
    availableUsers: [],
    loadingUsers: true,
    loadingPersonalDetails: true,
    savingPersonalDetails: false,
    saveStatus: undefined,
    userID: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    mobile: undefined,
    height: undefined,
    userVisible: undefined,
    companyAnon: undefined,
    customerAnon: undefined,
    reqUserProfile: {},
  },

  getters: {
    OCTs: (state) => {
      return state.OCTs
    },

    jobs: (state) => {
      return state.jobs
    },

    userID: () => {
      return getInstance().user[constants.AUTH0_USER_INFO_KEYS.USER_ID]
    },

    auth0User: () => {
      return getInstance().user
    },

    userRole: () => {
      return getRoles(getInstance().user)
    },

    userHasAdminRole: () => {
      return userIsAdmin(getInstance().user)
    },

    userHasAssessorRole: () => {
      return userIsAssessor(getInstance().user)
    },

    userHasLBAdminRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.LB_ADMIN)
    },

    userHasLBAssessorRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.LB_ASSESSOR)
    },

    userHasCustomerAdminRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.CUSTOMER_ADMIN)
    },

    userHasCompanyAdminRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.COMPANY_ADMIN)
    },

    userHasCustomerAssesorRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.CUSTOMER_ASSESSOR)
    },

    userHasCompanyAssesorRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.COMPANY_ASSESSOR)
    },

    userHasWorkerRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.WORKER)
    },

    userHasBaseWorkerRole: () => {
      return userHasRole(getInstance().user, constants.ROLES.BASE_WORKER)
    },

    availableCustomers: (state) => {
      return state.availableCustomers
    },

    customerMapper: (state) => {
      return state.customerMapper
    },

    availableCompanies: (state) => {
      return state.availableCompanies
    },

    companyMapper: (state) => {
      return state.companyMapper
    },

    currentCompany: (state) => {
      return state.currentCompany
        ? state.currentCompany
        : getInstance().user[constants.AUTH0_USER_INFO_KEYS.COMPANY]
    },

    currentCustomer: (state) => {
      return state.currentCustomer
        ? state.currentCustomer
        : getInstance().user[constants.AUTH0_USER_INFO_KEYS.CUSTOMER]
    },

    defaultCompanyName: (state) => {
      return state.defaultCompanyName
    },

    defaultCustomerName: (state) => {
      return state.defaultCustomerName
    },

    personalDetailsPopulated: (state) => {
      return (
        state.email &&
        (state.firstName ||
          state.lastName ||
          state.gender ||
          state.mobile ||
          state.height ||
          state.userVisible ||
          state.companyAnon ||
          state.customerAnon)
      )
    },

    availableUsers: (state) => {
      return state.availableUsers
    },

    loadingUsers: (state) => {
      return state.loadingUsers
    },

    loadingPersonalDetails: (state) => {
      return state.loadingPersonalDetails
    },

    savingPersonalDetails: (state) => {
      return state.savingPersonalDetails
    },

    saveStatus: (state) => {
      return state.saveStatus
    },

    userID: (state) => {
      return state.userID
    },

    email: (state) => {
      return state.email
    },

    firstName: (state) => {
      return state.firstName
    },

    lastName: (state) => {
      return state.lastName
    },

    gender: (state) => {
      return state.gender
    },

    mobile: (state) => {
      return state.mobile
    },

    height: (state) => {
      return state.height
    },

    userVisible: (state) => {
      return state.userVisible
    },

    companyAnon: (state) => {
      return state.companyAnon
    },

    customerAnon: (state) => {
      return state.customerAnon
    },

    reqUserProfile: (state) => {
      return state.reqUserProfile
    },
  },

  mutations: {
    setOCTs(state, payload) {
      state.OCTs = payload
    },

    setJobTypes(state, payload) {
      state.jobs = payload
    },

    setCurrentCompany(state, payload) {
      state.currentCompany = payload
    },

    setCurrentCustomer(state, payload) {
      state.currentCustomer = payload
    },

    setCompany(state, payload) {
      state.company = payload
    },

    setCustomer(state, payload) {
      state.customer = payload
    },

    setAvailableCompanies(state, payload) {
      state.availableCompanies = payload
    },

    setCompanyMapper(state, payload) {
      state.companyMapper = payload.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.name }),
        {}
      )
    },

    setAvailableCustomers(state, payload) {
      state.availableCustomers = payload
    },

    setCustomerMapper(state, payload) {
      state.customerMapper = payload.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.name }),
        {}
      )
    },

    setAvailableUsers(state, payload) {
      state.availableUsers = payload
    },

    setLoadingUsers(state, payload) {
      state.loadingUsers = payload
    },

    setLoadingPersonalDetails(state, payload) {
      state.loadingPersonalDetails = payload
    },

    setSavingPersonalDetails(state, payload) {
      state.savingPersonalDetails = payload
    },

    setPersonalDetails(state, payload) {
      state.userID = payload.userID
      state.email = payload.email
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.gender = payload.gender
      state.mobile = payload.mobile
      state.height = 165

      if (payload.anonymousMode) {
        state.userVisible = payload.anonymousMode.userVisible
        state.companyAnon = payload.anonymousMode.companyAnon
        state.customerAnon = payload.anonymousMode.customerAnon
      }

      state.defaultCompanyName = payload.company
      state.defaultCustomerName = payload.customer
    },

    setSaveStatus(state, payload) {
      state.saveStatus = payload
    },

    setReqUserProfile(state, payload) {
      state.reqUserProfile = payload
    },
  },

  actions: {
    async getAvailableCustomersAndCompanies({ dispatch, rootGetters }) {
      if (rootGetters.userHasAdminRole) {
        if (
          constants.DASHBOARD_PERMISSIONS_ENUM[rootGetters.userRole] >=
            constants.DASHBOARD_PERMISSIONS_ENUM[constants.ROLES.CUSTOMER_ADMIN] &&
          (!rootGetters.availableCompanies || !rootGetters.availableCompanies.length)
        ) {
          dispatch('getAvailableCompanies')
        }

        if (
          constants.DASHBOARD_PERMISSIONS_ENUM[rootGetters.userRole] >=
            constants.DASHBOARD_PERMISSIONS_ENUM[constants.ROLES.LB_ADMIN] &&
          (!rootGetters.availableCompanies || !rootGetters.availableCompanies.length)
        ) {
          dispatch('getAvailableCustomers')
        }
      }
    },

    async getAvailableCompanies({ commit, rootGetters }) {
      let availableCompanies = []
      try {
        availableCompanies = await CustomerService.getAllCompaniesInCustomer(
          rootGetters.currentCustomer
        )
      } catch (e) {
        console.log(e)
      }
      commit('setAvailableCompanies', availableCompanies)
      commit('setCompanyMapper', availableCompanies)
    },

    async getAvailableCustomers({ commit }) {
      let availableCustomers = []

      try {
        availableCustomers = await LbAdminService.getAllCustomers()
      } catch (e) {
        console.log(e)
      }
      commit('setAvailableCustomers', availableCustomers)
      commit('setCustomerMapper', availableCustomers)
    },

    async updateCurrentCompany({ commit, dispatch }, payload) {
      commit('setCurrentCompany', payload.id)
      await Promise.all([
        dispatch('updateJobTypes'),
        dispatch('updateAvailableUsers'),
        dispatch('getAllUsersInCompany'),
        dispatch('getAllCompanyTags'),
        dispatch('getInitiateCaptureUsers'),
        dispatch('updateWidgetsSettings'),
        dispatch('getAssessmentsFromCompany', {}),
        dispatch('getAllAssessmentTags'),
      ])
    },

    async updateCurrentCustomer({ commit, dispatch, rootGetters }, payload) {
      commit('setCurrentCustomer', payload.id)
      await dispatch('getAvailableCompanies')

      dispatch('updateCurrentCompany', rootGetters.availableCompanies[0])
    },

    async updateCurrentCustomerAndCompany({ commit, dispatch }, payload) {
      commit('setCurrentCustomer', payload.customerID)
      commit('setCurrentCompany', payload.companyID)
      Promise.all([
        dispatch('updateJobTypes'),
        dispatch('updateAvailableUsers'),
        dispatch('getAllUsersInCompany'),
        dispatch('getAllCompanyTags'),
        dispatch('getAllAssessmentTags'),
      ])
    },

    updateOCTs({ commit }) {
      const auth0Instance = getInstance()
      const tags = auth0Instance.user[constants.AUTH0_USER_INFO_KEYS.TAGS]
      let OCTs = tags.owner || []
      OCTs = OCTs.map((tag) => {
        return { name: tag }
      })
      commit('setOCTs', OCTs)
    },

    async updateJobTypes({ commit, rootGetters }) {
      let jobs = []
      try {
        jobs = await CompanyService.getAllCompanyJobTypes(
          rootGetters.currentCustomer,
          rootGetters.currentCompany
        )
        jobs = jobs.map((job) => {
          return { name: job.name, id: job.code }
        })
      } catch (e) {
        console.log(e)
      }
      commit('setJobTypes', jobs)
    },

    async updateAvailableUsers({ commit, rootGetters }) {
      let users = []
      commit('setLoadingUsers', true)
      try {
        users = await WorkerService.getAllAvailableWorkers(
          rootGetters.currentCustomer,
          rootGetters.currentCompany
        )
      } catch (e) {
        console.log(e)
      }
      commit('setAvailableUsers', users)
      commit('setLoadingUsers', false)
    },

    async getPersonalDetails({ commit, rootGetters }) {
      const hasDetails = rootGetters.personalDetailsPopulated
      commit('setLoadingPersonalDetails', !hasDetails)
      let user = {}
      try {
        user = await MeService.getProfile(rootGetters.currentCustomer, rootGetters.currentCompany)
      } catch (e) {
        console.log(e)
      }

      commit('setPersonalDetails', user)
      commit('setLoadingPersonalDetails', false)
    },

    async saveNewPersonalDetails({ commit, dispatch, rootGetters }, payload) {
      commit('setSavingPersonalDetails', true)
      let newDetails = cloneDeep(payload)
      let userID = getInstance().user[constants.AUTH0_USER_INFO_KEYS.USER_ID]
      try {
        let user = await MeService.updateProfile(
          rootGetters.currentCustomer,
          rootGetters.currentCompany,
          newDetails
        )
        commit('setSaveStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
        commit('setPersonalDetails', newDetails)
      } catch (e) {
        commit('setSaveStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
        console.log(e)
      }
      commit('setSavingPersonalDetails', false)
    },

    async saveNewUserVisibility({ commit, dispatch, rootGetters }, payload) {
      commit('setSavingPersonalDetails', true)
      let newDetails = cloneDeep(payload)
      let userID = getInstance().user[constants.AUTH0_USER_INFO_KEYS.USER_ID]
      try {
        let user = await MeService.updateUserVisibility(
          rootGetters.currentCustomer,
          rootGetters.currentCompany,
          userID,
          newDetails
        )
        commit('setSaveStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
        commit('setPersonalDetails', {
          anonymousMode: {
            userVisible: newDetails.userVisible,
          },
        })
      } catch (e) {
        commit('setSaveStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
        console.log(e)
      }

      commit('setSavingPersonalDetails', false)
    },

    updateSaveStatus({ commit }, payload) {
      commit('setSaveStatus', payload)
    },

    async getUserCompleteProfileByID({ rootGetters, commit }, payload) {
      try {
        const userProfile = await CompanyService.getUserCompleteProfileByID(
          rootGetters.currentCustomer,
          rootGetters.currentCompany,
          payload
        )

        commit('setReqUserProfile', userProfile)
      } catch (e) {
        console.log(e)
      }
    },
  },
}

export default user

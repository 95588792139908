import ApiService from './api.service'

const CustomerService = {
    getAllCompaniesInCustomer: async function (customerID) {
        const url = `/customers/${customerID}/companies`

        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },
}

export default CustomerService

<template lang="pug">
  div.modal-overlay(v-if="isVisible", @click.self="closeModal")
    .modal
      p.modal-text Deprecated
      button.close-button(@click="closeModal") Close
  </template>

<script>
/**
 * This component is no longer used (moved to the new UI), but is rather difficult to rip out of the system entirely. Using a simple deprecated modal to keep the app compliling
 */
export default {
  data() {
    return {
      isVisible: true, // Controls the visibility of the modal
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-text {
  margin-bottom: 20px;
}

.close-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f2f2f2;
  border: none;
  border-radius: 5px;
}
</style>

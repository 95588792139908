<template lang="pug">
    div(class="sz-ModalConfirmCapture")
        div(class="sz-ModalConfirmCapture-title") {{ $t(`modals.modalConfirmCapture.title`)}}
        div(class="sz-ModalConfirmCapture-body")
            div(class="sz-ModalConfirmCapture-message") {{ $t(`modals.modalConfirmCapture.instructions`, captureInfo) }}
        div(class="sz-ModalConfirmCapture-footer")
            div(
                @click.stop="close",
                class="sz-ModalConfirmCapture-button sz-ModalConfirmCapture-button-no") {{ $t(`modals.modalConfirmCapture.cancel`) }}
            div(
                @click.stop="confirm",
                class="sz-ModalConfirmCapture-button sz-ModalConfirmCapture-button-yes") {{ $t(`modals.modalConfirmCapture.start`) }}
</template>

<script>


    export default {
        name: "ModalConfirmCapture",

        props: {
            sid: {
                required: true,
                type: String,
            },

            devices: {
                required: true,
                type: Number,
            },
        },

        computed: {
            captureInfo () {
                return {
                    devices: this.devices,
                    sid: this.sid,
                }
            },
        },

        methods: {
            close () {
                this.$emit('close')
            },

            confirm () {
                console.log('started capture!')
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalConfirmCapture {
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &-body {
            text-align: center;
            font-size: 0.875rem;
            max-width: 80%;
        }

        &-footer {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        &-button {
            @extend %button-layout;
            width: 4rem;
        }
    }
</style>
<template lang="pug">
    div(class="sz-FormSectionSelection")
        div(class="sz-FormSectionSelection-label") {{ title }}
        div(
            :class="alignment",
            class="sz-FormSectionSelection-options")
            div(
                v-for="option in options",
                :key="option.name"
                class="sz-FormSectionSelection-option",
                :class="[alignment, {'selected': selectedOptions ? selectedOptions.includes(option.type) : ''}]")
                input(
                    :type="selectionType",
                    :id="option.type",
                    :value="option.type",
                    v-model="selectedOptions",
                    class="sz-FormSectionSelection-option-selector")
                SvgIcon(
                    v-if="option.icon",
                    :icon="option.icon",
                    width="15",
                    height="15",
                    class="sz-FormSectionSelection-option-icon",
                    :class="alignment")
                label(
                    :for="option.type",
                    class="sz-FormSectionSelection-option-title",
                    :class="alignment") {{ option.name }}
</template>

<script>
    import { cloneDeep } from 'lodash'
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: "FormSectionSelection",

        components: {
            SvgIcon,
        },

        props: {
            title: {
                required: true,
                type: String,
            },
            options: {
                type: Array,
                required: true,
            },
            selectionType: {
                type: String,
                required: true,
            },
            defaultSelectionOptions: {
                required: false,
                type: [Array, Object, String],
                default: null,
            },

            alignment: {
                type: String,
                required: false,
                default: 'column',
            },
        },

        data () {
            return {
                selectedOptions: [],
            }
        },

        watch: {
            selectedOptions () {
                const newSelectedOption = cloneDeep(this.selectedOptions)
                this.$emit('updateSelectedOptions', newSelectedOption)
            },
        },

        mounted () {
            this.selectedOptions = this.defaultSelectionOptions
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionSelection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-label {
            @extend %font-topbar-heading;
            font-size: 12px;
            color: $color-white;
            padding-bottom: 0.25rem;
        }

        &-options {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.row {
                flex-direction: row;
            }
        }

        &-option {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid $color-base-grey;
            height: 2.2rem;
            margin: 0.25rem 0;
            color: $color-white;

            &.row {
                width: 100%;
                margin: 0 0.25rem 0 0;
            }

            &.selected, &:hover {
                background-color: $color-lifebooster-medium-green;
                border: 1px solid $color-lifebooster-medium-green;
            }

            &-title {
                @extend %font-topbar-heading;
                font-size: 10px;
                color: $color-white;
                padding-left: 0.85rem;

                &.row {
                    padding-left: 0.4rem;
                }
            }

            &-icon {
                padding-top: 0.25rem;
                padding-left: 1rem;

                &.row {
                    padding-top: 0.4rem;
                    padding-left: 0.4rem;
                }
            }

            input {
                position: absolute;
                appearance: none;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                cursor: pointer;
            }
        }
    }

</style>

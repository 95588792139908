<template lang="pug">
    div(class="sz-ModalDeleteWidget")
        div(class="sz-ModalDeleteWidget-title") {{ $t(`modals.modalDeleteWidget.title`)}}
        div(class="sz-ModalDeleteWidget-body")
            div(class="sz-ModalDeleteWidget-message") {{ $t(`modals.modalDeleteWidget.confirmation`) }}
        div(class="sz-ModalDeleteWidget-footer")
            div(
                @click.stop="close",
                class="sz-ModalDeleteWidget-button sz-ModalDeleteWidget-button-no") {{ $t(`modals.modalDeleteWidget.no`) }}
            div(
                @click.stop="deleteModal",
                class="sz-ModalDeleteWidget-button sz-ModalDeleteWidget-button-yes") {{ $t(`modals.modalDeleteWidget.yes`) }}

    
</template>

<script>
    import EventBus from 'src/eventBus'
    export default {
        name: "ModalDeleteWidget",

        props: {
            widgetId: {
                type: String,
                required: true,
            },
        },

        methods: {
            close () {
                this.$emit('close')
            },

            deleteModal () {
                this.$store.dispatch('deleteWidget', this.widgetId)
                this.$emit('close')
                EventBus.$emit('DELETE_MUTABLE_WIDGET_SETTINGS', {
                    widgetId:  this.widgetId,
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalDeleteWidget {
        width: 20rem;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:  $color-dropdown-background;
        color: $color-white;
        padding: 1rem;




        &-title {
            @extend %font-topbar-heading;
            width: 100%;
        }
        &-body {
            width: 100%
        }

        &-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-button {
            @extend %button-layout;
            @extend %font-topbar-heading;

            color: $color-white;
            cursor: pointer;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            border-radius: 0;

            &-no {
                padding: 0.5rem 4rem;
                background-color: $color-dropdown-background;
                border-color: $color-white;

                &:hover {
                    background-color: lighten($color-lifebooster-light-green, 10%);
                    border-color:  lighten($color-lifebooster-light-green, 10%);
                }
            }

            &-yes {
                &:hover {
                    background-color:  lighten($color-lifebooster-light-green, 10%);
                }
            }
        }
    }
</style>
<template lang="pug">
    div(class="sz-ModalDefaultal")
        div(class="sz-ModalDefaultal-header") {{ data.header}}
        div(class="sz-ModalDefaultal-body") {{ data.body }}

</template>

<script>
    export default {
        name: "ModalDefaultal",

        props: {
            data: {
                type: Object,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ModalDefaultal {
        color: $color-table-dark-background;
        width: 25rem;
        height: 20rem;
    }

</style>
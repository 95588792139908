<template lang="pug">
    div(class="sz-ModalUploadCertificates")
        LoadingSpinner(
            v-show="uploadingCertificates",
            height="large",
            class="loading")
        div(class="sz-ModalUploadCertificates-title") {{ $t(`modals.modalUploadCertificates.title`)}}
        div(class="sz-ModalUploadCertificates-body")
            div(class="sz-ModalUploadCertificates-message") {{ $t(`modals.modalUploadCertificates.instructions`) }}
            input(
                type="file",
                id="files",
                ref="files",
                accept=".pem"
                multiple,
                @change="handleFilesUpload",
                class="sz-ModalUploadCertificates-fileUploader")
        div(class="sz-ModalUploadCertificates-footer")
            div(
                @click.stop="close",
                class="sz-ModalUploadCertificates-button sz-ModalUploadCertificates-button-cancel") {{ $t(`modals.modalUploadCertificates.cancel`) }}
            div(
                @click.stop="upload",
                class="sz-ModalUploadCertificates-button sz-ModalUploadCertificates-button-save",
                :class="{'inactive': !hasFiles}") {{ $t(`modals.modalUploadCertificates.upload`) }}
    </label>
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'

    export default {
        name: "ModalUploadCertificates",

        components: {
            LoadingSpinner,
        },

        mixins: [
            modalSettings,
        ],

        data () {
            return {
                files: null,
            }
        },

        computed: {
            ...mapGetters([
                'uploadingCertificates',
                'successfulCertificateUpload',
            ]),

            hasFiles () {
                return this.files && this.files.length > 0
            },
        },

        methods: {
            handleFilesUpload () {
                this.files = this.$refs.files.files
            },

            close () {
                this.$emit('close')
            },

            async upload () {
                if (this.hasFiles) {
                    let formData = new FormData()
                    for (let i=0; i<this.files.length; i++){
                        const file = this.files[i]
                        formData.append(file.name, file)
                    }

                    try {
                        await this.$store.dispatch('uploadCertificates', formData)
                        this.openSuccessModal()
                    } catch (error) {
                        this.openFailureModal(error)
                    }
                }
            },

            openSuccessModal () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_SUCCESS,
                    true,
                    {
                        message: this.$t('modals.modalUploadCertificates.success'),
                        dismissible: false,
                    },
                    true,
                    true)
                )
            },

            openFailureModal (error) {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_FAILURE,
                    false,
                    {
                        message: this.$t('modals.modalUploadCertificates.failure'),
                        reason: error,
                        dismissible: true,
                    },
                    true,
                    true)
                )
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalUploadCertificates {
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &-title {
            @extend %font-heading;
            margin: 1rem 0;
        }

        &-body {
            text-align: center;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            font-size: 0.875rem;
            max-width: 90%;
            @extend %font-content;
        }

        &-fileUploader {
            font: inherit;
            font-size: 12px;
            margin: 1.5rem 0;
        }

        &-footer {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 1rem 0;
        }

        &-button {
            width: 4rem;
            color: $color-white;
            display: flex;
            justify-content: center;
            @extend %font-topbar-heading;
            @extend %button-layout;
            margin: 0 1rem;

            &-cancel {
                @extend %button-layout;
                background-color: transparent;
                padding: 0.5rem 1rem;
            }
            &-save {
                padding: 0.5rem 1rem;
                border-radius: 0;
                background-color: $color-lifebooster-light-green;
            }
        }
    }

    .inactive {
        @extend %button-disabled;
        background-color: $color-button-disabled-bg;
        color: $color-unselected-text;
        cursor: not-allowed;
    }

    .loading {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: lighten($color-box-silver, 30%);
        opacity: .5;
        cursor: default;
        border-radius: 3px;
    }
</style>

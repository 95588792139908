<template lang="pug">
    div(class="sz-UserTable")
        div(class="sz-UserTable-optionsBar")
            div(class="sz-UserTable-optionsBar-title") {{ $t(`modals.modalInitiateCapture.userTableTitle`) }}
            div(class="sz-UserTable-optionsBar-search")
                input(
                    type="text",
                    v-model="search",
                    placeholder="Type here to search",
                    class="sz-UserTable-optionsBar-search-input")
                SvgIcon(
                    icon="search",
                    width="0.5rem",
                    height="0.5rem",
                    class="sz-UserTable-optionsBar-search-icon")
        div(class="sz-UserTable-wrapper")
            table(class="sz-UserTable-table sticky-header")
                thead
                    tr(class="sz-UserTable-headers")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-UserTable-headers-header-${header}`")
                            div(class="sz-UserTable-headers-header-content")
                                span {{ $t(`management.companyDashboard.userTable.headers.${header}`) }}
                                SvgIcon(
                                    icon="arrow",
                                    width="10",
                                    height="10",
                                    class="sz-UserTable-headers-header-arrow",
                                    :class="[currentSortDirection, header === currentSort ? 'show' : '']")
                tbody
                    tr(
                        v-if="initiateCaptureUserListLoading || !users.length",
                        class="sz-UserTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-UserTable-empty-cell")
                            span(
                                v-if="initiateCaptureUserListLoading"
                                class="sz-UserTable-empty-cell-loading") {{ $t(`management.companyDashboard.userTable.loading`) }}
                            span(
                                v-else-if="!users.length"
                                class="sz-UserTable-empty-cell-loading") {{ $t(`management.companyDashboard.userTable.empty`) }}
                    tr(
                        v-if="!initiateCaptureUserListLoading && users.length",
                        v-for="user of users",
                        :key="user.userID",
                        class="sz-UserTable-row")
                        td(
                            @click="userSelected(user)",
                            v-for="header of headers",
                            :key="header",
                            v-truncated-tooltip="",
                            :id="`sz-UserTable-row-item-${header}-${user.email}`"
                            :class="`sz-UserTable-row-item ${header}`") {{ user[header] ?  joinUserItems(user[header]) : null }}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { cloneDeep, get } from 'lodash'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import { userAssignableRoles } from 'helpers/permissionsHelper'
    import modalSettings from 'mixins/modalSettings'
    import SvgIcon from 'components/Shared/SvgIcon'
    import EventBus from 'src/eventBus'
    import { splitFirstNameLastName } from '@/helpers/utilities'

    export default {
        name: 'InitiateCaptureUserTable',

        components: {
            SvgIcon,
        },

        directives: {
            TruncatedTooltip,
        },

        mixins: [
            modalSettings,
        ],

        data () {
            return {
                currentSort: constants.USER_CHARACTERISTICS.F_NAME,
                currentSortDirection: 'down',
                search: '',
                selectedUser: null,
            }
        },

        computed: {
            ...mapGetters([
                'initiateCaptureUserListLoading',
                'initiateCaptureUsers',
                'auth0User',
            ]),

            headers () {
                return constants.EXPORT_DATA_USER_TABLE_HEADERS
            },

            users () {
                return this.initiateCaptureUsers
                    .map((user) => {
                        user['membertags'] = user.membertags.map(tag => tag.name || tag)
                        return user
                    }).filter((user) => {
                        let fullName = splitFirstNameLastName(this.search.toLowerCase())
                        return ((get(user, 'firstName', '').toString().toLowerCase().trim().indexOf(this.search.toLowerCase().trim()) > -1)
                            || (get(user, 'lastName', '').toString().toLowerCase().trim().indexOf(this.search.toLowerCase().trim()) > -1)
                            || (get(user, 'job', '').toString().toLowerCase().trim().indexOf(this.search.toLowerCase().trim()) > -1)
                            || (get(user, 'membertags', '').toString().toLowerCase().trim().indexOf(this.search.toLowerCase().trim()) > -1) ||
                            ((get(user, 'firstName', '').toString().toLowerCase().trim().indexOf(fullName.firstName) > -1)
                            && (get(user, 'lastName', '').toString().toLowerCase().trim().indexOf(fullName.lastName) > -1)))
                    }).sort((a,b) => {
                        let direction = 1
                        let valA = this.sortCaseInsensitive ? get(a, this.currentSort, '').toLowerCase() : get(a, this.currentSort, '')
                        let valB = this.sortCaseInsensitive ? get(b, this.currentSort, '').toLowerCase() : get(b, this.currentSort, '')
                        if (this.currentSortDirection === 'down') direction = -1
                        if (valA === null || valA === '' || valA.length === 0) return direction
                        if (valB === null || valB === '' || valB.length === 0) return -1 * direction
                        if (valA < valB) return -1 * direction
                        if (valA > valB) return direction
                        return 0
                    })
            },

            sortCaseInsensitive() {
                return this.currentSort === constants.USER_CHARACTERISTICS.F_NAME
                || this.currentSort === constants.USER_CHARACTERISTICS.L_NAME
            },
        },

        mounted () {
            if (!this.initiateCaptureUsers || this.initiateCaptureUsers.length === 0) {
                this.$store.dispatch('getInitiateCaptureUsers')
            }
        },

        methods: {
            setCurrentSort (header) {
                if (header === this.currentSort) {
                    this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
                }
                this.currentSort = header
            },

            userSelected (user) {
                this.selectedUser = user
                this.$emit('user', this.selectedUser)
            },

            joinUserItems (array) {
                if (Array.isArray(array) && array.length > 1) {
                    return array.join(', ')
                } else {
                    return array.toString()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: #E5E5E5;
    }

    td, th {
        padding: 0.5rem;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;

    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top:0;
            background-color: $color-box-background ;
        }
    }

    .sz-UserTable {
        display: flex;
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-direction: column;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem;
            font-size: 13px;

            @extend %font-heading;
            @include search-bar;

            &-search {
                margin-left: auto;
                width: 30%;
                min-width: 9rem;
            }
        }

        &-wrapper {
            width: inherit;
            min-height: 50vh;
            max-height: 50vh;
            overflow: scroll;
            @include scrollbar-widget;
            background-color: $color-box-background;
        }

        &-headers {
            &-header {
                &-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                }

                &-arrow {
                    visibility: hidden;
                    padding: 1px 3px;
                }

                &-firstName {
                    width: 14%;
                    overflow: hidden;
                }

                &-lastName {
                    width: 14%;
                    overflow: hidden;
                }

                &-gender {
                    width: 5%;
                    padding-left: 1rem;
                }

                &-job {
                    width: 20%;
                }

                &-timezone {
                    width: 16%;
                }

                &-membertags {
                    width: 31%;
                }
            }
        }

        &-row {
            cursor: pointer;

            &:hover {
                background: $color-table-row-hover;
                color: $color-table-row-hover-text;
            }

            &-item {
                text-align: left;

                &.firstName, &.lastName {
                    max-width: 3rem;
                }

                &.gender {
                    text-align: center;
                }

                &.timezone {
                    max-width: 5rem;
                }

                &.job {
                    min-width: 4rem;
                }

                &.membertags {
                    max-width: 8rem;
                }
            }
        }
    }

    .show {
        visibility: visible;
    }

    .up {
        transform: rotate(180deg);
    }
</style>

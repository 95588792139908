<template lang="pug">
    div(class="sz-FormSectionDateRangePicker")
        div(class="sz-FormSectionDateRangePicker-title")
            span(class="sz-FormSectionDateRangePicker-title-text") {{ title }}
        div(class="sz-FormSectionDateRangePicker-pickers")
            div(class="sz-FormSectionDateRangePicker-picker")
                div(class="sz-FormSectionDateRangePicker-picker-title") {{ $t(`datePicker.start`) }}
                input(
                    type="text",
                    v-model="start",
                    ref="startCalendar")
            div(class="sz-FormSectionDateRangePicker-picker")
                div(class="sz-FormSectionDateRangePicker-picker-end sz-FormSectionDateRangePicker-picker-title") {{ $t(`datePicker.end`) }}
                input(
                    type="text",
                    v-model="end",
                    ref="endCalendar")
</template>

<script>
    import flatpickr from 'flatpickr'
    import 'flatpickr/dist/flatpickr.min.css'
    import { cloneDeep } from 'lodash'
    import rdf from 'rdf-ts'
    import { rdfEncoder } from 'helpers/rdfHelper'
    import constants from 'helpers/constants'
    require("flatpickr/dist/themes/dark.css")

    export default {
        name: "FormSectionDateRangePicker",

        props: {
            title: {
                required: true,
                type: String,
            },

            defaultSelectedInterval: {
                required: false,
                default: '',
                type: String,
            },
        },

        data () {
            return {
                start: null,
                end: null,
                startDatePicker: null,
                endDatePicker: null,
                clearingDatePickers: false,
            }
        },

        computed: {
            flatpickrOptions () {
                return {
                    static: false,
                    dateFormat: "U",
                    altInput: true,
                    altFormat: "d M Y",
                }
            },

            defaultIsLastAssessment () {
                return this.defaultSelectedInterval
                    && this.defaultSelectedInterval === constants.INTERVAL_CODES.LAST_ASSESSMENT
            },

            defaultStart () {
                let [start, _end] = [null, null]
                if (!this.defaultIsLastAssessment) {
                    if (this.defaultSelectedInterval && this.intervalIsPreset) {
                        [start, _end] = rdf(constants.DATE_PRESETS[this.defaultSelectedInterval])
                    }
                    if (this.defaultSelectedInterval) {
                        [start, _end] = rdf(this.defaultSelectedInterval)
                    }
                }
                return start
            },

            defaultEnd () {
                let [_start, end] = [null, null]
                if (!this.defaultIsLastAssessment) {
                    if (this.defaultSelectedInterval && this.intervalIsPreset) {
                        [_start, end] = rdf(constants.DATE_PRESETS[this.defaultSelectedInterval])
                    }
                    if (this.defaultSelectedInterval) {
                        [_start, end] = rdf(this.defaultSelectedInterval)
                    }
                }
                return end
            },

            selectedInterval () {
                // Set the end time to the end of the day by adding 23 hours and 59 minutes (in seconds)
                let endTime = parseInt(this.end) + 86340
                return rdfEncoder(this.start, endTime)
            },

            startOptions () {
                let options = cloneDeep(this.flatpickrOptions)
                options["defaultDate"] = this.start * constants.UNIX_MILLISECONDS
                options["onClose"] = function(selectedDates, dateStr, instance) {
                    this.endDatePicker.set('minDate', dateStr)
                }.bind(this)
                if (this.defaultEnd) {
                    options['maxDate'] = this.end * constants.UNIX_MILLISECONDS
                }
                return options
            },

            endOptions () {
                let options = cloneDeep(this.flatpickrOptions)
                options["defaultDate"] = this.end * constants.UNIX_MILLISECONDS
                options["onClose"] = function(selectedDates, dateStr, instance) {
                    this.startDatePicker.set('maxDate', dateStr)
                }.bind(this)
                if (this.defaultStart) {
                    options['minDate'] = this.start * constants.UNIX_MILLISECONDS
                }
                return options
            },

            intervalIsPreset () {
                let widgetSettingsHasInterval = this.defaultSelectedInterval
                let intervalIsPreset = false
                if (widgetSettingsHasInterval) {
                    intervalIsPreset = Object.values(constants.DATE_PRESETS).includes(this.defaultSelectedInterval)
                }
                return intervalIsPreset
            },
        },

        watch: {
            start () {
                if (!this.clearingDatePickers) {
                    this.$emit('updateSelectedDateRange', this.selectedInterval)
                }
            },
            end () {
                if (!this.clearingDatePickers) {
                    this.$emit('updateSelectedDateRange', this.selectedInterval)
                }
            },
        },

        mounted () {
            this.start = this.defaultStart
            this.end = this.defaultEnd
            this.startDatePicker = flatpickr(this.$refs.startCalendar, this.startOptions)
            this.endDatePicker = flatpickr(this.$refs.endCalendar, this.endOptions)
        },

        methods: {
            async clearDatePickers () {
                this.clearingDatePickers = true
                await this.startDatePicker.clear()
                await this.endDatePicker.clear()
                this.clearingDatePickers = false
            },
        },
    }
</script>

<style lang="scss">
    @import '~design';
    .sz-FormSectionDateRangePicker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-title {
            @extend %font-topbar-heading;
            color: $color-white;
            font-size: 11px;
            padding-bottom: 0.5rem;
        }

        &-pickers {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &-picker {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            &-title {
                @extend %font-topbar-heading;
                color: $color-white;
                font-size: 10px;
            }
        }
    }

    input.form-control {
        outline: none;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom: 1px $color-lifebooster-medium-green solid;
        background-color: $color-table-font-black;
        @extend %font-content;
        color: $color-white;
        width: 95%;
    }

    .flatpickr-calendar {
        font-size: 12px;
        @extend %font-content;
    }

</style>

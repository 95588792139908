<template>
  <div class="sz-PageTitle">
    <div class="sz-PageTitle-breadcrumbs">
      <div class="sz-PageTitle-currentPage">
        <div>{{ routeName }}</div>
      </div>

      <div
        v-if="onAssessmentPage"
        class="sz-PageTitle-assessmentSwitcher"
      >
        <div class="sz-PageTitle-currentPage sz-PageTitle-currentPage-date">
          {{ currentAssessmentDate }}
        </div>

        <div class="sz-PageTitle-currentPage">
          {{ excludedFromCharts }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import moment from 'moment-timezone'
    import EventBus from 'src/eventBus'

    export default {
        data () {
            return {
                dropdownOpen: false,
                assessmentTimezone: null,
                assessmentDate: null,
                allAssessmentDates: null,
                captureIdx: null,
                disableChartInteraction: false,
            }
        },

        computed: {
            ...mapGetters([
                'workerId',
                'auth0User',
                'selectedModule',
                'selectedAssessment',
                'currentSettingsPage',
            ]),

            currentPage () {
                return this.$router.currentRoute
            },

            onAssessmentPage () {
                return this.$router.currentRoute.name === constants.PAGES.ASSESSMENT.MODULE ||
                    this.$router.currentRoute.name === constants.PAGES.ASSESSMENT.EDITOR
            },

            routeName () {
                /**
                 * @NOTE: Aug 18, 2022
                 * "Editor" has been renamed to "Assessment Repair". Due to this being temporary,
                 * we will not be updating the route name or any associated route logic.
                 * For now, just return the "Assessment Repair" translation.
                 */
                if (this.$router.currentRoute.name === constants.PAGES.ASSESSMENT.EDITOR) {
                    return this.$t('pageTitles.assessmentRepair')
                }

                if (this.onAssessmentPage) {
                    return this.$t(`pageTitles.assessment.${this.selectedModule}`)
                }

                if (this.$router.currentRoute.name !== "management") {
                    return this.$t(`pageTitles.${this.$router.currentRoute.name}`)
                }
                
                /**
                 * @NOTE: Aug 17, 2022
                 * "Company Dashboard" has been renamed to "User Management". Due to this being temporary,
                 * we will not be updating the route name or any associated route logic.
                 * For now, just return the "User Management" translation.
                 */
                if (this.currentSettingsPage === constants.SETTINGS_PAGES.COMPANY) {
                    return this.$t('pageTitles.userManagement')
                }

                return this.$t(`pageTitles.${this.currentSettingsPage}`)
            },

            currentAssessmentDate () {
                return this.assessmentDate ? `${moment.tz((this.assessmentDate * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format("MMM D, YYYY")}` : ``
            },

            excludedFromCharts () {
                if (!this.selectedAssessement) return
                return this.selectedAssessment.aggregationAllowed === false ? `${this.$t(`individualSummary.excludedFromCharts`)}` : ''
            },
        },

        mounted() {
            EventBus.$on('ASSESSMENT_TIMEZONE', (assessmentTimezone) => {
                this.assessmentTimezone = assessmentTimezone
            })

            EventBus.$on('ASSESSMENT_DATE', (assessmentDate) => {
                this.assessmentDate = assessmentDate
            })

            EventBus.$on('ALL_ASSESSMENT_DATES', (allAssessmentDates) => {
                this.allAssessmentDates = allAssessmentDates
            })

            EventBus.$on('CAPTURE_IDX', (captureIdx) => {
                this.captureIdx = captureIdx
            })

            EventBus.$on('SET_DISABLE_CHART_INTERACTION', (disabled) => {
                this.disableChartInteraction = disabled
            })
        },

        beforeUnmount() {
            EventBus.$off('ASSESSMENT_TIMEZONE', this.assessmentTimezone)
            EventBus.$off('ASSESSMENT_DATE', this.assessmentDate)
            EventBus.$off('ALL_ASSESSMENT_DATES', this.allAssessmentDates)
            EventBus.$off('CAPTURE_IDX', this.captureIdx)
            EventBus.$off('SET_DISABLE_CHART_INTERACTION', this.isAnimatorPlaying)
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-PageTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-dcaStartEnd {
            display: flex;
            flex-direction: column;

            &-header {
                @extend %info-bar-heading;
            }
        }

        &-breadcrumbs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0 0 0.625rem 0;
        }

        &-currentPage {
            cursor: default;

            &-date {
                padding-left: 1rem;
            }

            &-icons {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                color: $color-base-grey;

                &-calendar {
                    margin-left: 0.2rem;
                    margin-right: 0.2rem;
                    cursor: pointer;
                }

                &-globe {
                    margin-left: 0.1rem;
                }
            }
        }

        &-assessmentSwitcher {
            display: flex;
            align-items: center;
            &-dropdownArrow {
                cursor: pointer;
                padding-top: 0.25rem;
                padding-left: 0.5rem;

                &-open {
                    transform: rotate(180deg);
                }
            }
        }
    }
     .slide-enter-active {
        transition: all .3s ease;
        max-height: 250px;
    }

    .slide-leave-active {
        transition: all .2s ease;
        max-height: 250px;
    }

    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }

    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }

</style>

import constants from 'helpers/constants'
import { userCanAccessPage } from '../helpers/permissionsHelper'
import { getInstance } from 'auth/index'

export default [
  {
    path: '/',
    name: constants.PAGES.WELCOME,
    component: () => lazyLoadView(import('./views/welcome')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/home',
    name: constants.PAGES.HOME,
    component: () => lazyLoadView(import('./views/home')),
    meta: {
      authRequired: true,
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.HOME],
      },
      breadcrumbs: {
        required: false,
      },
    },
  },
  {
    path: '/initiate-capture',
    name: constants.PAGES.INITIATE_CAPTURE,
    component: () => lazyLoadView(import('./views/initiate-capture')),
    meta: {
      authRequired: true,
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.INITIATE_CAPTURE],
      },
      breadcrumbs: {
        required: false,
      },
    },
    beforeEnter(to, from, next) {
      // Base Workers no longer go straight to Start Assessment, they go to a welcome page
      window.location.href = `${process.env.VUE_APP_V2_BASE_URL}/welcome`
    },
  },
  {
    path: '/export/:exportID',
    name: constants.PAGES.EXPORT,
    component: () => lazyLoadView(import('./views/export')),
    props: true,
    meta: {
      authRequired: true,
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.EXPORT],
      },
      breadcrumbs: {
        required: false,
      },
    },
  },
  {
    path: '/individual-summary/:workerId/',
    name: constants.PAGES.INDIVIDUAL_SUMMARY,
    component: () => lazyLoadView(import('./views/individual-summary')),
    props: true,
    meta: {
      authRequired: true,
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.INDIVIDUAL_SUMMARY],
      },
      breadcrumbs: {
        required: true,
        previousPages: ['home'],
      },
    },
  },
  {
    path: '/assessment',
    name: constants.PAGES.ASSESSMENT,
    component: () => lazyLoadView(import('./views/AssessmentView')),
    props: true,
    children: [
      {
        path: 'editor/:customerID/:companyID/:workerId/:assessmentDate/:captureIdx/:assessmentIdx/:riskType',
        name: constants.PAGES.ASSESSMENT.EDITOR,
        component: () => lazyLoadView(import('./views/AssessmentView')),
        props: true,
        meta: {
          authRequired: true,
          roleRequired: {
            required: true,
            roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.ASSESSMENT.EDITOR],
          },
          breadcrumbs: {
            required: true,
            previousPages: ['home', 'individual-summary'],
          },
        },
      },
      {
        path: 'module/:assessmentType/:workerId/:assessmentDate/:captureIdx/:assessmentIdx/:riskType',
        component: () => lazyLoadView(import('./views/AssessmentView')),
        props: true,
        name: constants.PAGES.ASSESSMENT.MODULE,
        meta: {
          authRequired: true,
          roleRequired: {
            required: true,
            roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.ASSESSMENT],
          },
          breadcrumbs: {
            required: true,
            previousPages: ['home', 'individual-summary'],
          },
        },
      },
    ],
  },
  {
    path: '/management',
    redirect: {
      name: constants.PAGES.MANAGEMENT,
      params: {
        currentSettingsPage: constants.SETTINGS_PAGES.PROFILE,
      },
    },
    meta: {
      roleRequired: {
        required: false,
      },
      authRequired: true,
      adminRequired: false,
    },
  },
  {
    path: '/management/:currentSettingsPage/customer/:customer/company/:company',
    name: constants.PAGES.MANAGEMENT,
    component: () => lazyLoadView(import('./views/management')),
    props: true,
    meta: {
      roleRequired: {
        required: false,
      },
      authRequired: true,
      adminRequired: false,
    },
  },
  {
    path: '/devices',
    name: constants.PAGES.DEVICES,
    component: () => lazyLoadView(import('./views/devices')),
    meta: {
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.DEVICES],
      },
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/interview',
    name: constants.PAGES.INTERVIEW,
    component: () => lazyLoadView(import('./views/interview')),
    meta: {
      roleRequired: {
        required: true,
        roles: constants.DASHBOARD_PERMISSIONS_ROLE_MAP[constants.PAGES.INTERVIEW],
      },
      authRequired: true,
      adminRequired: true,
    },
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('./views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('./views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

<template lang="pug">
    div(class="sz-ModalConfirmation")
        div(class="sz-ModalConfirmation-title") {{ title }}
        div(class="sz-ModalConfirmation-message") {{ message }}
        div(
            v-if="showList()",
            class="sz-ModalConfirmation-list")
            div(
                v-for="(item,index) of list",
                :key="index",
                class="sz-ModalConfirmation-list-item") {{ item }}
        div(class="sz-ModalConfirmation-buttons")
            div(
                @click="close",
                class="sz-ModalConfirmation-closeButton") {{ $t("modals.modalConfirmation.cancel") }}
            div(
                @click="confirm",
                class="sz-ModalConfirmation-confirmButton") {{ $t("modals.modalConfirmation.yes") }}
</template>

<script>
    import EventBus from 'src/eventBus'

    export default {
        name: 'ModalConfirmation',

        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },

            message: {
                type: String,
                required: true,
            },

            list: {
                type: Array,
                required: false,
                default: () => [],
            },
        },

        data () {
            return {
                timer: null,
            }
        },

        methods: {
            close () {
                EventBus.$emit('USER_CANCELLED',{})
                this.$emit('close')
            },

            confirm () {
                EventBus.$emit('USER_CONFIRMED',{})
                this.$emit('close')
            },

            showList() {
                return this.list.length !== 0
            },
        },
    }
</script>

<style lang="scss" scoped>
@import '~design';
    .sz-ModalConfirmation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
        width: 20rem;
        padding: 0.8rem;
        color: $color-text;

        &-message {
            font-size: 14px;
            margin: 0.5rem 0;
            width: inherit;
            white-space: pre-wrap;
        }

        &-title {
            font-size: 14px;
            margin: 0.5rem 0;
            width: inherit;
            font-weight: bold;
        }

        &-list {
            @include scrollbar-widget;
            font-size: 12px;
            margin-bottom: 1rem;
            max-height: 10rem;
            overflow: auto;
        }

        &-buttons {
            margin-top: 1rem;
            display: flex;
        }

        &-confirmButton {
            @extend %button-layout;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            margin: 0 0.5rem;
            font-size: 12px;
            border-radius: 0;
            cursor: pointer;

            &:hover {
                background-color:  lighten($color-lifebooster-light-green, 10%);
            }
        }

        &-closeButton {
            @extend %button-layout;
            background-color: $color-base-grey;
            padding: 0.5rem 1rem;
            margin: 0 0.5rem;
            font-size: 12px;
            border-radius: 0;
            cursor: pointer;
        }
    }
</style>

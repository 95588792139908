<template lang="pug">
    div(class="sz-ModalSuccess")
        SuccessAnimation
        div(class="sz-ModalSuccess-title") {{ title }}
        div(class="sz-ModalSuccess-message") {{ message }}
        div(
            v-if="closeButton",
            @click="close",
            class="sz-ModalSuccess-closeButton") {{ $t("close") }}
</template>

<script>
    import constants from 'helpers/constants'
    import SuccessAnimation from 'components/Shared/SuccessAnimation'
    import EventBus from 'src/eventBus'

    export default {
        name: 'ModalSuccess',

        components: {
            SuccessAnimation,
        },

        props: {
            message: {
                type: String,
                required: true,
            },

            duration: {
                type: Number,
                required: false,
                default: constants.MESSAGE_TIMEOUT,
            },

            dismissible: {
                type: Boolean,
                required: false,
                default: true,
            },

            performAction: {
                type: Boolean,
                required: false,
                default: false,
            },

            action: {
                type: Function,
                required: false,
                default: () => false,
            },

            closeButton: {
                type: Boolean,
                required: false,
                default: false,
            },

            title: {
                type: String,
                required: false,
                default: '',
            },
        },

        data () {
            return {
                timer: null,
            }
        },

        mounted () {
            this.showMessage()
        },

        methods: {
            close () {
                clearTimeout(this.timer)
                if (this.performAction) {
                    this.action()
                }
                EventBus.$emit('CLOSE_WINDOW', true)
                this.$emit('close')
            },

            showMessage () {
                if (!this.dismissible) {
                    this.timer = setTimeout(() => this.close(), this.duration)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
@import '~design';
    .sz-ModalSuccess {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
        width: 14vw;
        padding: 0.8rem;
        color: $color-text;

        &-message {
            font-size: 12px;
            margin: 0.5rem 0;
            width: inherit;
            white-space: pre-wrap;
        }

        &-title {
            font-size: 14px;
            margin: 0.5rem 0;
            width: inherit;
            font-weight: bold;
        }

        &-closeButton {
            @extend %font-topbar-heading;
            color: $color-white;
            margin-top: 0.5rem;
            cursor: pointer;
            background-color: $color-lifebooster-light-green;
            @extend %button-layout;
            padding: 0.5rem 1rem;
            border-radius: 0;
        }
    }
</style>

<template lang="pug">
    form(
        class="sz-FormInitiateCapture")
        div(class="sz-FormInitiateCapture-section")
            span(class="sz-FormInitiateCapture-section-breadcrumb") {{ $t('modals.modalInitiateCapture.selectedWorker') }} {{ name }}
        div(class="sz-FormInitiateCapture-section")
            span(class="sz-FormInitiateCapture-section-list") {{ $t('modals.modalInitiateCapture.reminderHeader') }}
            span(class="sz-FormInitiateCapture-section-list") {{ $t('modals.modalInitiateCapture.reminder1') }}
            span(class="sz-FormInitiateCapture-section-list") {{ $t('modals.modalInitiateCapture.reminder2') }}
            span(class="sz-FormInitiateCapture-section-list-lastItem") {{ $t('modals.modalInitiateCapture.reminder3') }}
        div(class="sz-FormInitiateCapture-section")
            div(class="sz-FormInitiateCapture-section-list-lastItem") {{ $t('modals.modalInitiateCapture.ledNote') }}
        div(class="sz-FormInitiateCapture-section-images")
            div(class="sz-FormInitiateCapture-section-images-padded")
                img(
                    v-bind:src="frontImg",
                    class="sz-FormInitiateCapture")
            div(class="sz-FormInitiateCapture-section-images-padded")
                img(
                    v-bind:src="backImg",
                    class="sz-FormInitiateCapture")
        div(class="sz-FormInitiateCapture-section-input")
            FormSectionInput(
                :title="$t(`modals.modalInitiateCapture.enterSetID`)",
                textSize="noAllCaps",
                :maxLength="maxLength",
                :placeholder="$t(`modals.modalInitiateCapture.exampleSetID`)",
                @updated="updateSetID")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'

    import FormSectionInput from 'components/Shared/FormSectionInput'

    export default {
        name: "FormInitiateCapture",

        components: {
            FormSectionInput,
        },

        mixins: [
        ],

        props: {
            user: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                setID: '',
                frontImg: require("../../../assets/images/FrontSensorPos.png"),
                backImg: require("../../../assets/images/BackSensorPos.png"),
            }
        },

        computed: {
            maxLength() {
                return 6
            },

            name() {
                let firstName = this.user.firstName || ''
                let lastName = this.user.lastName || ''
                return `${firstName} ${lastName}`
            },
        },

        methods: {
            updateSetID(setID) {
                this.setID = setID
                this.$emit('setID', this.setID)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormInitiateCapture {
        display: flex;
        flex-direction: column;
        align-self: center;
        color: $color-white;

        &-section {
            &-breadcrumb {
              display: flex;
              justify-content: center;
              padding-top: 1rem;
              padding-bottom: 1rem;
            }

            &-images {
              display: flex;
              flex-direction: row;
              padding-bottom: 1rem;

              &-padded {
                padding: 0 0.5rem;
              }
            }

            &-list {
              display: flex;
              justify-content: flex-start;
              color: $color-white;

              &-lastItem {
                display: flex;
                color: $color-white;
                padding-bottom: 1rem;
              }
            }

            &-input {
                @extend %font-topbar-heading;
                padding-bottom: 1rem;
            }
        }
    }
</style>

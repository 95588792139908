<template lang="pug">
    div(
        id="defaultTooltip",
        class="sz-TooltipBody"
        :class="[tooltipIndex, tooltipBodyClasses]",
        @click.stop="swallow")
        div(
            v-if="tooltipBodySettings.component")
            component(
                :is="tooltipBodySettings.component"
                v-bind:componentProp="tooltipBodySettings.componentProp")

        div(
            v-else,
            class="sz-TooltipBody-simple")
            span(
                v-if="tooltipBodySettings.title",
                class="sz-TooltipBody-title") {{ tooltipBodySettings.title }}
            span(
                v-if="tooltipBodySettings.message",
                class="sz-TooltipBody-message",
                v-html="tooltipBodySettings.message")
            span(
                v-if="tooltipBodySettings.truncatedMessage",
                class="sz-TooltipBody-truncatedMessage",
                v-html="tooltipBodySettings.truncatedMessage")
            span(
                v-if="tooltipBodySettings.list",
                class="sz-TooltipBody-list")
                span(
                    v-for="item in tooltipBodySettings.list",
                    class="sz-TooltipBody-list-item") {{ item }}
</template>

<script>
    import WorkerIDTooltipBody from './WorkerIDTooltipBody'


    export default {
        name: "TooltipBody",

        components: {
            WorkerIDTooltipBody,
        },

        props: {
            tooltipBodySettings: {
                type: Object,
                required: false,
                default: function () {
                    return {
                        view: ['dark', 'center', 'regular-padding'],
                    }
                },
            },
        },

        computed: {
            tooltipBodyClasses () {
                let bodyClasses = Object.values(this.tooltipBodySettings)
                if (this.tooltipBodySettings.view) {
                    bodyClasses = Object.values(this.tooltipBodySettings.view)
                }
                return bodyClasses
            },

            tooltipIndex () {
                if (this.tooltipBodySettings && this.tooltipBodySettings.indexClass) {
                    return this.tooltipBodySettings.indexClass
                }
                // tooltipIndex is the default z-index css class
                return 'tooltipIndex'
            },
        },

        methods: {
            swallow () {
                // swallows click event
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-TooltipBody {
        font-size: 0.75rem;
        box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.18);
        line-height: 0.938rem;
        display: flex;
        flex-direction: column;
        background-color: $color-truncated-tooltip; //default to dark

        &-title {
            font-weight: bold;
            margin-bottom: 0.313rem;
        }

        &-truncatedMessage {
            max-width: 31.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-list {
            display: flex;
            flex-direction: column;
            margin-top: 0.25rem;
            margin-bottom: 0.313rem;
            margin-left: 0.25rem;

            &-item {
                margin-top: 0.425rem;
            }
        }
    }

    .dark {
        background-color: $color-tooltip-dark;
        color: $color-text;

    }

    .light {
        background-color: $color-tooltip-light;
        color: $color-text;
    }

    .regular-padding {
        padding: 0.25rem;
    }

    .extra-padding {
        padding: 0.5rem;
    }

    .extra-margin-left {
        margin-left: 0.5rem
    }

    .center {
        text-align: center;
    }

    .left {
        text-align: left;
    }

    .tooltipIndex {
        z-index: $layer-tooltip-z-index;
    }

    .index-lower-than-modal {
        z-index: $layer-tooltip-z-index-lower-than-modal !important;
    }

    .parameters-width {
        width: 17rem;
    }

    .taskbreakdown-width {
        display: inline-flex;
        white-space: pre;
    }

</style>

<template lang="pug">
    div(class="sz-ModalDropdownSelect")
        div(class="sz-ModalDropdownSelect-header") {{ title }}
        div(class="sz-ModalDropdownSelect-body")
            div(class="sz-ModalDropdownSelect-body-message") {{ message }}
            div(class="sz-ModalDropdownSelect-body-input")
                FormSectionDropdownSingleSelect(
                    :title="dropdownData.title",
                    :options="options",
                    :placeholder="dropdownData.placeholder",
                    @updateSelectedOption="updateSelectedOption")
        div(class="sz-ModalDropdownSelect-footer")    
            div(
                @click.stop="cancel",
                class="sz-ModalDropdownSelect-footer-button \
                    sz-ModalDropdownSelect-footer-button-cancel") {{ $t(`modals.cancel`)}}
            div(
                @click.stop="submit",
                type="submit",
                :class="{'sz-ModalDropdownSelect-footer-button-save-inactive': !settingsHaveChanged}"
                class="sz-ModalDropdownSelect-footer-button \
                    sz-ModalDropdownSelect-footer-button-save") {{ submitButtonTitle }}
</template>

<script>
    import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
    import EventBus from 'src/eventBus'
    
    export default {
        name: "ModalDropdownSelect",

        components: {
            FormSectionDropdownSingleSelect,
        },

        props: {
            title: {
                type: String,
                required: true,
            }, 

            message: {
                type: String,
                required: false,
                default: '',
            },

            dropdownData: {
                type: Object,
                required: true,
            },

            submitButtonTitle: {
                type: String,
                required: false,
                default: function() {
                    return this.$t(`modals.save`)
                },
            },
        },

        data () {
            return {
                selectedOption: null,
            }
        },
        
        computed: {
            options () {
                return Object.values(this.dropdownData.options).map(val => {
                    return {
                        name: val,
                        type: val,
                    }
                }) 
            },

            settingsHaveChanged () {
                return this.selectedOption !== null
            },
        },

        methods: {
            updateSelectedOption (option) {
                this.selectedOption = option

            },

            submit () {
                if (this.settingsHaveChanged) {
                    EventBus.$emit('MODAL_DROPDOWN_RESULT', this.selectedOption)
                    this.$emit('close')
                }
            },

            cancel () {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ModalDropdownSelect {
        color: $color-table-dark-background;
        width: 28rem;
        height: 15rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;

        &-header {
            @extend %font-heading;
            margin: 1rem;

        }

        &-body {
            @extend %font-content;
            margin: 0 2rem;

            &-message {
                margin-bottom: 2rem;
                font-size: 0.8rem;
            }

            &-input {
                margin: 0 1rem;
                font-size: 0.8rem;
            }
        }

        &-footer {
            display: flex;
            justify-content: center;
            margin: 0 1rem;
            cursor: pointer;
            margin-top: auto;

            &-button {
                display: flex;
                justify-content: center;
                @extend %button-layout;
                @extend %font-topbar-heading;
                color: $color-white;

                &-save {
                    background-color: $color-lifebooster-light-green;
                    padding: 0.5rem 1rem;
                    border-radius: 0;

                    &-inactive {
                        background-color: $color-button-disabled-bg;
                        color: $color-unselected-text;
                        cursor: not-allowed;
                    }
                }

                &-cancel {
                    background-color: transparent;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }

</style>
<template lang="pug">
    div(class="sz-TopBar")
        div(class="sz-TopBar-Left")
            div(class="sz-TopBar-Left-image")
                img(v-bind:src="image")
            div(class="sz-TopBar-Left-pageInfo")
                div(
                    v-if="pagesToShowExternalGoBackButton"
                    class="sz-TopBar-Left-back-external"
                    @click="goBackExternal") {{ $t("navigationBar.goBack") }}
                WorkerIdentificationPanel(v-if="showWorkerCard")
                PageTitle
</template>

<script>
    import PageTitle from './PageTitle'
    import WorkerIdentificationPanel from '../Shared/WorkerIdentificationPanel'
    import LifeBoosterLogo from "./LifeBoosterLogo"
    import SvgIcon from '../Shared/SvgIcon'
    import DropdownProfile from './DropdownProfile'
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import { mapGetters } from 'vuex'
    import { getInstance } from 'auth/index'
    import { userCanAccessPage } from 'helpers/permissionsHelper'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import EventBus from 'src/eventBus'
    import moment from 'moment-timezone'

    export default {
        components: {
            PageTitle,
            WorkerIdentificationPanel,
            LifeBoosterLogo,
            SvgIcon,
            DropdownProfile,
        },

        directives: {
            TruncatedTooltip,
        },

        mixins: [
            modalSettings,
        ],

        props: {
            showWorkerCard: {
                type: Boolean,
                default: true,
            },
        },

        data () {
            return {
                dropdownOpen: false,
                assessmentTimezone: null,
                assessmentStart: null,
                assessmentEnd: null,
                image: require("@/assets/images/LifeBoosterDarkLogo.png"),
            }
        },

        computed: {
            ...mapGetters([
                'nextWidgetOrder',
                'personalDetailsPopulated',
                'firstName',
                'lastName',
                'email',
                'defaultCompanyName',
                'defaultCustomerName',
                'selectedModule',
            ]),

            pagesToShowExternalGoBackButton () {
                const pagesToShowExternalGoBackButton = [
                    `/${constants.PAGES.DEVICES}`,
                    `/${constants.PAGES.MANAGEMENT}/${constants.SETTINGS_PAGES.COMPANY}`,
                    `/${constants.PAGES.MANAGEMENT}/${constants.SETTINGS_PAGES.ASSESSMENT}`,
                    `/${constants.PAGES.ASSESSMENT.ASSESSMENT}/${constants.ASSESSMENT_PAGE.EDITOR}`,
                ]

                return pagesToShowExternalGoBackButton.some((path) => this.$route.path.includes(path))
            },

            userCanIntiateCapture () {
                return userCanAccessPage(getInstance().user, constants.PAGES.INITIATE_CAPTURE)
            },

            userName () {
                if (this.firstName || this.lastName) {
                    return `${this.firstName || ''} ${this.lastName ||  ''}`
                }

                return this.email ? this.email : ''
            },

            isWidgetDashboard () {
                return this.$router.currentRoute.name === constants.PAGES.HOME
            },

            isAdminPage () {
                return this.$router.currentRoute.name === constants.PAGES.MANAGEMENT
            },

            isInitiateCapturePage () {
                return this.$router.currentRoute.name === constants.PAGES.INITIATE_CAPTURE
            },

            dcaStartEndHeader () {
                return this.$t(`motionAssessment.detailedEventCountHeaders.${constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DCA_START_END}`)
            },

            dcaStartEnd () {
                return `${moment.tz((this.assessmentStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS')} -
                ${moment.tz((this.assessmentEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS')}`
            },

            onAssessmentPage () {
                return this.$router.currentRoute.name === constants.PAGES.ASSESSMENT.MODULE ||
                    this.$router.currentRoute.name === constants.PAGES.ASSESSMENT.EDITOR
            },

            isAssessmentEditor () {
                return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            },
        },

        mounted () {
            this.$store.dispatch('getPersonalDetails')
            this.$store.dispatch('getAvailableCustomersAndCompanies')

            EventBus.$on('ASSESSMENT_TIMEZONE', (assessmentTimezone) => {
                this.assessmentTimezone = assessmentTimezone
            })

            EventBus.$on('ASSESSMENT_START', (assessmentStart) => {
                this.assessmentStart = assessmentStart
            })

            EventBus.$on('ASSESSMENT_END', (assessmentEnd) => {
                this.assessmentEnd = assessmentEnd
            })
        },

        methods: {
            initiateCapture () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_GO_TO_EXTERNAL_PAGE,
                    false,
                    {
                      subtitle: this.$t(`initiateCapture.navigateAway.startAssessmentUpdated`),
                      body: [
                          this.$t(`initiateCapture.navigateAway.willBeRedirected`),
                          this.$t(
                              `initiateCapture.navigateAway.askForFeedback`,
                              { email: constants.FEEDBACK_EMAIL },
                          ),
                      ],
                      targetURL: `${process.env.VUE_APP_V2_BASE_URL}/start-assessment`,
                    },
                    false,
                    true,
                ))
            },

            newWidget () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_WIDGET,
                    false,
                    {
                        widgetSettings: {
                            order: this.nextWidgetOrder,
                        },
                    },
                    false,
                    true)
                )
            },

            toggleDropdown () {
                if (!this.dropdownOpen) {
                    this.dropdownOpen = true
                } else {
                    this.dropdownOpen = false
                }
            },

            goBackExternal() {
                window.history.back()
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import '~design';

    .sz-TopBar {
        @extend %font-topbar-heading;
        position: fixed;
        left: 0;
        top: 0;
        width: 98vw;
        z-index: 100;
        height: 4.3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        background-color: $color-body-bg;

        &-Middle {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: center;
        }

        &-Left {
            display: flex;
            flex-direction: row;
            padding-top: 1rem;

            &-image {
                width: 53px;

                img {
                    width: 50px;
                }
            }

            &-pageInfo {
                display: flex;
                flex-direction: column;
                padding-left: 1rem;
            }

            &-back-external {
                border: 2px solid #FFF;
                border-radius: 4px;
                cursor: pointer;
                max-width: 90px;
                margin-bottom: 0.25rem;
            }
        }
    }
</style>

<template lang="pug">
    div(class="sz-DropdownSearchable")
        input(
            type="text",
            v-model="search",
            v-click-away="closeDropdown",
            :placeholder="placeholder",
            @click="openDropdown",
            @input="onChange",
            @keydown.down="onArrowDown",
            @keydown.up="onArrowUp",
            @keydown.enter="onEnter",
            class="sz-DropdownSearchable-input")
        div(class="sz-DropdownSearchable-resultsDropdown")
            ul(
                v-show="isOpen",
                class="sz-DropdownSearchable-results")
                li(
                    v-for="(result, i) in results",
                    :key="i",
                    @click="setResult(result)",
                    class="sz-DropdownSearchable-result",
                    :class="{ 'active': (i + 1) === arrowCounter }") {{ result }}
</template>

<script>
    import { ClickAway } from 'directives/clickAway'
    import { uniq } from 'lodash'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'

    export default {
        name: "DropdownSearchable",

        directives: {
            ClickAway,
        },

        props: {
            items: {
                type: Array,
                required: false,
                default: () => [],
            },

            isSingleSelect: {
                type: Boolean,
                required: false,
                default: false,
            },

            defaultSelectedOption: {
                type: String,
                required: false,
                default: '',
            },

            placeholder: {
                type: String,
                required: false,
                default: '',
            },

            isSorted: {
                type: Boolean,
                required: false,
                default: true,
            },
        },

        data () {
            return {
                search: '',
                searchResults: [],
                results: [],
                isOpen: false,
                arrowCounter: 0,
            }
        },

        mounted () {
            if (this.isSingleSelect) {
                this.search = this.defaultSelectedOption
                this.setResult(this.defaultSelectedOption)
            }

            EventBus.$on(constants.EVENTS.CLEAR_SELECTION, () => {
                this.clearSelection()
            })
        },

        methods: {
            onChange() {
                this.$emit('input', this.search)
                this.filterResults()
                this.openDropdown()
            },
            openDropdown () {
                this.isOpen = true
                this.filterResults()
            },
            filterResults() {
                this.results = this.items.filter(item => {
                    return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })

                if (this.isSorted) {
                    this.sortResults()
                }
            },
            sortResults () {
                this.results = this.results.sort()
            },
            setResult (result) {
                if (this.isSingleSelect) {
                    this.searchResults = result
                    this.search = result
                } else {
                    let newResults = this.searchResults.concat(result)
                    this.searchResults = uniq(newResults)
                    this.search = ''
                }
                this.closeDropdown()
                this.$emit('setResult', result)
            },
            closeDropdown () {
                if (this.isSingleSelect) {
                    this.search = this.searchResults
                }
                this.isOpen = false
            },
            onArrowDown () {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter ++
                    if (!this.isOpen) {
                        this.openDropdown()
                    }
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter --
                }
            },
            onEnter() {
                if (this.arrowCounter > 0) {
                    this.setResult(this.results[this.arrowCounter - 1])
                    this.arrowCounter = 0
                }
            },

            clearSelection () {
                this.search = ''
                this.searchResults = ''
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-DropdownSearchable {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
            outline: none;
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom: 1px $color-lifebooster-light-green solid;
            background-color: inherit;
            @extend %font-content;
            font-size: 12px;
            height: 1.25rem;
            color: $color-white;
            width: 100%;
        }

        &-results {
            @include scrollbar-widget;
            position: absolute;
            padding: 0;
            margin: 0;
            max-height: 7.5rem;
            overflow: auto;
            background-color: $color-body-bg;
            color: $color-white;
            width: 100%;
            z-index: $layer-dropdown-z-index;
        }

        &-result {
            list-style: none;
            text-align: left;
            padding: 0.3rem 0.3rem;
            cursor: pointer;

            &.active, &:hover {
                background-color: $color-table-row-hover;
            }
        }
    }
</style>

<template>
  <div class="sz-ModalEditConfigurations">
    <div
      v-if="updatingSetConfiguration"
      class="sz-ModalEditConfigurations-loading"
    >
      <LoadingSpinner />
    </div>
    <div class="sz-ModalEditConfigurations-title">
      {{ $t(`deviceManagement.assignConfiguration.editConfigurations`) }}
    </div>
    <div class="sz-ModalEditConfigurations-body">
      <div
        v-if="!areSelectedSetsSameDeviceType"
        class="sz-ModalEditConfigurations-body-multiDeviceTypes"
      >
        {{ $t(`deviceManagement.assignConfiguration.editModal.multiDeviceTypeViolation`) }}
      </div>
      <div class="sz-ModalEditConfigurations-body-selection">
        <div class="sz-ModalEditConfigurations-body-selection-input">
          <FormSectionSingleSelectSearchable
            :title="$t(`deviceManagement.assignConfiguration.headers.network`)"
            :options="networkOptions"
            :default-selected-option="defaultOption"
            width="fullWidth"
            @updateSelectedOption="updateNetwork"
          />
        </div>
        <div
          class="sz-ModalEditConfigurations-body-selection-input"
          :class="`sz-ModalEditConfigurations-body-selection-input${areSelectedSetsSameDeviceType ? '' : '-disabled'}`"
        >
          <FormSectionSingleSelectSearchable
            :title="$t(`deviceManagement.assignConfiguration.headers.firmware`)"
            :options="firmwareOptions"
            :default-selected-option="defaultOption"
            width="fullWidth"
            @updateSelectedOption="updateFirmware"
          />
        </div>
        <div
          class="sz-ModalEditConfigurations-body-selection-input"
          :class="`sz-ModalEditConfigurations-body-selection-input${areSelectedSetsSameDeviceType ? '' : '-disabled'}`"
        >
          <FormSectionSingleSelectSearchable
            :title="$t(`deviceManagement.assignConfiguration.headers.operations`)"
            :options="operationsOptions"
            :default-selected-option="defaultOption"
            width="fullWidth"
            @updateSelectedOption="updateOperations"
          />
        </div>
        <div class="sz-ModalEditConfigurations-body-selection-input">
          <FormSectionSingleSelectSearchable
            :title="$t(`deviceManagement.assignConfiguration.headers.azure`)"
            :options="azureOptions"
            :default-selected-option="defaultOption"
            width="fullWidth"
            @updateSelectedOption="updateAzure"
          />
        </div>
      </div>
      <ListSelectedSets
        class="sz-ModalEditConfigurations-body-table"
        :sets="selectedSets"
        @updateSets="updateSets"
      />
    </div>
    <div class="sz-ModalEditConfigurations-footer">
      <div
        class="sz-ModalEditConfigurations-footer-button sz-ModalEditConfigurations-footer-cancel"
        @click="cancelEdit"
      >
        {{ $t(`modals.modalEditWidget.cancel`) }}
      </div>
      <div
        class="sz-ModalEditConfigurations-footer-button sz-ModalEditConfigurations-footer-save"
        type="submit"
        @click="submit"
      >
        {{ $t(`modals.modalEditWidget.save`) }}
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ListSelectedSets from './ModalEditConfigurations/ListSelectedSets'
    import constants from 'helpers/constants'
    import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
    import LoadingSpinner from '../Shared/LoadingSpinner'
    import EventBus from 'src/eventBus'
    import formValidator from 'mixins/formValidator'
    import constructModalSettings from 'mixins/modalSettings'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'ModalEditConfigurations',

        components: {
            ListSelectedSets,
            FormSectionSingleSelectSearchable,
            LoadingSpinner,
        },


        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            sets: {
                required: true,
                type: Array,
            },
        },

        data() {
            return {
                selectedSets: [],
                newSettings: {
                    network: undefined,
                    firmware: undefined,
                    operations: undefined,
                    azure: undefined,
                },
                showConfirmation: true,
                configMessages: {
                    network: undefined,
                    firmware: undefined,
                    operations: undefined,
                    azure: undefined,
                },
            }
        },

        computed: {
            ...mapGetters([
                'updatingSetConfiguration',
                'updateSetConfigurationStatus',
                'allFirmware',
                'allNetworkConfigs',
                'allOperationConfigs',
                'allAzureConfigs',
            ]),

            defaultOption () {
                return ''
            },

            networkOptions () {
                return this.allNetworkConfigs.map(network => {
                    return network.description
                })
            },

            firmwareOptions () {
                return this.allFirmware
                    .filter(firmware => firmware.deviceType === this.selectedSetsDeviceType)
                    .map(firmware => {
                        return firmware.description
                    })
            },

            operationsOptions () {
                return this.allOperationConfigs
                    .filter(op => op.deviceType === this.selectedSetsDeviceType)
                    .map(op => {
                        return op.description
                    })
            },

            azureOptions () {
                return this.allAzureConfigs.map(az => {
                    return az.description
                })
            },

            updateSucceeded () {
                return (this.updateSetConfigurationStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS) && this.settingsHaveChanged
            },

            isUserConfirmed() {
                return this.showConfirmation === false
            },

            settingsHaveChanged () {
                return !!Object.values(this.newSettings).find(setting => setting !== undefined)
            },

            areSelectedSetsSameDeviceType() {
                return this.selectedSets.every((set) => set.deviceType === this.selectedSets[0].deviceType)
            },

            selectedSetsDeviceType() {
                if (!this.areSelectedSetsSameDeviceType || this.selectedSets.length === 0) {
                    return undefined
                }

                return this.selectedSets[0].deviceType
            },
        },

        watch: {
            showConfirmation: {
                //watches if submit is confirmed by user & calls submit again
                handler: function () {
                    if (this.isUserConfirmed) {
                        this.submit()
                    }
                },
                deep: true,
            },
        },

        mounted () {
            this.selectedSets = this.sets

            EventBus.$on('USER_CONFIRMED', () => {
                this.showConfirmation = false
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.showConfirmation = true
            })

            this.$store.dispatch('getAllFirmware')
            this.$store.dispatch('getAllNetworkConfigs')
            this.$store.dispatch('getAllAzureConfigs')
            this.$store.dispatch('getAllOperationConfigs')
        },

        methods: {
            cancelEdit () {
                this.$emit('close')
            },

            updateNetwork(newNetwork) {
                if (newNetwork) {
                    this.newSettings.network = (this.allNetworkConfigs.find(network => network.description === newNetwork)).id
                    this.configMessages.network = this.$t('deviceManagement.assignConfiguration.editModal.assignedNetwork', { name: newNetwork })
                }
            },

            updateFirmware(newFirmware) {
                if (newFirmware) {
                    this.newSettings.firmware = (this.allFirmware.find(firmware => firmware.description === newFirmware)).id
                    this.configMessages.firmware = this.$t('deviceManagement.assignConfiguration.editModal.assignedFirmware', { name: newFirmware })
                }
            },

            updateOperations(newOperations) {
                if (newOperations) {
                    this.newSettings.operations = (this.allOperationConfigs.find(op => op.description === newOperations)).id
                    this.configMessages.operations = this.$t('deviceManagement.assignConfiguration.editModal.assignedOperation', { name: newOperations })
                }
            },

            updateAzure(newAzure) {
                if (newAzure) {
                    this.newSettings.azure = (this.allAzureConfigs.find(az => az.description === newAzure)).id
                    this.configMessages.azure = this.$t('deviceManagement.assignConfiguration.editModal.assignedAzure', { name: newAzure })
                }
            },

            updateSets(setID) {
                this.selectedSets = this.selectedSets.filter(set => set.setID !== setID)
                EventBus.$emit('SET_REMOVED', setID)
            },

            submit () {
                if (this.settingsHaveChanged) {
                    this.showConfirmation
                        ? this.confirmModal(
                            this.confirmMessage().title,
                            this.confirmMessage().message,
                            this.confirmMessage().list)
                        : this.saveUpdate()
                }
            },

            async saveUpdate () {
                try {
                    await this.$store.dispatch('updateSetConfigurations', this.formatUpdateInfo())

                    this.updateSucceeded
                        ? this.successModal(
                            this.$t('deviceManagement.assignConfiguration.editModal.updateMessage.checkLaterMessage'),
                            true,
                            undefined,
                            () => this.$store.dispatch('closeAllModals'),
                            this.$t('deviceManagement.assignConfiguration.editModal.updateMessage.checkLaterTitle'))
                        : this.failModal(
                            this.$t('pleaseTryAgain'),
                            this.$t('somethingWentWrong'),
                            () => this.showConfirmationModal = true)
                } catch(err) {
                    this.failModal(
                        this.$t('pleaseTryAgain'),
                        this.$t('somethingWentWrong'),
                        () => this.showConfirmationModal = true)
                }
            },

            // Formats all relevant information into an array of objects that will be consumable by the 'updateSetConfigurations' API call
            formatUpdateInfo () {
                return cloneDeep(this.selectedSets).map(set => {
                    return {
                        id: set.setID,
                        setConfig: {
                            firmware: {
                                operation: this.setOperation(this.newSettings.firmware),
                                id: this.newSettings.firmware,
                            },
                            azure: {
                                operation: this.setOperation(this.newSettings.azure),
                                id: this.newSettings.azure,
                            },
                            network: {
                                operation: this.setOperation(this.newSettings.network),
                                id: this.newSettings.network,
                            },
                            operation: {
                                operation: this.setOperation(this.newSettings.operations),
                                id: this.newSettings.operations,
                            },
                        },
                    }
                })
            },

            // If a valid id is present, the operation is 'assign'. Otherwise, the operation is 'unassign'
            setOperation(id) {
                switch(id) {
                    case(constants.DEVICE_DEFAULT_CONFIG.DESCRIPTION):
                        return constants.DEVICE_CONFIG_OPERATIONS.UNASSIGN

                    case(undefined):
                        return undefined
                        
                    default:
                        return constants.DEVICE_CONFIG_OPERATIONS.ASSIGN
                }
            },

            confirmMessage () {
                return {
                    title: this.$t(`deviceManagement.assignConfiguration.editModal.assignConfirm`),
                    message: this.$t('deviceManagement.assignConfiguration.editModal.assignMessage'),
                    list: Object.values(this.configMessages),
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
@import '~design';
    .sz-ModalEditConfigurations {
        width: 45vw;
        max-height: 94vh;
        color: $color-white;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar-widget;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        &-title {
            color: $color-white;
            margin-bottom: 1rem;
            font-size: 12px;
            @extend %font-heading;
            font-size: 15px;
            padding-top: 0.5rem;
        }

        &-body {
            display: flex;
            flex-direction: column;

            &-selection {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &-input {
                    font-size: 12px;
                    width: 10rem;
                    margin-right: 0.5rem;

                    &-disabled {
                        cursor: not-allowed;

                        ::v-deep .sz-FormSectionTagSelection-title {
                            color: gray;
                        }

                        ::v-deep .sz-FormSectionTagSelection {
                            cursor: not-allowed;
                            pointer-events:none;
                        }
                    }
                }
            }

            &-table {
                padding: 1rem 0 0.5rem 0;
            }

            &-multiDeviceTypes {
                color:red;
                padding: 0.5rem 0 1.5rem 0;
                font-size: 14px;
            }
        }

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-table-row-silver;
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.5rem;

            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
                font-size: 12px;
            }

            &-save {
                @extend %button-layout;
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1rem;
                border-radius: 0;
            }
        }

    }
</style>

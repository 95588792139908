<template lang="pug">
    div(
        class="sz-FormSectionTagSelection",
        :class="height")
        div(class="sz-FormSectionTagSelection-header")
            div(class="sz-FormSectionTagSelection-title") {{ title }}
            div(
                v-if="selectAll",
                class="sz-FormSectionTagSelection-selectAll")
                div(
                    @click.stop="toggleSelectAll",
                    :class="`sz-FormSectionTagSelection-selectAll-checkbox-` + (isSelectAll ? 'checked ': 'unchecked')",
                    class="sz-FormSectionTagSelection-selectAll-checkbox")
                div() {{ $t(`form.selectAll`) }}
        div(class="sz-FormSectionTagSelection-selector")
            TagList(
                :selectedTags="selectedOptions",
                @unselectTag="unselectTag",
                :height="height")
            DropdownSearchable(
                :items="availableOptions",
                @setResult="addResult")
</template>

<script>
    import TagList from './TagList'
    import DropdownSearchable from './DropdownSearchable'
    import EventBus from 'src/eventBus'

    export default {
        name: "FormSectionTagSelection",

        components: {
            TagList,
            DropdownSearchable,
        },

        props: {
            title: {
                required: true,
                type: String,
            },

            defaultSelectedTags: {
                required: false,
                type: Array,
                default: () => [],
            },

            options: {
                required: true,
                type: Array,
            },

            height: {
                required: false,
                type: String,
                default: '',
            },

            selectAll: {
                required: false,
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                selectedOptions: [],
                isSelectAll: false,
            }
        },


        computed: {
            availableOptions () {
                return this.options.filter((option) => {
                    return !this.selectedOptions.includes(option)
                })
            },
        },

        mounted () {
            this.selectedOptions = this.defaultSelectedTags

            EventBus.$on('CLEAR_SELECTED_TAGS', () => {
                this.clearOptions()
            })
        },

        beforeDestroy() {
            EventBus.$off('CLEAR_SELECTED_TAGS')
        },
        
        methods: {
            unselectTag (tag) {
                this.selectedOptions = this.selectedOptions.filter(item => item !== tag)
                this.$emit('updateSelectedTags', this.selectedOptions)
            },

            addResult (tag) {
                this.selectedOptions.push(tag)
                this.$emit('updateSelectedTags', this.selectedOptions)
            },

            toggleSelectAll () {
                this.isSelectAll = !this.isSelectAll
                this.isSelectAll
                    ? this.selectedOptions = this.options
                    : this.selectedOptions = []
                this.$emit('updateSelectedTags', this.selectedOptions)
            },

            clearOptions () {
                this.selectedOptions = []
                this.$emit('updateSelectedTags', this.selectedOptions)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionTagSelection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        &.twoLineHeight {
            height:50%;
        }

        &-header {
            display: flex;
            align-items: center;
        }

        &-title {
            @extend %font-topbar-heading;
            color: $color-white;
        }

        &-selectAll {
            display: flex;
            @extend %font-content-unselectable;
            color: $color-white;
            align-items: center;
            margin: 0.25rem 1rem;
            &-checkbox {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                &-checked {
                    background-color: $color-box-silver;
                }
            }
        }

        &-selector {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }
    }

</style>

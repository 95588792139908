import ApiService from './api.service'

const WidgetsService = {
    getAllWidgetSettings: async function (customerID, companyID, userID) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets`

        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    getWidgetData: async function (customerID, companyID, userID, widgetID) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets/${widgetID}/data`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    getUnrestrictedWidgetData: async function (customerID, companyID, userID, widgetID) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets/${widgetID}/data/unrestricted`
        try {
            const response = await ApiService.get(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    postNewWidget: async function (customerID, companyID, userID, widgetSettings) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets`
        try {
            const response = await ApiService.post(url, widgetSettings)
            return response.data
        } catch (error) {
            throw new Error(error.message)
        }
    },

    patchWidget: async function (customerID, companyID, userID, widgetID, widgetSettings) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets/${widgetID}`
        try {
            const response = await ApiService.patch(url, widgetSettings)
            return response.data
        } catch (error) {
            throw new Error(error.message)
        }
    },

    deleteWidget: async function (customerID, companyID, userID, widgetID) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets/${widgetID}`
        try {
            const response = await ApiService.delete(url)
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },

    updateWidgetOrder: async function (customerID, companyID, userID, widgetOrderList) {
        const url = `customers/${customerID}/companies/${companyID}/users/${userID}/widgets/order`
        try {
            const response = await ApiService.patch(url, { widgetIDs: widgetOrderList })
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },
}

export default WidgetsService

<template lang="pug">
    div(class="sz-ModalEditUser")
        div(
            v-if="updatingUser",
            class="sz-ModalEditUser-sectionSettings sz-ModalEditUser-sectionSettings-loading")
            LoadingSpinner
        div(class="sz-ModalEditUser-sectionSettings")
            div(class="sz-ModalEditUser-sectionSettings-title") {{ formTitle }}
            div(
                v-if="!user.userID",
                class="sz-ModalEditUser-sectionSettings-required")
                span(class="sz-ModalEditUser-sectionSettings-required-asterix") {{ $t('form.required') }}
                span(class="sz-ModalEditUser-sectionSettings-required-text") {{ $t('form.requiredField') }}
            div(class="sz-ModalEditUser-sectionSettings-top")
                div(class="sz-ModalEditUser-sectionSettings-left")
                    div(class="sz-ModalEditUser-sectionSettings-input")
                        FormSectionInput(
                            data-personal-info
                            :title="$t(`management.companyDashboard.userTable.headers.firstName`)",
                            :defaultInput="defaultSettings.firstName",
                            textSize="content",
                            @updated="updateFirstName"
                            :useInputTag="formUseInput")
                    div(class="sz-ModalEditUser-sectionSettings-input")
                        div(class="sz-ModalEditUser-sectionSettings-input-gender")
                            FormSectionDropdownSingleSelect(
                                :title="$t(`management.companyDashboard.userTable.headers.gender`)",
                                :options="genderOptions",
                                :defaultSelectedOption="defaultSettings.gender",
                                :placeholder="$t('form.select')"
                                @updateSelectedOption="updateSelectedGender"
                                data-personal-info)
                        div(class="sz-ModalEditUser-sectionSettings-input-mobile")
                            FormSectionInput(
                                :title="$t(`management.companyDashboard.userTable.headers.mobile`)",
                                :defaultInput="defaultSettings.mobile",
                                textSize="content",
                                @updated="updateMobile"
                                :useInputTag="formUseInput"
                                data-personal-info)
                    div(class="sz-ModalEditUser-sectionSettings-input-select")
                        FormSectionSingleSelectSearchable(
                            :title="$t(`management.companyDashboard.userTable.headers.job`)",
                            :options="jobOptions",
                            :defaultSelectedOption="defaultSettings.job",
                            @updateSelectedOption="updateJobType",
                            width="fullWidth",
                            :required="formRequired.job",
                            class="sz-ModalEditUser-sectionSettings-input-select-job"
                            data-personal-info)
                div(class="sz-ModalEditUser-sectionSettings-right")
                    div(class="sz-ModalEditUser-sectionSettings-input")
                        FormSectionInput(
                            :title="$t(`management.companyDashboard.userTable.headers.lastName`)",
                            :defaultInput="defaultSettings.lastName",
                            textSize="content",
                            @updated="updateLastName",
                            :useInputTag="formUseInput"
                            data-personal-info)
                    div(class="sz-ModalEditUser-sectionSettings-input")
                        FormSectionInput(
                            :title="$t(`management.companyDashboard.userTable.headers.email`)",
                            :defaultInput="defaultSettings.email",
                            :maxLength="320",
                            textSize="content",
                            :disableInput="isDisabled",
                            @updated="updateEmail",
                            :useInputTag="formUseInput",
                            :required="formRequired.email",
                            class="sz-ModalEditUser-sectionSettings-input-email"
                            data-personal-info)
                    div(class="sz-ModalEditUser-sectionSettings-input-select")
                        FormSectionSingleSelectSearchable(
                            :title="$t(`management.companyDashboard.userTable.headers.timezone`)",
                            :options="timezoneOptions"
                            :defaultSelectedOption="defaultSettings.timezone"
                            width="fullWidth"
                            :isSorted="false"
                            @updateSelectedOption="updateSelectedTimezone"
                            :required="formRequired.timezone",
                        )
            div(class="sz-ModalEditUser-sectionSettings-bottom")
                div(
                    v-if="user.userID",
                    class="sz-ModalEditUser-sectionSettings-input-select-title") {{ $t(`management.companyDashboard.userTable.headers.tags`) }}
                div(
                    v-if="user.userID",
                    class="sz-ModalEditUser-sectionSettings-input-select-tags")
                    FormSectionTagSelection(
                        :title="$t(`management.companyDashboard.userTable.headers.membertags`)",
                        :options="tagOptions",
                        height="twoLineHeight",
                        :selectAll="formSelectAll",
                        :defaultSelectedTags="defaultSettings.membertags",
                        @updateSelectedTags="updateMemberTags",
                        class="sz-ModalEditUser-sectionSettings-input-select-tags-tag"
                        data-personal-info)
                    FormSectionTagSelection(
                        :title="$t(`management.companyDashboard.userTable.headers.viewertags`)",
                        :options="tagOptions",
                        :selectAll="formSelectAll",
                        height="twoLineHeight",
                        :defaultSelectedTags="defaultSettings.viewertags",
                        @updateSelectedTags="updateViewerTags",
                        class="sz-ModalEditUser-sectionSettings-input-select-tags-tag"
                        data-personal-info)
            div(class="sz-ModalEditUser-sectionSettings-bottom")
                FormSectionToggle(
                    v-if="isLBAdmin && user.userID"
                    :title="$t(`management.companyDashboard.userTable.headers.animatorVisible`)",
                    :defaultChecked="defaultSettings.animatorVisible",
                    :options="animatorVisibilityOptions",
                    @changed="updateAnimatorVisible")
                div(class="sz-ModalEditUser-sectionSettings-input-select-buttons")
                    div(class="sz-ModalEditUser-buttons")
                        div(class="sz-ModalEditUser-buttons-submit")
                            div(
                                v-if="user.userID",
                                @click="submit",
                                :class="{'sz-ModalEditUser-buttons-submit-button-inactive': !settingsHaveChanged}"
                                class="sz-ModalEditUser-buttons-submit-button") {{ $t(`management.submit`) }}
            div(
                v-if="user.userID",
                class="sz-ModalEditUser-sectionSettings-bottom-divider")
            div(class="sz-ModalEditUser-sectionSettings-bottom")
                div(
                    v-if="!user.userID"
                    class="sz-ModalEditUser-sectionSettings-input-select")
                    FormSectionDropdownSingleSelect(
                        :title="$t(`management.companyDashboard.userTable.headers.roles`)",
                        :options="newUserRoleOptions",
                        :placeholder="$t(`management.companyDashboard.editUserOption.selectRoles`)",
                        :required="formRequired.roles",
                        @updateSelectedOption="updateSelectedRoles",
                        class="sz-ModalEditUser-sectionSettings-input-select"
                        data-personal-info)
                    FormSectionToggle(
                        v-if="isLBAdmin"
                        :title="$t(`management.companyDashboard.userTable.headers.animatorVisible`)",
                        :defaultChecked="defaultSettings.animatorVisible",
                        :options="animatorVisibilityOptions",
                        @changed="updateAnimatorVisible")

                div(
                    v-else,
                    class="sz-ModalEditUser-sectionSettings-input-select")
                    div(class="sz-ModalEditUser-sectionSettings-title") {{ $t(`management.companyDashboard.editUserOption.editRoles`) }}
                    EditRoles(
                        :title="$t(`management.companyDashboard.editUserOption.addARole`)",
                        :options="curUserRoleOptions",
                        :selectAll="formSelectAll",
                        :defaultSelectedRoles="defaultSettings.roles",
                        @updateSelectedRoles="updateSelectedRoles",
                        :userID="user.userID"
                        data-personal-info)
        div(class="sz-ModalEditUser-buttons")
            div(
                v-if="!user.userID"
                @click="cancelEdit"
                class="sz-ModalEditUser-buttons-cancel") {{ $t('management.cancel')}}
            div(class="sz-ModalEditUser-buttons-submit")
                div(
                    v-if="!user.userID",
                    @click="submit",
                    :class="{'sz-ModalEditUser-buttons-submit-button-inactive': !settingsHaveChanged}"
                    class="sz-ModalEditUser-buttons-submit-button") {{ $t(`management.companyDashboard.addNewUser`) }}
</template>

<script>
import constants from 'helpers/constants'
import formValidator from 'mixins/formValidator'
import { mapGetters } from 'vuex'
import { isEqual, cloneDeep } from 'lodash'
import { jobKeysAndValues } from 'helpers/socDirectMatchTitleFile'
import { userAssignableRoles } from 'helpers/permissionsHelper'
import FormSectionInput from 'components/Shared/FormSectionInput'
import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import DropdownMultiSelect from 'components/Shared/DropdownMultiSelect'
import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
import EditRoles from 'components/Modal/ModalEditUser/EditRoles'
import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
import LoadingSpinner from '../Shared/LoadingSpinner'
import constructModalSettings from 'mixins/modalSettings'
import EventBus from 'src/eventBus'
import { timezones } from 'helpers/timezones'
import { userIsLBAdmin } from '@/helpers/permissionsHelper'
import { getInstance } from 'auth/index'

export default {
  name: 'ModalEditUser',

  components: {
    LoadingSpinner,
    FormSectionInput,
    FormSectionDropdownSingleSelect,
    FormSectionToggle,
    DropdownMultiSelect,
    FormSectionTagSelection,
    FormSectionSingleSelectSearchable,
    EditRoles,
  },

  mixins: [formValidator, constructModalSettings],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      newFirstName: null,
      newLastName: null,
      newGender: null,
      newMobile: null,
      newEmail: null,
      newAnonymous: null,
      newRoles: null,
      newTimezone: null,
      status: constants.CRUD_OPERATION_STATUSES.SUCCESS,
      newMemberTags: null,
      newViewerTags: null,
      newJob: null,
      newAnimatorVisible: false,
      showConfirmation: true,
    }
  },

  computed: {
    ...mapGetters(['allCompanyTags', 'updatingUser', 'updateUserStatus', 'auth0User']),

    newSettings() {
      return {
        firstName: typeof this.newFirstName === 'string' ? this.newFirstName : this.user.firstName,
        lastName: typeof this.newLastName === 'string' ? this.newLastName : this.user.lastName,
        email: typeof this.newEmail === 'string' ? this.newEmail : this.user.email,
        gender: typeof this.newGender === 'string' ? this.newGender : this.user.gender,
        mobile: typeof this.newMobile === 'string' ? this.newMobile : this.user.mobile,
        timezone: typeof this.newTimezone === 'string' ? this.newTimezone : this.user.timezone,
        job: typeof this.newJob === 'string' ? this.newJob : this.user.job,
        membertags: this.hasNewMemberTags
          ? this.tagNameToID(this.newMemberTags)
          : this.tagNameToID(this.user.membertags),
        viewertags: this.hasNewViewerTags
          ? this.tagNameToID(this.newViewerTags)
          : this.tagNameToID(this.user.viewertags),
        animatorVisible:
          typeof this.newAnimatorVisible === 'boolean'
            ? this.newAnimatorVisible
            : this.user.animatorVisible,
        roles: this.isNewUser ? this.newRoles : undefined,
      }
    },

    hasNewViewerTags() {
      return Array.isArray(this.newViewerTags) && !isEqual(this.user.viewertags, this.newViewerTags)
    },

    hasNewMemberTags() {
      return Array.isArray(this.newMemberTags) && !isEqual(this.user.membertags, this.newMemberTags)
    },

    hasNewRoles() {
      return Array.isArray(this.newRoles) && !isEqual(this.user.roles, this.newRoles)
    },

    defaultSettings() {
      return {
        firstName: this.user.firstName || '',
        lastName: this.user.lastName || '',
        email: this.user.email || '',
        gender: this.user.gender || null,
        mobile: this.user.mobile || '',
        timezone: this.user.timezone || null,
        job: this.user.job || '',
        membertags: cloneDeep(this.user.membertags) || [],
        viewertags: cloneDeep(this.user.viewertags) || [],
        roles: cloneDeep(this.user.roles) || [],
        animatorVisible: this.user.animatorVisible || false,
      }
    },

    genderOptions() {
      return Object.values(constants.GENDER_NAME_API_MAP).map((gender) => {
        return {
          name: gender,
          type: constants.GENDER_API_NAME_MAP[gender],
        }
      })
    },

    newUserRoleOptions() {
      return Object.values(constants.BASE_ROLES).map((role) => {
        return {
          name: role,
          type: role,
        }
      })
    },

    curUserRoleOptions() {
      return userAssignableRoles(this.auth0User).map((role) => {
        return role
      })
    },

    timezoneOptions() {
      return timezones
    },

    tagOptions() {
      return this.allCompanyTags.map((tag) => {
        return tag.name
      })
    },

    jobOptions() {
      return Object.values(jobKeysAndValues)
    },

    settingsHaveChanged() {
      return (
        (typeof this.newFirstName === 'string'
          ? this.newFirstName !== this.user.firstName
          : false) ||
        (typeof this.newLastName === 'string' ? this.newLastName !== this.user.lastName : false) ||
        (typeof this.newEmail === 'string' ? this.newEmail !== this.user.email : false) ||
        (typeof this.newMobile === 'string' ? this.newMobile !== this.user.mobile : false) ||
        (typeof this.newGender === 'string' ? this.newGender !== this.user.gender : false) ||
        (typeof this.newTimezone === 'string' ? this.newTimezone !== this.user.timezone : false) ||
        this.hasNewRoles ||
        (typeof this.newJob === 'string' ? this.newJob !== this.user.job : false) ||
        this.hasNewMemberTags ||
        this.hasNewViewerTags ||
        (typeof this.newAnimatorVisible === 'boolean'
          ? this.newAnimatorVisible !== this.user.animatorVisible
          : false)
      )
    },

    filteredNewSettings() {
      //remove any parameters with nulls. Add User Endpoint will return an error if there are nulls
      var filteredSettings = cloneDeep(this.newSettings)
      Object.keys(filteredSettings).forEach((setting) => {
        if (filteredSettings[setting] == null) {
          delete filteredSettings[setting]
        }
      })
      return filteredSettings
    },

    updateSucceeded() {
      return (
        this.updateUserStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS &&
        this.settingsHaveChanged
      )
    },

    isDisabled() {
      return this.user.email ? true : false
    },

    isUserConfirmed() {
      return this.showConfirmation === false
    },

    isNewUser() {
      return this.user.userID == null || this.user.userID == undefined
    },

    formUseInput() {
      return true
    },

    formSelectAll() {
      return true
    },

    formRequired() {
      return {
        email: this.isNewUser ? true : false,
        roles: this.isNewUser ? true : false,
        job: this.isNewUser ? true : false,
        timezone: this.isNewUser ? true : false,
      }
    },

    formTitle() {
      return this.isNewUser
        ? this.$t(`management.companyDashboard.addNewUser`)
        : this.$t(`management.companyDashboard.editUserOption.editProfile`)
    },

    animatorVisibilityOptions() {
      return [
        this.$t(`management.companyDashboard.editUserOption.hidden`),
        this.$t(`management.companyDashboard.editUserOption.visible`),
      ]
    },

    isLBAdmin() {
      return userIsLBAdmin(getInstance().user)
    },
  },

  watch: {
    settingsHaveChanged() {
      if (this.settingsHaveChanged) {
        this.setUpdateStatus(constants.CRUD_OPERATION_STATUSES.SUCCESS)
      }
    },

    showConfirmation: {
      handler: function () {
        if (this.isUserConfirmed) {
          this.submit()
        }
      },
      deep: true,
    },
  },

  mounted() {
    EventBus.$on('USER_CONFIRMED', () => {
      this.showConfirmation = false
    })

    EventBus.$on('USER_CANCELLED', () => {
      this.showConfirmation = true
    })
  },

  methods: {
    updateFirstName(newFirstName) {
      this.newFirstName = newFirstName.trim()
    },

    updateLastName(newLastName) {
      this.newLastName = newLastName.trim()
    },

    updateEmail(newEmail) {
      this.newEmail = newEmail.trim()
    },

    updateMobile(newMobile) {
      this.newMobile = newMobile.trim()
    },

    updateSelectedGender(newGender) {
      this.newGender = newGender
    },

    updateSelectedRoles(newRoles) {
      this.newRoles = newRoles
    },

    updateSelectedTimezone(newTimezone) {
      this.newTimezone = newTimezone
    },

    updateMemberTags(newMemberTags) {
      this.newMemberTags = newMemberTags
    },

    updateViewerTags(newViewerTags) {
      this.newViewerTags = newViewerTags
    },

    updateJobType(newJob) {
      this.newJob = newJob
    },

    updateAnimatorVisible(newAnimatorVisible) {
      this.newAnimatorVisible = newAnimatorVisible
    },

    async submit() {
      if (!this.settingsHaveChanged) return

      const validator = this.isNewUser
        ? this.validateNewUserSettings()
        : this.validateProfileSettings()

      if (!validator.valid) return this.invalidModal(validator, 'user', false)

      this.isNewUser ? this.saveNewUser() : this.updateUser()
    },

    async saveNewUser() {
      await this.$store.dispatch('saveNewUserToCompany', {
        settings: this.filteredNewSettings,
        role: this.newSettings.roles,
      })

      this.updateSucceeded
        ? this.successModal(
            this.$t('management.companyDashboard.updateMessage.newUserSuccess'),
            true,
            () => this.$store.dispatch('getAllUsersInCompany'),
            () => this.$store.dispatch('closeAllModals')
          )
        : this.failModal(
            this.$t('pleaseTryAgain'),
            this.$t('somethingWentWrong'),
            () => (this.showConfirmation = true)
          )
    },

    async updateUser() {
      await this.$store.dispatch('saveUserDetails', {
        newSettings: this.newSettings,
        userID: this.user.userID,
      })
      this.updateSucceeded
        ? this.successModal(
            this.$t('management.companyDashboard.updateMessage.editUserSuccess'),
            true,
            () => this.$store.dispatch('getAllUsersInCompany'),
            () => this.$store.dispatch('closeAllModals')
          )
        : this.failModal(
            this.$t('pleaseTryAgain'),
            this.$t('somethingWentWrong'),
            () => (this.showConfirmation = true)
          )
    },

    cancelEdit() {
      this.$emit('close')
    },

    setUpdateStatus(status) {
      this.status = status
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.sz-ModalEditUser {
  width: 58vw;
  max-height: 85.75vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar-widget;

  &-sectionSettings {
    position: relative;
    display: block;
    width: 100%;
    font-size: 12px;
    flex-direction: row;

    &-required {
      color: $color-unselected-text;
      margin-bottom: 1rem;
      margin-left: 1rem;
      text-align: left;
      font-style: italic;

      &-asterix {
        color: red;
      }
    }

    &-title {
      @extend %font-topbar-heading;
      font-size: 14px;
      color: $color-white;
      margin-bottom: 0.5rem;
    }

    &-input {
      display: flex;
      margin-bottom: 2rem;
      &-select {
        font-size: 12px;
        margin-bottom: 1.5rem;
        margin-right: 1.5rem;
        &-title {
          @extend %font-heading;
          font-size: 12px;
          color: $color-white;
          display: flex;
          align-items: flex-start;
          margin-bottom: 0.25rem;
        }

        &-tags {
          margin-bottom: 1rem;
          display: flex;

          &-tag {
            margin-right: 1.5rem;
          }
        }
      }

      &-mobile {
        flex-basis: 100%;
      }

      &-gender {
        width: 6rem;
        margin-right: 1rem;
      }
    }

    &-loading {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-table-row-silver;
      opacity: 0.5;
      filter: blur(1px);
      z-index: $layer-modal-loader-z-index;
    }

    &-top {
      display: flex;
      margin: 0 1rem;
    }

    &-bottom {
      margin: 0 1rem;

      &-divider {
        height: 0.05rem;
        background: white;
        opacity: 0.15;
        margin: 0.75rem 0;
      }
    }

    &-right {
      flex: 1;
      flex-direction: column;
    }

    &-left {
      flex: 50%;
      flex-direction: column;
      flex-basis: 0;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    @extend %font-topbar-heading;
    color: $color-white;
    margin: 0 1rem;
    cursor: pointer;

    &-submit {
      display: flex;
      justify-content: center;
      &-button {
        background-color: $color-lifebooster-light-green;
        @extend %button-layout;
        padding: 0.5rem 1rem;
        border-radius: 0;
      }
    }

    &-cancel {
      display: flex;
      justify-content: center;
      @extend %button-layout;
      background-color: transparent;
      padding: 0.5rem 1rem;
    }
  }
}
</style>

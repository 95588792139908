import constants from "helpers/constants"
import WorkerService from 'services/worker.service'
import { cloneDeep } from 'lodash'
import router from 'router/index'

const individualSummary = {
    state: {
        workerId: null,
        workerCard: {},
        selectedModule: constants.RISK_MODULES.MOTION,
        selectedAssessmentTimestamp: null,
        workerHasAnySkus: true,
        assessments: {},
        riskGraph: {},
        currentCapture: {},
        disabledRiskTypes: [],
    },
    mutations: {
        setWorkerId (state, payload) {
            state.workerId = payload
        },

        setWorkerCard (state, payload) {
            state.workerCard = payload
        },

        setWorkerHasAnySkus (state, payload) {
            state.workerHasAnySkus = payload
        },

        setAssessments (state, payload) {
            state.assessments = cleanAssessments(payload)
        },

        setSelectedModule (state, payload) {
            state.selectedModule = payload
        },

        setSelectedAssessmentTimestamp (state, payload) {
            state.selectedAssessmentTimestamp = payload
        },

        setSelectedRiskTypes (state, payload) {
            const index = state.disabledRiskTypes.indexOf(payload)
            if (index > -1) {
                state.disabledRiskTypes.splice(index, 1)
            } else {
                const newDisabledRiskTypes = cloneDeep(state.disabledRiskTypes)
                newDisabledRiskTypes.push(payload)
                state.disabledRiskTypes = newDisabledRiskTypes
            }

        },
    },
    actions: {
        async updateWorkerCard ({ commit, rootGetters }, { workerId }) {
            commit('setWorkerCard', {})
            try {
                const info = await WorkerService.workerCard(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    workerId)
                commit('setWorkerCard', info)
            } catch (e) {
                // handle the worker id error
            }
        },

        async updateAssessments ({ commit, rootGetters, state }) {
            try {
                const assessments = await WorkerService.assessments(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.workerId
                )

                commit('setAssessments', assessments
                    .sort((a,b) => {
                        return a.startTimePosix*100 -  b.startTimePosix*100
                    }))

                // Retains the selected assessment in the Individual Summary Page when returning from the Assessment Page
                if (state.selectedAssessmentTimestamp && Object.keys(state.assessments).includes(state.selectedAssessmentTimestamp.toString())) {
                  commit('setSelectedAssessmentTimestamp', state.selectedAssessmentTimestamp)
                // Retains the currently selected assessment in the Assessment Page upon refreshing the page
                } else if (router.currentRoute.params.assessmentDate) {
                  commit('setSelectedAssessmentTimestamp', router.currentRoute.params.assessmentDate)
                // Selects latest assessment when loading a new user's Individual Summary Page
                } else {
                  commit('setSelectedAssessmentTimestamp', rootGetters.latestAvailableAssessment)
                }
            } catch (e) {
                // handle the worker error
                console.log(e)
            }
        },

        updateSelectedModule({ commit }, currentModule) {
            commit('setSelectedModule', currentModule)
        },

        updateSelectedAssessmentTimestamp({ commit }, timestamp) {
            commit('setSelectedAssessmentTimestamp', timestamp)
        },

        updateWorkerId ({ commit }, workerId) {
            commit('setWorkerId', workerId)
        },

        toggleRiskType ({ commit }, payload) {
            commit('setSelectedRiskTypes', payload)
        },
    },
    getters: {
        workerId: (state) => {
            return state.workerId
        },

        workerCard: (state) => {
            return state.workerCard
        },

        workerHasAnySkus: (state) => {
            return state.workerHasAnySkus
        },

        assessments: (state) => {
            return state.assessments
        },

        numberOfAssessments: (state) => {
            return Object.keys(state.assessments).length
        },

        latestAvailableAssessment: (state, rootGetters) => {
            if (state.assessments && rootGetters.numberOfAssessments) {
                return Object.keys(state.assessments)[rootGetters.numberOfAssessments - 1]
            }

            return false
        },

        currentCapture: (state) => {
            if (Object.keys(state.assessments).length > 0) {
                return state.currentCapture || Object.keys(state.assessments)[0]
            }
            return null
        },

        selectedAssessment: (state, rootGetters) => {
            return state.assessments[rootGetters.selectedAssessmentTimestamp]
        },

        selectedAssessmentTimestamp: (state) => {
            return state.selectedAssessmentTimestamp
        },

        selectedModuleAssessmentDetail: (state, rootGetters) => {
            return rootGetters.selectedAssessment[rootGetters.selectedModule]
        },

        disabledRiskTypes: (state) => {
            return state.disabledRiskTypes
        },

        riskGraph: (state) => {
            return state.riskGraph
        },

        selectedModule: (state) => {
            return state.selectedModule
        },

        currentCaptureByModule: (state, getters) => (moduleName) => {
            return getters.selectedAssessment[moduleName]
        },
    },
}

/**
 * Fills out capture related information in individual-summary state
 * @param  {string} capturesJSON JSON string of captures for worker
 * @param  {object} summary      summary to be filled out
 */
function buildCaptures(capturesJSON, summary) {
    let captures = JSON.parse(capturesJSON)

    summary.captures = captures.reduce((obj, cap) => {
        obj[cap.calibDate] = cap
    }, {})
}

function getFirstAvailableModule (assessment) {
    let hasModules = assessment.motion || assessment.thermal || assessment.spatial

    let moduleSummariesHasErgo = !!assessment.motion

    let nextAvailableModule = Object.keys(assessment).find((module) => {
        return !!assessment[module].riskTypes
    })

    if (!nextAvailableModule) {
        return false
    }

    return hasModules && moduleSummariesHasErgo ? constants.RISK_MODULES.MOTION : nextAvailableModule
}

function cleanAssessments (assessments) {
    let cleanAssessments = {}
    if (assessments.length > 0) {
        assessments.map(assessment => {
            let cleanedAssessment = {}
            cleanedAssessment[constants.RISK_MODULES.AGGREGATE] = {
                risk: assessment.risk,
            }

            Object.values(constants.RISK_MODULES).map(moduleName => {
                if (moduleName !== constants.RISK_MODULES.AGGREGATE) {
                    let moduleDetails = assessment.ModuleSummaries.find((summary) => {
                        return summary.name.toLowerCase() === moduleName
                    })
                    if (moduleDetails) {
                        const riskTypes = extractRiskTypesFromModule(moduleDetails)
                        const moduleSpecificDetails = extractModuleSpecificDetails(moduleDetails)
                        cleanedAssessment[moduleName] = {
                            risk: moduleDetails.risk,
                            ...riskTypes,
                            ...moduleSpecificDetails,
                        }
                    }
                }
            })

            cleanAssessments[assessment.startTimePosix] = {
                    timestamp: assessment.startTimePosix,
                    captureIdx: assessment.capture,
                    assessmentIdx: assessment.assessment,
                    humidity: assessment.humidity,
                    timezone: assessment.timezone,
                    aggregationAllowed: assessment.aggregationAllowed,
                    assessmentTags: assessment.assessmentTags,
                    ...cleanedAssessment,
            }
        })
    }

    return cleanAssessments
}

function extractModuleSpecificDetails (moduleSummary) {
    if (moduleSummary.RiskTypes[0].payload) {
        return moduleSummary.RiskTypes[0].payload
    }
}

function extractRiskTypesFromModule (moduleSummary) {
    let details = {
        riskTypes: {},
    }
    for (let riskType of moduleSummary.RiskTypes) {
        details.riskTypes[riskType.name] = {
            risk: parseFloat(`0.${riskType.r}${riskType.o}${riskType.y}`),
            color: getRiskColor(riskType.r, riskType.o, riskType.y),
            payload: riskType.payload,
        }
    }
    return details
}

function getRiskColor (r, o, y) {
    if (!!r) return constants.RISK_SCORES.RED
    if (!!o) return constants.RISK_SCORES.ORANGE
    if (!!y) return constants.RISK_SCORES.YELLOW
    return constants.RISK_SCORES.GREEN
}

export default individualSummary

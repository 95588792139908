import ApiService from './api.service'
import LogService from 'services/log.service'
import constants from 'helpers/constants'

const CompanyService = {
  getAllCompanies: async function () {
    const url = `/companies`

    try {
      const response = await ApiService.get(url)
      return response.data.data
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error.response)
      throw new Error(error.response)
    }
  },

  getCompany: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAllCompanyJobTypes: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/jobtypes`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAllCompanyTags: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/tags`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAllCompanySKUs: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/skus`

    try {
      // const response = await ApiService.get(url)
      // return response.data
      return ['D-EST']
    } catch (error) {
      throw new Error(error.response)
    }
  },

  uploadUsersToCompany: async function (customerID, companyID, newUsers, role) {
    const url = `/customers/${customerID}/companies/${companyID}/users/batch?role=${role}`
    try {
      const response = await ApiService.post(url, newUsers)
      return response.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  },

  getAllUsersInCompany: async function (customerID, companyID) {
    const url = `/admin/customers/${customerID}/companies/${companyID}/users`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  saveNewUserToCompany: async function (customerID, companyID, newUser, role) {
    const url = `/customers/${customerID}/companies/${companyID}/users?role=${role}`
    try {
      const response = await ApiService.post(url, newUser)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  bulkEditUsersToCompany: async function (customerID, companyID, users) {
    const url = `/customers/${customerID}/companies/${companyID}/users/update`
    try {
      const response = await ApiService.post(url, users)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateTagToCompany: async function (customerID, companyID, tagID, tagName) {
    const url = `/customers/${customerID}/companies/${companyID}/tags/${tagID}`
    try {
      const response = await ApiService.put(url, tagName)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  saveNewTagToCompany: async function (customerID, companyID, tagName) {
    const url = `/customers/${customerID}/companies/${companyID}/tags`
    try {
      const response = await ApiService.post(url, tagName)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateCompanyAnonymity: async function (customerID, companyID, companyAnon) {
    const url = `/customers/${customerID}/companies/${companyID}/anonymous`
    try {
      const response = await ApiService.post(url, companyAnon)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateCompanyVisibility: async function (customerID, companyID, companyVisible) {
    const url = `/customers/${customerID}/companies/${companyID}/visible`
    try {
      const response = await ApiService.post(url, companyVisible)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateMAPV: async function (customerID, companyID, MAPV) {
    const url = `/customers/${customerID}/companies/${companyID}/mapv`
    try {
      const response = await ApiService.post(url, MAPV)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAssessmentTags: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  createAssessmentTag: async function (customerID, companyID, tagName) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags`

    try {
      const response = await ApiService.post(url, { name: tagName })
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateAssessmentTag: async function (customerID, companyID, assessmentTagID, tagName) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags/${assessmentTagID}`

    try {
      const response = await ApiService.patch(url, { name: tagName })
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  deleteAssessmentTag: async function (customerID, companyID, assessmentTagID) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags/${assessmentTagID}`

    try {
      const response = await ApiService.delete(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getUsersForApplyDefaultAssessmentTags: async function (
    customerID,
    companyID,
    orgChartTags,
    jobs,
    genders,
    userIDs
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/users`

    try {
      const response = await ApiService.get(url, {
        addDefaultAssessmentTags: true,
        orgChartTags,
        jobs,
        genders,
        userIDs,
      })
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not obtain users from company ${companyID}.`)
    }
  },

  getUserCompleteProfileByID: async function (customerID, companyID, userID) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not obtain users from company ${companyID}.`)
    }
  },

  getUserCompleteProfileByID: async function (customerID, companyID, userID) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not obtain user\'s profile with ID ${userID}.`)
    }
  },
}

export default CompanyService

import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import ApiService from 'services/api.service'
import constants from '@/helpers/constants'
import { createHmac } from 'crypto'
/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      }
    },

    /** Use this lifecycle method to instantiate the SDK client */
    created: async function () {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
      })

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        try {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.user = await this.auth0Client.getUser()

          if (
            this.user &&
            this.user.hasRegisteredEmail !== undefined &&
            !this.user.hasRegisteredEmail
          ) {
            // User has been authenticated via the Identity Provider but is not registered in Senz. Log them out
            this.logout({
              returnTo: `${process.env.VUE_APP_V2_BASE_URL}/401`,
              client_id: options.clientId,
            })
          } else {
            ApiService.setAuthHeader((await this.getTokenSilently()).toString())

            if (this.user) {
              window.dataLayer = []

              const intercomUserID = `auth0|${this.user[constants.AUTH0_USER_INFO_KEYS.USER_ID]}`

              const hash = createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRET)
                .update(intercomUserID)
                .digest('hex')

              window.dataLayer.push({
                event: 'user',
                logged_in: true,
                email: this.user[constants.AUTH0_USER_INFO_KEYS.EMAIL], //required
                userId: intercomUserID,
                name: `${this.user[constants.AUTH0_USER_INFO_KEYS.GIVEN_NAME]} ${
                  this.user[constants.AUTH0_USER_INFO_KEYS.FAMILY_NAME]
                }`,
                hash,
              })
            }
            this.loading = false
          }
        } catch (e) {
          ApiService.removeHeader()
          this.isAuthenticated = false
          this.loading = false
        }
      }
    },

    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)
        } catch (e) {
          // eslint-disable-next-line
          console.error(e)
        } finally {
          this.popupOpen = false
        }

        this.user = await this.auth0Client.getUser()
        this.isAuthenticated = true
      },

      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          ApiService.setAuthHeader(await this.getTokenSilently())
          this.isAuthenticated = true
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },

      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },

      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },

      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },

      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },

      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        // 'vuex-persistedstate' plugin stores the current session's state until the window is closed
        // Logging out does not close the window, so we need to manually clear the state
        window.sessionStorage.clear()

        window.dataLayer = []

        ApiService.removeHeader()
        return this.auth0Client.logout(o)
      },
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}

<template>
  <div class="modalManageTaskBreakdown">
    <div class="title">
      {{ $t("taskAssessment.manageTaskBreakdown.title") }}
    </div>
    <div class="body">
      <div class="body__text">
        {{ $t("taskAssessment.manageTaskBreakdown.downloadLatestFileMessage") }}
      </div>
      <div class="body__text">
        {{
          $t("taskAssessment.manageTaskBreakdown.replaceExistingFileMessage")
        }}
      </div>
    </div>
    <div class="footer">
      <div
        class="footer__buttons footer__buttons-downloadExistingFile"
        @click.stop="downloadExistingTaskBreakdown"
      >
        <div v-if="!requestedDownload">
          {{ $t("taskAssessment.manageTaskBreakdown.downloadExistingFile") }}
        </div>
        <div v-else>
          <LoadingSpinner
            color="light"
            height="button"
          />
        </div>
      </div>
      <div
        class="footer__buttons footer__buttons-replaceExistingFile"
        @click="openUploadTaskBreakdown"
      >
        {{ $t("taskAssessment.manageTaskBreakdown.replaceExistingFile") }}
      </div>
    </div>
  </div>
</template>

<script>
  import constants from "helpers/constants"
  import constructModalSettings from "mixins/modalSettings"
  import formValidator from 'mixins/formValidator'
  import { Parser } from "json2csv"
  import moment from 'moment-timezone'
  import LoadingSpinner from 'components/Shared/LoadingSpinner'

  export default {
    name: "ModalManageTaskBreakdown",

    components: {
      LoadingSpinner,
    },

    mixins: [
      constructModalSettings,
      formValidator,
    ],

    props: {
      customerID: {
        type: String,
        required: true,
      },
      companyID: {
        type: String,
        required: true,
      },
      userID: {
        type: String,
        required: true,
      },
      captureID: {
        type: String,
        required: true,
      },
      metadataID: {
        type: String,
        required: true,
      },
      setID: {
        type: String,
        required: true,
      },
      timezone: {
        type: String,
        required: true,
      },
      startTime: {
        type: Number,
        required: true,
      },
      endTime: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        requestedDownload: false,
      }
    },

    methods: {
      async downloadExistingTaskBreakdown() {
        if (!this.requestedDownload) {
          try {
            this.requestedDownload = true

            const taskBreakdownJson = await this.$store.dispatch(
              "downloadTaskBreakdown",
              {
                customerID: this.customerID,
                companyID: this.companyID,
                userID: this.userID,
                captureID: this.captureID,
              }
            )

            const captureStartTimeReadable = moment(moment.tz(this.startTime, this.timezone)).format('YYYYMMDD-hhmmss')
            const taskBreakdownFileWithoutPosixTimes = this.removeTaskPosixTime(taskBreakdownJson)

            const json2csvParser = new Parser()
            const csv = json2csvParser.parse(taskBreakdownFileWithoutPosixTimes)

            let anchor = document.createElement("a")
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
            anchor.target = "_blank"
            anchor.download = `${this.setID}_${captureStartTimeReadable}_TaskBreakdown.csv`
            anchor.click()
            anchor.remove()
          } catch (err) {
            await this.failModal(
              this.$t("pleaseTryAgain"),
              this.$t("somethingWentWrong"),
            )
          } finally {
            this.requestedDownload = false
          }
        }
      },

      openUploadTaskBreakdown() {
        this.$store.dispatch(
          "showModal",
          this.constructModalSettings(
            constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_UPLOAD_TASK_BREAKDOWN,
            false,
            {
              customerID: this.customerID,
              companyID: this.companyID,
              userID: this.userID,
              captureID: this.captureID,
              metadataID: this.metadataID,
              timezone: this.timezone,
              startTime: this.startTime,
              endTime: this.endTime,
              replaceExistingFile: true,
            },
            false,
            true
          )
        )
      },

      // Silently removes Posix time from each task in the downloaded task breakdown JSON file 
      removeTaskPosixTime(taskBreakdownJson) {
        if (!taskBreakdownJson && !taskBreakdownJson.length > 0) {
          throw new Error('Task breakdown file is empty or does not exist')
        }

        return taskBreakdownJson.map((row) => {
          delete row.PosixTaskStartTime
          delete row.PosixTaskEndTime
          return row
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "~design";

.modalManageTaskBreakdown {
  display: flex;
  flex-direction: column;
  color: $color-white;
  padding: 1.75rem 2.5rem 1.75rem 2.5rem;
}

.body {
  width: 25rem;
  text-align: left;
  padding-bottom: 1rem;

  &__text {
    padding-bottom: 0.5rem;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__buttons {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    cursor: pointer;
  }

  &__buttons-downloadExistingFile {
    background-color: $color-lifebooster-light-green;
    margin-right: 1rem;
  }

  &__buttons-replaceExistingFile {
    background-color: $color-lifebooster-light-green;
    margin-left: 1rem;
  }
}
</style>

<template lang="pug">
    SvgIcon(
        icon="checkmark",
        width="15",
        height="15",
        class="checkmark")
</template>

<script>
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: "SuccessAnimation",

        components: {
            SvgIcon,
        },
    }
</script>

<style lang="scss">
    @import '~design';

    .sz-svg-checkmark {
        width: 100%;
        height: 100%;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sz-SvgIcon-checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: $color-white;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px $color-table-row-silver;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    }

    .sz-SvgIcon-checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $color-table-row-silver;
        fill: none;
        animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
    }

    .sz-SvgIcon-checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {
        0%, 100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px $color-lifebooster-extra-light-green;
        }
    }
</style>
import { render, staticRenderFns } from "./WorkerIdentificationPanel.vue?vue&type=template&id=6e6a82c6&scoped=true&lang=pug"
import script from "./WorkerIdentificationPanel.vue?vue&type=script&lang=js"
export * from "./WorkerIdentificationPanel.vue?vue&type=script&lang=js"
import style0 from "./WorkerIdentificationPanel.vue?vue&type=style&index=0&id=6e6a82c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e6a82c6",
  null
  
)

export default component.exports
import ApiService from './api.service'

const WorkerService = {
  getAllAvailableWorkers: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/users`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  workerCard: async function (customerID, companyID, userID) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/personal`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateWorker: async function (customerID, companyID, userID, newSettings) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}`
    try {
      const response = await ApiService.patch(url, newSettings)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateWorkerRole: async function (customerID, companyID, userID, role, updateType) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/${updateType}`
    try {
      const response = await ApiService.patch(url, role)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },
}

export default WorkerService

<template lang="pug">
    div(class="sz-ListSelectedSets")
        div(class="sz-ListSelectedSets-label") {{ $t(`deviceManagement.assignConfiguration.selectedSets`) }}
        div(class="sz-ListSelectedSets-list")
            div(class="sz-ListSelectedSets-list-headers")
                div(class="sz-ListSelectedSets-list-header") {{ $t(`deviceManagement.assignConfiguration.headers.setID`) }}
                div(class="sz-ListSelectedSets-list-header") {{ $t(`deviceManagement.assignConfiguration.headers.network`) }}
                div(class="sz-ListSelectedSets-list-header") {{ $t(`deviceManagement.assignConfiguration.headers.firmware`) }}
                div(class="sz-ListSelectedSets-list-header") {{ $t(`deviceManagement.assignConfiguration.headers.operations`) }}
                div(class="sz-ListSelectedSets-list-header") {{ $t(`deviceManagement.assignConfiguration.headers.azure`) }}
            div(
                class="sz-ListSelectedSets-list-set",
                v-for="set in sets")
                    div(class="sz-ListSelectedSets-list-set-info") {{ set.setID }}
                    div(class="sz-ListSelectedSets-list-set-info") {{ set.network }}
                    div(class="sz-ListSelectedSets-list-set-info") {{ set.firmware }}
                    div(class="sz-ListSelectedSets-list-set-info") {{ set.operations }}
                    div(class="sz-ListSelectedSets-list-set-info") {{ set.azure }}
                    div(
                        @click.stop="removeSet(set.setID)"
                        class="sz-ListSelectedSets-list-set-remove")
                        SvgIcon(
                            icon="close",
                            width=12,
                            height=12,
                            class="sz-ListSelectedSets-list-set-remove-icon")
</template>

<script>
    import SvgIcon from 'components/Shared/SvgIcon'
    import EventBus from 'src/eventBus'

    export default {
        name: "ListSelectedSets",

        components: {
            SvgIcon,
        },

        props: {
            sets: {
                required: true,
                type: Array,
            },
        },

        methods: {
            removeSet(setID) {
                this.$emit('updateSets', setID)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ListSelectedSets {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        &-label {
            @extend %font-topbar-heading;
            text-align: left;
            margin-bottom: 0.5rem;
        }

        &-list {
            width: 100%;
            overflow: scroll;
            @include scrollbar-widget;
            max-height: 25vh;

            &-headers {
                grid-template-columns:
                    minmax(6rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    1rem;
                display: grid;
                text-align: left;
                margin-bottom: 0.25rem;
                grid-gap: 0.5rem;
            }

            &-set {
                @extend %font-content;
                color: $color-unselected-text;
                display: grid;
                grid-template-columns:
                    minmax(6rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    1rem;
                text-align: left;
                grid-gap: 0.5rem;
                margin-bottom: 0.5rem;
                padding-right: 0.25rem;

                &-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &-remove {
                    padding-top: 0.5rem;

                    &-icon {
                        color: $color-risk-red;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>

import colors from 'design/_colors.scss'

const independentConstants = {
  ONE_PAGE_BACK: -1,
  OPEN_MODAL: true,
  CLOSE_MODAL: false,
  UNIX_MILLISECONDS: 1000,
  MILLISECONDS_TO_SECONDS: 1 / 1000,
  MINIMUM_ZOOM_RANGE: 9000,
  MAX_ZOOM_FACTOR: 5,
  MIN_ZOOM_FACTOR: 0,
  DEFAULT_MAX_SAMPLE: 512,
  SECONDS_IN_MINUTE: 60,
  SECONDS_IN_HOUR: 3600,
  TETHER_TIMEOUT: 60,
  MESSAGE_TIMEOUT: 2000,
  PERCENT: 100,
  COLSPAN_3: 3,
  COLSPAN_1: 1,

  // chunk size is 20 seconds (in milliseconds)
  CHUNK_SIZE: 20 * 1000,
  CHUNK_SIZE_IN_ARRAY_INDEX_LENGTH: 1000,
  // the amount of time we need to simulate every time the mainloop calls update() in milliseconds
  CHARACTER_VIEWER_TIMESTEP: 50,

  // 5000 = number of milliseconds in five seconds,
  // 50 represents the character viewer timestep
  // so the animation read index is at five seconds
  ANIMATION_READ_INDEX: 5000 / 50,
  // the minimum amount of time before the player needs to pause and make more requests is 10 seconds
  // to request more data, it takes roughly 500 ms to 1 second to complete
  // so 10 seconds is more than enough as an intial buffer
  // Dividing by 50 represents the character viewer timestep
  MINIMUM_ANIMATION_ARRAY_LENGTH: 10000 / 50,

  EVENTS: {
    CLEAR_SELECTION: 'CLEAR_SELECTION',
  },

  ERGO_RISK_TYPES: {
    LEFT_SHOULDER_INC: 'leftShoulderInc',
    RIGHT_SHOULDER_INC: 'rightShoulderInc',
    LEFT_ELBOW_FLEX: 'leftElbowFlexExt',
    RIGHT_ELBOW_FLEX: 'rightElbowFlexExt',
    LEFT_ELBOW_PRON: 'leftElbowPronSup',
    RIGHT_ELBOW_PRON: 'rightElbowPronSup',
    BACK_ROT: 'backRot',
    BACK_FLEX: 'backFlexExt',
    BACK_SIDE_BEND: 'backSideBend',
    PELVIS_FLEX: 'pelvisFlexExt',
  },

  AUTH0_USER_INFO_KEYS: {
    COMPANY: 'https://lifebooster.senz.ca/company',
    CUSTOMER: 'https://lifebooster.senz.ca/customer',
    JOB: 'https://lifebooster.senz.ca/job',
    JOB_CODE: 'https://lifebooster.senz.ca/soc:',
    TAGS: 'https://lifebooster.senz.ca/tags',
    USER_ID: 'https://lifebooster.senz.ca/userID',
    VISIBILITY: 'https://lifebooster.senz.ca/visibility',
    ROLE: 'https://lifebooster.senz.ca/roles',
    GIVEN_NAME: 'given_name',
    FAMILY_NAME: 'family_name',
    NICKNAME: 'nickname',
    NAME: 'name',
    UPDATED_AT: 'updated_at',
    EMAIL: 'email',
  },

  ERGO_RISK_JOINTS: {
    LEFT_SHOULDER: 'leftShoulder',
    LEFT_ELBOW: 'leftElbow',
    RIGHT_SHOULDER: 'rightShoulder',
    RIGHT_ELBOW: 'rightElbow',
    BACK: 'back',
    PELVIS: 'pelvis',
  },

  MOTION_INDIVIDUAL_SUMMARY_JOINTS: {
    LEFT_SHOULDER: 'leftShoulder',
    LEFT_ELBOW: 'leftElbow',
    RIGHT_SHOULDER: 'rightShoulder',
    RIGHT_ELBOW: 'rightElbow',
    BACK: 'back',
  },

  QUAT_STREAM_RISK_NAMES: {
    LEFT_SHOULDER_INC: 'leftShoulderIncRisk',
    RIGHT_SHOULDER_INC: 'rightShoulderIncRisk',
    LEFT_ELBOW_FLEX: 'leftElbowFlexExtRisk',
    RIGHT_ELBOW_FLEX: 'rightElbowFlexExtRisk',
    LEFT_ELBOW_PRON: 'leftElbowPronSupRisk',
    RIGHT_ELBOW_PRON: 'rightElbowPronSupRisk',
    BACK_ROT: 'backRotRisk',
    BACK_FLEX: 'backFlexExtRisk',
    BACK_SIDE_BEND: 'backSideBendRisk',
  },

  ERGO_RISK_SIDES: {
    LEFT: 'left',
    UP: 'up',
    RIGHT: 'right',
    DOWN: 'down',
  },

  ERGO_SIDE_COLOURS: {
    LEFT: '#1368F7',
    RIGHT: '#F713B8',
  },

  ERGO_JOINT_MOVEMENTS: {
    ELBOW_PRON_SUP: 'elbowPronSup',
    ELBOW_FLEX_EXT: 'elbowFlexExt',
    SHOULDER_INC: 'shoulderInc',
    BACK_FLEX_EXT: 'backFlexExt',
    BACK_SIDE_BEND: 'backSideBend',
    BACK_ROT: 'backRot',
  },

  ERGO_ALL_RISK_TYPES: [
    'leftShoulderInc',
    'rightShoulderInc',
    'leftElbowFlexExt',
    'rightElbowFlexExt',
    'backRot',
    'backFlexExt',
    'backSideBend',
  ],

  POSTURE_DETAILS_TABLE_ROWS: [
    'windowDuration',
    'windowContribution',
    'totalDuration',
    'totalContribution',
  ],

  POSTURE_ALL_RISKS: ['leftShoulderPosture', 'rightShoulderPosture'],

  VIBRATION_ALL_RISKS: ['leftHandVibration', 'rightHandVibration'],

  VIBRATION_THRESHOLD_VALUES: {
    RED: 5,
    ORANGE: 2.5,
    YELLOW: 1,
  },

  VIBRATION_THRESHOLD_SOURCE: 'EU',

  VIBRATION_FREQ_RANGE: '1 - 10 Hz',

  VIBRATION_ASSESSMENT_GRAPH_LABEL: 'a8',

  THERMAL_ALL_RISKS: ['heat'],

  THERMAL_TEMPERATURE_TYPES: {
    BODY_AMBIENT_MAX: 'maxBodyAmbient',
    BODY_AMBIENT_MEAN: 'body_ambient_mean',
    BODY_AMBIENT_MIN: 'minBodyAmbient',
    SCALE: {
      CELSIUS: 'celsius',
      FAHRENHEIT: 'fahrenheit',
    },
  },

  THERMAL_RISK_SCORES: {
    TIME_SPENT_GREEN: 'greenDuration',
    TIME_SPENT_YELLOW: 'yellowDuration',
    TIME_SPENT_ORANGE: 'orangeDuration',
    TIME_SPENT_RED: 'redDuration',
  },

  POSE_TYPES: {
    IN_VEHICLE: 'inVehicle',
    OTHER: 'other',
    WALK_RUN: 'walkRun',
    SITTING: 'sitting',
    STANDING: 'standing',
    LYING_SUPINE: 'lyingSupine',
    LYING_PRONE: 'lyingProne',
    LYING_SIDE: 'lyingSide',
  },

  RISK_MODULES: {
    MOTION: 'motion',
    POSTURE: 'posture',
    VIBRATION: 'vibration',
    THERMAL: 'thermal',
    AGGREGATE: 'aggregate',
  },

  RISK_SUMMARY_COMPONENTS: {
    ERGO_SUMMARY: 'ErgoSummary',
    VIBRATION_SUMMARY: 'VibrationSummary',
    THERMAL_SUMMARY: 'ThermalSummary',
  },

  RISK_SCORES: {
    GREEN: 0,
    YELLOW: 1,
    ORANGE: 2,
    RED: 3,
  },

  RISK_EVENT_HEIGHT: 3,

  GRAPH_SETTINGS: {
    RESPONSIVE: true,
    MAINTAIN_ASPECT: false,
    HOVER_MODE: null,
    DISPLAY_LEGEND: false,
    ENABLE_TOOLTIPS: false,
    AXIS_TYPE: 'logarithmic',
    STACK_X: true,
    TICKS_X: false,
    GRID_X: false,
    MAX_X: null,
    ZERO_X: true,
    STACK_Y: true,
    TICKS_Y: false,
    GRID_Y: false,
    MIN_Y: 0.0005,
    MAX_Y: 15,
    ZERO_Y: true,
  },

  DISTRIBUTION_CHART_TYPES: {
    OCT: 'tags',
    JOB_TYPE: 'jobType',
    RISK: 'detailedRiskTypes',
  },

  CHANGE_ANALYSIS_CHART_TYPES: {
    OCT: 'tags',
    TIME_TYPE: 'timeType',
  },

  CHARACTER_VIEWER_JOINT_NAMES: {
    PELVIS: 'pelvis',
    U_BACK: 'uback',
    L_ELBOW: 'lelbow',
    L_SHOULDER: 'lshoulder',
    R_ELBOW: 'relbow',
    R_SHOULDER: 'rshoulder',
  },

  QUAT_OBJECT_JOINT_NAMES: {
    PELVIS_W: 'Pelvis_w',
    PELVIS_X: 'Pelvis_x',
    PELVIS_Y: 'Pelvis_y',
    PELVIS_Z: 'Pelvis_z',
    U_BACK_W: 'Thorax_w',
    U_BACK_X: 'Thorax_x',
    U_BACK_Y: 'Thorax_y',
    U_BACK_Z: 'Thorax_z',
    L_ELBOW_W: 'LFA_w',
    L_ELBOW_X: 'LFA_x',
    L_ELBOW_Y: 'LFA_y',
    L_ELBOW_Z: 'LFA_z',
    L_SHOULDER_W: 'LUA_w',
    L_SHOULDER_X: 'LUA_x',
    L_SHOULDER_Y: 'LUA_y',
    L_SHOULDER_Z: 'LUA_z',
    R_ELBOW_W: 'RFA_w',
    R_ELBOW_X: 'RFA_x',
    R_ELBOW_Y: 'RFA_y',
    R_ELBOW_Z: 'RFA_z',
    R_SHOULDER_W: 'RUA_w',
    R_SHOULDER_X: 'RUA_x',
    R_SHOULDER_Y: 'RUA_y',
    R_SHOULDER_Z: 'RUA_z',
  },

  DISTRIBUTION_CHART_TYPE_API_KEYS: {
    TAG_DIST_CHART: 'TagDistributionChart',
    JOB_TYPE_DIST_CHART: 'JobTypeDistributionChart',
    RISK_TYPE_DIST_CHART: 'RiskTypeDistributionChart',
  },

  ANALYSIS_CHART_TYPE_API_KEYS: {
    TAG_ANALYSIS_CHART: 'TagAnalysisChart',
    TIME_TYPE_ANALYSIS_CHART: 'TimeTypeAnalysisChart',
  },

  EXTERNAL_LINKS: {
    MARKETING_WEBSITE: 'https://lifebooster.ca/',
  },

  WIDGET_TYPES_API_KEYS: {
    LIST: 'UserList',
    TREND: 'TrendChart',
    DISTRIBUTION: 'DistributionBarChart',
    CHANGE_ANALYSIS: 'ChangeAnalysisChart',
    SUMMARY: 'Summary',
  },

  RISK_SCORE_TYPES_API_KEYS: {
    RISK_SEVERITY: 'roy',
    TOTAL_RISKS: 'R',
  },

  INTERNAL_KEYS: {
    RISK_TYPE_NONE: 'none',
  },

  WIDGET_TYPES_COMPONENT_NAMES: {
    LIST: 'WidgetUserList',
    TREND: 'WidgetTrendChart',
    DISTRIBUTION: 'WidgetDistributionBarChart',
    CHANGE_ANALYSIS: 'WidgetChangeAnalysisChart',
    SUMMARY: 'WidgetAssessmentSummary',
  },

  WIDGET_SETTINGS_API_KEYS: {
    ID: 'id',
    ORDER: 'order',
    ANTHROPOMETRIC_TYPES: 'anthropometricTypes',
    GENDER: 'Gender',
    INTERVAL: 'interval',
    JOB_TYPES: 'jobTypes',
    TAGS: 'tags',
    TITLE: 'title',
    RISK_MODULES: 'riskTypes',
  },

  WIDGET_LIST_HEADERS: {
    firstName: 'First',
    lastName: 'Last',
    job: 'Job Type',
    gender: 'Gender',
    risk: 'R-Risk Score',
  },

  WIDGET_LIST_RISK_COLORS: {
    RED: '-red',
    ORANGE: '-orange',
    YELLOW: '-yellow',
    GREEN: '-green',
  },

  FORM_EDIT_WIDGET_SECTIONS: [
    'title',
    'type',
    'distributionType',
    'analysisType',
    'interval',
    'presets',
    'modules',
    'genders',
    'jobTypes',
    'orgChartTags',
    'assessmentTags',
    'riskScoreType',
    'timeRefLabel',
    'timeCompLabel',
    'intervalRef',
    'intervalComp',
    'refAssessmentTags',
    'compAssessmentTags',
    'compAssessmentTagsLabel',
    'refAssessmentTagsLabel',
    'groupComparisonLabel',
  ],

  FORM_EXPORT_DATA_SECTIONS: ['interval', 'presets', 'modules'],

  FORM_TIME: {
    AM: 'AM',
    PM: 'PM,',
  },

  CUSTOM_TOOLTIP_BODY: {
    WORKER_ID_TOOLTIP: 'WorkerIDTooltipBody',
  },

  MODAL_TYPES_COMPONENT_NAMES: {
    DEFAULT: 'ModalDefault',
    MODAL_EDIT_WIDGET: 'ModalEditWidget',
    MODAL_DELETE_WIDGET: 'ModalDeleteWidget',
    MODAL_EDIT_USER: 'ModalEditUser',
    MODAL_EDIT_USER_TAGS: 'ModalEditUserTags',
    MODAL_EDIT_BULK: 'ModalEditBulkUser',
    MODAL_BULK_UPLOAD: 'ModalBulkUpload',
    MODAL_CONFIRM_ASSESSMENT: 'ModalConfirmAssessment',
    MODAL_UPLOAD_CERTIFICATES: 'ModalUploadCertificates',
    MODAL_INITIATE_CAPTURE: 'ModalInitiateCapture',
    MODAL_SUCCESS: 'ModalSuccess',
    MODAL_FAILURE: 'ModalFailure',
    MODAL_INVALID_FORM_INPUT: 'ModalInvalidFormInput',
    MODAL_CONFIRMATION: 'ModalConfirmation',
    MODAL_DROPDOWN_SELECT: 'ModalDropdownSelect',
    MODAL_BULK_EDIT_ASSESSMENTS: 'ModalBulkEditAssessments',
    MODAL_EDIT_CONFIGURATIONS: 'ModalEditConfigurations',
    MODAL_UPLOAD_CONFIGURATION: 'ModalUploadConfiguration',
    MODAL_EDIT_DEFAULT_ASSESSMENT_TAGS: 'ModalEditDefaultAssessmentTags',
    MODAL_GO_TO_EXTERNAL_PAGE: 'ModalGoToExternalPage',
    MODAL_UPLOAD_TASK_BREAKDOWN: 'ModalUploadTaskBreakdown',
    MODAL_MANAGE_TASK_BREAKDOWN: 'ModalManageTaskBreakdown',
    MODAL_CAPTURE_DETAILS: 'ModalCaptureDetails',
  },

  GENDER_NAME_API_MAP: {
    M: 'Male',
    F: 'Female',
    U: 'Unspecified',
  },

  GENDER_API_NAME_MAP: {
    Male: 'M',
    Female: 'F',
    Unspecified: 'U',
  },

  DATE_PRESETS: {
    ALL_TIME: 'allTime',
    LAST_7_DAYS: 'lastSevenDays',
    LAST_30_DAYS: 'lastThirtyDays',
    LAST_90_DAYS: 'lastNinetyDays',
    LAST_ASSESSMENT: 'lastAssessment',
  },

  INTERVAL_CODES: {
    ALL_TIME: '$range$0$now$',
    LAST_7_DAYS: '$trail$now$7d$',
    LAST_30_DAYS: '$trail$now$30d$',
    LAST_90_DAYS: '$trail$now$90d$',
    LAST_ASSESSMENT: 'latestAssessment',
  },

  EDITOR: {
    END: 1,
    START: 0,
    CHANGE_OPTIONS: {
      TIMEZONE: 'timezone',
      START_TIME: 'startTime',
      END_TIME: 'endTime',
    },
  },

  EDITOR_OPTIONS: {
    EDIT: 'edit',
    REPAIR: 'repair',
  },

  REPAIR_OPTIONS: {
    ROTATION: 'rotation',
    SHIFT: 'shift',
  },

  MOMENT_TIME_FORMAT: {
    DATE_TIME: 'MM-DD-YYYY HH:mm:ss A',
    TIME: 'hh:mm:ss A',
  },

  ASSESSMENT_CHART_PANEL_COMPONENTS: 'ChartGroup',

  ASSESSMENT_INFO_COMPONENTS: 'AssessmentInfo',

  MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS: {
    DCA_START_END: 'dca start/end',
    DURATION_ABOVE_POS: 'durationAbove',
    DURATION_WITHIN: 'durationWithin',
    DURATION_ABOVE_NEG: 'durationBelow',
    HUMIDITY: 'humidity',
    A8: 'a8',
  },

  MOTION_ASSESSMENT_GRAPH_LABELS: {
    ZOOM: 'zoom',
    JOINT_ANGLE: 'jointAngle',
    RISKY_EVENTS: 'riskyEvents',
  },

  POSTURE_ASSESSMENT_GRAPH_LABELS: {
    MVC_RATIO: 'mvcRatio',
  },

  THERMAL_ASSESSMENT_GRAPH_LABELS: {
    TEMPERATURE_F: 'temperatureF',
    TEMPERATURE_C: 'temperatureC',
    RISK_DISTRIBUTION: 'riskDistribution',
  },

  SUMMARY_ASSESSMENT_LABELS: {
    ASSESSMENT_COUNT: 'assessmentCount',
    ASSESSMENT_HOURS: 'totalAssessmentHours',
    FIRST_ASSESSMENT: 'oldestAssessment',
    LAST_ASSESSMENT: 'latestAssessment',
  },

  ASSESSMENT_GRAPH_INDICES: {
    TIMESTAMP: 0,
    PRIMARY_VALUE: 1,
    QUAT_VALUES: 2,
    RISK_TYPE_VALUES: 3,
    EVENT_DESCRIPTIONS: 4,
    THERMAL: {
      THRESHOLD_VALUES: 2,
    },
    POSTURE: {
      MVC_RATIO_VALUES: 2,
      WINDOW_DURATION_VALUES: 5,
      WINDOW_CONTRIBUTION_VALUES: 6,
      QUAT_VALUES: 7,
      RISK_TYPE_VALUES: 8,
    },
  },

  EVENT_DESCRIPTION_INDICES: {
    MOTION: {
      CONDITION: 0,
      RISK_LEVEL: 1,
      STANDARD_CODE: 2,
      COUNT: 3,
    },

    POSTURE: {
      TIME_ABOVE_THRESHOLD: 0,
      DUTY_CYCLE: 1,
      MVCL: 2,
      MVCO: 3,
    },

    THERMAL: {
      ACTIVITY: 0,
      WORK_REST_PER: 1,
      STANDARD_CODE: 2,
    },

    VIBRATION: {
      YELLOW_COUNT: 0,
      ORANGE_COUNT: 1,
      RED_COUNT: 2,
      STANDARD_CODE: 3,
    },
  },

  ASSESSMENT_STREAM_INDICES: {
    TIMESTAMP: 0,
    QUAT_VALUES: 1,
    RISK_TYPE_VALUES: 2,
  },

  EVENT_TYPES: {
    YELLOW: 'yellowRiskCount',
    ORANGE: 'orangeRiskCount',
    RED: 'redRiskCount',
    GREEN: 'greenRiskCount',
    YELLOW_POSITIVE: 'positiveYellowRiskCount',
    ORANGE_POSITIVE: 'positiveOrangeRiskCount',
    RED_POSITIVE: 'positiveRedRiskCount',
    YELLOW_NEGATIVE: 'negativeYellowRiskCount',
    ORANGE_NEGATIVE: 'negativeOrangeRiskCount',
    RED_NEGATIVE: 'negativeRedRiskCount',
  },

  ERGO_THRESHOLD_TYPES: {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
  },

  POSITIVE_DURATION_TYPES: {
    YELLOW_POSITIVE: 'yellowPositiveRiskDuration',
    ORANGE_POSITIVE: 'orangePositiveRiskDuration',
    RED_POSITIVE: 'redPositiveRiskDuration',
  },

  NEGATIVE_DURATION_TYPES: {
    YELLOW_NEGATIVE: 'yellowNegativeRiskDuration',
    ORANGE_NEGATIVE: 'orangeNegativeRiskDuration',
    RED_NEGATIVE: 'redNegativeRiskDuration',
  },

  NORM_RISK_COUNTS: {
    RED: 'normRedRiskCount',
    ORANGE: 'normOrangeRiskCount',
    YELLOW: 'normYellowRiskCount',
  },

  COLORS: {
    WHITE: colors['color-white'],
    WIDGET: {
      GRAPH: colors['color-bar-chart-background'],
      XAXIS_COLOR: colors['color-chart-xAxis-label'],
      XAXIS_COLOR_GREEN: colors['color-chart-xAxis-risk-decrease'],
      XAXIS_COLOR_RED: colors['color-chart-xAxis-risk-increase'],
      XAXIS_COLOR_NORMAL: colors['color-chart-xAxis-no-risk'],
      YAXIS_COLOR: colors['color-chart-yAxis-label'],
      TITLE_LABEL_COLOR: colors['color-chart-title-label'],
      GRID_COLOR: colors['color-chart-grid'],
      AXIS_COLOR: colors['color-chart-axis'],
      BAR_COLOR_HOVERED: colors['color-bar-chart-hover-bar'],
      BAR_COLOR_NORMAL: colors['color-bar-chart-normal-bar'],
      TREND_LINE_COLOR: colors['color-trend-chart-line'],
      TREND_MARKER_COLOR: colors['color-trend-chart-marker'],
    },
    ASSESSMENT: {
      ASSESSMENT_PANEL: colors['color-chart-motion-panel-background'],
      SCROLLER: colors['color-motion-scroller'],
      CHART_BACKGROUND: colors['color-chart-motion-graph-background'],
      TICKS: colors['color-chart-motion-ticks'],
      YAXIS_COLOR: colors['color-chart-yAxis-label'],
      ANGLE_LINE: colors['color-white'],
      MARKER: colors['color-motion-marker'],
      ANNOTATION: colors['color-motion-annotation'],
    },
    INDIV_SUMMARY: {
      ERGO_COLORS: {
        L_SHOULDER: colors['color-graph-left-shoulder'],
        L_ELBOW: colors['color-graph-left-elbow'],
        R_SHOULDER: colors['color-graph-right-shoulder'],
        R_ELBOW: colors['color-graph-right-elbow'],
        BACK: colors['color-graph-back'],
      },
      VIBRATION_COLORS: {
        L_HAND: colors['color-graph-left-shoulder'],
        R_HAND: colors['color-graph-left-elbow'],
      },

      ERGO_LINES_MAP: {
        0: 'solid',
        2: 'dotted',
        5: 'dashed',
      },
    },
  },

  PAGES: {
    INDIVIDUAL_SUMMARY: 'individual-summary',
    ASSESSMENT: 'assessment',
    HOME: 'home',
    MANAGEMENT: 'management',
    INITIATE_CAPTURE: 'initiate-capture',
    DEVICES: 'devices',
    ASSESSMENT: {
      ASSESSMENT: 'assessment',
      EDITOR: 'editor',
      MODULE: 'module',
    },
    EXPORT: 'export',
    INTERVIEW: 'interview',
    WELCOME: 'welcome',
  },

  SETTINGS_PAGES: {
    PROFILE: 'profileDashboard',
    CUSTOMER: 'customerDashboard',
    COMPANY: 'companyDashboard',
    LB_ADMIN: 'lbAdminDashboard',
    ASSESSMENT: 'assessmentDashboard',
  },

  SETTINGS_PAGE_COMPONENT_NAMES: {
    PROFILE: 'ProfileSettings',
    CUSTOMER: 'CustomerSettings',
    COMPANY: 'CompanySettings',
    LB_ADMIN: 'LBAdminSettings',
    ASSESSMENT: 'AssessmentSettings',
  },

  ASSESSMENT_PAGE: {
    MOTION: 'motion',
    POSTURE: 'posture',
    VIBRATION: 'vibration',
    THERMAL: 'thermal',
    AGGREGATE: 'aggregate',
    EDITOR: 'editor',
  },

  ROLES: {
    LB_ADMIN: 'Lifebooster Admin',
    LB_ASSESSOR: 'Lifebooster Assessor',
    CUSTOMER_ADMIN: 'Customer Admin',
    COMPANY_ADMIN: 'Company Admin',
    CUSTOMER_ASSESSOR: 'Customer Assessor',
    COMPANY_ASSESSOR: 'Company Assessor',
    WORKER: 'Worker',
    BASE_WORKER: 'Worker (Limited Visibility)',
  },

  CRUD_OPERATION_STATUSES: {
    SUCCESS: 'success',
    FAIL: 'fail',
  },

  USER_CHARACTERISTICS: {
    USER_ID: 'userID',
    ROLE: 'roles',
    EMAIL: 'email',
    F_NAME: 'firstName',
    L_NAME: 'lastName',
    GENDER: 'gender',
    MOBILE_NUMBER: 'mobile',
    TIME_ZONE: 'timezone',
    TAGS: 'tags',
    VIEWER: 'viewertags',
    MEMBER: 'membertags',
    ANIMATOR: 'animator',
    SKU: 'sku',
    JOB: 'job',
    ANONYMOUS: 'anonymous',
    CREATED: 'createdAt',
    USER_VISIBLE: 'userVisible',
  },

  DEVICE_CHARACTERISTICS: {
    SHIPPED: 'shipped',
    SN: 'sn',
    POS: 'pos',
    SID: 'sid',
    DEPLOYMENT_MODE: 'deploymentMode',
    DEVICE_TYPE: 'deviceType',
  },

  DEVICE_SETTINGS: {
    MANAGE_DEVICES: 'BatchTable',
    STATUS_CONFIGURATION: 'StatusConfiguration',
    ASSIGN_CONFIGURATION: 'AssignConfiguration',
    RENAME_CONFIGURATION: 'RenameConfiguration',
  },

  DEVICE_TYPES: {
    SGDT: 'SGD-T',
    ISGCT: 'ISGC-T',
  },

  DEVICE_POSITIONS: {
    THX: 'THX',
    PEL: 'PEL',
    RUA: 'RUA',
    LUA: 'LUA',
    RFA: 'RFA',
    LFA: 'LFA',
  },

  DEVICE_STATUS_CHARACTERISTICS: {
    SET_ID: 'setID',
    PEL: 'PEL',
    THX: 'THX',
    RUA: 'RUA',
    LUA: 'LUA',
    RFA: 'RFA',
    LFA: 'LFA',
  },

  DEVICE_CONFIG_CHARACTERISTICS: {
    SET_ID: 'setID',
    NETWORK: 'network',
    FIRMWARE: 'firmware',
    OPERATIONS: 'operations',
    AZURE: 'azure',
    FILE_TYPE: 'type',
    UPLOAD_DATE: 'uploadDate',
    DESCRIPTION: 'description',
    UTC_CREATION_TIMESTAMP: 'creationTimestampUTC',
  },

  DEVICE_CONFIG_API_PARAMS: {
    FILE: 'raw',
    DESC: 'description',
    DISABLE: 'disable',
    DEVICE_TYPE: 'deviceType',
  },

  DEVICE_CONFIG_API_TYPES: {
    NETWORK: 'Network',
    FIRMWARE: 'Firmware',
    OPERATIONS: 'Operation',
    AZURE: 'Azure',
  },

  DEVICE_CONFIG_OPERATIONS: {
    ASSIGN: 'assign',
    UNASSIGN: 'unassign',
  },

  DEVICE_DEFAULT_CONFIG: {
    DESCRIPTION: 'unassigned',
  },

  COMPANY_SETTINGS: {
    EDIT: {
      OCT: 'OctManage',
      USERS: 'UserTable',
      TAGS: {
        ASSIGN: 'assign',
        CLEAR: 'clear',
        REMOVE: 'remove',
        NEWTAG: 'new',
        EDITTAG: 'edit',
      },
      ROLES: {
        ADD: 'add-role',
        REMOVE: 'remove-role',
      },
      ANONYMOUS_MODE: 'CompanyAnonymousMode',
    },
  },

  ANONYMOUS_MODE_SETTINGS: {
    ANONYMOUS: 'anonymous',
    VISIBLE: 'visible',
  },

  ASSESSMENT_SETTINGS: {
    HISTORY: 'AssessmentHistory',
    MANAGE_ASSESSMENT_TAGS: 'ManageAssessmentTags',
    ASSIGN_ASSESSMENT_TAGS: 'AssignAssessmentTags',
    APPLY_DEFAULT_TAGS: 'ApplyDefaultAssessmentTags',
    EXCLUDE_ASSESSMENTS: 'ExcludeAssessments',
    ASSIGN: 'assign',
    REMOVE: 'remove',
  },

  INITIATE_CAPTURE: {
    SID_MAX_LENGTH: 6,
    POSE_TIMER: 10,
    SIGN_OUT_TIMEOUT: 15000,
  },

  HTTP_RESPONSE_CODES: {
    BAD_REQUEST: '400',
    NOT_FOUND: '404',
    GONE: '410',
    UNPROCESSABLE_ENTITY: '422',
    INTERNAL_SERVER_ERROR: '500',
  },

  TIME_PERIODS: {
    MOTION: 0.05,
    THERMAL: 30,
    SPATIAL: 0.05,
    VIBRATION: 60,
    POSTURE: 0.05,
  },

  ASSESSMENT_PROGRESS_TABLE_HEADERS: {
    F_NAME: 'firstName',
    L_NAME: 'lastName',
    SET_ID: 'setID',
    START: 'start',
    OFFSET: 'offset',
    STATE: 'status',
    COMPANY: 'company',
    CUSTOMER: 'customer',
    ASSESSMENT_ID: 'assessmentID',
  },

  ASSESSMENT_PROGRESS_STATE: {
    STARTED: 'Started',
    UPLOADING: 'Uploading',
    DATA_PROCESSING: 'DataProcessing',
    RISK_PROCESSING: 'RiskProcessing',
    CHALLENGED: 'Challenged',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    CALIBRATION_FAILED: 'CalibrationFailed',
  },

  LOG_LEVELS: {
    EMERGENCY: 'Emergency',
    ALERT: 'Alert',
    CRITICAL: 'Critical',
    ERROR: 'Error',
    WARNING: 'Warning',
    NOTICE: 'Notice',
    INFO: 'Info',
    DEBUG: 'Debug',
  },

  CONFIRM_ACTIONS: {
    SAVE: 'Save',
    DELETE: 'Delete',
  },

  ASSESSMENT_TABLE_HEADERS: {
    F_NAME: 'firstName',
    L_NAME: 'lastName',
    START: 'start',
    END: 'end',
    JOB: 'job',
    GENDER: 'gender',
    OCTS: 'orgChartTags',
    ASSESSMENT_TAGS: 'assessmentTags',
  },

  EXCLUDE_ASSESSMENT_TABLE_HEADERS: {
    ID: 'id',
    START: 'start',
    END: 'end',
    CUSTOMER: 'customer',
    COMPANY: 'company',
    EXCLUDED: 'excluded',
  },

  UPDATE_TAG_OPTIONS: {
    ASSIGN: 0,
    REMOVE: 1,
  },

  BOOLEAN_MAPPER: {
    true: 'yes',
    false: 'no',
  },

  RISK_MODULES_SHORT: {
    MOTION: 'M',
    THERMAL: 'T',
    VIBRATION: 'V',
    POSTURE: 'P',
    EDITOR: 'E',
  },

  OFF_BODY_PERIOD_TABLE_PROPS: {
    POSITION: 'position',
    START: 'startTime',
    END: 'endTime',
    FLIPPED: 'flipped',
  },

  DEFAULT_SENSOR_ROTATIONS: {
    FLIP: 180,
    NEUTRAL: 0,
  },

  FEEDBACK_EMAIL: 'feedback@lifebooster.ca',

  CAPTURE_DETAILS_HEADERS: {
    ATTRIBUTE_OF_INTEREST: 'attributeOfInterest',
    PEL: 'PEL',
    THX: 'THX',
    RFA: 'RFA',
    RUA: 'RUA',
    LFA: 'LFA',
    LUA: 'LUA',
  },

  CAPTURE_DETAILS_ROWS: {
    SERIAL_NUMBER: 'deviceID',
    RECORDING_START_TIME: 'recordingStartTime',
    LAST_QUAT_TIME: 'lastQuatTime',
    CRADLE_REMOVAL_TIME: 'cradleRemovalTime',
    CRADLE_INSERTION_TIME: 'cradleInsertionTime',
    UPLOAD_END_TIME: 'uploadEndTime',
    FILE_SIZE: 'fileSize',
    PREVIOUS_RECORDING_START_TIME: 'previousRecordingStartTime',
    PREVIOUS_RECORDING_UPLOAD_END_TIME: 'previousRecordingUploadEndTime',
    PREVIOUS_RECORDING_FILE_SIZE: 'previousRecordingFileSize',
    NUMBER_OF_GAPS: 'numberOfGaps',
    NUMBER_OF_KEY_MEASSAGES: 'numberOfKeyMessages',
    FIRMWARE_VERSION: 'firmwareVersion',
    MIN_HUMIDITY: 'minHumidity',
    MIN_HUMIDITY_TIME: 'minHumidityTime',
    MAX_HUMIDITY: 'maxHumidity',
    MAX_HUMIDITY_TIME: 'maxHumidityTime',
    MIN_TEMPERATURE: 'minTemperature',
    MIN_TEMPERATURE_TIME: 'minTemperatureTime',
    MAX_TEMPERATURE: 'maxTemperature',
    MAX_TEMPERATURE_TIME: 'maxTemperatureTime',
    FIRST_BATTERY_LEVEL: 'firstBatteryLevel',
    FIRST_BATTERY_LEVEL_TIME: 'firstBatteryLevelTime',
    LAST_BATTERY_LEVEL: 'lastBatteryLevel',
    LAST_BATTERY_LEVEL_TIME: 'lastBatteryLevelTime',
  },
}

const dependentConstants = {
  ERGO_RISK_JOINTS_SIDE_MAP: {
    [independentConstants.ERGO_RISK_SIDES.LEFT]: [
      independentConstants.ERGO_RISK_JOINTS.LEFT_SHOULDER,
      independentConstants.ERGO_RISK_JOINTS.LEFT_ELBOW,
    ],
    [independentConstants.ERGO_RISK_SIDES.RIGHT]: [
      independentConstants.ERGO_RISK_JOINTS.RIGHT_SHOULDER,
      independentConstants.ERGO_RISK_JOINTS.RIGHT_ELBOW,
    ],
    [independentConstants.ERGO_RISK_SIDES.DOWN]: [independentConstants.ERGO_RISK_JOINTS.BACK],
    [independentConstants.ERGO_RISK_SIDES.UP]: [],
  },

  ERGO_RISK_TYPES_JOINT_MAP: {
    [independentConstants.ERGO_RISK_JOINTS.LEFT_SHOULDER]: [
      independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC,
    ],
    [independentConstants.ERGO_RISK_JOINTS.RIGHT_SHOULDER]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC,
    ],
    [independentConstants.ERGO_RISK_JOINTS.LEFT_ELBOW]: [
      independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX,
    ],
    [independentConstants.ERGO_RISK_JOINTS.RIGHT_ELBOW]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX,
    ],
    [independentConstants.ERGO_RISK_JOINTS.BACK]: [
      independentConstants.ERGO_RISK_TYPES.BACK_ROT,
      independentConstants.ERGO_RISK_TYPES.BACK_FLEX,
      independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND,
    ],
  },

  JOINT_CHARACTER_VIEWER_JOINT_MAP: {
    [independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER,
    [independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW,
    [independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_PRON]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_PRON]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW,
    [independentConstants.ERGO_RISK_TYPES.BACK_ROT]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK,
    [independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK,
    [independentConstants.ERGO_RISK_TYPES.BACK_FLEX]:
      independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK,
  },

  CHARACTER_VIEWER_JOINT_RISK_JOINT_MAP: {
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS]: [],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK]: [
      independentConstants.ERGO_RISK_TYPES.BACK_ROT,
      independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND,
      independentConstants.ERGO_RISK_TYPES.BACK_FLEX,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER]: [
      independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW]: [
      independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX,
      independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_PRON,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_PRON,
      independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX,
    ],
  },

  CHARACTER_VIEWER_JOINT_QUAT_STREAM_JOINT_RISK_MAP: {
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS]: [],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK]: [
      independentConstants.QUAT_STREAM_RISK_NAMES.BACK_ROT,
      independentConstants.QUAT_STREAM_RISK_NAMES.BACK_SIDE_BEND,
      independentConstants.QUAT_STREAM_RISK_NAMES.BACK_FLEX,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER]: [
      independentConstants.QUAT_STREAM_RISK_NAMES.RIGHT_SHOULDER_INC,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER]: [
      independentConstants.QUAT_STREAM_RISK_NAMES.LEFT_SHOULDER_INC,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW]: [
      independentConstants.QUAT_STREAM_RISK_NAMES.LEFT_ELBOW_FLEX,
      independentConstants.QUAT_STREAM_RISK_NAMES.LEFT_ELBOW_PRON,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW]: [
      independentConstants.QUAT_STREAM_RISK_NAMES.RIGHT_ELBOW_PRON,
      independentConstants.QUAT_STREAM_RISK_NAMES.RIGHT_ELBOW_FLEX,
    ],
  },

  QUAT_JOINT_MAP: {
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_Z,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_Z,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_Z,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_Z,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_Z,
    ],
    [independentConstants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW]: [
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_W,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_X,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_Y,
      independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_Z,
    ],
  },

  POSE_INDEX_MAP: {
    [-1]: independentConstants.POSE_TYPES.IN_VEHICLE,
    0: [independentConstants.POSE_TYPES.OTHER],
    1: [independentConstants.POSE_TYPES.WALK_RUN],
    2: [independentConstants.POSE_TYPES.SITTING],
    3: [independentConstants.POSE_TYPES.STANDING],
    4: [independentConstants.POSE_TYPES.LYING_SUPINE],
    5: [independentConstants.POSE_TYPES.LYING_PRONE],
    6: [independentConstants.POSE_TYPES.LYING_SIDE],
  },

  RISK_COLOR_MAP: {
    [independentConstants.RISK_SCORES.GREEN]: colors['color-risk-green'],
    [independentConstants.RISK_SCORES.YELLOW]: colors['color-risk-yellow'],
    [independentConstants.RISK_SCORES.ORANGE]: colors['color-risk-orange'],
    [independentConstants.RISK_SCORES.RED]: colors['color-risk-red'],
  },

  RISK_COLOR_CHARACTER_VIEWER_COMMAND_MAP: {
    [independentConstants.RISK_SCORES.YELLOW]: 'Yellow',
    [independentConstants.RISK_SCORES.ORANGE]: 'Orange',
    [independentConstants.RISK_SCORES.RED]: 'Red',
  },

  RISK_COLOR_COMMAND_MAP: {
    [independentConstants.RISK_SCORES.YELLOW]: 'Yellow',
    [independentConstants.RISK_SCORES.ORANGE]: 'Orange',
    [independentConstants.RISK_SCORES.RED]: 'Red',
    [independentConstants.RISK_SCORES.GREEN]: 'Green',
  },

  POSE_COLOR_MAP: {
    [independentConstants.POSE_TYPES.IN_VEHICLE]: colors['color-pose-inVehicle'],
    [independentConstants.POSE_TYPES.OTHER]: colors['color-pose-other'],
    [independentConstants.POSE_TYPES.WALK_RUN]: colors['color-pose-walkRun'],
    [independentConstants.POSE_TYPES.SITTING]: colors['color-pose-sitting'],
    [independentConstants.POSE_TYPES.STANDING]: colors['color-pose-standing'],
    [independentConstants.POSE_TYPES.LYING_SUPINE]: colors['color-pose-lyingSupine'],
    [independentConstants.POSE_TYPES.LYING_PRONE]: colors['color-pose-lyingProne'],
    [independentConstants.POSE_TYPES.LYING_SIDE]: colors['color-pose-lyingSide'],
  },

  RISK_TYPE_RISK_SUMMARY_COMPONENTS_MAP: {
    [independentConstants.RISK_MODULES.MOTION]:
      independentConstants.RISK_SUMMARY_COMPONENTS.ERGO_SUMMARY,
    [independentConstants.RISK_MODULES.THERMAL]:
      independentConstants.RISK_SUMMARY_COMPONENTS.THERMAL_SUMMARY,
  },

  INDIVIDUAL_SUMMARY_GRAPH_LINES_MAP: {
    [independentConstants.ASSESSMENT_PAGE.VIBRATION]: 'vibrationRiskLines',
    [independentConstants.ASSESSMENT_PAGE.MOTION]: 'motionRiskLines',
    [independentConstants.ASSESSMENT_PAGE.POSTURE]: 'postureRiskLines',
  },

  SPATIAL_SUMMARY_SUM_POSES: [
    independentConstants.POSE_TYPES.OTHER,
    independentConstants.POSE_TYPES.WALK_RUN,
    independentConstants.POSE_TYPES.SITTING,
    independentConstants.POSE_TYPES.STANDING,
    independentConstants.POSE_TYPES.LYING_SUPINE,
    independentConstants.POSE_TYPES.LYING_PRONE,
    independentConstants.POSE_TYPES.LYING_SIDE,
  ],

  RISK_SUMMARY_COMPONENTS_RISK_TYPE_MAP: {
    [independentConstants.RISK_SUMMARY_COMPONENTS.ERGO_SUMMARY]:
      independentConstants.RISK_MODULES.MOTION,
    [independentConstants.RISK_SUMMARY_COMPONENTS.THERMAL_SUMMARY]:
      independentConstants.RISK_MODULES.THERMAL,
    [independentConstants.RISK_SUMMARY_COMPONENTS.VIBRATION_SUMMARY]:
      independentConstants.RISK_MODULES.VIBRATION,
  },

  ERGO_RISK_TYPE_JOINT_MOVEMENT_MAP: {
    [independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC]:
      independentConstants.ERGO_JOINT_MOVEMENTS.SHOULDER_INC,
    [independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC]:
      independentConstants.ERGO_JOINT_MOVEMENTS.SHOULDER_INC,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_PRON]:
      independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_PRON_SUP,
    [independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_PRON]:
      independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_PRON_SUP,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX]:
      independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_FLEX_EXT,
    [independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX]:
      independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_FLEX_EXT,
    [independentConstants.ERGO_RISK_TYPES.BACK_ROT]:
      independentConstants.ERGO_JOINT_MOVEMENTS.BACK_ROT,
    [independentConstants.ERGO_RISK_TYPES.BACK_FLEX]:
      independentConstants.ERGO_JOINT_MOVEMENTS.BACK_FLEX_EXT,
    [independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND]:
      independentConstants.ERGO_JOINT_MOVEMENTS.BACK_SIDE_BEND,
  },

  JOINT_MOVEMENT_ERGO_RISK_MAP: {
    [independentConstants.ERGO_JOINT_MOVEMENTS.SHOULDER_INC]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC,
      independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC,
    ],
    [independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_PRON_SUP]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_PRON,
      independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_PRON,
    ],
    [independentConstants.ERGO_JOINT_MOVEMENTS.ELBOW_FLEX_EXT]: [
      independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX,
      independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX,
    ],
    [independentConstants.ERGO_JOINT_MOVEMENTS.BACK_ROT]: [
      independentConstants.ERGO_RISK_TYPES.BACK_ROT,
    ],
    [independentConstants.ERGO_JOINT_MOVEMENTS.BACK_FLEX_EXT]: [
      independentConstants.ERGO_RISK_TYPES.BACK_FLEX,
    ],
    [independentConstants.ERGO_JOINT_MOVEMENTS.BACK_SIDE_BEND]: [
      independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND,
    ],
  },

  MOTION_EVENT_COUNT_HEADERS: {
    LEFT_SHOULDER: {
      LEFT_SHOULDER_INC: independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC,
    },
    RIGHT_SHOULDER: {
      RIGHT_SHOULDER_INC: independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC,
    },
    LEFT_ELBOW: {
      LEFT_ELBOW_FLEX: independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX,
    },
    RIGHT_ELBOW: {
      RIGHT_ELBOW_FLEX: independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX,
    },
    BACK: {
      BACK_SIDE_BEND: independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND,
      BACK_FLEX: independentConstants.ERGO_RISK_TYPES.BACK_FLEX,
      BACK_ROT: independentConstants.ERGO_RISK_TYPES.BACK_ROT,
    },
    PELVIS: {
      PELVIS_FLEX: independentConstants.ERGO_RISK_TYPES.PELVIS_FLEX,
    },
  },

  MODULES_WITH_RISK_TYPE_GROUPS: [independentConstants.RISK_MODULES.MOTION],

  RISK_MODULE_GROUPS: {
    [independentConstants.RISK_MODULES.MOTION]: {
      groups: independentConstants.MOTION_INDIVIDUAL_SUMMARY_JOINTS,
      groupMap: 'ERGO_RISK_TYPES_JOINT_MAP',
    },
  },

  MODULE_RISK_TYPES: {
    [independentConstants.RISK_MODULES.MOTION]: independentConstants.ERGO_ALL_RISK_TYPES,
    [independentConstants.RISK_MODULES.POSTURE]: independentConstants.POSTURE_ALL_RISKS,
    [independentConstants.RISK_MODULES.THERMAL]: independentConstants.THERMAL_ALL_RISKS,
    [independentConstants.RISK_MODULES.VIBRATION]: independentConstants.VIBRATION_ALL_RISKS,
    [independentConstants.RISK_MODULES.AGGREGATE]: ['aggregate'],
  },

  ASSESSMENT_PAGES: [
    independentConstants.ASSESSMENT_PAGE.MOTION,
    independentConstants.ASSESSMENT_PAGE.POSTURE,
    independentConstants.ASSESSMENT_PAGE.THERMAL,
    independentConstants.ASSESSMENT_PAGE.VIBRATION,
    independentConstants.ASSESSMENT_PAGE.EDITOR,
  ],

  ASSESSMENT_MODULE_COMPONENT_MAP: {
    [independentConstants.ASSESSMENT_PAGE.EDITOR]: 'EditorExtraInfoPanel',
    [independentConstants.ASSESSMENT_PAGE.VIBRATION]: 'RightPanelVibration',
    [independentConstants.ASSESSMENT_PAGE.THERMAL]: 'RightPanelThermal',
  },

  ASSESSMENT_RISK_SELECTOR_COMPONENT_MAP: {
    [independentConstants.ASSESSMENT_PAGE.EDITOR]: 'MotionRiskTypeSelector',
    [independentConstants.ASSESSMENT_PAGE.MOTION]: 'MotionRiskTypeSelector',
    [independentConstants.ASSESSMENT_PAGE.VIBRATION]: 'VibrationRiskTypeSelector',
    [independentConstants.ASSESSMENT_PAGE.THERMAL]: 'ThermalRiskTypeSelector',
    [independentConstants.ASSESSMENT_PAGE.POSTURE]: 'PostureRiskTypeSelector',
  },

  WIDGET_TYPE_API_KEY_COMPONENT_MAP: {
    [independentConstants.WIDGET_TYPES_API_KEYS.LIST]:
      independentConstants.WIDGET_TYPES_COMPONENT_NAMES.LIST,
    [independentConstants.WIDGET_TYPES_API_KEYS.TREND]:
      independentConstants.WIDGET_TYPES_COMPONENT_NAMES.TREND,
    [independentConstants.WIDGET_TYPES_API_KEYS.DISTRIBUTION]:
      independentConstants.WIDGET_TYPES_COMPONENT_NAMES.DISTRIBUTION,
    [independentConstants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS]:
      independentConstants.WIDGET_TYPES_COMPONENT_NAMES.CHANGE_ANALYSIS,
    [independentConstants.WIDGET_TYPES_API_KEYS.SUMMARY]:
      independentConstants.WIDGET_TYPES_COMPONENT_NAMES.SUMMARY,
  },

  RISK_MODULE_RISK_TYPE_MAP: {
    [independentConstants.RISK_MODULES.MOTION]: [independentConstants.ERGO_ALL_RISK_TYPES][0],
    [independentConstants.RISK_MODULES.POSTURE]: [independentConstants.POSTURE_ALL_RISKS][0],
    [independentConstants.RISK_MODULES.THERMAL]: [independentConstants.THERMAL_ALL_RISKS][0],
    [independentConstants.RISK_MODULES.VIBRATION]: [independentConstants.VIBRATION_ALL_RISKS][0],
  },

  RISK_MODULE_API_NAME_MAP: {
    [independentConstants.RISK_MODULES.MOTION]: 'Motion',
    [independentConstants.RISK_MODULES.POSTURE]: 'Posture',
    [independentConstants.RISK_MODULES.THERMAL]: 'Thermal',
    [independentConstants.RISK_MODULES.VIBRATION]: 'Vibration',
  },

  RISK_MODULE_API_EXPORT_NAME_MAP: {
    [independentConstants.RISK_MODULES.MOTION]: 'Motion',
    [independentConstants.RISK_MODULES.THERMAL]: 'Thermal',
    [independentConstants.RISK_MODULES.VIBRATION]: 'Vibration',
    [independentConstants.RISK_MODULES.POSTURE]: 'Posture',
    rula: 'RULA',
  },

  JOINT_INFO_EVENT_COUNT: [
    independentConstants.EVENT_TYPES.GREEN,
    independentConstants.EVENT_TYPES.YELLOW,
    independentConstants.EVENT_TYPES.ORANGE,
    independentConstants.EVENT_TYPES.RED,
  ],

  JOINT_INFO_EVENT_HEADERS: {
    [independentConstants.ASSESSMENT_PAGE.MOTION]: 'riskyEventCount',
    [independentConstants.ASSESSMENT_PAGE.EDITOR]: 'riskyEventCount',
    [independentConstants.ASSESSMENT_PAGE.THERMAL]: 'timeSpent',
    [independentConstants.ASSESSMENT_PAGE.VIBRATION]: 'durWithin',
  },

  DISTRIBUTION_TYPE_API_KEY_NAME_MAP: {
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.TAG_DIST_CHART]: [
      independentConstants.DISTRIBUTION_CHART_TYPES.OCT,
    ],
    [independentConstants.DISTRIBUTION_CHART_TYPES.OCT]: [
      independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.TAG_DIST_CHART,
    ],
    [independentConstants.DISTRIBUTION_CHART_TYPES.JOB_TYPE]: [
      independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.JOB_TYPE_DIST_CHART,
    ],
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.JOB_TYPE_DIST_CHART]: [
      independentConstants.DISTRIBUTION_CHART_TYPES.JOB_TYPE,
    ],
    [independentConstants.DISTRIBUTION_CHART_TYPES.RISK]: [
      independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART,
    ],
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART]: [
      independentConstants.DISTRIBUTION_CHART_TYPES.RISK,
    ],
  },

  RISK_MODULES_WITH_CHART: [
    independentConstants.RISK_MODULES.MOTION,
    independentConstants.RISK_MODULES.THERMAL,
  ],

  EXPORT_DATE_PRESETS: {
    LAST_7_DAYS: independentConstants.DATE_PRESETS.LAST_7_DAYS,
    LAST_30_DAYS: independentConstants.DATE_PRESETS.LAST_30_DAYS,
    ALL_TIME: independentConstants.DATE_PRESETS.ALL_TIME,
  },

  DATE_PRESET_INTERVAL_MAP: {
    [independentConstants.DATE_PRESETS.ALL_TIME]: '$range$0$now$',
    [independentConstants.DATE_PRESETS.LAST_7_DAYS]: '$trail$now$7d$',
    [independentConstants.DATE_PRESETS.LAST_30_DAYS]: '$trail$now$30d$',
    [independentConstants.DATE_PRESETS.LAST_90_DAYS]: '$trail$now$90d$',
    [independentConstants.DATE_PRESETS.LAST_ASSESSMENT]: 'latestAssessment',
  },

  INTERVAL_DATE_PRESET_MAP: {
    $range$0$now$: independentConstants.DATE_PRESETS.ALL_TIME,
    $trail$now$7d$: independentConstants.DATE_PRESETS.LAST_7_DAYS,
    $trail$now$30d$: independentConstants.DATE_PRESETS.LAST_30_DAYS,
    $trail$now$90d$: independentConstants.DATE_PRESETS.LAST_90_DAYS,
    latestAssessment: independentConstants.DATE_PRESETS.LAST_ASSESSMENT,
  },

  SETTING_PAGE_COMPONENT_NAME_MAP: {
    [independentConstants.SETTINGS_PAGES.PROFILE]:
      independentConstants.SETTINGS_PAGE_COMPONENT_NAMES.PROFILE,
    [independentConstants.SETTINGS_PAGES.CUSTOMER]:
      independentConstants.SETTINGS_PAGE_COMPONENT_NAMES.CUSTOMER,
    [independentConstants.SETTINGS_PAGES.COMPANY]:
      independentConstants.SETTINGS_PAGE_COMPONENT_NAMES.COMPANY,
    [independentConstants.SETTINGS_PAGES.LB_ADMIN]:
      independentConstants.SETTINGS_PAGE_COMPONENT_NAMES.LB_ADMIN,
    [independentConstants.SETTINGS_PAGES.ASSESSMENT]:
      independentConstants.SETTINGS_PAGE_COMPONENT_NAMES.ASSESSMENT,
  },

  ADMIN_ROLES: [
    independentConstants.ROLES.LB_ADMIN,
    independentConstants.ROLES.CUSTOMER_ADMIN,
    independentConstants.ROLES.COMPANY_ADMIN,
  ],

  ASSESSOR_ROLES: [
    independentConstants.ROLES.LB_ASSESSOR,
    independentConstants.ROLES.CUSTOMER_ASSESSOR,
    independentConstants.ROLES.COMPANY_ASSESSOR,
  ],

  DASHBOARD_PERMISSIONS_ENUM: {
    [independentConstants.ROLES.LB_ADMIN]: 7,
    [independentConstants.ROLES.LB_ASSESSOR]: 6,
    [independentConstants.ROLES.CUSTOMER_ADMIN]: 5,
    [independentConstants.ROLES.COMPANY_ADMIN]: 4,
    [independentConstants.ROLES.CUSTOMER_ASSESSOR]: 3,
    [independentConstants.ROLES.COMPANY_ASSESSOR]: 2,
    [independentConstants.ROLES.WORKER]: 1,
    [independentConstants.ROLES.BASE_WORKER]: 0,
  },

  ASSIGN_ROLES_PERMISSIONS_ENUM: {
    [independentConstants.ROLES.LB_ADMIN]: 7,
    [independentConstants.ROLES.LB_ASSESSOR]: 6,
    [independentConstants.ROLES.CUSTOMER_ADMIN]: 5,
    [independentConstants.ROLES.CUSTOMER_ASSESSOR]: 4,
    [independentConstants.ROLES.COMPANY_ADMIN]: 3,
    [independentConstants.ROLES.COMPANY_ASSESSOR]: 2,
    [independentConstants.ROLES.WORKER]: 1,
    [independentConstants.ROLES.BASE_WORKER]: 0,
  },

  DASHBOARD_HIERARCHY_ENUM: {
    [independentConstants.PAGES.HOME]: 6,
    [independentConstants.SETTINGS_PAGES.LB_ADMIN]: 5,
    [independentConstants.SETTINGS_PAGES.CUSTOMER]: 4,
    [independentConstants.SETTINGS_PAGES.COMPANY]: 3,
    [independentConstants.PAGES.WELCOME]: 2,
    [independentConstants.PAGES.INITIATE_CAPTURE]: 1,
    [independentConstants.SETTINGS_PAGES.PROFILE]: 0,
  },

  DASHBOARD_PERMISSIONS_ROLE_MAP: {
    [independentConstants.PAGES.HOME]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
    ],
    [independentConstants.PAGES.MANAGEMENT]: [...Object.values(independentConstants.ROLES)],
    [independentConstants.PAGES.DEVICES]: [
      independentConstants.ROLES.LB_ADMIN,
      independentConstants.ROLES.CUSTOMER_ADMIN,
      independentConstants.ROLES.COMPANY_ADMIN,
    ],
    [independentConstants.SETTINGS_PAGES.LB_ADMIN]: [independentConstants.ROLES.LB_ADMIN],
    [independentConstants.PAGES.EXPORT]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
    ],
    [independentConstants.SETTINGS_PAGES.CUSTOMER]: [
      independentConstants.ROLES.LB_ADMIN,
      independentConstants.ROLES.CUSTOMER_ADMIN,
    ],
    [independentConstants.SETTINGS_PAGES.COMPANY]: [
      independentConstants.ROLES.LB_ADMIN,
      independentConstants.ROLES.CUSTOMER_ADMIN,
      independentConstants.ROLES.COMPANY_ADMIN,
    ],
    [independentConstants.SETTINGS_PAGES.ASSESSMENT]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ADMIN,
      independentConstants.ROLES.COMPANY_ADMIN,
    ],
    [independentConstants.PAGES.INDIVIDUAL_SUMMARY]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
      independentConstants.ROLES.WORKER,
    ],
    [independentConstants.PAGES.ASSESSMENT]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
      independentConstants.ROLES.WORKER,
    ],
    [independentConstants.PAGES.INITIATE_CAPTURE]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.WORKER,
      independentConstants.ROLES.BASE_WORKER,
    ],
    [independentConstants.PAGES.ASSESSMENT.EDITOR]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
    ],
    [independentConstants.SETTINGS_PAGES.PROFILE]: [...Object.values(independentConstants.ROLES)],
    [independentConstants.PAGES.INTERVIEW]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.LB_ADMIN,
    ],
    [independentConstants.PAGES.WELCOME]: [
      independentConstants.ROLES.LB_ASSESSOR,
      independentConstants.ROLES.COMPANY_ASSESSOR,
      independentConstants.ROLES.CUSTOMER_ASSESSOR,
      independentConstants.ROLES.WORKER,
      independentConstants.ROLES.BASE_WORKER,
    ],
  },

  BASE_ROLES: [independentConstants.ROLES.WORKER, independentConstants.ROLES.BASE_WORKER],

  PERSONAL_SETTINGS: [
    independentConstants.USER_CHARACTERISTICS.EMAIL,
    independentConstants.USER_CHARACTERISTICS.F_NAME,
    independentConstants.USER_CHARACTERISTICS.L_NAME,
    independentConstants.USER_CHARACTERISTICS.GENDER,
    independentConstants.USER_CHARACTERISTICS.MOBILE_NUMBER,
  ],

  DEFAULT_ANIMATOR_JOINT_KEYS: {
    [independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC]: 1,
    [independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC]: 2,
    [independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX]: 0,
    [independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX]: 3,
    [independentConstants.ERGO_RISK_TYPES.BACK_ROT]: 6,
    [independentConstants.ERGO_RISK_TYPES.BACK_FLEX]: 4,
    [independentConstants.ERGO_RISK_TYPES.BACK_SIDE_BEND]: 5,
  },

  DEFAULT_ANIMATOR_QUAT_KEYS: {
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_W]: 20,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_X]: 21,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_Y]: 22,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.PELVIS_Z]: 23,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_W]: 16,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_X]: 17,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_Y]: 18,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.U_BACK_Z]: 19,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_W]: 12,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_X]: 13,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_Y]: 14,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_ELBOW_Z]: 15,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_W]: 8,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_X]: 9,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_Y]: 10,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.L_SHOULDER_Z]: 11,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_W]: 0,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_X]: 1,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_Y]: 2,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_ELBOW_Z]: 3,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_W]: 4,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_X]: 5,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_Y]: 6,
    [independentConstants.QUAT_OBJECT_JOINT_NAMES.R_SHOULDER_Z]: 7,
  },

  GENERAL_USER_SETTINGS: [...Object.values(independentConstants.USER_CHARACTERISTICS)],

  USER_INFO_TABLE_HEADERS: [
    independentConstants.USER_CHARACTERISTICS.EMAIL,
    independentConstants.USER_CHARACTERISTICS.F_NAME,
    independentConstants.USER_CHARACTERISTICS.L_NAME,
    independentConstants.USER_CHARACTERISTICS.JOB,
    independentConstants.USER_CHARACTERISTICS.ROLE,
    independentConstants.USER_CHARACTERISTICS.VIEWER,
    independentConstants.USER_CHARACTERISTICS.MEMBER,
    independentConstants.USER_CHARACTERISTICS.ANIMATOR,
    independentConstants.USER_CHARACTERISTICS.TIME_ZONE,
    independentConstants.USER_CHARACTERISTICS.USER_VISIBLE,
  ],

  EXPORT_DATA_USER_TABLE_HEADERS: [
    independentConstants.USER_CHARACTERISTICS.F_NAME,
    independentConstants.USER_CHARACTERISTICS.L_NAME,
    independentConstants.USER_CHARACTERISTICS.GENDER,
    independentConstants.USER_CHARACTERISTICS.TIME_ZONE,
    independentConstants.USER_CHARACTERISTICS.JOB,
    independentConstants.USER_CHARACTERISTICS.MEMBER,
  ],

  ONBOARDING_CSV_COLUMNS: [
    independentConstants.USER_CHARACTERISTICS.EMAIL,
    independentConstants.USER_CHARACTERISTICS.F_NAME,
    independentConstants.USER_CHARACTERISTICS.L_NAME,
    independentConstants.USER_CHARACTERISTICS.GENDER,
    independentConstants.USER_CHARACTERISTICS.MOBILE_NUMBER,
    independentConstants.USER_CHARACTERISTICS.TIME_ZONE,
    independentConstants.USER_CHARACTERISTICS.JOB,
  ],

  NESTED_DEVICE_CHARACTERISTICS: {
    MANUFACTURING: {
      SHIPPED: independentConstants.DEVICE_CHARACTERISTICS.SHIPPED,
      SN: independentConstants.DEVICE_CHARACTERISTICS.SN,
      POS: independentConstants.DEVICE_CHARACTERISTICS.POS,
    },

    PROFILE: {
      ATTRIBUTES: {
        SID: independentConstants.DEVICE_CHARACTERISTICS.SID,
        DEPLOYMENT_MODE: independentConstants.DEVICE_CHARACTERISTICS.DEPLOYMENT_MODE,
      },
      CUSTOMER: independentConstants.DEVICE_CHARACTERISTICS.CUSTOMER,
      CUSTOMER_SHIP_DATE: independentConstants.DEVICE_CHARACTERISTICS.CUSTOMER_SHIP_DATE,

      COMPANY: independentConstants.DEVICE_CHARACTERISTICS.COMPANY,
      COMPANY_SHIP_DATE: independentConstants.DEVICE_CHARACTERISTICS.COMPANY_SHIP_DATE,
    },

    DEVICE_TYPE: independentConstants.DEVICE_CHARACTERISTICS.DEVICE_TYPE,
  },

  WIDGET_TYPE_ICON_TYPE: {
    [independentConstants.WIDGET_TYPES_API_KEYS.LIST]: 'list',
    [independentConstants.WIDGET_TYPES_API_KEYS.TREND]: 'timeline',
    [independentConstants.WIDGET_TYPES_API_KEYS.DISTRIBUTION]: 'bar',
    [independentConstants.WIDGET_TYPES_API_KEYS.CHANGE_ANALYSIS]: 'analytics',
    [independentConstants.WIDGET_TYPES_API_KEYS.SUMMARY]: 'summary',
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.TAG_DIST_CHART]: 'tag',
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.JOB_TYPE_DIST_CHART]: 'job',
    [independentConstants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART]: 'risk',
    [independentConstants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART]: 'tag',
    [independentConstants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART]: 'clock',
    [independentConstants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY]: 'trend',
    [independentConstants.RISK_SCORE_TYPES_API_KEYS.TOTAL_RISKS]: 'warning',
  },

  USER_MANAGE_EDIT_OPTIONS: [
    independentConstants.USER_CHARACTERISTICS.TIME_ZONE,
    independentConstants.USER_CHARACTERISTICS.JOB,
    independentConstants.USER_CHARACTERISTICS.TAGS,
  ],

  PRIOR_PAGE: (currentPage, workerId = null) => {
    let route_object = {}
    switch (currentPage) {
      case independentConstants.PAGES.ASSESSMENT: {
        route_object = {
          name: independentConstants.PAGES.INDIVIDUAL_SUMMARY,
          params: { workerId: workerId },
        }
        break
      }
      case independentConstants.PAGES.INDIVIDUAL_SUMMARY: {
        route_object = {
          name: independentConstants.PAGES.HOME,
        }
        break
      }
      case independentConstants.PAGES.MANAGEMENT: {
        route_object = {
          name: independentConstants.PAGES.HOME,
        }
      }
    }

    return route_object
  },

  USER_VISIBILITY_MAP: {
    false: independentConstants.ANONYMOUS_MODE_SETTINGS.ANONYMOUS,
    true: independentConstants.ANONYMOUS_MODE_SETTINGS.VISIBLE,
  },

  TEMPERATURE_SYMBOLS: {
    [independentConstants.THERMAL_TEMPERATURE_TYPES.SCALE.FAHRENHEIT]: 'F',
    [independentConstants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS]: 'C',
  },

  DEVICE_STATUS_COLOUR_MAP: {
    UNKNOWN: 'unknown',
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
  },

  BOOT_STATUS: {
    DYNAMIC_PASS: 'dynamicPass',
    FACTORY_FAIL: 'factoryFail',
    FACTORY_PASS: 'factoryPass',
  },

  C2D_STATUS: {
    CONFIGURATION_PASS: 'configurationPass',
    CONFIGURATION_NOT_RECEIVED: 'configurationNotRcvd',
    CONFIGURATION_CORRUPTED: 'configurationCorrupted',
    FIRMWARE_NOT_RECEIVED: 'firmwareNotRcvd',
  },

  COMPONENT_FAULT: {
    NO_FAULT: 'noFault',
    TEMPORAL_FAILURE: 'temporalFailure',
    PERIPHERAL_FAILURE: 'peripheralFailure',
    CRITICAL_FAILURE: 'criticalFailure',
  },

  DEVICE_CONFIG_HEADERS: [
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.SET_ID,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.AZURE,
  ],

  DEVICE_CONFIG_TYPES: [
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.AZURE,
  ],

  DEVICE_CONFIG_FILE_TYPES: {
    [independentConstants.DEVICE_CONFIG_CHARACTERISTICS.NETWORK]: '.json',
    [independentConstants.DEVICE_CONFIG_CHARACTERISTICS.FIRMWARE]: '.bin',
    [independentConstants.DEVICE_CONFIG_CHARACTERISTICS.OPERATIONS]: '.json',
    [independentConstants.DEVICE_CONFIG_CHARACTERISTICS.AZURE]: '.json',
  },

  DEVICE_CONFIG_API_DEFAULT_VALUES: {
    [independentConstants.DEVICE_CONFIG_API_PARAMS.DEVICE_TYPE]: 'SGD-T',
  },

  DEVICE_CONFIG_RENAME_HEADERS: [
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.FILE_TYPE,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.UPLOAD_DATE,
    independentConstants.DEVICE_CONFIG_CHARACTERISTICS.DESCRIPTION,
  ],

  DEVICE_CONFIG_SGD_DEVICE_TYPES: [independentConstants.DEVICE_TYPES.SGDT],

  DEVICE_CONFIG_ISGC_DEVICE_TYPES: [independentConstants.DEVICE_TYPES.ISGCT],

  EXCLUSION_ASSESSMENT_TABLE_HEADERS: [
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.ID,
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.START,
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.END,
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.CUSTOMER,
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.COMPANY,
    independentConstants.EXCLUDE_ASSESSMENT_TABLE_HEADERS.EXCLUDED,
  ],

  // For converting boolean aggregation statuses into text-based exclusion statuses
  EXCLUSION_BOOLEAN_MAPPER: {
    // If aggregation is allowed, then the assessment is not excluded
    true: independentConstants.BOOLEAN_MAPPER[false],
    // If aggregation is not allowed, then the assessment is excluded
    false: independentConstants.BOOLEAN_MAPPER[true],
    // Undefined behaves the same as true, default is the assessments are not excluded
    undefined: independentConstants.BOOLEAN_MAPPER[false],
  },

  APPLY_DEFAULT_ASSESSMENT_TAGS_TABLE_HEADERS: [
    independentConstants.ASSESSMENT_TABLE_HEADERS.F_NAME,
    independentConstants.ASSESSMENT_TABLE_HEADERS.L_NAME,
    independentConstants.ASSESSMENT_TABLE_HEADERS.JOB,
    independentConstants.ASSESSMENT_TABLE_HEADERS.GENDER,
    independentConstants.ASSESSMENT_TABLE_HEADERS.OCTS,
    'defaultAssessmentTagNames',
  ],

  ASSESSMENT_ID_MODULE_MAP: {
    [independentConstants.RISK_MODULES_SHORT.MOTION]: independentConstants.ASSESSMENT_PAGE.MOTION,
    [independentConstants.RISK_MODULES_SHORT.POSTURE]: independentConstants.ASSESSMENT_PAGE.POSTURE,
    [independentConstants.RISK_MODULES_SHORT.THERMAL]: independentConstants.ASSESSMENT_PAGE.THERMAL,
    [independentConstants.RISK_MODULES_SHORT.VIBRATION]:
      independentConstants.ASSESSMENT_PAGE.VIBRATION,
    [independentConstants.RISK_MODULES_SHORT.EDITOR]: independentConstants.ASSESSMENT_PAGE.EDITOR,
  },

  MODULE_ASSESSMENT_ID_MAP: {
    [independentConstants.ASSESSMENT_PAGE.MOTION]: independentConstants.RISK_MODULES_SHORT.MOTION,
    [independentConstants.ASSESSMENT_PAGE.POSTURE]: independentConstants.RISK_MODULES_SHORT.POSTURE,
    [independentConstants.ASSESSMENT_PAGE.THERMAL]: independentConstants.RISK_MODULES_SHORT.THERMAL,
    [independentConstants.ASSESSMENT_PAGE.VIBRATION]:
      independentConstants.RISK_MODULES_SHORT.VIBRATION,
    [independentConstants.ASSESSMENT_PAGE.EDITOR]: independentConstants.RISK_MODULES_SHORT.EDITOR,
    [independentConstants.ASSESSMENT_PAGE.AGGREGATE]:
      independentConstants.RISK_MODULES_SHORT.EDITOR,
  },

  SENSOR_RISK_TYPE_MAP: {
    RFA: independentConstants.ERGO_RISK_TYPES.RIGHT_ELBOW_FLEX,
    RUA: independentConstants.ERGO_RISK_TYPES.RIGHT_SHOULDER_INC,
    LFA: independentConstants.ERGO_RISK_TYPES.LEFT_ELBOW_FLEX,
    LUA: independentConstants.ERGO_RISK_TYPES.LEFT_SHOULDER_INC,
    THX: independentConstants.ERGO_RISK_TYPES.BACK_FLEX,
    PEL: independentConstants.ERGO_RISK_TYPES.PELVIS_FLEX,
  },

  OFF_BODY_PERIOD_TABLE_HEADERS: [
    independentConstants.OFF_BODY_PERIOD_TABLE_PROPS.POSITION,
    independentConstants.OFF_BODY_PERIOD_TABLE_PROPS.START,
    independentConstants.OFF_BODY_PERIOD_TABLE_PROPS.END,
    independentConstants.OFF_BODY_PERIOD_TABLE_PROPS.FLIPPED,
  ],

  EXPORTABLE_RISK_MODULES: {
    MOTION: independentConstants.RISK_MODULES.MOTION,
    POSTURE: independentConstants.RISK_MODULES.POSTURE,
    THERMAL: independentConstants.RISK_MODULES.THERMAL,
    RULA: 'rula',
  },
}

const constants = { ...independentConstants, ...dependentConstants }

export default constants

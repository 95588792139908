<template lang="pug">
    div(class="sz-ModalEditDefaultAssessmentTags")
        div(
            v-if="updatingAssessmentTag",
            class="sz-ModalEditDefaultAssessmentTags-loading")
            LoadingSpinner
        div(class="sz-ModalEditDefaultAssessmentTags-sectionSettings-label") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.editDefaultAssessmentTags`, { editType: editTypeTitle }) }}
        div(class="sz-ModalEditDefaultAssessmentTags-options")
            div(class="sz-ModalEditDefaultAssessmentTags-sectionSettings")
                FormSectionTagSelection(
                    class="sz-ModalEditDefaultAssessmentTags-sectionSettings-input-select",
                    :options="tagOptions",
                    :selectAll="true",
                    :title="$t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.assessmentTags`)",
                    @updateSelectedTags="updateDefaultAssessmentTags")
                ListSelectedUsers(
                    :users="selectedUsers",
                    @updateUsers="updateUsers")
        div(class="sz-ModalEditDefaultAssessmentTags-footer")
            div(
                @click="cancelEdit",
                class="sz-ModalEditDefaultAssessmentTags-footer-button \
                    sz-ModalEditDefaultAssessmentTags-footer-cancel") {{ $t(`modals.modalEditWidget.cancel`)}}
            div(
                @click="submit"
                type="submit",
                class="sz-ModalEditDefaultAssessmentTags-footer-button \
                    sz-ModalEditDefaultAssessmentTags-footer-save") {{ $t(`modals.modalEditWidget.save`)}}
</template>

<script>
    import { mapGetters } from 'vuex'
    import ListSelectedUsers from './ModalEditDefaultAssessmentTags/ListSelectedUsers'
    import constants from 'helpers/constants'
    import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
    import LoadingSpinner from '../Shared/LoadingSpinner'
    import EventBus from 'src/eventBus'
    import formValidator from 'mixins/formValidator'
    import constructModalSettings from 'mixins/modalSettings'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'ModalEditDefaultAssessmentTags',

        components: {
            ListSelectedUsers,
            FormSectionTagSelection,
            LoadingSpinner,
        },


        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            users: {
                required: true,
                type: Array,
            },

            editType: {
                required: true,
                type: Number,
            },
        },

        data() {
            return {
                selectedUsers: [],
                newSettings: {},
                showConfirmation: true,
                tagMessages: {},
            }
        },

        computed: {
            ...mapGetters([
                'allAssessmentTags',
                'updatingAssessmentTag',
                'updateAssessmentTagStatus',
                'assessmentTagMapper',
            ]),

            tagOptions () {
                return this.allAssessmentTags.map(tag => tag.name)
            },

            updateSucceeded () {
                return (this.updateAssessmentTagStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS) && this.settingsHaveChanged
            },

            isUserConfirmed() {
                return this.showConfirmation === false
            },

            isAssign() {
                return this.editType === constants.UPDATE_TAG_OPTIONS.ASSIGN
            },

            isRemove() {
                return this.editType === constants.UPDATE_TAG_OPTIONS.REMOVE
            },

            settingsHaveChanged () {
                return Array.isArray(this.newSettings.defaultAssessmentTags)
            },

            editTypeTitle() {
                if (this.isAssign) {
                    return this.$t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.apply`)
                } else {
                    return this.$t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.remove`)
                }
            },
        },

        watch: {
            showConfirmation: {
                //watches if submit is confirmed by user & calls submit again
                handler: function () {
                    if (this.isUserConfirmed) {
                        this.submit()
                    }
                },
                deep: true,
            },
        },

        mounted () {
            this.selectedUsers = this.users

            EventBus.$on('USER_CONFIRMED', () => {
                this.showConfirmation = false
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.showConfirmation = true
            })
        },

        beforeDestroy () {
            EventBus.$off('USER_CONFIRMED')

            EventBus.$off('USER_CANCELLED')
        },

        methods: {
            cancelEdit () {
                this.$emit('close')
            },

            updateDefaultAssessmentTags (tags) {
                this.tagMessages = tags
                this.newSettings.defaultAssessmentTags = this.assessmentTagNameToID(tags)
            },

            updateUsers (userID) {
                this.selectedUsers = this.selectedUsers.filter(user => user.userID !== userID)
                EventBus.$emit('UPDATE_SELECTED_USERS', userID)
            },

            submit () {
                if (this.settingsHaveChanged) {
                    this.showConfirmation
                        ? this.confirmModal(
                            this.confirmMessage().title,
                            this.confirmMessage().message,
                            this.confirmMessage().list)
                        : this.saveUpdate()
                }
            },

            async saveUpdate () {
                if (this.isAssign) {
                    await this.$store.dispatch('bulkAddDefaultUsersToAssessmentTags', {
                        assessmentTagIDs: this.newSettings.defaultAssessmentTags,
                        userIDs: this.selectedUsers.map(user => user.userID),
                    })
                } else if (this.isRemove) {
                    await this.$store.dispatch('bulkRemoveDefaultUsersToAssessmentTags', {
                        assessmentTagIDs: this.newSettings.defaultAssessmentTags,
                        userIDs: this.selectedUsers.map(user => user.userID),
                    })
                }

                this.updateSucceeded
                    ? this.successModal(
                        this.$t('management.assessmentDashboard.applyDefaultAssessmentTags.editModal.updateMessage.checkLaterMessage'),
                        true,
                        undefined,
                        () => {
                            this.$store.dispatch('closeAllModals')
                            EventBus.$emit('DEFAULT_TAGS_UPDATED')
                        },
                        this.$t('management.assessmentDashboard.applyDefaultAssessmentTags.editModal.updateMessage.checkLaterTitle'))
                    : this.failModal(
                        this.$t('pleaseTryAgain'),
                        this.$t('somethingWentWrong'),
                        () => this.showConfirmationModal = true)


            },

            confirmMessage () {
                if (this.isAssign) {
                    return {
                        title: this.$t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.assignConfirm`),
                        message: this.$t('management.assessmentDashboard.applyDefaultAssessmentTags.editModal.assignMessage'),
                        list: this.tagMessages,
                    }
                } else {
                    return {
                        title: this.$t(`management.assessmentDashboard.applyDefaultAssessmentTags.editModal.removeConfirm`),
                        message: this.$t('management.assessmentDashboard.applyDefaultAssessmentTags.editModal.removeMessage'),
                        list: this.tagMessages,
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
@import '~design';
    .sz-ModalEditDefaultAssessmentTags {
        width: 50vw;
        max-height: 94vh;
        color: $color-white;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar-widget;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        &-edit {
            margin-bottom: 1rem;
        }

        &-sectionSettings {
            color: $color-white;
            margin-bottom: 1rem;
            font-size: 12px;

            &-label {
                @extend %font-heading;
                font-size: 15px;
                padding-top: 0.5rem;
                padding-bottom: 1rem;
            }

            &-input{
                &-select {
                    margin-bottom: 1rem;
                }
            }
        }

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-table-row-silver;
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.5rem;

            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
                font-size: 12px;
            }

            &-save {
                @extend %button-layout;
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1rem;
                border-radius: 0;
            }
        }

    }
</style>

import ApiService from './api.service'
import constants from 'helpers/constants'
import LogService from 'services/log.service'
import { filter } from 'lodash'

const AssessmentService = {
  getAssessment: async function (
    customerID,
    companyID,
    workerId,
    captureIdx,
    assessmentIdx,
    module
  ) {
    const url =
      `/customers/${customerID}/companies/${companyID}` +
      `/users/${workerId}/captures/${captureIdx}/assessments/${assessmentIdx}/modules/${module}`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getCapture: async function (customerID, companyID, workerId, captureIdx) {
    const url =
      `/customers/${customerID}/companies/${companyID}` +
      `/users/${workerId}/captures/${captureIdx}`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAssessmentManifest: async function (
    customerID,
    companyID,
    workerId,
    captureIdx,
    assessmentIdx,
    module
  ) {
    const url =
      `/customers/${customerID}/companies/${companyID}` +
      `/users/${workerId}/captures/${captureIdx}/assessments/${assessmentIdx}/manifest/${module}`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getAssessmentGraphByRiskType: async function (
    customerID,
    companyID,
    workerId,
    captureIdx,
    assessmentIdx,
    riskType,
    cursorTime,
    startTime,
    endTime
  ) {
    const url =
      `/customers/${customerID}/companies/${companyID}` +
      `/users/${workerId}/captures/${captureIdx}/assessments/${assessmentIdx}/graph/${riskType}?cursorTime=${cursorTime}&startTime=${startTime}&endTime=${endTime}`

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getStreamedAssessmentData: async function (
    customerID,
    companyID,
    workerId,
    captureIdx,
    assessmentIdx,
    start,
    end,
    module
  ) {
    const url =
      `/customers/${customerID}/companies/${companyID}` +
      `/users/${workerId}/captures/${captureIdx}/assessments/${assessmentIdx}/stream/${module}?startTime=${start}&endTime=${end}`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error(error.response)
    }
  },

  getAssessmentProgress: async function (captureID) {
    const url = `/captures/${captureID}/progress`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  makeReAssessment: async function (
    customerID,
    companyID,
    workerId,
    captureIdx,
    assessmentIdx,
    details
  ) {
    const url =
      `/customers/${customerID}/companies/${companyID}/users/${workerId}` +
      `/captures/${captureIdx}/assessments/${assessmentIdx}`
    try {
      const response = await ApiService.patch(url, details)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateTimezone: async function (customerID, companyID, userID, captureID, metadataID, timezone) {
    try {
      const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/captures/${captureID}/metadata/${metadataID}`
      const response = await ApiService.patch(url, { timezone })
      return response.data
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(error)
    }
  },

  requestExport: async function (customerID, companyID, riskModules, userIDs, timespan, email) {
    const url =
      `/customers/${customerID}/companies/${companyID}/export?` +
      riskModules
        .map((module) => {
          return `riskModules=${module}&`
        })
        .join('') +
      userIDs
        .map((user) => {
          return `userIDs=${user}&`
        })
        .join('') +
      `timespan=${encodeURIComponent(timespan)}&` +
      `email=${encodeURIComponent(email)}`
    try {
      const response = await ApiService.post(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getExportFile: async function (customerID, companyID, exportID) {
    const url = `/customers/${customerID}/companies/${companyID}/export/${exportID}`
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: url,
        responseType: 'blob',
      })
      return {
        data: response.data,
        name: response.headers['content-disposition'],
      }
    } catch (error) {
      throw new Error(error.response.status)
    }
  },

  getCaptureProgress: async function (filterForm, continuationToken) {
    let url = `/admin/capture-status`
    try {
      if (continuationToken) {
        var headers = { continuationToken: continuationToken }
      }
      const response = await ApiService.getPages(
        url,
        {
          ...filterForm,
        },
        headers
      )
      return {
        data: response.data,
        continuationToken: response.headers['x-lb-continuation-token'],
      }
    } catch (error) {
      throw new Error(error.response)
    }
  },

  downloadRecordingLogs: async function (customerID, companyID, userID, captureID) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/captures/${captureID}/capture-details`
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: url,
        responseType: 'blob',
      })
      return {
        data: response.data,
        name: response.headers['content-disposition'],
      }
    } catch (error) {
      throw new Error(error.response)
    }
  },

  getCaptureDetails: async function (customerID, companyID, captureID) {
    let url = `/customers/${customerID}/companies/${companyID}/capture-details/${captureID}/info`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not get capture details for Capture with ID ${captureID}.`)
    }
  },

  toggleCaptureProgressVisibility: async function (captureID, details) {
    const url = `/captures/${captureID}/toggle-visibility`
    try {
      const response = await ApiService.post(url, details)
      return response.data
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Capture ${captureID}'s visibility could not be toggled.`)
    }
  },

  getAssessmentsForExclusion: async function (filterForm, continuationToken) {
    let url = `/assessments`
    try {
      if (continuationToken) {
        var headers = { continuationToken: continuationToken }
      }
      const response = await ApiService.getPages(url, filterForm, headers)
      return {
        data: response.data,
        continuationToken: response.headers['x-lb-continuation-token'],
      }
    } catch (err) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Unable to get assessments for exclusion`)
    }
  },

  getAssessmentsFromCompany: async function (
    customerID,
    companyID,
    orgChartTags,
    jobs,
    genders,
    userIDs,
    interval,
    aggregationAllowed
  ) {
    let octsOrEmpty = orgChartTags
      ? orgChartTags
          .map((oct) => {
            return `orgChartTags=${oct}&`
          })
          .join('')
      : ''
    let jobsOrEmpty = jobs
      ? jobs
          .map((job) => {
            return `jobs=${job}&`
          })
          .join('')
      : ''
    let gendersOrEmpty = genders
      ? genders
          .map((gender) => {
            return `genders=${gender}&`
          })
          .join('')
      : ''
    let userIDsOrEmpty = userIDs
      ? userIDs
          .map((userID) => {
            return `userIDs=${userID}&`
          })
          .join('')
      : ''
    let intervalOrEmpty = interval ? `interval=${interval}&` : ''
    let aggregationAllowedOrEmpty =
      aggregationAllowed !== undefined ? `aggregationAllowed=${aggregationAllowed}` : ''

    const url =
      `/legacy/customers/${customerID}/companies/${companyID}/assessments?` +
      octsOrEmpty +
      jobsOrEmpty +
      gendersOrEmpty +
      userIDsOrEmpty +
      intervalOrEmpty +
      aggregationAllowedOrEmpty

    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not obtain assessments from company ${companyID}.`)
    }
  },

  bulkAssignAssessmentTags: async function (
    customerID,
    companyID,
    assessmentsInfo,
    assessmentTagIDs
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/captures/assessmentTags/assign`

    try {
      const response = await ApiService.patch(url, {
        assessmentsInfo: assessmentsInfo,
        assessmentTagIDs: assessmentTagIDs,
      })
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not assign assessment tags.`)
    }
  },

  bulkRevokeAssessmentTags: async function (
    customerID,
    companyID,
    assessmentsInfo,
    assessmentTagIDs
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/captures/assessmentTags/revoke`

    try {
      const response = await ApiService.patch(url, {
        assessmentsInfo: assessmentsInfo,
        assessmentTagIDs: assessmentTagIDs,
      })
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not revoke assessment tags.`)
    }
  },

  updateAggregationAllowance: async function (
    customerID,
    companyID,
    assessmentsInfo,
    aggregationAllowed
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/captures/aggregation/update`

    try {
      const response = await ApiService.patch(url, {
        assessmentsInfo: assessmentsInfo,
        aggregationAllowed: aggregationAllowed,
      })
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not update aggregation allowance.`)
    }
  },

  getAssessmentPageMetadataFromCapture: async function (captureID) {
    const url = `/assessments?captureID=${captureID}`

    try {
      const response = await ApiService.get(url)

      if (response.data[0]) {
        return response.data[0]
      } else {
        throw new Error(`Could not get assessment with captureID ${captureID}`)
      }
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not get assessment with captureID ${captureID}`)
    }
  },

  getAssessmentPageMetadataFromAssessment: async function (assessmentID) {
    const url = `/assessments?assessmentID=${assessmentID}`

    try {
      const response = await ApiService.get(url)

      if (response.data[0]) {
        return response.data[0]
      } else {
        throw new Error(`Could not get assessment with id ${assessmentID}`)
      }
    } catch (error) {
      await LogService.send(constants.LOG_LEVELS.ERROR, error)
      throw new Error(`Could not get assessment with id ${assessmentID}`)
    }
  },

  bulkAddDefaultUsersToAssessmentTags: async function (
    customerID,
    companyID,
    assessmentTagIDs,
    userIDs
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags/bulkAddDefaultUsers`
    try {
      const response = await ApiService.post(url, {
        assessmentTagIDs: assessmentTagIDs,
        users: userIDs,
      })
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw new Error(error.response)
    }
  },

  bulkRemoveDefaultUsersToAssessmentTags: async function (
    customerID,
    companyID,
    assessmentTagIDs,
    userIDs
  ) {
    const url = `/customers/${customerID}/companies/${companyID}/assessmentTags/bulkRemoveDefaultUsers`
    try {
      const response = await ApiService.post(url, {
        assessmentTagIDs: assessmentTagIDs,
        users: userIDs,
      })
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw new Error(error.response)
    }
  },

  uploadTaskBreakdown: async function (customerID, companyID, userID, captureID, taskBreakdown) {
    try {
      const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/captures/${captureID}/breakdown`

      const response = await ApiService.post(url, taskBreakdown)

      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw error
    }
  },

  downloadTaskBreakdown: async function (customerID, companyID, userID, captureID) {
    try {
      const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/captures/${captureID}/breakdown`

      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw error
    }
  },

  downloadTaskExport: async function (customerID, companyID, captureID) {
    try {
      const url = `/customers/${customerID}/companies/${companyID}/captures/${captureID}/task-export`

      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw error
    }
  },

  processCapture: async function (customerID, companyID, userID, captureID, retryAttempts) {
    try {
      const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/captures/${captureID}/process?${
        retryAttempts !== undefined ? `retryAttempts=${retryAttempts}` : ''
      }`

      const response = await ApiService.post(url, { retryAttempts: retryAttempts })
      return response.data
    } catch (error) {
      LogService.send(constants.LOG_LEVELS.ERROR, error.message)
      throw error
    }
  },
}

export default AssessmentService

import rdf from 'rdf-ts'
import constants from 'helpers/constants'

export function rdfEncoder (start, end) {
    // TODO: this will become slightly more complicated once we introduce date presets but really not that much more complicated
    if (end === 0 || !end) {
        return `$range$${start}$now$`
    }
    return `$range$${start}$${end}$`
}

export function rdfDecoder (dateString) {
    let [start, end] = [null, null]
    try {
        [start, end] = rdf(dateString)
    } catch (error) {
        console.error('the rdf string is not correctly formatted', error)
    }
    return [start, end]
}
import { render, staticRenderFns } from "./ModalInitiateCapture.vue?vue&type=template&id=738543e0&scoped=true&lang=pug"
import script from "./ModalInitiateCapture.vue?vue&type=script&lang=js"
export * from "./ModalInitiateCapture.vue?vue&type=script&lang=js"
import style0 from "./ModalInitiateCapture.vue?vue&type=style&index=0&id=738543e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738543e0",
  null
  
)

export default component.exports
<template lang="pug">
    div(class="sz-ModalEditWidget")
        div(
            v-if="savingWidget",
            class="sz-ModalEditWidget-loading")
            LoadingSpinner
        div(class="sz-ModalEditWidget-title") {{ $t(`modals.modalEditWidget.title`) }}
        div(class="sz-ModalEditWidget-body")
            FormEditWidget(
                :widgetSettings="widgetSettings",
                @close="close"
                @savingWidget="setSaveState")
</template>

<script>
    import FormEditWidget from './ModalEditWidget/FormEditWidget'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'

    export default {
        name: "ModalEditWidget",

        components: {
            LoadingSpinner,
            FormEditWidget,
        },

        props: {
            widgetSettings: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                savingWidget: false,
            }
        },

        methods: {
            close () {
                this.$emit('close')
            },

            setSaveState (saving) {
                this.savingWidget = saving
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalEditWidget {
        min-height: 36vw;
        max-height: 91vh;
        width: 59.625rem;
        min-width: 48vw;
        max-width: 89vw;
        display: flex;
        flex-direction: column;
        transition: height 0.5s;

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-body {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 13px;
            flex-grow: 1;
            @include scrollbar-widget;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

</style>

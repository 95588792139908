<template lang="pug">
    div(
        v-once,
        v-html="require(`../../assets/icons/icon-${icon}.svg`)",
        :style="{'font-size': width + 'px'}",
        :class="`sz-SvgIcon-${icon}`")
</template>

<script>
    /*
        usage: import it as a component in your .vue file, pass
        it props 'icon' and 'size'. 'icon' should correspond to the file name
        of the svg that resides in ./assets/icons. When creating the icon svg, make
        sure to name the .svg file 'icon-' + name. 'size' is the font size you want it to listen to.
    */
    export default {
        props: {
            icon: {
                type: String,
                required: true,
            },
            width: {
                type: String,
                default: '10',
                required: false,
            },
            height: {
                type: String,
                default: '10',
                required: false,
            },
        },
        mounted () {
            this.$el.firstChild.removeAttribute('height')
            this.$el.firstChild.removeAttribute('width')
            this.$el.firstChild.setAttribute('height', this.height)
            this.$el.firstChild.setAttribute('width', this.width)
        },
    }
</script>

<style module>
    svg {
        display: block;
        fill: currentColor;
        margin-top: -4px;
        vertical-align: middle;
    }
</style>

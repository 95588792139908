import Vue from 'vue'
import App from 'src/App.vue'
import router from 'router/index'
import store from 'state/store'
import i18n from 'src/i18n'
import ApiService from 'services/api.service'
import { domain, clientId, audience } from '../auth_config.json'
import { Auth0Plugin } from 'src/auth/index'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(window.location.pathname)
  },
})

Vue.config.productionTip = false
ApiService.init(process.env.VUE_APP_ROOT_API)

Vue.use(VueGtm, {
  id: `${process.env.VUE_APP_GTM_CONTAINER}`,
  vueRouter: router,
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

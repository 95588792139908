/*
Truncated Text Tooltip Directive
---------------------------------
Add this to any element that contains truncated text.

Default: v-truncated-tooltip=""
This directive does NOT require any parameters to the render of the tooltip.
Default values that are defined below are used.

If necessary, an object of visual parameters can be accepted.

Usage: v-truncated-tooltip="tooltipObject"

tooltipObject = {
  ** Tether attributes for the pointer **
    pointer: {
        placement: placement relative to the element,
        offset: 'horizontal-offset, vertical-offset',
    },
    ** Tether attributes for the body **
    body: {
        placement: placement relative to the element,
        offset: 'horizontal-offset, vertical-offset',
    }
}

see https://popper.js.org/popper-documentation.html#Popper.placements for options regarding the placement parameter.

NOTE: Not all values are needed. The default values are located below.
WARNING: An id MUST be created for an element with a tooltip directive.
*/

import Popper from 'popper.js'
import TooltipBody from 'components/Tooltip/TooltipBody'
import TooltipPointer from 'components/Tooltip/TooltipPointer'
import Vue from 'vue'
import { get } from 'lodash'

let popperHash = {}
const defaultAttachment = 'bottom-start'
const defaultPointerOffset = '6, 3'
const defaultBodyOffset = '0, 5'

/*
Directive
------------------
Directive has 5 possible hooks
1. Bind: Called when the directive is first bound to the element
2. Inserted: Called when the component or element is inserted into it’s parent node. It may not be in the DOM yet.
3. Update: Called when the containing component has updated but potentially before its children do.
4. Component Updated: Called when the containing component has updated but after its children do.
5. Unbind: Called when the directive is unbound from the component or element.
------------------
All hooks take in at minimum of 3 parameters
1. el: The element the directive is bound to. May be undefined.
2. binding: Contains any arguments, values, and modifiers passed to the directive.
3. vnode: This is a virtual node used by Vue’s renderer. Don’t touch this unless you know what you’re doing.
4. oldVnode: The previous version of the above vnode. Only available in the update hooks.
*/

function removeTether () {
    const item = document.getElementById('defaultTooltip')
    const item2 = document.getElementById('tooltipPointer')
    const content = document.querySelector('.sz-container')

    if (item && item.parentNode === content)  {
        content.removeChild(item)
    }
    if (item2 && item2.parentNode === content)   {
        content.removeChild(item2)
    }
}

function appendTooltipComponents (value) {
    const BodyConstructor = Vue.extend(TooltipBody)
    const PointerConstructor = Vue.extend(TooltipPointer)
    const constructorObject = {
        propsData: value || {},
    }
    const vm = new BodyConstructor(constructorObject).$mount()
    const vm2 = new PointerConstructor(constructorObject).$mount()
    document.querySelector('.sz-container').appendChild(vm.$el)
    document.querySelector('.sz-container').appendChild(vm2.$el)
}

function elementHasPopperInstance (elementId) {
    return get(popperHash, elementId, false)
        && get(popperHash[elementId], 'body', false)
        && get(popperHash[elementId], 'pointer', false)
}

function tether (el, value) {
    if (!el || el.scrollWidth <= el.offsetWidth || !el.innerText) {
        // if the element you've added this directive to doesn't exist, doesn't have any truncated text, or
        // doesn't have text at all, do nothing.
        return
    }

    const tooltipValue = {
        tooltipBodySettings: {
            truncatedMessage: el.innerText,
            view: [
                'dark',
                'center',
                'regular-padding',
            ],
            indexClass: 'tooltipIndex',
        },
        tooltipPointerSettings: {
            indexClass: 'tooltipIndex',
        },
    }

    removeTether()

    appendTooltipComponents(tooltipValue)

    const pointerElement = document.getElementById('tooltipPointer')
    const pointerTarget = el

    const pointerPopper = new Popper(
        pointerTarget,
        pointerElement,
        {
            placement: get(value, '[pointer, placement]', defaultAttachment),
            modifiers: {
                offset: {
                    enabled: true,
                    offset: get(value, '[pointer, offset]', defaultPointerOffset),
                },
            },
        })

    const tooltipElement = document.getElementById('defaultTooltip')
    const bodyTarget = el
    const bodyPopper = new Popper(
        bodyTarget,
        tooltipElement,
        {
            placement: get(value, '[body, placement]', defaultAttachment),
            modifiers: {
                offset: {
                    enabled: true,
                    offset: get(value, '[pointer, offset]', defaultBodyOffset),
                },
            },
        })

    pointerPopper.scheduleUpdate()
    bodyPopper.scheduleUpdate()

    if (elementHasPopperInstance(el.id)) {
        popperHash[el.id]['body'].destroy()
        popperHash[el.id]['pointer'].destroy()
    }

    popperHash[el.id]['pointer'] = pointerPopper
    popperHash[el.id]['body'] = bodyPopper
}

function bind (el, binding) {
    unbind(el, binding)

    if (!popperHash[el.id]) {
        popperHash[el.id] = {
            element: tether.bind(this, el, binding.value),
        }
    }

    el.addEventListener('mouseenter', popperHash[el.id]['element'], false)
    el.addEventListener('mouseleave', removeTether, false)
}

function unbind (el) {
    removeTether()

    if (popperHash[el.id]) {
        if (elementHasPopperInstance(el.id)) {
            popperHash[el.id]['body'].destroy()
            popperHash[el.id]['pointer'].destroy()
        }
        el.removeEventListener('mouseenter', popperHash[el.id]['element'], false)
        el.removeEventListener('mouseleave', removeTether, false)
    }

    delete popperHash[el.id]
}

export const TruncatedTooltip = {
    bind,
    unbind,
}

<template lang="pug">
  div(class="sz-LifeBooster-Logo")
    img(
        v-bind:src="image",
        class="sz-LifeBooster-Logo-image")
</template>

<script>
    export default {
        data () {
            return {
                image: require("../../assets/images/LifeBoosterLogo.png"),
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    img {
        width: 3.125rem;
    }

    .sz-LifeBooster-Logo {
        width: 2.375rem;
        height: 2.375rem;
        background-color: $color-box-silver;
        cursor: pointer;

        img {
            display: block;
            max-width: 2.375rem;
            max-height: 2.375rem;
            width: auto;
            height: auto;
        }
    }
</style>
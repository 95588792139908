<template lang="pug">
    div(:class="$style.container")
        TopBar(:showWorkerCard="showWorkerCard")
        div(:class="$style.content")
            transition(:name="$style.fade")
                <slot />
</template>

<script>
    import TopBar from "components/Navigation/TopBar"
    export default {
        components: {
            TopBar,
        },

        props: {
            showWorkerCard: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>

<style lang="scss" module>
    @import '~design';

    .fade-enter-active, .fade-leave-active {
      transition: opacity .3s ease;
    }

    .fade-enter, .fade-leave-to {
      opacity: 0;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 25rem;
        max-width: 100vw;
        height: 100%;
    }

    .content {
        margin-top: 4.8rem;
        height: 100%;
    }
</style>

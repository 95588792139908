import ApiService from './api.service'
import moment from 'moment-timezone'

const LogService = {
    send: async function(level, message) {
        const url = `/api/log`
        try {
            const response = await ApiService.post(url, {
              "level": level,
              "message": message,
              "timestamp": moment().format(),
            })
            return response.data
        } catch (error) {
            throw new Error(error.response)
        }
    },
}

export default LogService

<template lang="pug">
    div(class="sz-ModalInitiateCapture")
        div(class="sz-ModalInitiateCapture-title") {{ $t(`modals.modalInitiateCapture.title`) }}
        div(
            v-if="init"
            class="sz-ModalInitiateCapture-body-centered")
            InitiateCaptureUserTable(
                @user="updateUser")
        div(
            v-if="userSelected && !setSelected"
            class="sz-ModalInitiateCapture-body")
            FormInitiateCapture(
                :user = "user",
                @setID="updateSetID")
        div(
            v-if="userSelected && setSelected && !captureStarted"
            class="sz-ModalInitiateCapture-body")
                span(class="sz-ModalInitiateCapture-section-breadcrumb-top") {{ $t('modals.modalInitiateCapture.selectedWorker') }} {{ name }}
                span(class="sz-ModalInitiateCapture-section-breadcrumb") {{ $t('modals.modalInitiateCapture.selectedSet') }} {{ setID }}
                div(class="sz-ModalInitiateCapture-section-img")
                    PoseInitiateCapture
                div(class="sz-ModalInitiateCapture-section-content")
                    div(class="sz-ModalInitiateCapture-section-content-instructions") {{ $t('modals.modalInitiateCapture.askPose') }}
        div(
            v-if="captureStarted"
            class="sz-ModalInitiateCapture-body-centered")
                div(class="sz-ModalInitiateCapture-section-img")
                    PoseInitiateCapture
                span(class="sz-ModalInitiateCapture-section-countdown") {{ $t('modals.modalInitiateCapture.holdPose') }}
                span(class="sz-ModalInitiateCapture-section-countdown-timer") {{ timerCount }}
                span(class="sz-ModalInitiateCapture-section-countdown") {{ $t('modals.modalInitiateCapture.seconds') }}
        div(
          v-if="init"
          class="sz-ModalInitiateCapture-footer")
            div(
                @click="cancelCapture",
                class="sz-ModalInitiateCapture-footer-button \
                    sz-ModalInitiateCapture-footer-cancel-single") {{ $t(`modals.cancel`)}}
        div(
          v-if="userSelected && !setSelected"
          class="sz-ModalInitiateCapture-footer")
            div(
                @click="reselectUser",
                class="sz-ModalInitiateCapture-footer-button \
                    sz-ModalInitiateCapture-footer-cancel") {{ $t(`modals.modalInitiateCapture.back`)}}
            div(
                @click="confirmSet",
                class="sz-ModalInitiateCapture-footer-button \
                    sz-ModalInitiateCapture-footer-submit") {{ $t(`modals.modalInitiateCapture.next`)}}
        div(
          v-if="userSelected && setSelected && !captureStarted"
          class="sz-ModalInitiateCapture-footer")
            div(
                @click="reselectSet",
                class="sz-ModalInitiateCapture-footer-button \
                    sz-ModalInitiateCapture-footer-cancel") {{ $t(`modals.modalInitiateCapture.back`)}}
            div(
                @click="startCapture",
                class="sz-ModalInitiateCapture-footer-button \
                    sz-ModalInitiateCapture-footer-submit") {{ $t(`modals.modalInitiateCapture.start`)}}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import InitiateCaptureUserTable from './ModalInitiateCapture/InitiateCaptureUserTable'
    import FormInitiateCapture from './ModalInitiateCapture/FormInitiateCapture'
    import PoseInitiateCapture from './ModalInitiateCapture/PoseInitiateCapture'
    import formValidator from 'mixins/formValidator'
    import constructModalSettings from 'mixins/modalSettings'
    import EventBus from 'src/eventBus'

    export default {
        name: "ModalInitiateCapture",

        components: {
            InitiateCaptureUserTable,
            FormInitiateCapture,
            PoseInitiateCapture,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        data () {
            return {
                user: null,
                setID: '',
                init: true,
                userSelected: false,
                setSelected: false,
                captureStarted: false,
                timerCount: 0,
            }
        },

        computed: {
            ...mapGetters([
                  'currentCustomer',
                  'currentCompany',
                  'startingCapture',
            ]),

            name() {
                let firstName = this.user.firstName || ''
                let lastName = this.user.lastName || ''
                return `${firstName} ${lastName}`
            },

            fullSetID() {
                return `0x`+this.setID.toUpperCase()
            },
        },

        watch: {
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--
                        }, 1000)
                    }
                },
                immediate: true,
            },
        },

        mounted() {
            EventBus.$on('CLOSE_WINDOW', () => {
                this.$emit('close')
            })

            EventBus.$on('USER_CONFIRMED', () => {
                this.setSelected = true
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.setSelected = false
            })
        },

        methods: {
            cancelCapture() {
                this.$emit('close')
            },

            reselectUser() {
                this.user = null
                this.init = true
                this.userSelected = false
            },

            reselectSet() {
                this.setID = ''
                this.setSelected = false
            },

            updateUser(user) {
                this.user = user
                this.init = false
                this.userSelected = true
            },

            updateSetID(setID) {
                this.setID = setID
            },

            setTimer() {
                this.timerCount = constants.INITIATE_CAPTURE.POSE_TIMER
            },

            async confirmSet() {
                if (this.setID.length === 6) {
                  try {
                      await this.validateSet()
                      let isSetActive = await this.isSetActive()
                      if (isSetActive) {
                          await this.confirmModal(
                              this.$t("modals.modalInitiateCapture.setNotice"),
                              this.$t("modals.modalInitiateCapture.setInUse", { set: this.setID }))
                      } else {
                          this.setSelected = true
                      }
                  } catch(error) {
                      await this.failModal(
                            this.$t("modals.modalInitiateCapture.enterValidSet"),
                            this.$t("modals.modalInitiateCapture.invalidSet"))
                  }
                } else {
                    await this.failModal(
                          this.$t("modals.modalInitiateCapture.enterValidSet"),
                          this.$t("modals.modalInitiateCapture.invalidSet"))
                }
            },

            async startCapture() {
                try {
                    this.captureStarted = true

                    await this.$store.dispatch('startCapture', {
                        sid: this.fullSetID,
                        userID: this.user.userID,
                    })

                    this.setTimer()
                    await new Promise(resolve => {
                        setTimeout(async() => {
                            resolve(
                                await this.successModal(
                                    this.$t(`modals.modalInitiateCapture.successMessage`, {name: this.name, set: this.setID, date: new Date().toLocaleTimeString()}) +
                                    this.$t(`modals.modalInitiateCapture.confirmationMessage`),
                                    true
                                )
                            )
                        }, this.timerCount * constants.UNIX_MILLISECONDS)
                    })
                } catch(error) {
                    this.failModal(
                        this.$t("pleaseTryAgain"),
                        this.$t("somethingWentWrong"),
                        () => this.showConfirmModal = true
                    )
                }
            },

            async validateSet() {
                return await this.$store.dispatch(
                    'getDeviceSet',
                    this.fullSetID,
                )
            },

            async isSetActive() {
                try {
                    let capture = await this.$store.dispatch(
                        'getActiveCapture',
                        this.fullSetID,
                    )
                    return capture ? true : false
                } catch (err) {
                    // If this API call fails, we cannot confirm or deny if the set is currently in use
                    // So we return true here to trigger a warning that the set may be in use
                    return true
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ModalInitiateCapture {
        position: relative;
        min-height: 72vh;
        min-width: 40vw;
        display: flex;
        flex-direction: column;
        flex-grow: 5;

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-title {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-size: 15px;
            @extend %font-topbar-heading;
            color: $color-white;
        }

        &-body {
            max-height: 70vh;
            max-width: 40vw;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-grow: 1;
            @include scrollbar-widget;
            overflow-y: auto;
            overflow-x: hidden;

            &-centered {
                max-height: 70vh;
                max-width: 40vw;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;
                @include scrollbar-widget;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        &-section {
            &-breadcrumb {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $color-white;
                padding-bottom: 2rem;

                &-top {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: $color-white;
                    padding-top: 1rem;
                    padding-bottom: 0.5rem;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $color-white;
                white-space: pre;

                &-instructions {
                    padding-top: 1rem;
                }
            }

            &-countdown {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                align-self: center;
                color: $color-white;
                padding-bottom: 1rem;

                &-timer {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  align-self: center;
                  color: $color-white;
                  @extend %font-topbar-heading;
                  font-size: 25px;
                  padding-bottom: 1rem;
                }
            }

            &-img {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-bottom: 1rem;
            }
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
            }

            &-submit {
                @extend %button-layout;
                padding: 0.5rem 1rem;
                background-color: $color-lifebooster-light-green;
                border-radius: 0;
            }

            &-cancel {
                font-size: 13px;

                &-single {
                    font-size: 13px;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

</style>

<template lang="pug">
    div(
        v-if="workerCardLoaded",
        class="sz-WorkerIdentificationPanel")
        div(class="sz-WorkerIdentificationPanel-topLevel")
            div(
                class="sz-WorkerIdentificationPanel-title",
                :id="workerIdRows.uid.value",
                v-truncated-tooltip="") {{ workerIdRows.name.value }}
</template>

<script>
    import constants from 'helpers/constants'
    import SvgIcon from 'components/Shared/SvgIcon'

    import { tooltip } from 'directives/tooltip'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            SvgIcon,
        },

        directives: {
            tooltip,
            TruncatedTooltip,
        },

        computed: {
            ...mapGetters([
                'workerId',
                'workerCard',
            ]),

            workerCardLoaded () {
                return !!this.workerCard.userID
            },

            extendedInfoTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'regular-padding',
                        },
                        component: constants.CUSTOM_TOOLTIP_BODY.WORKER_ID_TOOLTIP,

                        componentProp: {
                            list: Object.values(this.workerIdRows).map((row) => {
                                return {
                                    label: this.$t(`individualSummary.worker.` + row.id),
                                    value: row.value,
                                }
                            }),

                        },
                    },

                    body: {
                        placement: 'right-center',
                        offset: '46, 15',
                    },

                    allowHover: true,
                }
            },

            workerIdRows () {
                return {
                    name: {
                        id: 'name',
                        value: this.workerCard ? `${this.workerCard.firstName || ''}  ${this.workerCard.lastName || ''}` : '',
                    },
                    jobType: {
                        id: 'jobType',
                        value: this.workerCard ? this.workerCard.job : '',
                    },
                    mobile: {
                        id: 'mobile',
                        value: this.workerCard ? this.workerCard.mobile : '',
                    },
                    uid: {
                        id: 'uid',
                        value: this.workerCard ? this.workerId: '',
                    },
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-WorkerIdentificationPanel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 15rem;

        &-title {
            @extend %font-content;
            font-size: 0.8125rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
        }

        &-topLevel {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 80%;
        }

        &-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin: .1rem 0rem;
        }

        &-label {
            text-align: left;
            width: 50%;
        }

        &-value {
            text-align: left;
            width: 50%;
        }

    }
</style>

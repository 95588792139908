<template lang="pug">
    div(class="sz-DropdownMultiSelect")
        div(
            @click="openDropdown",
            @keyup.esc="closeDropdown",
            v-click-away="closeDropdown",
            :class="withBottomBorder + (isDisabled ? '-disabled' : '')")
            div(
                class="sz-DropdownMultiSelect-title"
                :class="titleStyle") {{ title }}
            SvgIcon(
                icon="arrow",
                class="sz-DropdownMultiSelect-arrow",
                :class="`sz-DropdownMultiSelect-arrow-` + (isOpen ? 'up' : 'down')")
        transition(name="slide")
            div(
                v-show="isOpen",
                class="sz-DropdownMultiSelect-options",
                :class="dropdownDimensions")
                div(
                    @click.stop="selectAll",
                    class="sz-DropdownMultiSelect-option sz-DropdownMultiSelect-selectAll")
                    div(
                        :class="`sz-DropdownMultiSelect-option-checkbox-` + (allSelected ? 'checked ': 'unchecked')")
                    div(class="sz-DropdownMultiSelect-option-text") Select All
                div(
                    v-for="(option, i) in options",
                    :key="i",
                    @click.stop="selectOption(option.type)",
                    class="sz-DropdownMultiSelect-option",
                    :class="(selectRestricted ? (option.selectable ? 'select' : 'no-select') : '') ")
                    div(
                        :class="['sz-DropdownMultiSelect-option-checkbox-' + (selectedOptions.includes(option.type) ? 'checked ': 'unchecked'),\
                        (selectRestricted ? (option.selectable ? 'select' : 'no-select') : '')]",
                        class="sz-DropdownMultiSelect-option-checkbox")
                    div(
                        class="sz-DropdownMultiSelect-option-text") {{ option.name }}
        div(
            class="sz-DropdownMultiSelect-selectedOptions",
            v-if="showSelectedOptions")
            span(class="sz-DropdownMultiSelect-selectedOptions-string") {{ optionsList }}
</template>

<script>
    import { ClickAway } from 'directives/clickAway'
    import { cloneDeep, pull, intersection } from 'lodash'
    import SvgIcon from './SvgIcon'

    export default {
        name: "DropdownMultiSelect",

        components: {
            SvgIcon,
        },

        directives: {
            ClickAway,
        },

        props: {
            options: {
                type: Array,
                required: false,
                default: () => [],
            },

            defaultSelectionOptions: {
                type: [Array, String],
                required: false,
                default: () => [],
            },

            selectionTitle: {
                type: String,
                required: true,
            },

            selectRestricted: {
                required: false,
                type: Boolean,
                default: false,
            },

            titleStyle: {
                required: false,
                type: String,
                default: '',
            },

            showSelectedOptions: {
                required: false,
                type: Boolean,
                default: true,
            },

            dropdownDimensions: {
                required: false,
                type: String,
                default: '',
            },

            disableSelection: {
                required: false,
                type: Boolean,
                default: false,
            },

            noBottomBorder: {
                required: false,
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isOpen: false,
                selectedOptions: [],
                allSelected: false,
            }
        },

        computed: {
            hasSelectedOptions () {
                return this.selectedOptions.length > 0
            },

            title () {
                return `${this.selectionTitle}${this.hasSelectedOptions ? ` (${this.selectedOptions.length})` : ''}`
            },

            optionsList () {
                return this.selectedOptions.map(option => this.options.find(opt => opt.type === option).name).join(', ')
            },

            isDisabled() {
                return this.disableSelection
            },

            withBottomBorder() {
                return this.noBottomBorder ? "sz-DropdownMultiSelect-box_with_no_bottom_border" : "sz-DropdownMultiSelect-box_with_bottom_border"
            },
        },

        watch: {
            selectedOptions () {
                if (this.options.length && (this.selectedOptions.length === this.options.length)) {
                    this.allSelected = true
                }
            },
        },

        mounted () {
            // just in case there is a discrepancy between the available options and
            // the currently selected options.
            const availableOptions = this.options.map((option) => {
                return option.type
            })
            this.selectedOptions = intersection(availableOptions, this.defaultSelectionOptions)
            if (this.options.length && (this.selectedOptions.length === this.options.length)) {
                this.allSelected = true
            }
        },

        methods: {
            openDropdown () {
                if (this.disableSelection === false) {
                    this.isOpen = true
                }
            },

            closeDropdown () {
                this.isOpen = false
            },

            selectOption (option) {
                if (this.selectedOptions.includes(option)) {
                    this.unselectOption(option)
                    this.allSelected = false
                } else {
                    this.selectedOptions.push(option)
                }
                this.$emit('updateSelectedOptions', this.selectedOptions)
            },

            unselectOption (option) {
                let currentlySelected = cloneDeep(this.selectedOptions)
                pull(currentlySelected, option)
                this.selectedOptions = currentlySelected
            },

            selectAll () {
                if (this.selectRestricted) {
                    this.selectedOptions = !this.allSelected
                        ? this.options.filter(option => {
                            //only allows selectable options to be selected
                            if (option.selectable === true) {
                                return option.type
                            }
                        }).map(option => option.type)
                        : []
                } else {
                    this.selectedOptions = !this.allSelected
                        ? this.options.map(option => option.type)
                        : []
                }
                this.allSelected = !this.allSelected
                this.$emit('updateSelectedOptions', this.selectedOptions)
            },

            clearSelectedOptions() {
                this.selectedOptions = []
                this.$emit('updateSelectedOptions', this.selectedOptions)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-DropdownMultiSelect {
        width: 100%;
        height: 100%;
        color: $color-white;
        position: relative;

        &-title {
            display: flex;
            align-self: center;
            @extend %font-content;
            font-weight: 600;
            font-size: 13px;

            &.heading {
                @extend %font-topbar-heading;
                padding: 0.3rem 0;
            }
        }

        &-box_with_bottom_border {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $color-lifebooster-light-green;
            cursor: pointer;

            &-disabled {
                cursor:not-allowed;
            }
        }

        &-box_with_no_bottom_border {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &-disabled {
                cursor:not-allowed;
            }
        }

        &-arrow {
            margin-top: .3rem;
            transition: 0.5s;

            &-up {
                margin-bottom: 0.4rem;
                transform: rotate(180deg)
            }
        }

        &-options {
            background-color: $color-body-bg;
            z-index: $layer-popover-z-index;
            margin-top: 0.125rem;
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            user-select: none;
        }

        .setDimensions {
            overflow-y: scroll;
            overflow-x: scroll;
            max-height: 10rem;
            width: 100%;
        }

        &-selectedOptions {
            margin-top: 0.3rem;
            text-align: left;
            width: 100%;
            cursor: default;
            font-size: 13px;
        }

        &-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            cursor: pointer;

            &:hover {
                background-color: $color-lifebooster-medium-green;
            }
            &.no-select {
                color: grey;
                pointer-events:none;
                cursor: not-allowed;
            }

            &-checkbox {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                &-checked {
                    background-color: $color-box-silver;
                }
                &.no-select {
                    border-color: grey;
                    pointer-events:none;
                    cursor: not-allowed;
                }
            }
        }
    }
</style>

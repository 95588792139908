import ApiService from './api.service'

const LbAdminService = {
  getAllCustomers: async function () {
    const url = `/customers`

    try {
      const response = await ApiService.get(url)
      return response.data.data
    } catch (error) {
      throw new Error(error.response)
    }
  },
}

export default LbAdminService

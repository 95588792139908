import constants from 'helpers/constants'

const modal = {
    state: {
        modals: [],
        modalOpen: false,
    },
    mutations: {
        setModalStatus (state, { action, modal, key }) {
            if (action === constants.OPEN_MODAL) {
                modal.key = key
                state.modals.push(modal)
                state.modalOpen = true
            }
            else {
                state.modals.pop()
                if (state.modals.length === 0) {
                    state.modalOpen = false
                }
            }
        },

        closeAll (state) {
            state.modals = []
            state.modalOpen = false
        },
    },
    getters: {
        isModalOpen: (state) => {
            return state.modalOpen
        },
        openModals: (state) => {
            return state.modals
        },
    },
    actions: {
        showModal ({ commit, state }, payload) {
            let modalOrder = state.modals.length + 1
            commit('setModalStatus', { action: constants.OPEN_MODAL, modal: payload, key: modalOrder})
        },

        closeModal ({ commit }) {
            commit('setModalStatus', { action: constants.CLOSE_MODAL, modal: undefined })
        },

        closeAllModals ({ commit }) {
            commit('closeAll')
        },
    },
}

export default modal

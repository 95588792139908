<template lang="pug">
    div(:class="`sz-FormSectionTagSelection ${width}`")
        div(class="sz-FormSectionTagSelection-title")
            span(
                v-if="required",
                class="sz-FormSectionTagSelection-title-required") {{ $t('form.required') }}
            span(class="sz-FormSectionTagSelection-title") {{ title }}
        div(:class="`sz-FormSectionTagSelection-selector ${width}`")
            DropdownSearchable(
                :items="options",
                isSingleSelect=true,
                :defaultSelectedOption="defaultSelectedOption"
                :isSorted="isSorted"
                @setResult="setResult",
                ref="dropdown")
</template>

<script>
    import DropdownSearchable from './DropdownSearchable'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'

    export default {
        name: "FormSectionSingleSelectSearchable",

        components: {
            DropdownSearchable,
        },

        props: {
            title: {
                required: true,
                type: String,
            },

            defaultSelectedOption: {
                required: false,
                type: String,
                default: '',
            },

            options: {
                required: true,
                type: Array,
            },

            width: {
                required: false,
                type: String,
            },

            required: {
                required: false,
                type: Boolean,
                default: false,
            },
            
            isSorted: {
                required: false,
                type: Boolean,
                default: true,
            },
        },

        data () {
            return {
                selectedOption: null,
            }
        },

        mounted () {
            if (this.defaultSelectedOption){
                this.selectedOption = this.defaultSelectedOption
            }

            EventBus.$on(constants.EVENTS.CLEAR_SELECTION, () => {
                this.clearSelection()
            })
        },

        beforeDestroy() {
            EventBus.$off(constants.EVENTS.CLEAR_SELECTION)
        },

        methods: {
            setResult (result) {
                if (this.selectedOption !== result) {
                    this.selectedOption = result
                    this.$emit('updateSelectedOption', this.selectedOption)
                }
            },

            clearSelection () {
                this.selectedOption = null
                this.$emit('updateSelectedOption', this.selectedOptions)
                this.$refs.dropdown.clearSelection()
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionTagSelection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 20rem;
        height: 100%;

        &.fullWidth {
            width: 100%;
         }

        &-selector {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 20rem;

            &.fullWidth {
                width: 100%;
            }
        }

        &-title {
            @extend %font-topbar-heading;
            color: $color-white;
            font-size: 12px;
            padding-bottom: 0.3rem;

            &-required {
                color: red
            }

            &.row {
                padding-left: 0.4rem;
            }
        }
    }

</style>

<template lang="pug">
    div(class="sz-WorkerIDTooltipBody")
        div(class="sz-WorkerIDTooltipBody-items") 
            div(
                class="sz-WorkerIDTooltipBody-item",
                v-for="item in componentProp.list",
                :key="item.value")
                    div(class="sz-WorkerIDTooltipBody-label") {{ item.label }}
                    div(class="sz-WorkerIDTooltipBody-value") {{ item.value }}
</template>
    
<script>
    export default {
        name: 'WorkerIDTooltipBody',
        props: {
            componentProp: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        list: [],
                    }
                },
            },

        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-WorkerIDTooltipBody {
        padding: 0.375rem 0.8rem;
        font-size: 0.75rem;
        line-height: 0.938rem;
        display: flex;
        flex-direction: column;
        width: 15.625rem;
        

        &-item {
            display: flex;
        }

        &-value {
            text-align: right;
            white-space: pre-wrap;
            font-weight: 600;
            width: 9rem;
            margin-bottom: 0.625rem
        }

        &-label {
            width: 6rem;
            color: $color-UserInfo-labels;
            padding-right: 0.625rem; 
            margin-bottom: 0.625rem
        }

    }
</style>

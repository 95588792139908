<template lang="pug">
    div(
        class="sz-FormSectionInput")
        div(class="sz-FormSectionInput-label")
            span(
                v-if="required",
                class="sz-FormSectionInput-label-required") {{ $t('form.required') }}
            label(
                :for="`formSectionInput-${title}`",
                class="sz-FormSectionInput-label",
                :class="{'sz-FormSectionInput-label-center': centerTitle}") {{ title }}
        textarea(
            v-if="!useInputTag",
            :placeholder="placeholder",
            v-model="input",
            :maxlength="maxLength",
            @input="onInput",
            @keyup.enter.stop="onEnter",
            class="sz-FormSectionInput-input",
            :id="`formSectionInput-${title}`")
        input(
            v-if="useInputTag",
            :placeholder="placeholder",
            v-model="input",
            :maxlength="maxLength",
            @input="onInput",
            @keyup.enter.stop="onEnter",
            class="sz-FormSectionInput-input",
            :readonly="disableInput",
            :class="[`sz-FormSectionInput-input-${textSize}`, {readonly : disableInput}]"
            :id="`formSectionInput-${title}`")
</template>

<script>
    export default {
        name: "FormSectionInput",

        props: {
            title: {
                required: true,
                type: String,
            },
            defaultInput: {
                required: false,
                type: String,
                default: '',
            },
            placeholder: {
                required: false,
                type: String,
                default: '',
            },
            centerTitle: {
                required: false,
                type: Boolean,
                default: false,
            },
            maxLength: {
                required: false,
                type: Number,
                default: 10000,
            },
            useInputTag: {
                required: false,
                type: Boolean,
                default: false,
            },
            disableInput: {
                required: false,
                type: Boolean,
                default: false,
            },
            textSize: {
                required: false,
                type: String,
                default: '',
            },
            required: {
                required:false,
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                input: '',
            }
        },

        mounted() {
            this.input = this.defaultInput
        },

        methods: {
            onInput () {
                const newInput = this.input
                this.$emit('updated', newInput)
            },

            onChange () {
                const newInput = this.input
                this.$emit('changed', newInput)
            },

            onEnter () {
                this.$emit('enter')
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionInput {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        &-label {
            @extend %font-topbar-heading;
            display: flex;
            font-size: 12px;
            color: $color-white;

            &-center {
                align-self: center;
            }

            &-required {
                color: red
            }
        }

        input {
            width: 70%;
        }

        textarea.sz-FormSectionInput-input {
            outline: none;
            padding-top: 1rem;
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom: 1px $color-lifebooster-light-green solid;
            background-color: inherit;
            @extend %font-topbar-heading;
            @include scrollbar-widget;
            font-size: 18px;
            color: $color-white;
            resize: none;
            width: 95%;
        }

        input.sz-FormSectionInput-input{
            outline: none;
            padding-top: 1rem;
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom: 1px $color-lifebooster-light-green solid;
            background-color: inherit;
            padding: 0.3rem 0;
            @extend %font-topbar-heading;
            @include scrollbar-widget;
            font-size: 18px;
            color: $color-white;
            resize: none;
            width: 95%;

            &-content {
                @extend %font-content;
                font-size: 12px;
            }

            &-noAllCaps {
                text-transform: none;
            }

            &.readonly {
                cursor:not-allowed;
                color: #999999;
            }

        }
    }

</style>

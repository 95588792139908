import constants from 'helpers/constants'
import { noop } from 'lodash'
import EmailValidator from 'email-validator'

const formValidator = {
    data() {
        return {
            titleIsValid: true,
            typeIsValid: true,
            riskScoreTypeIsValid: true,
            datesAreValid: true,
            riskTypesAreValid: true,
            tagsAreValid: true,
            jobTypesAreValid: true,
            gendersAreValid: true,
            distributionTypeIsValid: true,
            firstNameIsValid: true,
            lastNameIsValid: true,
            emailIsValid: true,
            heightIsValid: true,
            genderIsValid: true,
            mobileIsValid: true,
            MAPVIsValid: true,
        }
    },

    computed: {
        intervalIsLastAssessment() {
            return this.newSettings.interval === constants.DATE_PRESETS.LAST_ASSESSMENT
        },

        widgetTypeIsList() {
            return this.newSettings.type === constants.WIDGET_TYPES_API_KEYS.LIST
        },

        widgetAnalysisIsTagType() {
            return this.newSettings.type === constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART
        },

        widgetAnalysisIsTimeType() {
            return this.newSettings.type === constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART
        },

        widgetTypeIsSummary() {
            return this.newSettings.type === constants.WIDGET_TYPES_API_KEYS.SUMMARY
        },
    },

    methods: {
        validateWidgetSettings() {
            const validTitle = this.validateTitle()
            const validType = this.validateType()
            const validDates = this.validateDates()
            const validRiskScoreType = this.validateRiskScoreType()
            const validRiskTypes = this.validateRiskTypes()

            return {
                valid: validTitle
                    && validType
                    && validDates
                    && validRiskScoreType
                    && validRiskTypes,
                title: validTitle,
                type: validType,
                date: validDates,
                riskScoreType: validRiskScoreType,
                riskTypes: validRiskTypes,
            }
        },

        validateAnalysisSettings() {
            const validTitle = this.validateTitle()
            const validType = this.validateType()
            const validDates = this.widgetAnalysisIsTimeType ? this.validateAnalysisIntervals() : this.validateDates()
            const validTags = this.widgetAnalysisIsTagType ? this.validateAnalysisTags() : true
            const validRiskScoreType = this.validateRiskScoreType()
            const validRiskTypes = this.validateRiskTypes()
            return {
                valid: validTitle
                    && validType
                    && validDates
                    && validRiskScoreType
                    && validRiskTypes
                    && validTags,
                title: validTitle,
                type: validType,
                date: validDates,
                riskScoreType: validRiskScoreType,
                riskTypes: validRiskTypes,
                tags: validTags,
            }
        },

        validateExportSettings() {
            const validDates = this.validateDates()
            const validRiskModules = this.validateRiskModules()
            const validUserIDs = this.validateUserIDs()

            return {
                valid: validDates
                    && validRiskModules
                    && validUserIDs,
                date: validDates,
                riskModules: validRiskModules,
                userIDs: validUserIDs,
            }
        },

        validateProfileSettings() {
            const validFirstName = this.validateFirstName()
            const validLastName = this.validateLastName()
            const validMobile = this.validateMobile()

            return {
                valid: validFirstName
                    && validLastName
                    && validMobile,
                firstName: validFirstName,
                lastName: validLastName,
                mobile: validMobile,
            }
        },

        validateTagSettings() {
            const validTag = this.validateTags()
            const uniqueTag = this.isTagUnique()
            return {
                valid: validTag
                    && uniqueTag,
                tag: validTag,
                uniqueTag: uniqueTag,
            }
        },

        validateAssessmentTagSettings() {
            const validTag = this.validateTags()
            const uniqueTag = this.isAssessmentTagUnique()
            return {
                valid: validTag && uniqueTag,
                tag: validTag,
                uniqueTag: uniqueTag,
            }
        },

        validateNewUserSettings() {
            const validFirstName = this.validateFirstName()
            const validLastName = this.validateLastName()
            const validMobile = this.validateMobile()
            const validEmail = this.validateEmail()
            const validRequiredFields = this.validateNonEmptyUserFields()

            return {
                valid: validFirstName
                    && validLastName
                    && validMobile
                    && validEmail
                    && validRequiredFields,
                firstName: validFirstName,
                lastName: validLastName,
                mobile: validMobile,
                email: validEmail,
                required: validRequiredFields,
            }

        },

        validateCompanyAnonymousModeSettings() {
            const validMAPV = this.validateMAPV()
            return {
                valid: validMAPV,
                MAPV: validMAPV,
            }
        },

        validateEmail() {
            let isEmail = EmailValidator.validate(this.newSettings.email)

            if (!isEmail) {
                this.emailIsValid = false
                return false
            }

            return true
        },

        validateTitle() {
            if (this.newSettings.title === "" || !this.newSettings.title) {
                this.titleIsValid = false
                return false
            }
            return true
        },

        validateType() {
            if (!this.newSettings.type) {
                this.typeIsValid = false
                return false
            }
            return true
        },

        validateDates() {
            if (!this.newSettings.interval) {
                this.datesAreValid = false
                return false
            }
            if (this.intervalIsLastAssessment && !this.widgetTypeIsList) {
                this.datesAreValid = false
                return false
            }
            return true
        },

        validateAnalysisIntervals() {

            let intervalRef = this.newAnalysisSettings.groups[0].interval
            let intervalComp = this.newAnalysisSettings.groups[1].interval

            if (!intervalRef || !intervalComp
                || intervalRef === '' || intervalComp === '') {
                return false
            }

            let splitRefInterval = intervalRef.split('$')
            let splitCompInterval = intervalComp.split('$')

            //If start is null, for example, this means go all the way back in time, to the current end date.
            //Note that the picker calender automatically enforces that end date is past the start date, which is
            //why there is no check in place for that. This Code currently enforces that the user has to manually choose
            //two dates per each range; You can not just leave one empty.

            if (splitRefInterval[3] == "now" || splitCompInterval[2] == "null"
                || parseInt(splitRefInterval[3]) >= parseInt(splitCompInterval[2])) {
                return false
            }

            return true
        },

        validateAnalysisTags() {
            let refAssessmentTags = this.newAnalysisSettings.groups[0].assessmentTags
            let compAssessmentTags = this.newAnalysisSettings.groups[1].assessmentTags

            if (refAssessmentTags && compAssessmentTags
                && refAssessmentTags.length != 0 && compAssessmentTags.length != 0) {
                return true
            }

            return false
        },

        validateRiskScoreType() {
            if (this.widgetTypeIsSummary) {
                return true
            }

            if (!this.newSettings.riskScoreType || this.newSettings.riskScoreType === "") {
                this.riskScoreTypeIsValid = false
                return false
            }
            return true
        },

        validateRiskTypes() {
            if (this.widgetTypeIsSummary) {
                return true
            }

            if (this.newSettings.riskTypes.length < 1) {
                this.riskTypesAreValid = false
                return false
            }
            return true
        },

        isStringEmpty(field) {
            return field == null
                || field == undefined
                || field.trim().length === 0
        },

        validateNonEmptyUserFields() {
            const isJobEmpty = this.isStringEmpty(this.newSettings.job)
            const isTimezoneEmpty = this.isStringEmpty(this.newSettings.timezone)
            const isEmailEmpty = this.isStringEmpty(this.newSettings.email)
            const isRoleEmpty = this.isStringEmpty(this.newSettings.roles)

            if (isJobEmpty
                || isEmailEmpty
                || isTimezoneEmpty
                || isRoleEmpty) {

                return false
            }

            return true
        },

        validateLastName() {
            if (this.isStringEmpty(this.newSettings.lastName)) return true

            if (this.newSettings.lastName.length > 128) {
                this.lastNameIsValid = false
                return false
            }
            return true
        },

        validateFirstName() {
            if (this.isStringEmpty(this.newSettings.firstName)) return true

            if (this.newSettings.firstName.length > 128) {
                this.firstNameIsValid = false
                return false
            }
            return true
        },

        validateMobile() {
            if (this.isStringEmpty(this.newSettings.mobile)) return true
            if (this.newSettings.mobile.length > 30) {
                this.emailIsValid = false
                return false
            }
            return true
        },

        validateTags() {
            if (!(/^[a-zA-Z0-9\s]+$/.test(this.newTagName))) {
                this.tagsAreValid = false
                return false
            }
            return true
        },

        isTagUnique() {
            const isDuplicate = this.allCompanyTags.filter(tag => {
                return tag.name.toLowerCase().trim() === this.newTagName.toLowerCase().trim()
            })

            if (isDuplicate.length !== 0) {
                return false
            }

            return true
        },

        isAssessmentTagUnique() {
            const isDuplicate = this.allAssessmentTags.filter(tag => {
                return tag.name.toLowerCase().trim() === this.newTagName.toLowerCase().trim()
            })

            if (isDuplicate.length !== 0) {
                return false
            }

            return true
        },

        validateMAPV() {
            if (isNaN(this.newMAPV)) {
                this.MAPVIsValid = false
                return false
            }
            return true
        },

        validateRiskModules() {
            if (this.widgetTypeIsSummary) {
                return true
            }

            if (this.riskModules) {
                let result = this.riskModules.map(m => {
                    return Object.values(constants.RISK_MODULE_API_EXPORT_NAME_MAP).includes(m)
                })
                if (!result.includes(false) && result.length > 0) {
                    return true
                }
            }
            return false
        },

        validateUserIDs() {
            if (this.userIDs.length > 0) {
                return true
            } else {
                return false
            }
        },

        tagNameToID(tags) {
            if (tags === null || tags === undefined) return null
            try {
                return tags
                    .map(tagName => this.allCompanyTags
                        .find(tag => tag.name === tagName)
                        .tagID)
            } catch (error) {
                this.failModal(
                    this.$t('pleaseTryAgain'),
                    this.$t('somethingWentWrong'))
            }
        },

        assessmentTagNameToID(assessmentTags) {
            if (assessmentTags === null || assessmentTags === undefined) {
                return null
            }

            try {
                return assessmentTags.map(name => {
                    return Object.keys(this.assessmentTagMapper).find(key => this.assessmentTagMapper[key] === name)
                })
            } catch (error) {
                this.failModal(
                    this.$t('pleaseTryAgain'),
                    this.$t('somethingWentWrong'))
            }
        },

        failModal(reason, message, action = noop) {
            action()
            this.$store.dispatch("showModal", this.constructModalSettings(
                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_FAILURE,
                false,
                {
                    reason: reason,
                    message: message,
                },
                true,
                true)
            )
        },

        invalidModal(validator, type, dismissible) {
            this.$store.dispatch('showModal', this.constructModalSettings(
                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_INVALID_FORM_INPUT,
                false,
                {
                    errors: validator,
                    type: type,
                    dismissible: dismissible,
                },
                true,
                true)
            )
        },

        successModal(message, closeButton = true, action = noop, successModalAction = noop, title = '') {
            action()
            this.$store.dispatch('showModal', this.constructModalSettings(
                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_SUCCESS,
                false,
                {
                    title: title,
                    message: message,
                    action: successModalAction,
                    performAction: (successModalAction) ? true : false,
                    closeButton: closeButton,
                },
                false,
                true)
            )
            this.$emit('closeEdit')
        },

        confirmModal(title, message = '', list = []) {
            this.$store.dispatch("showModal", this.constructModalSettings(
                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_CONFIRMATION,
                false,
                {
                    title: title,
                    message: message,
                    list: list,
                },
                false,
                true)
            )
        },
    },
}

export default formValidator

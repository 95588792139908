import { render, staticRenderFns } from "./FormSectionTagSelection.vue?vue&type=template&id=1713328e&scoped=true&lang=pug"
import script from "./FormSectionTagSelection.vue?vue&type=script&lang=js"
export * from "./FormSectionTagSelection.vue?vue&type=script&lang=js"
import style0 from "./FormSectionTagSelection.vue?vue&type=style&index=0&id=1713328e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1713328e",
  null
  
)

export default component.exports
<template lang="pug">
    Layout(showWorkerCard=false)
        div(class="sz-Loading")
            LoadingSpinner(color="light")
</template>

<script>
import Layout from '../layouts/main'
import LoadingSpinner from '../../components/Shared/LoadingSpinner'

export default {
  name: 'Loading',

  components: {
    LoadingSpinner,
    Layout,
  },
}
</script>

<style scoped>
.sz-Loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<template lang="pug">
    div(class="sz-ModalEditBulkUser")
        div(
            v-if="updatingUser",
            class="sz-ModalEditBulkUser-loading")
            LoadingSpinner
        div(class="sz-ModalEditBulkUser-sectionSettings-label") {{ $t(`management.companyDashboard.editUserOption.edit`, {option: option}) }}       
        div(class="sz-ModalEditBulkUser-options" data-personal-info) 
            div(
                v-if="job && isSelectAssign",
                class="sz-ModalEditBulkUser-sectionSettings")
                FormSectionSingleSelectSearchable(
                    :title="$t(`management.companyDashboard.userTable.headers.job`)",
                    :options="jobOptions",
                    defaultSelectedOption="",
                    @updateSelectedOption="updateJobType",
                    class="sz-ModalEditUser-sectionSettings-input-select")
            div(
                v-if="timezone && isSelectAssign",
                class="sz-ModalEditBulkUser-sectionSettings") 
                FormSectionSingleSelectSearchable(
                    :title="$t(`management.companyDashboard.userTable.headers.timezone`)",
                    :options="timezoneOptions",
                    :isSorted="false"
                    @updateSelectedOption="updateSelectedTimezone",
                    class="sz-ModalEditUser-sectionSettings-input-select")
            div(
                v-if="tags",
                class="sz-ModalEditBulkUser-sectionSettings")
                div(class="sz-ModalEditBulkUser-editType") 
                    div(
                        @click="assignSelected()",
                        class="sz-ModalEditBulkUser-editType-button",
                        :class="`sz-ModalEditBulkUser-editType-button-` + (isSelectAssign ? 'checked ': 'unchecked')") 
                    div(class="sz-ModalEditBulkUser-editType-label") {{ $t(`management.companyDashboard.editUserOption.assign`, {option: option}) }}
                    div(
                        @click="removeSelected()",
                        class="sz-ModalEditBulkUser-editType-button",
                        :class="`sz-ModalEditBulkUser-editType-button-` + (isSelectRemove ? 'checked ': 'unchecked')") 
                    div(class="sz-ModalEditBulkUser-editType-label") {{ $t(`management.companyDashboard.editUserOption.remove`, {option: option}) }}
                FormSectionTagSelection(
                    class="sz-ModalEditBulkUser-sectionSettings-input-select",
                    :options="tagOptions",
                    :selectAll="true",
                    :title="$t(`management.companyDashboard.userTable.headers.membertags`)",
                    @updateSelectedTags="updateMemberTags")
                FormSectionTagSelection(
                    class="sz-ModalEditBulkUser-sectionSettings-input-select",
                    :options="tagOptions",
                    :selectAll="true"
                    :title="$t(`management.companyDashboard.userTable.headers.viewertags`)",
                    @updateSelectedTags="updateViewerTags")
        ListSelectedUser(
            :users="selectedUsers",
            @updateUsers="updateUsers")
        div(class="sz-ModalEditBulkUser-footer")
            div(
                @click="cancelEdit",
                class="sz-ModalEditBulkUser-footer-button \
                    sz-ModalEditBulkUser-footer-cancel") {{ $t(`modals.modalEditWidget.cancel`)}}
            div(
                @click="submit"
                type="submit",
                class="sz-ModalEditBulkUser-footer-button \
                    sz-ModalEditBulkUser-footer-save") {{ $t(`modals.modalEditWidget.save`)}}
</template>
<script>
import { mapGetters } from 'vuex'
import { userAssignableRoles } from 'helpers/permissionsHelper'
import { cloneDeep, xor, difference } from 'lodash'
import { jobKeysAndValues } from 'helpers/socDirectMatchTitleFile'
import ListSelectedUser from './ModalEditUser/ListSelectedUser'
import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
import constants from 'helpers/constants'
import moment from 'moment-timezone'
import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
import DropdownMultiSelect from 'components/Shared/DropdownMultiSelect'
import constructModalSettings from 'mixins/modalSettings'
import LoadingSpinner from '../Shared/LoadingSpinner'
import EventBus from 'src/eventBus'
import formValidator from 'mixins/formValidator'
import { timezones } from 'helpers/timezones'

export default {
    name: 'ModalEditBulkUser',

    components: {
        ListSelectedUser,
        FormSectionDropdownSingleSelect,
        FormSectionSingleSelectSearchable,
        FormSectionTagSelection,
        DropdownMultiSelect,
        LoadingSpinner,
    },


    mixins: [
        constructModalSettings,
        formValidator,
    ],

    props: {
        users: {
            required: true,
            type: Array,
        },

        option: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            selectedUsers: [],
            newSettings: {},
            showConfirmation: true,
            selectEditType: constants.COMPANY_SETTINGS.EDIT.TAGS.ASSIGN,
            tagMessages: {}, 
        }
    },

    computed: {
        ...mapGetters([
            'allCompanyTags',
            'jobs',
            'auth0User',
            'updatingUser',
            'updateUserStatus',
        ]),

        tags () {
            return this.option === constants.USER_CHARACTERISTICS.TAGS
        },

        job () {
            return this.option === constants.USER_CHARACTERISTICS.JOB
        },

        timezone () {
            return this.option === constants.USER_CHARACTERISTICS.TIME_ZONE
        },

        jobOptions () {
            return Object.values(jobKeysAndValues)
        },

        timezoneOptions () {
            return timezones
        },

        tagOptions () {
            return this.allCompanyTags.map(tag => tag.name)
        },

        updateSucceeded () {
            return (this.updateUserStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS) && this.settingsHaveChanged
        },


        isUserConfirmed() {
            return this.showConfirmation === false
        }, 

        isSelectAssign() {
            return this.selectEditType === constants.COMPANY_SETTINGS.EDIT.TAGS.ASSIGN
        },

        isSelectRemove() {
            return this.selectEditType === constants.COMPANY_SETTINGS.EDIT.TAGS.REMOVE
        },

        settingsHaveChanged () {
            return (typeof this.newSettings.timezone === 'string') ||
                (typeof this.newSettings.job === 'string') ||
                (Array.isArray(this.newSettings.membertags)) ||
                (Array.isArray(this.newSettings.viewertags))
        },
    },

    watch: {
        showConfirmation: {
            //watches if submit is confirmed by user & calls submit again 
            handler: function () {
                if (this.isUserConfirmed) {
                    this.submit()
                }
            },
            deep: true,
        },
    },

    mounted () {
        EventBus.$on('USER_CONFIRMED', () => {
            this.showConfirmation = false
        })

        this.selectedUsers = this.users


        EventBus.$on('USER_CANCELLED', () => {
            this.showConfirmation = true
        })
    },

    methods: {
        cancelEdit () {
            this.$emit('close')
        },

        updateViewerTags (tags) {
            this.tagMessages.viewertags = tags.join(', ')
            this.newSettings.viewertags = this.tagNameToID(tags)
        },

        updateMemberTags (tags) {
            this.tagMessages.membertags = tags.join(', ')
            this.newSettings.membertags = this.tagNameToID(tags)
        },

        updateSelectedTimezone (timezone) {
           this.newSettings.timezone = timezone
        },

        updateJobType (job) {
            this.newSettings.job = job
        },

        updateUsers (userID) {
            this.selectedUsers = this.selectedUsers.filter(user => user.userID !== userID)
            EventBus.$emit('UPDATE_SELECTED_USERS', userID)
        },

        assignSelected () {
            this.selectEditType = constants.COMPANY_SETTINGS.EDIT.TAGS.ASSIGN
        }, 

        removeSelected () {
            this.selectEditType = constants.COMPANY_SETTINGS.EDIT.TAGS.REMOVE
        },

        getNewTags (newTags,userTags) {
            //this function returns the newTags IDs depending on the tagOption. 
            //If assign, any duplicate tags are removed and then the new tags are added onto the pre-existing tags
            //If remove, the removed tags are removed from user tags and returned
            if (this.selectEditType === constants.COMPANY_SETTINGS.EDIT.TAGS.ASSIGN) {
                const addedTags = difference(newTags, userTags) 
                return userTags.concat(addedTags)
            } else {
                const removedTags = userTags
                    .filter( tag => !newTags 
                        .includes(tag))
                return removedTags
            }
        },

        submit () {
            if (this.settingsHaveChanged) {
                this.showConfirmation 
                    ? this.confirmModal(
                        this.confirmMessage().title, 
                        this.confirmMessage().message, 
                        this.confirmMessage().list) 
                    : this.saveUpdate()
            } 
        },


        async saveUpdate () {
            await this.$store.dispatch('bulkEditUserToCompany', this.getEditedUsers())
            this.updateSucceeded 
                ? this.successModal(
                    this.$t('management.companyDashboard.updateMessage.checkLaterMessage'), 
                    true, 
                    () => this.$store.dispatch('getAllUsersInCompany'), 
                    () => this.$store.dispatch('closeAllModals'),
                    this.$t('management.companyDashboard.updateMessage.checkLaterTitle'))
                : this.failModal(
                    this.$t('pleaseTryAgain'), 
                    this.$t('somethingWentWrong'),  
                    () => this.showConfirmationModal = true)
        },

        getEditedUsers () {
            const editedUsers = cloneDeep(this.selectedUsers)

            editedUsers.map(user => {
                //Map old tags back to ids 
                user[constants.USER_CHARACTERISTICS.MEMBER] = this.tagNameToID(user.membertags)
                user[constants.USER_CHARACTERISTICS.VIEWER] = this.tagNameToID(user.viewertags)

                if (this.tags) {

                    //Members tags changed
                    if (Array.isArray(this.newSettings.membertags) && this.newSettings.membertags.length ) {
                        const newTags = this.getNewTags(this.newSettings[constants.USER_CHARACTERISTICS.MEMBER], user[constants.USER_CHARACTERISTICS.MEMBER])
                        user[constants.USER_CHARACTERISTICS.MEMBER] = newTags
                    }

                    //Viewer tags changed 
                    if (Array.isArray(this.newSettings.viewertags) && this.newSettings.viewertags.length ) {
                        const newTags = this.getNewTags(this.newSettings[constants.USER_CHARACTERISTICS.VIEWER], user[constants.USER_CHARACTERISTICS.VIEWER])
                        user[constants.USER_CHARACTERISTICS.VIEWER] = newTags
                    }

                } else {
                    user[this.option] = this.newSettings[this.option]
                }
            })
            return editedUsers
        },

        getAssignList () {
            return this.tags 
                ? this.formatTagMessage()
                : [this.newSettings[this.option]]
        }, 

        getRemoveList () {
            return this.tags 
                ? this.formatTagMessage()
                : []
        },

        formatTagMessage () {
            return [
                constants.USER_CHARACTERISTICS.MEMBER.toUpperCase(), 
                this.tagMessages.membertags,
                '\0', //line break
                constants.USER_CHARACTERISTICS.VIEWER.toUpperCase(), 
                this.tagMessages.viewertags]
        },

        confirmMessage () {
            return this.isSelectAssign 
            ? {   
                title: this.$t(`management.companyDashboard.updateMessage.assignConfirm`, {option: this.option}),
                message: this.$t('management.companyDashboard.updateMessage.assignMsg'),
                list: this.getAssignList(),
            } 
            : {
                title: this.$t(`management.companyDashboard.updateMessage.removeConfirm`, {option: this.option}),
                message: this.$t('management.companyDashboard.updateMessage.removeMsg'),
                list: this.getRemoveList(), 
            }
        }, 
    },
}
</script>
<style lang="scss" scoped>
@import '~design';
    .sz-ModalEditBulkUser {
        width: 50vw;
        min-height: 51vh;
        max-height: 94vh;  
        color: $color-white;
        overflow-y: auto; 
        overflow-x: hidden;
        @include scrollbar-widget;
        padding-right: 0.5rem;
        &-edit {
            margin-bottom: 1rem;
        }
        &-sectionSettings {
            color: $color-white;
            margin-bottom: 1rem;
            font-size: 12px;
            &-label {
                font-size: 15px;
            }
            &-input{
                &-select {
                    margin-bottom: 1rem;
                }
            }
        }

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-table-row-silver;
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-editType {
            @extend %font-heading;
            display: flex;
            margin-bottom: 1rem;
            font-size: 12px;

            &-label {
                margin-right: 1rem;
            }

            &-button {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                align-self: center;
                &-checked {
                    background-color: $color-box-silver;
                }
            }
        }
        
        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
                font-size: 12px;
            }
            &-save {
                @extend %button-layout;
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1rem;
                border-radius: 0;
            }
        }
        
    }
</style> 
import constants from 'helpers/constants'
import WidgetsService from "services/widgets.service"
import EventBus from 'src/eventBus'

const senzDashboard = {
    state: {
        widgetsSettings: [],
        settingsLoaded: false,
        sliderOpen: true,
    },

    mutations: {
        toggleSlider(state, payload) {
            state.sliderOpen = payload
        },

        setWidgetsSettings(state, payload) {
            state.widgetsSettings = payload
        },

        setSettingsLoadedStatus(state, payload) {
            state.settingsLoaded = payload
        },

        updateWidgetSettings(state, { newSettings, id }) {
            let newSettingsList = state.widgetsSettings.filter(setting => setting.id !== id)
            newSettingsList.push(newSettings)
            state.widgetsSettings = newSettingsList
        },

        delete(state, widgetId) {
            // find the widget in the state with the given order of the widget to be deleted, then remove it form the list.
            let newSettings = state.widgetsSettings.filter(widget => widget.id !== widgetId)
            state.widgetsSettings = newSettings
        },
    },

    getters: {
        sliderOpen: (state) => {
            return state.sliderOpen
        },

        widgetsSettings: (state) => {
            return state.widgetsSettings
        },

        nextWidgetOrder: (state) => {
            // first sorts the widgets in the state by their order as returned by the API if there are any widgets to sort
            if (state.widgetsSettings.length) {
                let sortedWidgets = state.widgetsSettings.sort((a, b) => {
                    return a.order - b.order
                })
                // then gets the last widget in the sorted widgets, gets that widget's number in that order, then adds one
                // so that the getter returns the next number in the widgets order.
                return sortedWidgets[sortedWidgets.length - 1].order + 1
            }
            return 1

        },
        widgetSettingsLoaded: (state) => {
            return state.settingsLoaded
        },
    },
    actions: {
        updateSlider({ commit }, payload) {
            commit('toggleSlider', payload)
        },

        async saveWidget({ commit, rootGetters }, newSettings) {
            try {
                await WidgetsService.patchWidget(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.userID,
                    newSettings.id,
                    newSettings)
                commit('updateWidgetSettings', {
                    newSettings: newSettings,
                    id: newSettings.id,
                })
                // Make a POST request to the server to update the settings list or maybe just push the one setting.
            } catch (e) {
                throw new Error(e.message)
            }
        },

        async newWidget({ commit, rootGetters }, newSettings) {
            try {
                let newWidget = await WidgetsService.postNewWidget(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.userID,
                    newSettings)
                newSettings.id = newWidget.id
                commit('updateWidgetSettings', { newSettings: newSettings, order: newSettings.order })
                EventBus.$emit('ADD_MUTABLE_WIDGET_SETTINGS', {
                    widgetId: newSettings.id,
                })
            } catch (e) {
                throw new Error(e.message)
            }
        },

        async updateWidgetsSettings({ commit, state, rootGetters }) {
            if (!state.widgetsSettings.length) {
                commit('setSettingsLoadedStatus', false)
            }
            let settings = []

            try {
                settings = await WidgetsService.getAllWidgetSettings(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.userID)
            } catch (e) {
                // TODO: error handling for widget data failure
                console.log(e)
            } finally {
                commit('setWidgetsSettings', settings)
                commit('setSettingsLoadedStatus', true)
            }
        },

        async deleteWidget({ commit, rootGetters }, widget) {
            //make post request to delete this widget, then commit the mutation.
            try {
                await WidgetsService.deleteWidget(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.userID,
                    widget)
                commit('delete', widget)
            } catch (e) {
                console.log('couldnt save widget for some reason')
                throw new Error('failed widget update')
            }
        },

        async updateWidgetOrder({ commit, rootGetters }, widgetIdOrderList) {
            //make post request to update widget order, then commit the widget settings with new ordering
            try {
                await WidgetsService.updateWidgetOrder(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    rootGetters.userID,
                    widgetIdOrderList)
            } catch (e) {
                console.log('Failed to save widget order for some reason')
                throw new Error('failed widget order update')
            }
        },
    },
}

export default senzDashboard

<template lang="pug">
    div(class="sz-ListSelectedAssessments")
        div(class="sz-ListSelectedAssessments-label") {{ $t(`management.assessmentDashboard.assignAssessmentTags.editModal.selectedAssessments`) }}
        div(class="sz-ListSelectedAssessments-list")
            div(class="sz-ListSelectedAssessments-list-headers")
                div(class="sz-ListSelectedAssessments-list-header") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.headers.firstName`) }}
                div(class="sz-ListSelectedAssessments-list-header") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.headers.lastName`) }}
                div(class="sz-ListSelectedAssessments-list-header") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.headers.start`) }}
                div(class="sz-ListSelectedAssessments-list-header") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.headers.end`) }}
            div(
                class="sz-ListSelectedAssessments-list-assessment",
                v-for="assessment in assessments")
                    div(class="sz-ListSelectedAssessments-list-assessment-info") {{ assessment.firstName }}
                    div(class="sz-ListSelectedAssessments-list-assessment-info") {{ assessment.lastName }}
                    div(class="sz-ListSelectedAssessments-list-assessment-info") {{ assessment.start }}
                    div(class="sz-ListSelectedAssessments-list-assessment-info") {{ assessment.end }}
                    div(
                        @click.stop="removeAssessment(assessment.assessmentMetadataID)"
                        class="sz-ListSelectedAssessments-list-user-remove")
                        SvgIcon(
                            icon="plus",
                            width=16,
                            height=16,
                            class="sz-ListSelectedAssessments-list-assessment-remove-icon")
</template>

<script>
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: "ListSelectedAssessments",

        components: {
            SvgIcon,
        },

        props: {
            assessments: {
                required: true,
                type: Array,
            },
        },

        methods: {
            removeAssessment(assessmentMetadataID) {
                this.$emit('updateAssessments', assessmentMetadataID)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ListSelectedAssessments {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        &-label {
            @extend %font-topbar-heading;
            text-align: left;
            margin-bottom: 0.5rem;
        }

        &-list {
            width: 100%;
            overflow: scroll;
            @include scrollbar-widget;
            min-width: 30vw;
            max-height: 25vh;

            &-headers {
                grid-template-columns:
                    minmax(6rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    1rem;
                display: grid;
                text-align: left;
                margin-bottom: 0.25rem;
                grid-gap: 0.5rem;
            }

            &-assessment {
                @extend %font-content;
                color: $color-unselected-text;
                display: grid;
                grid-template-columns:
                    minmax(6rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    minmax(3rem, 1fr)
                    1rem;
                text-align: left;
                grid-gap: 0.5rem;
                margin-bottom: 0.5rem;
                padding-right: 0.25rem;

                &-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &-remove-icon {
                    transform: rotate(45deg);
                    color: $color-risk-red;
                    cursor: pointer;
                }
            }
        }
    }
</style>

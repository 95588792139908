/*
Tooltip Directive
---------------------------------
Add this to an element to give it a tooltip on hover.

This directive requires some parameters to render the tooltip.
Default values that are defined below are used to fill in missing parameters.

The tooltip object can have many parameters. It can take a number of text inputs, but it can also take
a component name as a parameter. This allows for more complicated tooltips.

Usage: v-tooltip="tooltipObject"

Possible Object: {
  ** Specify look and message of the tooltip body **
  ** props for TooltipBody are passed from here**
  tooltipBodySettings: {
    Go to TooltipBody.vue to see values
  },
** Tether attributes for the body **
body: {
    placement: placement relative to the element,
    offset: 'horizontal-offset, vertical-offset',
}


  allowHover: 'Will allow the user to hover within the tooltip',
  disabled: 'Disable the tooltip from appearing for changing components.',
}
NOTE: Not all values are needed. The default values are located below.
WARNING: An id MUST be created for an element with a tooltip directive.
*/

import Popper from 'popper.js'
import TooltipBody from 'components/Tooltip/TooltipBody'
import Vue from 'vue'
import constants from 'helpers/constants'
import { get, isEqual } from 'lodash'

let popperHash = {}
const defaultAttachment = 'bottom-start'
const defaultBodyOffset = '0, 5'
let tooltipTimer = null
let hoveringOverTooltip = false

/*
Directive
------------------
Directive has 5 possible hooks
1. Bind: Called when the directive is first bound to the element
2. Inserted: Called when the component or element is inserted into it’s parent node. It may not be in the DOM yet.
3. Update: Called when the containing component has updated but potentially before its children do.
4. Component Updated: Called when the containing component has updated but after its children do.
5. Unbind: Called when the directive is unbound from the component or element.
------------------
All hooks take in at minimum of 3 parameters
1. el: The element the directive is bound to. May be undefined.
2. binding: Contains any arguments, values, and modifiers passed to the directive.
3. vnode: This is a virtual node used by Vue’s renderer. Don’t touch this unless you know what you’re doing.
4. oldVnode: The previous version of the above vnode. Only available in the update hooks.
*/

function delayRemoveTether () {
    tooltipTimer = setTimeout(() => {
        if (!hoveringOverTooltip) {
            removeTether()
        }
    }, constants.TETHER_TIMEOUT)
}


function removeTether () {
    if (hoveringOverTooltip) {
        hoveringOverTooltip = false
    }

    if (tooltipTimer) {
        clearTimeout(tooltipTimer)
    }

    const tooltip = document.getElementById('defaultTooltip')
    const content = document.querySelector('.sz-container')

    if (tooltip && tooltip.parentNode === content)  {
        content.removeChild(tooltip)
    }
}

function setHoveringInTooltip () {
    hoveringOverTooltip = true
}

function appendTooltipComponents (value) {
    const BodyConstructor = Vue.extend(TooltipBody)
    const constructorObject = {
        propsData: value || {},
    }

    const vm = new BodyConstructor(constructorObject).$mount()
    document.querySelector('.sz-container').appendChild(vm.$el)

    if (value.allowHover) {
        vm.$el.addEventListener('mouseenter', setHoveringInTooltip, false)
        vm.$el.addEventListener('mouseleave', removeTether, false)
    }
}

function elementHasPopperInstance (elementId) {
    return get(popperHash, elementId, false)
        && get(popperHash[elementId], 'body', false)
}

function tether (el, value) {
    if (!el || !value || value.disabled) {
        // if the element you've added this directive to doesn't exist, doesn't have associated settings,
        // or the settings have the tooltip disabled, do nothing.
        return
    }

    const tooltipValue = {
        tooltipBodySettings: {
            view: [
                'light',
                'left',
                'regular-padding',
            ],
            indexClass: 'tooltipIndex',
        },
    }

    removeTether()

    appendTooltipComponents(value)

    const tooltipElement = document.getElementById('defaultTooltip')
    const bodyTarget = el
    const bodyPopper = new Popper(
        bodyTarget,
        tooltipElement,
        {
            placement: get(value, 'body.placement', defaultAttachment),
            modifiers: {
                offset: {
                    enabled: true,
                    offset: get(value, 'body.offset', defaultBodyOffset),
                },
            },
        })
    bodyPopper.scheduleUpdate()

    if (elementHasPopperInstance(el.id)) {
        popperHash[el.id]['body'].destroy()
        popperHash[el.id]['body'] = bodyPopper
    }
}

function bind (el, binding) {
    unbind(el, binding)

    if (!popperHash[el.id]) {
        popperHash[el.id] = {
            element: tether.bind(this, el, binding.value),
        }
    }

    el.addEventListener('mouseenter', popperHash[el.id]['element'], false)
    if (binding.value && binding.value.allowHover) {
        el.addEventListener('mouseleave', delayRemoveTether, false)
    } else {
        el.addEventListener('mouseleave', removeTether, false)
    }
}

function unbind (el, binding) {
    removeTether()

    if (popperHash[el.id]) {
        if (elementHasPopperInstance(el.id)) {
            popperHash[el.id]['body'].destroy()
        }

        el.removeEventListener('mouseenter', popperHash[el.id]['element'], false)
        el.removeEventListener('mouseleave', removeTether, false)
        el.removeEventListener('mouseleave', delayRemoveTether, false)
    }

    delete popperHash[el.id]
}

export const tooltip = {
    bind,
    update (el, binding) {
        if (isEqual(binding.value, binding.oldValue)) return
        bind(el, binding)
    },
    unbind,
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'
import { getInstance } from 'auth/index'
import ApiService from 'services/api.service'
import { userHasAnyRole, userHomePage } from 'helpers/permissionsHelper'
import constants from '../helpers/constants'
import i18n from 'src/i18n'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
})

router.beforeEach((to, _from, next) => {
  let pageTitle = i18n.messages.en.pageTitles[to.name.toString()]

  // The way the route names are set up for the assessment/management pages are not useful for tracking.
  // We have to manually determine which 'sub-page' we're currently in because
  // each page is assigned a generic name with no other context.
  // Refer to the routes in routes.js assigned the name of constants.PAGES[...]
  if (to.name === constants.PAGES.ASSESSMENT.MODULE) {
    pageTitle = i18n.messages.en.pageTitles.assessment[to.params.assessmentType]
  }

  if (to.name === constants.PAGES.MANAGEMENT) {
    pageTitle = i18n.messages.en.pageTitles[to.params.currentSettingsPage]
  }

  // Change the tab title to the route name
  document.title = pageTitle

  next()
})

const authorizedUserRouteGuard = (to, from, next) => {
  const authService = getInstance()

  const checkAuthenticationAndPermissions = async (to, from, next) => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const token = await authService.getTokenSilently()
      ApiService.setAuthHeader(token)
      if (!checkPermissions(to, authService.user)) {
        return next({ name: '404' })
      }

      return next()
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  const checkPermissions = (to, user) => {
    const roleRequired = to.matched.find((record) => {
      return record.meta.roleRequired && record.meta.roleRequired.required
    })

    if (roleRequired) {
      return userHasAnyRole(user, to.meta.roleRequired.roles)
    }

    return true
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return checkAuthenticationAndPermissions(to, from, next)
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return checkAuthenticationAndPermissions(to, from, next)
    }
  })
}

// Before each route evaluates...
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    authorizedUserRouteGuard(to, from, next)
  } else {
    next() // make sure to always call next()!
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
})

export default router

<template>
  <div class="sz-CaptureDetails">
    <div class="title">
      {{ $t('captureDetails.title') }}
    </div>
    <div v-if="isLoading">
      <LoadingSpinner color="light" />
    </div>
    <div v-else class="sz-CaptureDetails-assessmentInfo">
      <div class="sz-CaptureDetails-assessmentInfo-row">
        {{ `${$t('captureDetails.captureID')}: ${captureID} (${status})` }}
      </div>
      <div class="sz-CaptureDetails-assessmentInfo-row">
        {{ `${$t('captureDetails.sensorSetID')}: ${setID}` }}
      </div>
      <div class="sz-CaptureDetails-assessmentInfo-row">
        {{ `${$t('captureDetails.assessmentStartTime')}: ${startTime}` }}
      </div>
    </div>
    <div class="sz-CaptureDetails-wrapper">
      <table class="sz-CaptureDetails-table">
        <thead>
          <tr class="sz-CaptureDetails-headers">
            <th v-for="header of headers" :key="header" scope="col">
              <div class="sz-CaptureDetails-headers-header-content">
                <span>
                  {{ $t(`captureDetails.captureDetailsTable.headers.${header}`) }}
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="rowName of rowNames"
            :key="rowName"
            :class="`sz-CaptureDetails-row`"
            scope="row"
          >
            <th>
              <div>
                <span>
                  {{ $t(`captureDetails.captureDetailsTable.rowNames.${rowName}`) }}
                </span>
              </div>
            </th>
            <td
              v-for="device of formatDevices"
              :key="device.deviceID"
              v-truncated-tooltip=""
              :class="`sz-CaptureDetails-row-item`"
            >
              {{ isNullOrUndefined(device[rowName]) ? 'Unknown' : device[rowName] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      <div class="footer__buttons footer__buttons-close" @click="close">
        {{ $t('captureDetails.close') }}
      </div>
      <div
        v-if="!requestedDownload && userHasLBAssessorRole"
        class="footer__buttons footer__buttons-download-all-files"
        @click="downloadRecordingLogs"
      >
        {{ $t('captureDetails.downloadAllFiles') }}
      </div>
      <div
        v-else-if="userHasLBAssessorRole"
        class="footer__buttons footer__buttons-download-all-files"
      >
        {{ $t('captureDetails.downloading') }}
      </div>
      <div :class="`footer__buttons footer__buttons-process-capture`" @click="processCapture">
        {{ $t('captureDetails.reprocessCapture') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import constants from 'helpers/constants'
import constructModalSettings from 'mixins/modalSettings'
import { TruncatedTooltip } from 'directives/truncatedTooltip'
import { mapGetters } from 'vuex'
import formValidator from 'mixins/formValidator'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import EventBus from 'src/eventBus'

export default {
  name: 'ModalCaptureDetails',

  components: {
    LoadingSpinner,
  },

  directives: {
    TruncatedTooltip,
  },

  mixins: [constructModalSettings, formValidator],

  props: {
    customerID: {
      type: String,
      required: true,
    },
    companyID: {
      type: String,
      required: true,
    },
    userID: {
      type: String,
      required: true,
    },
    captureID: {
      type: String,
      required: true,
    },
    setID: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      requestedDownload: false,
      isLoading: null,
      headers: constants.CAPTURE_DETAILS_HEADERS,
      rowNames: constants.CAPTURE_DETAILS_ROWS,
    }
  },

  computed: {
    ...mapGetters(['userHasLBAssessorRole']),

    formatDevices() {
      let Unknown = this.$t('captureDetails.captureDetailsTable.unknown')
      let devices = this.devices

      devices.forEach((device) => {
        // For the current recording
        if (
          device.currentRecording !== undefined &&
          device.currentRecording.statistics !== undefined
        ) {
          let cStats = device.currentRecording.statistics
          device.fileSize = this.isNullOrUndefined(cStats.fileSize)
            ? Unknown
            : parseFloat(cStats.fileSize).toFixed(2)
          device.recordingStartTime = this.isNullOrUndefined(cStats.recordingStartTime)
            ? Unknown
            : this.unixToDate(cStats.recordingStartTime)
          device.uploadEndTime = this.isNullOrUndefined(cStats.uploadEndTime)
            ? Unknown
            : this.unixToDate(cStats.uploadEndTime)
          device.firmwareVersion = this.isNullOrUndefined(cStats.firmwareVersion)
            ? Unknown
            : cStats.firmwareVersion
          device.firstBatteryLevel = this.isNullOrUndefined(cStats.firstBatteryLevel)
            ? Unknown
            : cStats.firstBatteryLevel
          device.firstBatteryLevelTime = this.isNullOrUndefined(cStats.firstBatteryLevelTime)
            ? Unknown
            : this.unixToDate(cStats.firstBatteryLevelTime)
          device.lastBatteryLevel = this.isNullOrUndefined(cStats.lastBatteryLevel)
            ? Unknown
            : cStats.lastBatteryLevel
          device.lastBatteryLevelTime = this.isNullOrUndefined(cStats.lastBatteryLevelTime)
            ? Unknown
            : this.unixToDate(cStats.lastBatteryLevelTime)
          device.cradleRemovalTime = this.isNullOrUndefined(cStats.cradleRemovalTime)
            ? Unknown
            : this.unixToDate(cStats.cradleRemovalTime)
          device.cradleInsertionTime = this.isNullOrUndefined(cStats.cradleInsertionTime)
            ? Unknown
            : this.unixToDate(cStats.cradleInsertionTime)
          device.minHumidity = this.isNullOrUndefined(cStats.minHumidity)
            ? Unknown
            : cStats.minHumidity
          device.minHumidityTime = this.isNullOrUndefined(cStats.minHumidityTime)
            ? Unknown
            : this.unixToDate(cStats.minHumidityTime)
          device.maxHumidity = this.isNullOrUndefined(cStats.maxHumidity)
            ? Unknown
            : cStats.maxHumidity
          device.maxHumidityTime = this.isNullOrUndefined(cStats.maxHumidityTime)
            ? Unknown
            : this.unixToDate(cStats.maxHumidityTime)
          device.minTemperature = this.isNullOrUndefined(cStats.minTemperature)
            ? Unknown
            : cStats.minTemperature.toFixed(2)
          device.minTemperatureTime = this.isNullOrUndefined(cStats.minTemperatureTime)
            ? Unknown
            : this.unixToDate(cStats.minTemperatureTime)
          device.maxTemperature = this.isNullOrUndefined(cStats.maxTemperature)
            ? Unknown
            : cStats.maxTemperature.toFixed(2)
          device.maxTemperatureTime = this.isNullOrUndefined(cStats.maxTemperatureTime)
            ? Unknown
            : this.unixToDate(cStats.maxTemperatureTime)
          device.numberOfKeyMessages = this.isNullOrUndefined(cStats.keyMessageCount)
            ? Unknown
            : cStats.keyMessageCount
          device.numberOfGaps = this.isNullOrUndefined(cStats.numberOfGaps)
            ? Unknown
            : cStats.numberOfGaps
          device.lastQuatTime = this.isNullOrUndefined(cStats.lastQuatTime)
            ? Unknown
            : this.unixToDate(cStats.lastQuatTime)
        }

        // For the previous recording
        if (
          device.previousRecording !== undefined &&
          device.previousRecording.statistics !== undefined
        ) {
          let pStats = device.previousRecording.statistics
          device.previousRecordingFileSize = this.isNullOrUndefined(pStats.fileSize)
            ? Unknown
            : parseFloat(pStats.fileSize).toFixed(2)
          device.previousRecordingStartTime = this.isNullOrUndefined(pStats.recordingStartTime)
            ? Unknown
            : this.unixToDate(pStats.recordingStartTime)
          device.previousRecordingUploadEndTime = this.isNullOrUndefined(pStats.uploadEndTime)
            ? Unknown
            : this.unixToDate(pStats.uploadEndTime)
        }

        device.deviceID = device.device.deviceID
        device.position = device.device.position
      })

      return this.sortedDevices
    },

    /**
     * Sorts devices by position in a fixed order, which is a requirement of this modal
     * This function assumes there is only 1 device for a given position. If multiple devices
     * exist for a position, the first one that find() finds will be selected.
     *
     * @returns List of devices sorted by position in the desired order
     */
    sortedDevices() {
      let desiredOrder = ['PEL', 'THX',  'RFA', 'RUA', 'LFA','LUA']
      let sortedDevices = []

      desiredOrder.forEach((position) => {
        let foundDevice = this.devices.find((device) => device.device.position === position)

        if (foundDevice) {
          sortedDevices.push(foundDevice)
        }
      })

      return sortedDevices
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async processCapture() {
      try {
        this.isLoading = true
        await this.$store.dispatch('processCapture', {
          customerID: this.customerID,
          companyID: this.companyID,
          userID: this.userID,
          captureID: this.captureID,
          retryAttempts: 0,
        })

        EventBus.$emit('captureProcessed')
        this.isLoading = false
      } catch (error) {
        await this.failModal(
          this.$t(`captureDetails.processingError`),
          this.$t('somethingWentWrong'),
          () => (this.showConfirmation = true)
        )
        this.isLoading = false
      }
    },

    async downloadRecordingLogs() {
      if (!this.requestedDownload) {
        try {
          this.requestedDownload = true

          let response = await this.$store.dispatch('downloadRecordingLogs', {
            customerID: this.customerID,
            companyID: this.companyID,
            userID: this.userID,
            captureID: this.captureID,
          })

          let blob = new Blob([response.data], { type: 'application/octet-stream' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.captureID}_device_logs.zip`
          link.target = '_blank'
          link.setAttribute('type', 'hidden')

          document.body.appendChild(link)

          link.click()
          link.remove()
        } catch (err) {
          await this.failModal(
            this.$t('pleaseTryAgain'),
            this.$t('somethingWentWrong'),
            () => (this.showConfirmation = true)
          )
        } finally {
          this.requestedDownload = false
        }
      }
    },

    /**
     * Checks if the property is a valid value
     * @params  Property to check
     * @return  True if the property is undefined or null, false otherwise
     */
    isNullOrUndefined(property) {
      if (property === undefined || property === null) {
        return true
      }
      return false
    },

    unixToDate(unix) {
      return moment.tz(unix, this.timezone).format('LL LTS')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

table,
tr,
td {
  border: none;
  position: relative;
}

td,
th {
  padding: 0.5rem;
}

th {
  color: $color-table-row-hover-text;
  text-align: left;
}

td {
  height: 1.5rem;
  vertical-align: middle;
  text-align: center;
}

thead {
  display: table-header-group;
  vertical-align: left;
  border-color: inherit;

  tr {
    position: sticky;
    top: 0;
    z-index: 2;
    background: $color-box-background;
  }
}

tbody {
  display: table-row-group;
  overflow: auto;
}

table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  flex-grow: 1;
  background-color: $color-box-background;
  color: $color-unselected-text;
}

.sz-CaptureDetails {
  width: auto;
  display: flex;
  flex-direction: column;
  color: $color-white;
  padding: 1.75rem 2.5rem 1.75rem 2.5rem;

  &-wrapper {
    width: inherit;
    max-height: 60vh;
    overflow: scroll;
    @include scrollbar-widget;
    background-color: $color-box-background;
    margin-top: 1rem;
  }

  &-assessmentInfo {
    font-size: 1rem;
    &-row {
      text-align: left;
      margin: 0.15rem;
    }
  }

  &-headers {
    &-header {
      &-content {
        font-size: 0.85rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-row {
    font-size: 0.75rem;
    &-item {
      text-align: left;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__buttons {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  &__buttons-close {
    background-color: $color-base-grey;
  }

  &__buttons-download-all-files {
    background-color: $color-lifebooster-light-green;
  }

  &__buttons-process-capture {
    background-color: $color-lifebooster-light-green;
  }
}
</style>

import ApiService from './api.service'

const MeService = {
  getProfile: async function (customerID, companyID) {
    const url = `/customers/${customerID}/companies/${companyID}/users/me`
    try {
      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },

  updateUserVisibility: async function (customerID, companyID, userID, newSettings) {
    const url = `/customers/${customerID}/companies/${companyID}/users/${userID}/visible`
    try {
      const response = await ApiService.post(url, newSettings)
      return response.data
    } catch (error) {
      throw new Error(error.response)
    }
  },
}

export default MeService

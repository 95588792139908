<template lang="pug">
    div(class="sz-Error")
        div(class="sz-Error-body")
            img(
                v-bind:src="logo",
                class="sz-Error-image",
                @click.stop="goHome")
            div(class="sz-Error-text") {{ $t(`errorPage.message`) }}
</template>


<script>
    import constants from 'helpers/constants'
    import { userCanAccessPage } from 'helpers/permissionsHelper'
    import { mapGetters } from 'vuex'
    import { userHomePage } from 'helpers/permissionsHelper'

    export default {
        name: "Error",

        data () {
            return {
                logo: require("../../assets/images/LifeBoosterLogoWhiteText.png"),
            }
        },

        computed: {
            ...mapGetters([
                'workerId',
                'auth0User',
            ]),
        },

        methods: {
            goHome() {
                let homePage = userHomePage(this.auth0User)
                if (this.$router.currentRoute.name !== homePage.name) {
                    return this.$router.push(homePage)
                }
                if (this.$router.currentRoute.name === constants.PAGES.MANAGEMENT) {
                    return homePage.params.currentSettingsPage !== this.$router.currentRoute.params.currentSettingsPage
                        ? this.$router.push(homePage)
                        : null
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-Error {
        display: flex;
        justify-content: center;

        &-body {
            padding-top: 10rem;
        }

        &-image {
            cursor: pointer;
        }

        &-text {
            @extend %font-content;
            color: $color-white;
            padding-top: 0.5rem;
            font-size: 25px;
        }
    }
</style>

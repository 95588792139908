<template lang="pug">
  div(
    class="sz-container",
    id="app")
    RouterView(key="$route.fullPath")
    div(v-if="isModalOpen")
      Modal(
        v-for="modal in openModals",
        :key="modal.key",
        :modal="modal")
</template>
<script>
    import { mapGetters } from 'vuex'
    import Modal from 'components/Modal/Modal'

    export default {
        page: {
            titleTemplate () {
                // document.title is set on the router level
                // See src/router/index.js
                return document.title
            },
        },

        components: {
            Modal,
        },

        computed: {
            ...mapGetters([
                'isModalOpen',
                'openModals',
                'widgetIdOrderList',
            ]),
        },
    }
</script>
<style lang="scss">
    @import '~design';

    body {
        background-color: $color-body-bg;
        overflow-y: scroll;
        @include scrollbar-widget;
    }

    #app {
        font-family: $system-default-font-family, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: white;
        height: 97vh;
        width: 98.5vw;
    }
</style>

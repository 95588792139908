import constants from 'helpers/constants'
import { intersection } from 'lodash'

export function userIsAdmin(user) {
  const roles = getRoles(user)
  return !!intersection(constants.ADMIN_ROLES, roles).length
}

export function userIsAssessor(user) {
  const roles = getRoles(user)
  return !!intersection(constants.ASSESSOR_ROLES, roles).length
}

export function userIsLBAssessor(user) {
  return userHasRole(user, constants.ROLES.LB_ASSESSOR)
}

export function userIsLBAdmin(user) {
  return userHasRole(user, constants.ROLES.LB_ADMIN)
}

export function userIsWorkerOrBaseWorker(user) {
  return userHasAnyRole(user, [constants.ROLES.WORKER, constants.ROLES.BASE_WORKER])
}

export function getRoles(user) {
  return user[constants.AUTH0_USER_INFO_KEYS.ROLE]
}

export function highestRole(user) {
  const roles = getRoles(user)
  const roleNumbers = roles.map((role) => constants.DASHBOARD_PERMISSIONS_ENUM[role])
  const highestPermissions = Math.max(...roleNumbers)

  return Object.keys(constants.DASHBOARD_PERMISSIONS_ENUM).find(
    (key) => constants.DASHBOARD_PERMISSIONS_ENUM[key] === highestPermissions
  )
}

export function userHasRole(user, role) {
  const userRoles = getRoles(user)
  return userRoles.includes(role)
}

export function userHasAnyRole(user, roles) {
  const userRoles = getRoles(user)
  return intersection(userRoles, roles).length > 0
}

export function userAssignableRoles(user) {
  const roles = getRoles(user)
  const roleNumbers = roles.map((role) => constants.ASSIGN_ROLES_PERMISSIONS_ENUM[role])
  const highestPermissions = Math.max(...roleNumbers)

  return Object.keys(constants.ASSIGN_ROLES_PERMISSIONS_ENUM).filter(
    (key) => constants.ASSIGN_ROLES_PERMISSIONS_ENUM[key] <= highestPermissions
  )
}

export function userCanAccessPage(user, page) {
  const userRoles = getRoles(user)
  if (userRoles) {
    return intersection(userRoles, constants.DASHBOARD_PERMISSIONS_ROLE_MAP[page]).length > 0
  }
  return false
}

export function allAccessiblePages(user) {
  return Object.keys(constants.DASHBOARD_PERMISSIONS_ROLE_MAP).filter((page) => {
    return userCanAccessPage(user, page)
  })
}

export function userHomePage(user) {
  if (user && getRoles(user)) {
    if (getRoles(user).length === 0) {
      return {
        name: constants.PAGES.MANAGEMENT,
        params: {
          currentSettingsPage: constants.SETTINGS_PAGES.PROFILE,
        },
        replace: true,
      }
    }

    if (userIsAssessor(user) || userIsWorkerOrBaseWorker(user)) {
      return {
        name: constants.PAGES.WELCOME,
        replace: true,
      }
    }

    const accessiblePages = allAccessiblePages(user)
      .map((page) => {
        return constants.DASHBOARD_HIERARCHY_ENUM[page]
      })
      .filter((page) => !!page && page !== 0)

    const highestPriority = Math.max(...accessiblePages)

    const homePage = Object.keys(constants.DASHBOARD_HIERARCHY_ENUM).find((key) => {
      return constants.DASHBOARD_HIERARCHY_ENUM[key] === highestPriority
    })

    if (Object.values(constants.SETTINGS_PAGES).includes(homePage)) {
      return {
        name: constants.PAGES.MANAGEMENT,
        params: {
          currentSettingsPage: homePage,
        },
        replace: true,
      }
    }

    return {
      name: homePage,
      replace: true,
    }
  }
}

export function userCanAccessAssessment(
  user,
  reqCustomerID,
  reqCompanyID,
  targetCustomerID,
  targetCompanyID
) {
  if (reqCustomerID === targetCustomerID) {
    if (reqCompanyID === targetCompanyID) {
      return userHasAnyRole(user, [
        constants.ROLES.LB_ASSESSOR,
        constants.ROLES.CUSTOMER_ASSESSOR,
        constants.ROLES.COMPANY_ASSESSOR,
      ])
    } else {
      return userHasAnyRole(user, [constants.ROLES.LB_ASSESSOR, constants.ROLES.CUSTOMER_ASSESSOR])
    }
  } else {
    return userHasAnyRole(user, [constants.ROLES.LB_ASSESSOR])
  }
}

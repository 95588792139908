<template lang="pug">
    div(class="sz-EditRoles",)
        div(class="sz-EditRoles-header")
            div(class="sz-EditRoles-title") {{ title }}
            DropdownSearchable(
                :items="availableOptions",
                @setResult="addConfirm",
                placeholder="Select Role",
                class="sz-EditRoles-input")
        div(class="sz-EditRoles-selector")
            TagList(
                :placeholder="$t('management.companyDashboard.editUserOption.editTagsPlaceholer')"
                :selectedTags="selectedOptions",
                @unselectTag="removeConfirm")
           
</template>

<script>
    import TagList from 'components/Shared/TagList'
    import DropdownSearchable from 'components/Shared/DropdownSearchable'
    import constructModalSettings from 'mixins/modalSettings'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import formValidator from 'mixins/formValidator'

    export default {
        name: 'EditRoles',

        components: {
            TagList,
            DropdownSearchable,
        },

        mixins: [
            constructModalSettings,
            formValidator,
        ],

        props: {
            title: {
                required: true,
                type: String,
            },

            defaultSelectedRoles: {
                required: false,
                type: Array,
                default: () => [],
            },

            options: {
                required: true,
                type: Array,
            },

            userID: {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                selectedOptions: [],
                changeConfirmed: false,
                updateType: null,
                role: null,
            }
        },

        computed: {
            ...mapGetters([
                'updateUserStatus', 
                'auth0User',
            ]),

            availableOptions () {
                return this.options.filter(option => {
                    return !this.selectedOptions.includes(option)
                })
            },

            isAddRole () {
                return this.updateType === constants.COMPANY_SETTINGS.EDIT.ROLES.ADD
            },

            updateSucceeded () {
                return (
                    this.updateUserStatus ===
                    constants.CRUD_OPERATION_STATUSES.SUCCESS
                )
            },
        },

        watch: {
            changeConfirmed: {
                //watches if submit is confirmed by user & calls submit again 
                handler: function () {
                    if (this.changeConfirmed === true) {
                        this.submit()
                    }
                },
                deep: true,
            },
        },

        mounted () {
            this.selectedOptions = this.defaultSelectedRoles

            EventBus.$on('USER_CONFIRMED', () => {
                this.changeConfirmed = true
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.changeConfirmed = false
            })
        },

        methods: {

            addConfirm (role) {
                this.updateType = constants.COMPANY_SETTINGS.EDIT.ROLES.ADD
                this.role = role
                this.confirmModal(this.$t(`management.companyDashboard.updateMessage.addRole`, { role: this.role}))
            },

            removeConfirm (role) {
                this.updateType = constants.COMPANY_SETTINGS.EDIT.ROLES.REMOVE
                this.role = role
                this.confirmModal(this.$t(`management.companyDashboard.updateMessage.removeRole`,{ role: this.role }))
            },

            successMessage () {
                return this.isAddRole
                    ? this.$t(`management.companyDashboard.updateMessage.addRoleSuccess`,{ role: this.role })
                    : this.$t(`management.companyDashboard.updateMessage.removeRoleSuccess`,{ role: this.role })
            },

            async submit () {
                let roles = {}
                roles.role = this.role 
                await this.$store.dispatch('updateRoleToUser', {
                    userID: this.userID,
                    role: roles,
                    updateType: this.updateType,
                })

                if (this.updateSucceeded) {
                    this.successModal(
                        this.successMessage(), 
                        true, 
                        () => this.changeConfirmed = false)
                    this.$store.dispatch('getAllUsersInCompany')

                    this.isAddRole
                        ? this.selectedOptions.push(this.role)
                        : (this.selectedOptions = this.selectedOptions.filter(
                              item => item !== this.role
                          ))
                } else {
                    this.failModal(
                        this.$t('pleaseTryAgain'), 
                        this.$t('somethingWentWrong'), 
                        () => this.changeConfirmed = false)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-EditRoles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        &.twoLineHeight {
            height: 50%;
        }

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }

        &-title {
            @extend %font-topbar-heading;
            color: $color-white;
            margin-right: 1rem;
        }

        &-input {
            width: 15.5rem;
        }

        &-selectAll {
            display: flex;
            @extend %font-content-unselectable;
            color: $color-white;
            align-items: center;
            margin: 0.25rem 1rem;
            &-checkbox {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                &-checked {
                    background-color: $color-box-silver;
                }
            }
        }

        &-selector {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }
    }
</style>
<template lang="pug">
    div(class="sz-FormSectionDropdownSingleSelect")
        div(class="sz-FormSectionDropdownSingleSelect-label") 
            span(
                v-if="required",
                class="sz-FormSectionDropdownSingleSelect-label-required") {{ $t('form.required') }}
            span(class="sz-FormSectionDropdownSingleSelect-label") {{ title }}

        div(
            v-click-away="closeDropdown",
            @click.stop="openDropdown",
            class="sz-FormSectionDropdownSingleSelect-box"
            :class="`sz-FormSectionDropdownSingleSelect-box ${textSize}`")
            div(class="sz-FormSectionDropdownSingleSelect-selectedOption") {{ selectedOptionTitle }}
            SvgIcon(
                icon="arrow",
                class="sz-FormSectionDropdownSingleSelect-arrow",
                :class="`sz-FormSectionDropdownSingleSelect-arrow-` + (isOpen ? 'up' : 'down')")
        div(class="sz-FormSectionDropdownSingleSelect-list")
            transition(name="slide")
                div(
                    v-show="isOpen",
                    class="sz-FormSectionDropdownSingleSelect-options")
                    div(
                        v-for="option in options",
                        :key="option.type",
                        @click="selectOption(option.type)",
                        class="sz-FormSectionDropdownSingleSelect-option",
                        :class="`sz-FormSectionDropdownSingleSelect-option-` + (selectRestricted ? \
                        (option.selectable ? 'select' : 'no-select') : '')")
                        div(class="sz-DropdownMultiSelect-option-text") {{ option.name }}
</template>

<script>
    import { cloneDeep } from 'lodash'
    import { ClickAway } from 'directives/clickAway'

    import SvgIcon from 'components/Shared/SvgIcon'
    import Vue from 'vue'

    export default {
        name: "FormSectionDropdownSingleSelect",

        components: {
            SvgIcon,
        },

        directives: {
            ClickAway,
        },

        props: {
            title: {
                required: true,
                type: String,
            },

            options: {
                type: Array,
                required: true,
            },

            defaultSelectedOption: {
                required: false,
                type: String,
                default: null,
            },

            placeholder: {
                required: true,
                type: String,
            },

            selectRestricted: {
                required: false,
                type: Boolean,
                default: false,
            }, 

            textSize: {
                required: false,
                type: String,
                default: "regular",
            },

            required: {
                required: false,
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                selectedOption: undefined,
                isOpen: false,
                clearingSelectedOption: false,
            }
        },

        computed: {
            selectedOptionTitle () {
                let selectedOption = this.options.find((option) => {
                    return option.type === this.selectedOption
                })
                return selectedOption ? selectedOption.name : this.placeholder
            },
        },

        watch: {
            selectedOption () {
                if (!this.clearingSelectedOption) {
                    const newSelectedOption = cloneDeep(this.selectedOption)
                    this.$emit('updateSelectedOption', newSelectedOption)
                }
            },
        },

        mounted () {
            this.selectedOption = this.defaultSelectedOption
        },

        methods: {
            openDropdown () {
                this.isOpen = true
            },

            closeDropdown () {
                this.isOpen = false
            },

            selectOption (option) {
                this.selectedOption = option
            },

            clearSelectedOption () {
                this.clearingSelectedOption = true
                this.selectedOption = null
                this.clearingSelectedOption = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionDropdownSingleSelect {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        position: relative;

        &-label {
            @extend %font-topbar-heading;
            font-size: 12px;
            color: $color-white;

            &-required {
                color: red
            }
        }

        &-box {
            height: 1.65rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $color-lifebooster-light-green;
            cursor: pointer;
            @extend %font-content;

            &.heading {
                @extend %font-topbar-heading;
                font-size: 18px;
                padding: 0.3rem 0;
            }
        }
        &-arrow {
            margin-top: .3rem;
            transition: 0.5s;

            &-up {
                margin-bottom: 0.4rem;
                transform: rotate(180deg)
            }
        }

        &-list {
            width: 100%;
        }

        &-options {
            background-color: $color-body-bg;
            z-index: $layer-popover-z-index;
            box-shadow: $box-shadow;
            color: $color-white;
            width: inherit;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-height: 7.4rem;
            overflow-y: auto;
            overflow-x: hidden;
            text-overflow: ellipsis;
            @include scrollbar-widget;
        }
        
        &-option {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 0.2rem 0 0.2rem 0.5rem;
            cursor: pointer;

            &:hover {
                background-color: $color-lifebooster-medium-green;
            }

            &-no-select {
                color: grey;
                pointer-events:none;
                cursor: not-allowed;
            }
        }
    }

    .slide-enter-active {
        transition: all .3s ease;
        max-height: 250px;
    }

    .slide-leave-active {
        transition: all .2s ease;
        max-height: 250px;
    }

    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }

    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
    }
</style>

import AssessmentService from 'services/assessment.service'
import WorkerService from 'services/worker.service'

const motionAssessment = {
  actions: {
    async getAssessmentManifest({ commit }, params) {
      return await AssessmentService.getAssessmentManifest(
        params.currentCustomer,
        params.currentCompany,
        params.workerId,
        params.captureIdx,
        params.assessmentIdx,
        params.module
      )
    },

    async getAssessment({ commit }, params) {
      return await AssessmentService.getAssessment(
        params.currentCustomer,
        params.currentCompany,
        params.workerId,
        params.captureIdx,
        params.assessmentIdx,
        params.module
      )
    },

    async getCapture({ commit }, params) {
      return await AssessmentService.getCapture(
        params.currentCustomer,
        params.currentCompany,
        params.workerId,
        params.captureIdx
      )
    },

    async getAssessmentGraphData({ commit }, params) {
      return await AssessmentService.getAssessmentGraphByRiskType(
        params.currentCustomer,
        params.currentCompany,
        params.workerId,
        params.captureIdx,
        params.assessmentIdx,
        params.motion,
        params.cursorTime,
        params.startTime,
        params.endTime
      )
    },

    async getAssessmentProgress({ commit }, captureID) {
      const status = await AssessmentService.getAssessmentProgress(captureID)
      return status.inProgress
    },

    async makeReAssessment({ commit }, params) {
      return await AssessmentService.makeReAssessment(
        params.currentCustomer,
        params.currentCompany,
        params.workerId,
        params.captureIdx,
        params.assessmentIdx,
        params.details
      )
    },

    async requestExport({ commit }, params) {
      return await AssessmentService.requestExport(
        params.currentCustomer,
        params.currentCompany,
        params.riskModules,
        params.userIDs,
        params.timespan,
        params.email
      )
    },

    async getExportFile({ commit }, params) {
      return await AssessmentService.getExportFile(
        params.currentCustomer,
        params.currentCompany,
        params.exportID
      )
    },

    async toggleCaptureProgressVisibility({ commit }, params) {
      return await AssessmentService.toggleCaptureProgressVisibility(
        params.captureID,
        params.details
      )
    },

    async updateAggregationAllowance({ commit, rootGetters }, params) {
      return await AssessmentService.updateAggregationAllowance(
        rootGetters.currentCustomer,
        rootGetters.currentCompany,
        params.assessmentsInfo,
        params.aggregationAllowed
      )
    },

    async getAssessmentPageMetadataFromCapture({ commit, rootGetters }, params) {
      return await AssessmentService.getAssessmentPageMetadataFromCapture(params.captureID)
    },

    async getAssessmentPageMetadataFromAssessment({ commit, rootGetters }, params) {
      return await AssessmentService.getAssessmentPageMetadataFromAssessment(params.assessmentID)
    },

    async updateTimezone({ commit }, params) {
      return await AssessmentService.updateTimezone(
        params.customerID,
        params.companyID,
        params.userID,
        params.captureID,
        params.metadataID,
        params.timezone
      )
    },

    async uploadTaskBreakdown({ commit }, params) {
      return await AssessmentService.uploadTaskBreakdown(
        params.customerID,
        params.companyID,
        params.userID,
        params.captureID,
        params.taskBreakdown
      )
    },

    async downloadTaskBreakdown({ commit }, params) {
      return await AssessmentService.downloadTaskBreakdown(
        params.customerID,
        params.companyID,
        params.userID,
        params.captureID
      )
    },

    async downloadTaskExport({ commit }, params) {
      return await AssessmentService.downloadTaskExport(
        params.customerID,
        params.companyID,
        params.captureID
      )
    },

    async downloadRecordingLogs({ commit }, params) {
      return await AssessmentService.downloadRecordingLogs(
        params.customerID,
        params.companyID,
        params.userID,
        params.captureID
      )
    },

    async processCapture({ commit }, params) {
      return await AssessmentService.processCapture(
        params.customerID,
        params.companyID,
        params.userID,
        params.captureID,
        params.retryAttempts
      )
    },
  },
}
export default motionAssessment

<template lang="pug">
    div(
        class="sz-TagList",
        :class="height")
        div(
            v-if="noSelectedTags",
            class="sz-TagList-placeholder") {{ placeholder }}
        div(class="sz-TagList-tags")
            div(
                v-for="tag of selectedTags",
                :key="tag",
                class="sz-TagList-tag")
                div(class="sz-TagList-tag-wrapper")
                    div(class="sz-TagList-tag-name") {{ tag }}
                    div(
                        @click.stop="unselectTag(tag)"
                        class="sz-TagList-tag-delete")
                        SvgIcon(
                            icon="plus",
                            width="1rem",
                            height="1rem",
                            class="sz-TagList-tag-delete-button")
    
</template>

<script>
    import SvgIcon from './SvgIcon'
    export default {
        name: "TagList",

        components: {
            SvgIcon,
        },

        props: {
            selectedTags: {
                type: Array,
                required: true,
            },

            height: {
                type: String,
                required: false,
                default: '',
            }, 

            placeholder: {
                type: String,
                required: false,
                default: function () {
                    return this.$t(`modals.modalEditWidget.tagPlaceholder`)
                },
            },
        },

        computed: {
            noSelectedTags () {
                return !this.selectedTags || this.selectedTags.length === 0
            },
        },

        methods: {
            unselectTag (tag) {
                this.$emit('unselectTag', tag)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-TagList {
        width: 100%;
        max-height: 8rem;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar-widget;

        &.twoLineHeight {
            max-height: 4rem;
        }

        &-placeholder {
            font-style: italic;
            color: #A1A2A3;
            height: 1.95rem;
            display: flex;
            align-items: center;
        }

        &-tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap-reverse;
        }

        &-tag {
            display: flex;
            align-items: center;
            padding: 0.2rem 0.3rem;
            margin: 0.125rem;
            border-radius: 3px;
            background-color: $color-lifebooster-extra-light-green;
            cursor: default;
            color: $color-white;
            @extend %font-content;

            &-wrapper {
                display: flex;
                align-items: center;
            }

            &-name {
                padding-right: 0.25rem;
                text-align: left;
            }

            &-delete {
                width: 0.5rem;
                cursor: pointer;
                padding: 0.125rem;

                &-button {
                    margin-top: 0.3rem;
                    width: 0.5rem;
                    transform: rotate(45deg);
                    color: $color-white;
                }
            }
        }
    }

</style>
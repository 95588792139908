<template lang="pug">
    div(
        id="tooltipPointer",
        class="sz-Tooltip-container",
        :class="tooltipIndex")
        div(
            class="sz-TooltipPointer",
            :class="tooltipClasses")
</template>

<script>

    export default {
        props: {
            tooltipPointerSettings: {
                type: Object,
                required: false,
                default: function () {
                    return {
                        color: 'dark',
                        direction: 'down',
                    }
                },
            },
        },

        computed: {
            tooltipClasses () {
                return Object.values(this.tooltipPointerSettings)
            },

            tooltipIndex () {
                let indexClass = 'tooltipIndex'

                if (this.tooltipPointerSettings.indexClass) {
                    indexClass = this.tooltipPointerSettings.indexClass
                }

                return indexClass
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-Tooltip-container {
        width: 0.6rem;
        height: 0.6rem;
    }

    .tooltipIndex {
        z-index: $layer-tooltip-z-index - 1;
    }

    .index-lower-than-modal {
        z-index: $layer-tooltip-z-index-lower-than-modal - 1 !important;
    }

    .sz-TooltipPointer {
        z-index: $layer-tooltip-z-index;
        transform: rotate(45deg);
        width: 0.6rem;
        height: 0.6rem;
        background-color: $color-truncated-tooltip; //default to dark
        border-radius: 2px;
    }

    .light {
        background-color: $color-tooltip-light;
    }

    .dark {
        background-color: $color-tooltip-dark;
    }

    .up {
        box-shadow: -0.125rem -0.125rem 0.125rem rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        position: relative;
        top: 0.125rem;
    }

    .down {
        box-shadow: 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        position: relative;
        top: -0.125rem;
    }

    .left {
        box-shadow: -0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        position: relative;
        left: 0.125rem;
    }

    .right {
        box-shadow: 0.125rem -0.125rem 0.125rem rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        position: relative;
        left: -0.125rem;
    }
</style>

import axios from 'axios'
import { getInstance } from 'auth/index'
import buildUrl from 'build-url'

const ApiService = {
    async init(baseURL) {
        axios.defaults.baseURL = baseURL

        const authService = getInstance()
        axios.interceptors.response.use(undefined, function (err) {
            return new Promise(function (resolve, reject) {
                if (err.response.status === 401 && authService.isAuthenticated) {
                    // if you ever get an unauthorized, logout the user
                    console.log('not authorized')
                    // authService.logout({
                    //     returnTo: window.location.origin,
                    // })
                }
                throw err
            })
        })
    },

    removeHeader() {
        axios.defaults.headers.common.Authorization = {}
    },

    setAuthHeader (token) {
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }
        else {
            axios.defaults.headers.common['Authorization'] = ''
        }
    },

    get(resource, params) {
        const url = buildUrl('', {
            path: resource,
            disableCSV: true,
            queryParams: params,
        })
        return axios.get(url)
    },

    getPages(resource, params, headers) {
        const url = buildUrl('', {
            path: resource,
            disableCSV: true,
            queryParams: params,
        })

        let config = {
            headers: {},
        }
        if (headers && headers.continuationToken){
            config.headers['x-lb-continuation-token'] = headers.continuationToken
        }

        return axios.get(url, config)
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    patch(resource, data) {
        return axios.patch(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)
    },
}

export default ApiService

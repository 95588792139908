<template lang="pug">
    div(class="sz-ListSelectedUser") 
        div(class="sz-ListSelectedUser-label") {{ $t(`management.companyDashboard.selectedUsers`) }}
        div(class="sz-ListSelectedUser-list")
            div(class="sz-ListSelectedUser-list-headers")
                div(class="sz-ListSelectedUser-list-header") {{ $t(`management.companyDashboard.userTable.headers.email`) }}
                div(class="sz-ListSelectedUser-list-header") {{ $t(`management.companyDashboard.userTable.headers.firstName`) }}
                div(class="sz-ListSelectedUser-list-header") {{ $t(`management.companyDashboard.userTable.headers.lastName`) }}
            div(
                class="sz-ListSelectedUser-list-user",
                v-for="user in users") 
                    div(class="sz-ListSelectedUser-list-user-info") {{ user.email }}
                    div(class="sz-ListSelectedUser-list-user-info") {{ user.firstName }}
                    div(class="sz-ListSelectedUser-list-user-info") {{ user.lastName }}
                    div(
                        @click.stop="removeUser(user.userID)"
                        class="sz-ListSelectedUser-list-user-remove")
                        SvgIcon(
                            icon="plus",
                            width=16,
                            height=16,
                            class="sz-ListSelectedUser-list-user-remove-icon")
</template>

<script>
import SvgIcon from 'components/Shared/SvgIcon'
export default {
    name: "ListSelectedUser",

    components: {
        SvgIcon,
    }, 

    props: {
        users: {
            required: true,
            type: Array,
        },
    },

    methods: {
        removeUser(userID) {
            this.$emit('updateUsers', userID)
        },
    },
    
}
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ListSelectedUser {
        display: flex;
        flex-direction: column;
        @extend %font-heading;
        font-size: 12px;
        &-label {
            text-align: left;
            margin-bottom: 0.5rem;
        }
        &-list{
            width: 100%;
            overflow: scroll;
            @include scrollbar-widget;
            min-width: 30vw;
            max-height: 25vh;
            &-headers {
                @extend %font-table-heading;
                font-weight: $heading-font-weight;
                grid-template-columns: 
                    minmax(6rem, 1fr) 
                    minmax(3rem, 1fr) 
                    minmax(3rem, 1fr)
                    1rem; 
                display: grid;
                text-align: left;
                margin-bottom: 0.25rem;
                grid-gap: 0.5rem;
            }
            
            &-user {
                @extend %font-content;
                color: $color-unselected-text;
                display: grid;
                grid-template-columns: 
                    minmax(6rem, 1fr) 
                    minmax(3rem, 1fr) 
                    minmax(3rem, 1fr)
                    1rem;
                text-align: left;
                grid-gap: 0.5rem;
                margin-bottom: 0.5rem;
                padding-right: 0.25rem;
                &-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &-remove-icon {
                    transform: rotate(45deg);
                    color: $color-white;;
                }
            }
        }
    }
</style>
<template lang="pug">
    div(class="sz-ModalBulkEditAssessments")
        div(
            v-if="updatingAssessmentTag",
            class="sz-ModalBulkEditAssessments-loading")
            LoadingSpinner
        div(class="sz-ModalBulkEditAssessments-sectionSettings-label") {{ $t(`management.assessmentDashboard.assignAssessmentTags.edit`) }}
        div(class="sz-ModalBulkEditAssessments-options")
            div(class="sz-ModalBulkEditAssessments-sectionSettings")
                div(class="sz-ModalBulkEditAssessments-editType")
                    div(
                        @click="assignSelected()",
                        class="sz-ModalBulkEditAssessments-editType-button",
                        :class="`sz-ModalBulkEditAssessments-editType-button-` + (isSelectAssign ? 'checked ': 'unchecked')")
                    div(class="sz-ModalBulkEditAssessments-editType-label") {{ $t(`management.assessmentDashboard.assignAssessmentTags.editModal.assign`) }}
                    div(
                        @click="removeSelected()",
                        class="sz-ModalBulkEditAssessments-editType-button",
                        :class="`sz-ModalBulkEditAssessments-editType-button-` + (isSelectRemove ? 'checked ': 'unchecked')")
                    div(class="sz-ModalBulkEditAssessments-editType-label") {{ $t(`management.assessmentDashboard.assignAssessmentTags.editModal.remove`) }}
                FormSectionTagSelection(
                    class="sz-ModalBulkEditAssessments-sectionSettings-input-select",
                    :options="tagOptions",
                    :selectAll="true",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.editModal.assessmentTags`)",
                    @updateSelectedTags="updateAssessmentTags")
                ListSelectedAssessments(
                    :assessments="selectedAssessments",
                    @updateAssessments="updateAssessments")
        div(class="sz-ModalBulkEditAssessments-footer")
            div(
                @click="cancelEdit",
                class="sz-ModalBulkEditAssessments-footer-button \
                    sz-ModalBulkEditAssessments-footer-cancel") {{ $t(`modals.modalEditWidget.cancel`)}}
            div(
                @click="submit"
                type="submit",
                class="sz-ModalBulkEditAssessments-footer-button \
                    sz-ModalBulkEditAssessments-footer-save") {{ $t(`modals.modalEditWidget.save`)}}
</template>

<script>
import { mapGetters } from 'vuex'
import ListSelectedAssessments from './ModalEditAssessment/ListSelectedAssessments'
import constants from 'helpers/constants'
import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
import LoadingSpinner from '../Shared/LoadingSpinner'
import EventBus from 'src/eventBus'
import formValidator from 'mixins/formValidator'
import constructModalSettings from 'mixins/modalSettings'
import { cloneDeep } from 'lodash'

export default {
  name: 'ModalBulkEditAssessments',

  components: {
    ListSelectedAssessments,
    FormSectionTagSelection,
    LoadingSpinner,
  },

  mixins: [formValidator, constructModalSettings],

  props: {
    assessments: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      selectedAssessments: [],
      newSettings: {},
      showConfirmation: true,
      selectEditType: constants.ASSESSMENT_SETTINGS.ASSIGN,
      tagMessages: {},
    }
  },

  computed: {
    ...mapGetters([
      'allAssessmentTags',
      'updatingAssessmentTag',
      'updateAssessmentTagStatus',
      'assessmentTagMapper',
    ]),

    tagOptions() {
      return this.allAssessmentTags.map((tag) => tag.name)
    },

    updateSucceeded() {
      return (
        this.updateAssessmentTagStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS &&
        this.settingsHaveChanged
      )
    },

    isUserConfirmed() {
      return this.showConfirmation === false
    },

    isSelectAssign() {
      return this.selectEditType === constants.ASSESSMENT_SETTINGS.ASSIGN
    },

    isSelectRemove() {
      return this.selectEditType === constants.ASSESSMENT_SETTINGS.REMOVE
    },

    settingsHaveChanged() {
      return Array.isArray(this.newSettings.assessmentTags)
    },
  },

  watch: {
    showConfirmation: {
      //watches if submit is confirmed by user & calls submit again
      handler: function () {
        if (this.isUserConfirmed) {
          this.submit()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.selectedAssessments = this.assessments

    EventBus.$on('USER_CONFIRMED', () => {
      this.showConfirmation = false
    })

    EventBus.$on('USER_CANCELLED', () => {
      this.showConfirmation = true
    })
  },

  methods: {
    cancelEdit() {
      this.$emit('close')
    },

    updateAssessmentTags(tags) {
      this.tagMessages = tags
      this.newSettings.assessmentTags = this.assessmentTagNameToID(tags)
    },

    updateAssessments(assessmentMetadataID) {
      this.selectedAssessments = this.selectedAssessments.filter(
        (assessment) => assessment.assessmentMetadataID !== assessmentMetadataID
      )
      EventBus.$emit('UPDATE_SELECTED_ASSESSMENTS', assessmentMetadataID)
    },

    assignSelected() {
      this.selectEditType = constants.ASSESSMENT_SETTINGS.ASSIGN
    },

    removeSelected() {
      this.selectEditType = constants.ASSESSMENT_SETTINGS.REMOVE
    },

    submit() {
      if (this.settingsHaveChanged) {
        this.showConfirmation
          ? this.confirmModal(
              this.confirmMessage().title,
              this.confirmMessage().message,
              this.confirmMessage().list
            )
          : this.saveUpdate()
      }
    },

    async saveUpdate() {
      if (this.isSelectAssign) {
        await this.$store.dispatch('bulkAssignAssessmentTags', this.formatUpdateInfo())
      } else {
        await this.$store.dispatch('bulkRevokeAssessmentTags', this.formatUpdateInfo())
      }

      this.updateSucceeded
        ? this.successModal(
            this.$t(
              'management.assessmentDashboard.assignAssessmentTags.editModal.updateMessage.checkLaterMessage'
            ),
            true,
            undefined,
            () => this.$store.dispatch('closeAllModals'),
            this.$t(
              'management.assessmentDashboard.assignAssessmentTags.editModal.updateMessage.checkLaterTitle'
            )
          )
        : this.failModal(
            this.$t('pleaseTryAgain'),
            this.$t('somethingWentWrong'),
            () => (this.showConfirmationModal = true)
          )
    },

    // Formats all relevant information into an object that will be consumable by the 'bulkAssignAssessmentTags' and 'bulkRevokeAssessmentTags' API call
    formatUpdateInfo() {
      let assessmentsInfo = cloneDeep(this.selectedAssessments).map((assessment) => {
        return {
          captureID: assessment.captureID,
          assessmentID: assessment.id,
        }
      })

      return {
        assessmentsInfo: assessmentsInfo,
        assessmentTagIDs: this.newSettings.assessmentTags,
      }
    },

    confirmMessage() {
      if (this.isSelectAssign) {
        return {
          title: this.$t(
            `management.assessmentDashboard.assignAssessmentTags.editModal.assignConfirm`
          ),
          message: this.$t(
            'management.assessmentDashboard.assignAssessmentTags.editModal.assignMessage'
          ),
          list: this.tagMessages,
        }
      } else {
        return {
          title: this.$t(
            `management.assessmentDashboard.assignAssessmentTags.editModal.removeConfirm`
          ),
          message: this.$t(
            'management.assessmentDashboard.assignAssessmentTags.editModal.removeMessage'
          ),
          list: this.tagMessages,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.sz-ModalBulkEditAssessments {
  width: 50vw;
  max-height: 94vh;
  color: $color-white;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar-widget;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &-edit {
    margin-bottom: 1rem;
  }

  &-sectionSettings {
    color: $color-white;
    margin-bottom: 1rem;
    font-size: 12px;

    &-label {
      @extend %font-heading;
      font-size: 15px;
      padding-top: 0.5rem;
      padding-bottom: 1rem;
    }

    &-input {
      &-select {
        margin-bottom: 1rem;
      }
    }
  }

  &-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-table-row-silver;
    opacity: 0.5;
    filter: blur(1px);
    z-index: $layer-modal-loader-z-index;
  }

  &-editType {
    @extend %font-topbar-heading;
    display: flex;
    margin-bottom: 1rem;
    font-size: 12px;

    &-label {
      margin-right: 1rem;
    }

    &-button {
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.125rem 0.3rem;
      border: $button-border;
      align-self: center;

      &-checked {
        background-color: $color-box-silver;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;

    &-button {
      @extend %font-topbar-heading;
      color: $color-white;
      margin: 0 1rem;
      cursor: pointer;
      font-size: 12px;
    }

    &-save {
      @extend %button-layout;
      background-color: $color-lifebooster-light-green;
      padding: 0.5rem 1rem;
      border-radius: 0;
    }
  }
}
</style>

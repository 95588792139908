<template lang="pug">
    div(class="sz-LoadingSpinner")
        div(
            :class="height"
            class="spinner")
            div(:class="color")
            div(:class="color")
            div(:class="color")
            div(:class="color")

</template>

<script>
    export default {
        name: "LoadingSpinner",

        props: {
            color: {
                required: false,
                type: String,
                default: '',
            },
            height: {
                required: false,
                type: String,
                default: 'medium',
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .spinner {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .spinner div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: $color-body-bg;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .button {
        height: 1rem;
    }

    .button div {
        top: 0.25rem;
    }

    .small {
        height: 1.5rem;
    }

    .small div {
        top: 4px;
    }

    .light {
        background: $color-box-silver !important;
    }

    .spinner div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .spinner div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .spinner div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .spinner div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }
</style>
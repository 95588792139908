const validTimezonesCanada = [
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
]

const validTimezonesUS = [
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
]

const validTimezonesAmerica = ['America/Virgin', 'America/Guadeloupe', 'America/Vancouver']

const validTimezonesOCE = [
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
]

const validTimezonesAll = [
  ...validTimezonesCanada,
  ...validTimezonesUS,
  ...validTimezonesAmerica,
  ...validTimezonesOCE,
]

export const timezones = validTimezonesAll

<template lang="pug">
    div(class="sz-ModalFailure")
        div(class="sz-ModalFailure-message") {{ message }}
        div(class="sz-ModalFailure-reason") {{ reason }}
        div(class="sz-ModalFailure-footer")
            div(
                @click.stop="close",
                class="sz-ModalFailure-button") {{ $t(`modals.modalInvalidFormInput.okay`) }}
</template>

<script>
    import constants from 'helpers/constants'

    export default {
        name: "ModalFailure",

        props: {
            message: {
                type: String,
                required: false,
                default: () => this.$t('defaultFailureMessage'),
            },

            reason: {
                type: String,
                required: true,
            },

            duration: {
                type: Number,
                required: false,
                default: constants.MESSAGE_TIMEOUT,
            },

            dismissible: {
                type: Boolean,
                required: false,
                default: true,
            },
        },

        data () {
            return {
                timer: null,
            }
        },

        mounted () {
            this.showMessage()
        },

        methods: {
            close () {
                if (this.timer) {
                    clearTimeout(this.timer)
                }
                this.$emit('close')
            },

            showMessage () {
                if (!this.dismissible) {
                    this.timer = setTimeout(() => this.close(), this.duration)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ModalFailure {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 8rem;
        padding: 0.5rem;
        color: $color-white;

        &-message {
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 1rem;
        }

        &-reason {
            font-size: 12px;
            padding-bottom: 1rem;
            white-space: pre-wrap;
        }

        &-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &-button {
            @extend %button-layout;
            @extend %font-topbar-heading;

            color: $color-white;
            cursor: pointer;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            border-radius: 0;

            &:hover {
                background-color:  lighten($color-lifebooster-light-green, 10%);
            }
        }
    }
</style>

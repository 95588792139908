import constants from 'helpers/constants'

export function bSearch(arr, predicate) {
    let l = 0
    let r = arr.length

    while (l + 1 < r) {
        // right shift (l+r) gives the integral part of the quotient (l+r)/2
        var mid = (l + r) >> 1
        if (predicate(arr[mid])) {
            l = mid
        } else {
            r = mid
        }
    }
    return r
}

export function celsiusToFahrenheit(celsius) {
    if (!celsius) return null
    return ((celsius * 9) / 5 + 32)
}

export function fahrenheitToCelsius(fahrenheit) {
    if (!fahrenheit) return null
    return ((fahrenheit - 32) * 5)/9
}

export function secondsToMinutes(time) {
    return Math.round(time / 60)
}

export function formatRiskScore(riskScore, riskScoreType) {
    return riskScoreType == constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY ? ('000' + Math.round(riskScore * 1000)).slice(-3) : Math.trunc(riskScore)
}

export function splitFirstNameLastName(fullName){
    let trimmedName = fullName.trim()
    let spaceIdx = trimmedName.indexOf(" ")
    return {
        firstName: trimmedName.substring(0, spaceIdx),
        lastName: trimmedName.substring(spaceIdx).trim(),
    }
}

/**
 * Returns a new HTML body where emails have been converted to a hyperlink
 * IMPORTANT: If an email in the HTML already has a <a href> tag, the <a href> tag will be duplicated
 * @param  {[string]} email  an email
 * @param  {[string]} elemID the ID of the div element containing the email
 */
export function convertEmailsInHTMLBodyToHyperLinks(htmlBody) {
    // Inspired by: https://stackoverflow.com/questions/8644428/how-to-highlight-text-using-javascript
    // Regex from: https://stackoverflow.com/questions/42407785/regex-extract-email-from-strings
    let newHtmlBody = htmlBody

    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    const matches = newHtmlBody.match(emailRegex)

    if (matches) {
        matches.forEach(email => {
            let emailIndex = htmlBody.indexOf(email)

            newHtmlBody = newHtmlBody.substring(0, emailIndex) +
                `<a href='mailto: ${email}'>` +
                newHtmlBody.substring(emailIndex, emailIndex + email.length) +
                "</a>" +
                newHtmlBody.substring(emailIndex + email.length)
        })
    }

    return newHtmlBody
}
/*
Directive
------------------
Directive has 5 possible hooks
1. Bind: Called when the directive is first bound to the element
2. Inserted: Called when the component or element is inserted into it’s parent node. It may not be in the DOM yet.
3. Update: Called when the containing component has updated but potentially before its children do.
4. Component Updated: Called when the containing component has updated but after its children do.
5. Unbind: Called when the directive is unbound from the component or element.
------------------
All hooks take in at minimum of 3 parameters
1. el: The element the directive is bound to. May be undefined.
2. binding: Contains any arguments, values, and modifiers passed to the directive.
3. vnode: This is a virtual node used by Vue’s renderer. Don’t touch this unless you know what you’re doing.
4. oldVnode: The previous version of the above vnode. Only available in the update hooks.
*/

/*
Click Away Directive
---------------------------------
Add this to any component that requires an event listener for a click-away event.

Default: none, this directive requires a parameter.
Usage: v-click-away="someFunction"

This directive takes a funciton as a parameter and executes this function
when the element that this directive is invoked on is clicked.
*/

function bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
        }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
}

function unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
}

export const ClickAway = {
    bind,
    unbind,
}

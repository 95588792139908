import constants from 'helpers/constants'
import { cloneDeep, filter } from "lodash"
import { getInstance } from "auth"
import { userCanAccessPage } from 'helpers/permissionsHelper'
import WorkerService from "services/worker.service"
import CompanyService from "services/company.service"
import AssessmentService from "services/assessment.service"
import LogService from "services/log.service"

const settings = {
    state: {
        currentSettingsPage: constants.SETTINGS_PAGES.PROFILE,
        companyUserListLoading: false,
        exportableUserListLoading: false,
        initiateCaptureUserListLoading: false,
        allCompanies: [],
        companyInfo: undefined,
        companyTagListLoading: false,
        allCompanyUsers: [],
        exportableCompanyUsers: [],
        initiateCaptureUsers: [],
        allCompanyTags: [],
        companyTagMapper: {},
        allCompanySKUs: [],
        updatingUser: false,
        updatingTag: false,
        updatingCompanyInfo: false,
        updateTagStatus: null,
        updateUserStatus: null,
        updateCompanyInfoStatus: null,
        captureProgressListLoading: false,
        captureProgressPage: [],
        captureDetails: [],
        allAssessmentTags: [],
        assessmentTagMapper: {},
        assessmentTagsLoading: false,
        updatingAssessmentTag: false,
        updateAssessmentTagStatus: null,
        allCompanyAssessments: [],
        companyAssessmentListLoading: false,
        assessmentListFilters: {},
        exclusionAssessments: [],
        exclusionAssessmentsContinuationTokens: [],
        exclusionAssessmentsCurrentPageNumber: 0,
        exclusionAssessmentListLoading: false,
        exclusionAssessmentListFilters: {},
        applyDefaultAssessmentTagsUsers: [],
        applyDefaultAssessmentTagsListLoading: false,
        applyDefaultAssessmentTagsFilters: {},
        captureProgressContinuationTokens: [],
        captureProgressCurrentPageNumber: 0,
        captureProgressFilter: {},
    },

    getters: {
        currentSettingsPage: (state) => {
            return state.currentSettingsPage
        },

        companyUserListLoading: (state) => {
            return state.companyUserListLoading
        },

        exportableUserListLoading: (state) => {
            return state.exportableUserListLoading
        },

        initiateCaptureUserListLoading: (state) => {
            return state.initiateCaptureUserListLoading
        },

        companyTagListLoading: (state) => {
            return state.companyTagListLoading
        },

        updatingTag: (state) => {
            return state.updatingTag
        },

        updateTagStatus: (state) => {
            return state.updateTagStatus
        },

        allCompanyUsers: (state) => {
            return state.allCompanyUsers
        },

        exportableCompanyUsers: (state) => {
            return state.exportableCompanyUsers
        },

        initiateCaptureUsers: (state) => {
            return state.initiateCaptureUsers
        },

        updatingUser: (state) => {
            return state.updatingUser
        },

        allCompanies: (state) => {
            return state.allCompanies
        },

        companyInfo: (state) => {
            return state.companyInfo
        },

        allCompanyTags: (state) => {
            return state.allCompanyTags
        },

        companyTagMapper: (state) => {
            return state.companyTagMapper
        },

        allCompanySKUs: (state) => {
            return state.allCompanySKUs
        },

        updateUserStatus: (state) => {
            return state.updateUserStatus
        },

        updatingCompanyInfo: (state) => {
            return state.updatingCompanyInfo
        },

        updateCompanyInfoStatus: (state) => {
            return state.updateCompanyInfoStatus
        },

        captureProgressListLoading: (state) => {
            return state.captureProgressListLoading
        },

        captureProgressPage: (state) => {
            return state.captureProgressPage
        },

        getCaptureDetails: (state) => {
            return state.captureDetails
        },

        allAssessmentTags: (state) => {
            return state.allAssessmentTags
        },

        assessmentTagMapper: (state) => {
            return state.assessmentTagMapper
        },

        assessmentTagsLoading: (state) => {
            return state.assessmentTagsLoading
        },

        updatingAssessmentTag: (state) => {
            return state.updatingAssessmentTag
        },

        updateAssessmentTagStatus: (state) => {
            return state.updateAssessmentTagStatus
        },

        allCompanyAssessments: (state) => {
            return state.allCompanyAssessments
        },

        companyAssessmentListLoading: (state) => {
            return state.companyAssessmentListLoading
        },

        assessmentListFilters: (state) => {
            return state.assessmentListFilters
        },

        exclusionAssessments: (state) => {
            return state.exclusionAssessments
        },

        exclusionAssessmentsContinuationTokens: (state) => {
            return state.exclusionAssessmentsContinuationTokens
        },

        exclusionAssessmentsCurrentPageNumber: (state) => {
            return state.exclusionAssessmentsCurrentPageNumber
        },

        nextExclusionAssessmentsPageToken: (state) => {
            return state.exclusionAssessmentsContinuationTokens[state.exclusionAssessmentsContinuationTokens.length - 1]
        },

        hasNextExclusionAssessmentPage: (state) => {
            return !!state.exclusionAssessmentsContinuationTokens[state.exclusionAssessmentsContinuationTokens.length - 1]
        },

        hasPrevExclusionAssessmentPage: (state) => {
            return state.exclusionAssessmentsContinuationTokens.length > 1
        },

        exclusionAssessmentListLoading: (state) => {
            return state.exclusionAssessmentListLoading
        },

        exclusionAssessmentListFilters: (state) => {
            return state.exclusionAssessmentListFilters
        },

        applyDefaultAssessmentTagsUsers: (state) => {
            return state.applyDefaultAssessmentTagsUsers
        },

        applyDefaultAssessmentTagsListLoading: (state) => {
            return state.applyDefaultAssessmentTagsListLoading
        },

        applyDefaultAssessmentTagsFilters: (state) => {
            return state.applyDefaultAssessmentTagsFilters
        },

        captureProgressContinuationTokens: (state) => {
            return state.captureProgressContinuationTokens
        },

        captureProgressCurrentPageNumber: (state) => {
            return state.captureProgressCurrentPageNumber
        },

        nextCaptureProgressPageToken: (state) => {
            return state.captureProgressContinuationTokens[state.captureProgressContinuationTokens.length - 1]
        },

        currentCaptureProgressPageToken: (state) => {
            return state.captureProgressContinuationTokens[state.captureProgressContinuationTokens.length - 2]
        },

        hasNextCaptureProgressPage: (state) => {
            return !!state.captureProgressContinuationTokens[state.captureProgressContinuationTokens.length - 1]
        },

        hasPrevCaptureProgressPage: (state) => {
            return state.captureProgressContinuationTokens.length > 1
        },

        captureProgressFilter: (state) => {
            return state.captureProgressFilter
        },
    },

    mutations: {
        setCurrentSettingsPage (state, payload) {
            let user = getInstance().user
            state.currentSettingsPage = userCanAccessPage(user, payload) ? payload : constants.SETTINGS_PAGES.PROFILE
        },

        setCompanyUserListLoading (state, payload) {
            state.companyUserListLoading = payload
        },

        setExportableUserListLoading (state, payload) {
           state.exportableUserListLoading = payload
        },

        setInitiateCaptureUserListLoading (state, payload) {
            state.initiateCaptureUserListLoading = payload
        },

        setCompanyTagListLoading(state, payload) {
            state.companyTagListLoading = payload
        },

        setAllCompanyUsers (state, payload) {
            state.allCompanyUsers = payload
        },

        setExportableCompanyUsers (state, payload) {
            state.exportableCompanyUsers = payload
        },

        setInitiateCaptureUsers (state, payload) {
            state.initiateCaptureUsers = payload
        },

        setUpdateUserStatus (state, payload) {
            state.updateUserStatus = payload
        },

        setUpdatingUser (state, payload) {
            state.updatingUser = payload
        },

        setAllCompanies (state, payload) {
            state.allCompanies = payload
        },

        setCompanyInfo (state, payload) {
            state.companyInfo = payload
        },

        setUpdatingTag (state, payload) {
            state.updatingTag = payload
        },

        setUpdateTagStatus (state, payload) {
            state.updateTagStatus = payload
        },

        setAllCompanyTags (state, payload) {
            state.allCompanyTags = payload
        },

        setCompanyTagMapper (state, payload) {
            state.companyTagMapper = payload.reduce(
                (obj, item) => Object.assign(obj, { [item.tagID]: item.name }),
                {}
            )
        },

        setAllCompanySKUs (state, payload) {
            state.allCompanySKUs = payload
        },

        setUpdatingCompanyInfo (state, payload) {
            state.updatingCompanyInfo = payload
        },

        setUpdateCompanyInfoStatus (state, payload) {
            state.updateCompanyInfoStatus = payload
        },

        setCaptureProgressListLoading (state, payload) {
            state.captureProgressListLoading = payload
        },

        setCaptureProgressPage (state, payload) {
            state.captureProgressPage = payload
        },

        setCaptureDetails (state, payload) {
            state.captureDetails = payload
        },

        setAllAssessmentTags (state, payload) {
            state.allAssessmentTags = payload
        },

        setAssessmentTagMapper (state, payload) {
            state.assessmentTagMapper = payload.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.name }),
                {}
            )
        },

        setAssessmentTagsLoading (state, payload) {
            state.assessmentTagsLoading = payload
        },

        setUpdatingAssessmentTag (state, payload) {
            state.updatingAssessmentTag = payload
        },

        setUpdateAssessmentTagStatus (state, payload) {
            state.updateAssessmentTagStatus = payload
        },

        setAllCompanyAssessments (state, payload) {
            state.allCompanyAssessments = payload
        },

        setCompanyAssessmentListLoading (state, payload) {
            state.companyAssessmentListLoading = payload
        },

        setAssessmentListFilters (state, payload) {
            state.assessmentListFilters = payload
        },

        setExclusionAssessments (state, payload) {
            state.exclusionAssessments = payload
        },

        appendExclusionAssessmentsContinuationTokens (state, payload) {
            state.exclusionAssessmentsContinuationTokens.push(payload)
        },

        removeNextAndCurrentExclusionAssessmentsContinuationTokens (state, payload) {
            state.exclusionAssessmentsContinuationTokens.pop()
            state.exclusionAssessmentsContinuationTokens.pop()
        },

        resetExclusionAssessmentsContinuationTokens (state) {
            state.exclusionAssessmentsContinuationTokens = []
        },

        setExclusionAssessmentsCurrentPageNumber (state, payload) {
            state.exclusionAssessmentsCurrentPageNumber = payload
        },

        setExclusionAssessmentListLoading (state, payload) {
            state.exclusionAssessmentListLoading = payload
        },

        setExclusionAssessmentListFilters (state, payload) {
            state.exclusionAssessmentListFilters = payload
        },

        setApplyDefaultAssessmentTagsUsers (state, payload) {
            state.applyDefaultAssessmentTagsUsers = payload
        },

       setApplyDefaultAssessmentTagsListLoading (state, payload) {
            state.applyDefaultAssessmentTagsListLoading = payload
        },

        setApplyDefaultAssessmentTagsFilters (state, payload) {
            state.applyDefaultAssessmentTagsFilters = payload
        },

        appendCaptureProgressContinuationTokens (state, payload) {
            state.captureProgressContinuationTokens.push(payload)
        },

        removeNextAndCurrentCaptureProgressContinuationTokens (state) {
            state.captureProgressContinuationTokens.pop()
            state.captureProgressContinuationTokens.pop()
        },

        resetContinuationTokens (state) {
            state.captureProgressContinuationTokens = []
        },

        setCaptureProgressCurrentPageNumber (state, payload) {
            state.captureProgressCurrentPageNumber = payload
        },

        setCaptureProgressFilter (state, payload) {
            state.captureProgressFilter = payload
        },
    },

    actions: {
        updateCurrentSettingsPage ({ commit, state }, payload) {
            commit('setCurrentSettingsPage', payload)
        },

        async saveUserDetails ( { commit, rootGetters }, { newSettings, userID }) {
            commit('setUpdatingUser', true)
            let newDetails = cloneDeep(newSettings)
            try {
                newDetails = await WorkerService.updateWorker(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    userID,
                    newDetails)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingUser', false)
        },

        async getAllCompanies({ commit }) {
            let companies = []
            try {
                companies = await CompanyService.getAllCompanies()
            } catch(e) {
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setAllCompanies', companies)
        },

        async getCompanyInfo ({ commit, rootGetters }) {
            let company = {}
            try {
                company = await CompanyService.getCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,)
            } catch (e) {
                console.log(e)
            }

            commit('setCompanyInfo', company)
        },

        async getAllCompanyTags ({ commit, rootGetters }) {
            let allTags = []
            commit('setCompanyTagListLoading', true)
            try {
                allTags = await CompanyService.getAllCompanyTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,)
            } catch (e) {
                console.log(e)
            }
            commit('setCompanyTagListLoading', false)

            commit('setAllCompanyTags', allTags)
            commit('setCompanyTagMapper', allTags)
        },

        async getAllCompanySKUs ({ commit, rootGetters }) {
            let allCompanySKUs = []
            try {
                allCompanySKUs = await CompanyService.getAllCompanySKUs(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,)
            } catch (e) {
                console.log(e)
            }

            commit('setAllCompanySKUs', allCompanySKUs)
        },

        async saveNewUserToCompany ({ commit, dispatch, rootGetters }, { settings, role }) {
            commit('setUpdatingUser', true)
            let user = cloneDeep(settings)
            try {
                await CompanyService.saveNewUserToCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    user,
                    role)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                console.log(e)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
            }
            commit('setUpdatingUser', false)
        },

        async getAllUsersInCompany ({commit, rootGetters}) {
            let users = []
            commit('setCompanyUserListLoading', true)
            try {
                users = await CompanyService.getAllUsersInCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,)
            } catch (e) {
                console.log(e)
            }
            commit('setAllCompanyUsers', users)
            commit('setCompanyUserListLoading', false)
        },

        async getExportableUsersInCompany ({commit, rootGetters}) {
            let users = []
            commit('setExportableUserListLoading', true)
            try {
                users = await WorkerService.getAllAvailableWorkers(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany)
            } catch (e) {
              console.log(e)
            }
            commit('setExportableCompanyUsers', users)
            commit('setExportableUserListLoading', false)
        },

        async getInitiateCaptureUsers ({commit, rootGetters}) {
            let users = []
            commit('setInitiateCaptureUserListLoading', true)
            try {
                users = await WorkerService.getAllAvailableWorkers(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany)
            } catch (e) {
              console.log(e)
            }
            commit('setInitiateCaptureUsers', users)
            commit('setInitiateCaptureUserListLoading', false)
        },

        async saveNewUsersToCompany ({ commit, dispatch, rootGetters }, { newUsers, role }) {
            let users = cloneDeep(newUsers)
            try {
                await CompanyService.uploadUsersToCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    users,
                    role)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                throw(e)
            }

            dispatch('getAllUsersInCompany')
        },

        async bulkEditUserToCompany ({ commit, rootGetters }, users) {
            commit('setUpdatingUser', true)
            try {
                await CompanyService.bulkEditUsersToCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    users)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingUser', false)
        },

        async updateRoleToUser({ commit, rootGetters }, { userID, role, updateType }) {
            commit('setUpdatingUser', true)
            try {
                await WorkerService.updateWorkerRole(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    userID,
                    role,
                    updateType)
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateUserStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }
            commit('setUpdatingUser', false)
        },

        async updateTagToCompany({ commit, rootGetters }, { tagID, tagName }) {
            commit('setUpdatingTag', true)
            try {
                await CompanyService.updateTagToCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    tagID,
                    tagName)
                commit('setUpdateTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingTag', false)
        },

        async saveNewTagToCompany({ commit, rootGetters }, tagName) {
            commit('setUpdatingTag', true)
            try {
                await CompanyService.saveNewTagToCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    tagName)
                commit('setUpdateTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingTag', false)
        },

        async updateCompanyAnonymity({ commit, dispatch, rootGetters}, companyAnon) {
            commit('setUpdatingCompanyInfo', true)
            try {
                await CompanyService.updateCompanyAnonymity(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    companyAnon)
                    commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch(e) {
                commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingCompanyInfo', false)
        },

        async updateCompanyVisibility({ commit, dispatch, rootGetters}, companyVisible) {
            commit('setUpdatingCompanyInfo', true)
            try {
                await CompanyService.updateCompanyVisibility(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    companyVisible)
                    commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch(e) {
                commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingCompanyInfo', false)
        },

        async updateMAPV({ commit, dispatch, rootGetters}, MAPV) {
            commit('setUpdatingCompanyInfo', true)
            try {
                await CompanyService.updateMAPV(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    MAPV)
                    commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch(e) {
                commit('setUpdateCompanyInfoStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingCompanyInfo', false)
        },

        async getFirstCaptureProgressPage({ commit, dispatch, rootGetters }, filterForm) {
            commit('resetContinuationTokens')
            commit('setCaptureProgressFilter', filterForm)
            await dispatch('getNextCaptureProgress')
        },


        async getPrevCaptureProgress({ commit, dispatch }) {
            commit('removeNextAndCurrentCaptureProgressContinuationTokens')
            await dispatch('getNextCaptureProgress')
        },

        async getNextCaptureProgress({ commit, rootGetters }) {
            /*
            * This function takes the last continuation token in the array to retrieve the next page
            * It will append a new continuation token if the API returns one and undefined if there is not another page after
            * It will also set the current page number using the length of the continuation token array
            * getPrevCaptureProgress and getFirstCaptureProgressPage will call this function after they have prepared the continuation array
            * so that the last index will get the correct page
            */
            if (rootGetters.captureProgressContinuationTokens.length == 0 || rootGetters.nextCaptureProgressPageToken) {
                commit('setCaptureProgressListLoading', true)
                try {
                    let captureProgress = await AssessmentService.getCaptureProgress(
                      rootGetters.captureProgressFilter,
                      rootGetters.nextCaptureProgressPageToken)
                      commit('setCaptureProgressPage', captureProgress.data)
                      commit('setCaptureProgressListLoading', false)
                      commit('appendCaptureProgressContinuationTokens', captureProgress.continuationToken)
                      // Need to set it here instead of the getters because we want to control when we should update the page number in the UI component
                      commit('setCaptureProgressCurrentPageNumber', rootGetters.captureProgressContinuationTokens.length)
                } catch (e) {
                  await LogService.send(constants.LOG_LEVELS.ERROR, e)
                  commit('setCaptureProgressListLoading', false)
                }
            }
        },

        async refreshCaptureProgress({ commit, rootGetters }) {
            if (rootGetters.captureProgressContinuationTokens.length == 0 || rootGetters.nextCaptureProgressPageToken) {
                commit('setCaptureProgressListLoading', true)
                try {
                    let captureProgress = await AssessmentService.getCaptureProgress(
                      rootGetters.captureProgressFilter,
                      rootGetters.currentCaptureProgressPageToken)
                      commit('setCaptureProgressPage', captureProgress.data)
                      commit('setCaptureProgressListLoading', false)
                } catch (e) {
                  await LogService.send(constants.LOG_LEVELS.ERROR, e)
                  commit('setCaptureProgressListLoading', false)
                }
            }
        },

        async getAllAssessmentTags({ commit, rootGetters }) {
            let assessmentTags = []
            commit('setAssessmentTagsLoading', true)
            try {
                assessmentTags = await CompanyService.getAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                )
            } catch (e) {
              await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }
            commit('setAssessmentTagsLoading', false)

            commit('setAllAssessmentTags', assessmentTags)
            commit('setAssessmentTagMapper', assessmentTags)
        },

        async createAssessmentTag({ commit, rootGetters }, tagName) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await CompanyService.createAssessmentTag(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    tagName,
                )
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch(e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async updateAssessmentTag({ commit, rootGetters }, { assessmentTagID, tagName }) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await CompanyService.updateAssessmentTag(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    assessmentTagID,
                    tagName)
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async deleteAssessmentTag({ commit, rootGetters }, { assessmentTagID }) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await CompanyService.deleteAssessmentTag(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    assessmentTagID)
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async getAssessmentsFromCompany({ commit, rootGetters }, { orgChartTags, jobs, genders, userIDs, interval }) {
            let assessments = []
            commit('setCompanyAssessmentListLoading', true)
            try {
                assessments = await AssessmentService.getAssessmentsFromCompany(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    orgChartTags,
                    jobs,
                    genders,
                    userIDs,
                    interval,
                )
            } catch (e) {
              await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setCompanyAssessmentListLoading', false)
            commit('setAllCompanyAssessments', assessments)
        },

        async bulkAssignAssessmentTags({ commit, rootGetters}, { assessmentsInfo, assessmentTagIDs }) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await AssessmentService.bulkAssignAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    assessmentsInfo,
                    assessmentTagIDs,
                )

                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async bulkRevokeAssessmentTags({ commit, rootGetters}, { assessmentsInfo, assessmentTagIDs }) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await AssessmentService.bulkRevokeAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    assessmentsInfo,
                    assessmentTagIDs,
                )

                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        updateAssessmentListFilters({ commit, state }, payload) {
            commit('setAssessmentListFilters', payload)
        },

        async getFirstExclusionAssessmentsPage({ commit, dispatch, rootGetters }) {
            commit('resetExclusionAssessmentsContinuationTokens')
            await dispatch('getNextExclusionAssessmentsPage')
        },

        async getPrevExclusionAssessmentsPage({ commit, dispatch }) {
            commit('removeNextAndCurrentExclusionAssessmentsContinuationTokens')
            await dispatch('getNextExclusionAssessmentsPage')
        },

        async getNextExclusionAssessmentsPage({ commit, rootGetters }) {
            if (rootGetters.exclusionAssessmentsContinuationTokens.length === 0 || rootGetters.nextExclusionAssessmentsPageToken) {
                commit('setExclusionAssessmentListLoading', true)
                try {
                    let filterForm = rootGetters.exclusionAssessmentListFilters
                    let assessments = await AssessmentService.getAssessmentsForExclusion(
                        filterForm,
                        rootGetters.nextExclusionAssessmentsPageToken,
                    )
                    commit('setExclusionAssessments', assessments.data)
                    commit('appendExclusionAssessmentsContinuationTokens', assessments.continuationToken)
                    commit('setExclusionAssessmentsCurrentPageNumber', rootGetters.exclusionAssessmentsContinuationTokens.length)
                } catch(e) {
                    commit('setExclusionAssessmentListLoading', false)
                    await LogService.send(constants.LOG_LEVELS.ERROR, e)
                }
                commit('setExclusionAssessmentListLoading', false)
            }
        },

        updateExclusionAssessmentListFilters({ commit, state }, payload) {
            commit('setExclusionAssessmentListFilters', payload)
        },

        async getUsersForApplyDefaultAssessmentTags({ commit, rootGetters}, { orgChartTags, jobs, genders, userIDs }) {
            let users = []
            commit('setApplyDefaultAssessmentTagsListLoading', true)
            try {
                users = await CompanyService.getUsersForApplyDefaultAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    orgChartTags,
                    jobs,
                    genders,
                    userIDs,
                )
            } catch (e) {
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }

            commit('setApplyDefaultAssessmentTagsListLoading', false)
            commit('setApplyDefaultAssessmentTagsUsers', users)
        },

        updateApplyDefaultAssessmentTagsFilters({ commit, state }, payload) {
            commit('setApplyDefaultAssessmentTagsFilters', payload)
        },

        async bulkAddDefaultUsersToAssessmentTags ({ commit, rootGetters }, payload) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await AssessmentService.bulkAddDefaultUsersToAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    payload.assessmentTagIDs,
                    payload.userIDs)
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async bulkRemoveDefaultUsersToAssessmentTags ({ commit, rootGetters }, payload) {
            commit('setUpdatingAssessmentTag', true)
            try {
                await AssessmentService.bulkRemoveDefaultUsersToAssessmentTags(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    payload.assessmentTagIDs,
                    payload.userIDs)
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.SUCCESS)
            } catch (e) {
                commit('setUpdateAssessmentTagStatus', constants.CRUD_OPERATION_STATUSES.FAIL)
                console.log(e)
            }

            commit('setUpdatingAssessmentTag', false)
        },

        async getCaptureDetails({ commit, rootGetters }, payload) {
            try {
                let captureDetails = await AssessmentService.getCaptureDetails(
                    rootGetters.currentCustomer,
                    rootGetters.currentCompany,
                    payload.captureID
                )
                commit('setCaptureDetails', captureDetails)
            } catch (e) {
                await LogService.send(constants.LOG_LEVELS.ERROR, e)
            }
        },
    },
}

export default settings

const modalSettings = {
    methods: {
        constructModalSettings (modalType, clickOutside, modalSpecificSettings, showCloseButton=true, dark=false) {
            return {
                commonSettings: {
                    modalType,
                    clickOutside,
                    showCloseButton,
                    dark,
                },
                modalSpecificSettings,
            }
        },
    },
}

export default modalSettings
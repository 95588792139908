import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from 'vuex-persistedstate'

import individualSummary from './modules/individual-summary.js'
import motionAssessment from './modules/motion-assessment.js'
import senzDashboard from './modules/senz-dashboard.js'
import user from './modules/user.js'
import modal from './modules/modal.js'
import settings from './modules/settings.js'
import deviceManagement from './modules/device-management.js'

Vue.use(VueAxios, axios)
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    individualSummary,
    motionAssessment,
    senzDashboard,
    user,
    modal,
    settings,
    deviceManagement,
  },
})

<template lang="pug">
    div(class="sz-FormSectionToggle")
        span(class="sz-FormSectionToggle-title" v-if="showTitle") {{ title }}
        div(class="sz-FormSectionToggle-toggler")
            span(:class="`sz-FormSectionToggle-toggler-label-` + (falseOption.length == 0 ? '' : 'left')") {{ falseOption }}
            label(
                :for="`formSectionToggle-${title}`",
                class="sz-FormSectionToggle-toggle"
                v-tooltip="tooltipInfo"
                :id="`sz-FormSectionToggle-toggle-${title}`")
                input(
                    @click="onChange",
                    v-model="checked",
                    type="checkbox",
                    class="sz-FormSectionToggle-input",
                    :id="`formSectionToggle-${title}`")
                span(class="sz-FormSectionToggle-slider")
            span(:class="`sz-FormSectionToggle-toggler-label-` + (trueOption.length == 0 ? '' : 'right')") {{ trueOption }}
</template>

<script>
    import { cloneDeep } from 'lodash'
    import { tooltip } from 'directives/tooltip'
    
    export default {
        name: "FormSectionToggle",

        directives: {
            tooltip,
        },

        props: {
            title: {
                required: true,
                type: String,
                default: '',
            },
            defaultChecked: {
                required: true,
                type: Boolean,
            },

            showTitle: {
                type: Boolean,
                required: false,
                default: true,
            },

            options: {
                required: false,
                type: Array,
                validator: (prop) => prop.length === 2,
                default: () => ['',''],
            },
            tooltip: {
              required: false,
              type: String,
              default: () => '',
            },
        },

        data () {
            return {
                checked: null,
            }
        },

        computed: {
            falseOption () {
                return this.options[0]
            },

            trueOption () {
                return this.options[1]
            },

            tooltipInfo () {
                if (this.tooltip) {
                    return {
                        tooltipBodySettings: {
                            view: {
                                color: 'dark',
                                textAlign: 'left',
                                padding: 'extra-padding',
                                width: 'parameters-width',
                            },
                            title: this.tooltip,
                        },

                        body: {
                            placement: 'right',
                        },
                        allowHover: true,
                    }
                }
            },
        },

        mounted () {
            this.checked = this.defaultChecked
        },

        methods: {
            onChange () {
                const newInput = cloneDeep(!this.checked)
                this.$emit('changed', newInput)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-FormSectionToggle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-title {
          @extend %font-topbar-heading;
          font-size: 12px;
          color: $color-white;
          padding: 0.3rem 0;
        }

        &-toggle {
            position: relative;
            display: inline-block;
            width: 2.5rem;
            height: 1.04rem;
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        &-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            height: 1.04rem;
            width: 2.188rem;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 1.42rem;

            &:before {
                position: absolute;
                content: "";
                height: 0.78rem;
                width: 0.78rem;
                left: 0.1665rem;
                bottom: 0.1665rem;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }

        input:checked + .sz-FormSectionToggle-slider {
            background-color: $color-lifebooster-light-green;
        }

        input:focus + .sz-FormSectionToggle-slider {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .sz-FormSectionToggle-slider:before {
            -webkit-transform: translateX(1.08rem);
            -ms-transform: translateX(1.08rem);
            transform: translateX(1.08rem);
        }

        &-toggler {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            @extend %font-topbar-heading;
            color: $color-white;

            &-label {
                &-left {
                    padding-right: 0.5rem;
                }

                &-right {
                    padding-left: 0.5rem;
                }
            }
        }
    }
</style>
